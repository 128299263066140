import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';
import { isDateBetweenDateRange, currentDate } from '../../util/helper';

function requestHeaderFooterData() {
	return { type: types.REQ_HEADER_FOOTER_DATA }
};

function receiveHeaderFooterData(json) {
	return {
		type: types.RECV_HEADER_FOOTER_DATA,
		data: json
	}
};

function receiveHeaderFooterError(json) {
	return {
		type: types.RECV_HEADER_FOOTER_ERROR,
		data: json
	}
};

function filterByDate(data, date) {
	let massageData = Object.assign({}, data);
	let content = massageData.data.result.assets[0].content.Content;
	for (const item in content) {
		if(content[item].navigation_menu_02 && content[item].navigation_menu_02.feature_event_container) {
			let container = content[item].navigation_menu_02.feature_event_container;
			let featureEvents = container.filter(v=> isDateBetweenDateRange(v.online_date, v.offline_date, date)).slice(0, 2);
			content[item].navigation_menu_02.feature_event_container = featureEvents;
		}
	}
	return massageData
}

export function fetchHeaderFooterData() {
	return function (dispatch) {
		dispatch(requestHeaderFooterData());
		return axiosAdapter({
			url: config.json_headerfooter
		})
			.then(function (response) {
				let responseData = response;
				let filteredData = filterByDate(responseData, currentDate());
				return filteredData
			})
			.then(function (data) {
				dispatch(receiveHeaderFooterData(data.data.result.assets[0].content.Content));
			})
			.catch(function (response) {
				dispatch(receiveHeaderFooterError(response.data || response.message));
			})
	}
};
