import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import StaticPages from "../components/StaticPages/";
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
	staticPagesData: state.pageStaticPagesReducer,
	i18n: state.i18n,
	allowCookiesStatusReducer: state.allowCookiesStatusReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(StaticPages);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

const ComponentWithRouter = withRouter(ComponentWithErrorBoundary)

export default ComponentWithRouter;