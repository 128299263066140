// This component is used in 3 events containers, events/hkevents/overseas events
import React, { Component } from 'react';
import PlaceholderComponent from '../Common/PlaceholderComponent';

export default class PageMultiSlider extends Component {
	constructor(props) {
    super(props);
    this.state = {
			sliderInit: false,
			mousePause: false
    };
  }

	togglePause = () =>{
		if (typeof document.getElementById("item-inner-slider").swiper !== "undefined") {
			if (this.state.mousePause) {
				document.getElementById("item-inner-slider").swiper.autoplay.stop();
			} else {
				document.getElementById("item-inner-slider").swiper.autoplay.start();
			}
		}
	}
	changePauseStatus = () => {
		this.setState({
			mousePause: !this.state.mousePause
		}, () => {
			this.togglePause();
		});
	}
	
	initSwiper(_this) {
		let itemSlider = document.getElementById("item-inner-slider");
		if (itemSlider && itemSlider.swiper) {
			itemSlider.swiper.destroy(false, false);
		}
		let autoplayTime = 5000;
		let swiper = new window.Swiper(itemSlider, {
			slidesPerView: 3,
			slidesPerGroup: 3,
			spaceBetween: 40,
			observer: true,
			observeParents: true,
			loop: true,
			simulateTouch: true,
			speed: 800,
			// loopFillGroupWithBlank: true,
			watchSlidesProgress: true,
			autoplay: {
				delay: autoplayTime,
				disableOnInteraction: false,
			},
			navigation: {
				prevEl: ".item-slider__btn--prev",
				nextEl: ".item-slider__btn--next",
			},
			pagination: {
				el: ".item-slider__pagination",
				type: "bullets",
				clickable: true,
			},
			fadeEffect: {
				crossFade: true
			},
			breakpoints: {
				1240: {
					slidesPerView: 2,
					slidesPerGroup: 2,
					spaceBetween: 40,
				},
				768: {
					slidesPerView: 1,
					slidesPerGroup: 1,
					spaceBetween: 40,
				}
			},
			// Disable preloading of all images
			preloadImages: false,
			// Enable lazy loading
			lazy: {
				preloaderClass: 'mf'
			}
		});
		swiper.on("init", function () {
			swiper.update();
		})
		
	}


  componentDidMount() {

		let _this = this;
		let _thisInitSwiper = this.initSwiper;
		if(!_this.state.sliderInit){
			_thisInitSwiper(_this);
      _this.setState({
        sliderInit: true
      });
		}
		this.togglePause();
	}

  render() {

		let cmsData = this.props.cmsData || [];

    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};

		let txt_loading = this.props.loadingTxt || '';

		let item_slider = [];

		for (let key in cmsData) {
			let cmsVal = cmsData[key];
			let sliderImg = cmsVal.image;
			let sliderContentColor = "origin";
			let sliderTitle = cmsVal.event_name;
			let sliderDesc = cmsVal.event_description;
			let sliderLoc = cmsVal.event_location;
			let sliderDate = cmsVal.event_date_period;
			let silderLink = cmsVal.event_url;
			item_slider.push(
				<div className="multi-slider-container swiper-slide" key={'slider'+key}>
					<div className="slider__img"><a href={silderLink} target="_blank"><img src={sliderImg} title={sliderTitle} alt={sliderTitle} className="swiper-lazy" /><PlaceholderComponent loadingClass="mf" loadingTxt={txt_loading}/></a></div>
					<div className={"slider__title slider__title--"+sliderContentColor}><a href={silderLink} target="_blank">{sliderTitle}</a></div>
					<div className={"slider__desc slider__desc--"+sliderContentColor}>{sliderDesc}</div>
					<div className={"slider-location-date"}>
						<div className={"slider__location"}>{sliderLoc}</div>
						<div className={"slider__date"}>{sliderDate}</div>
					</div>
				</div>
			);
		}

    return (
      <div className="item-sliders">
        <div id="item-inner-slider" className="multi-sliders-container item-has-multi-slider">
					<div className={this.state.mousePause ? 'swiper-wrapper pause-active' : 'swiper-wrapper'}>
            {item_slider}
          </div>
          <div tabIndex="0" className={this.state.mousePause ? 'multi-sliders__slider-pause is-active' : 'multi-sliders__slider-pause'} onClick={this.changePauseStatus} onKeyPress={this.changePauseStatus}>
						<div className="pause-button"><span className="visually-hidden">{static_content.pause_btn}</span></div>
          </div>
					<button title={static_content.slider_button_prev} tabIndex="0" onClick={e => { e.preventDefault();}} className="item-slider__btn item-slider__btn--prev"><span className="visually-hidden">{static_content.slider_button_prev}</span></button>
					<button title={static_content.slider_button_next} tabIndex="0" onClick={e => { e.preventDefault();}} className="item-slider__btn item-slider__btn--next"><span className="visually-hidden">{static_content.slider_button_next}</span></button> 
          <div className="item-slider__pagination"></div>
        </div>
      </div>
    );
  }
}