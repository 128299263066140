
import React, { Component } from 'react';

import AppIntroContainer from '../../containers/AppIntroContainer';

import { Helmet } from "react-helmet";


export default class Services extends Component {
  render() {
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'mobileapp' }} />    
        <AppIntroContainer />
      </main>
    );
  }
}
