import { withRouter } from 'react-router-dom'
import config from '../../config';
import React, { Component } from 'react';
import store from '../../store/';
import { fetchSearchAutocomplete, clearSearchAutocomplete } from '../../actions/';
import NumberFormat from 'react-number-format';
import Link from '../../util/Link';
import * as h from '../../util/helper';

class SearchPanel extends Component {
    constructor(props) {
        super(props);
        this.updateSearchAutocompleteStore = h.debounce(this.updateSearchAutocompleteStore, 500);
        
        this.state = {
            keyword: this.props.keyword,
            type: this.props.type,
            sort: this.props.getSearchDataReducer.sort
        };
    }
    
    componentDidMount(){
        this._inputSearch.focus();
        // Fix IE focus not at the end
        let v = this._inputSearch.value;
        this._inputSearch.value = '';
        this._inputSearch.value = v; //
    }

    componentDidUpdate(prevProps) {
        if (this.props.keyword !== prevProps.keyword){
            this.setState({
                keyword: this.props.keyword
            });
        }

        if (this.props.getSearchDataReducer !== prevProps.getSearchDataReducer) {
            if (this.props.getSearchDataReducer.data) {
                if (this.props.getSearchDataReducer.data.q) {
                    this.setState({
                        keyword: this.props.getSearchDataReducer.data.q
                    });
                }                
            }            
        }
    }

    handleChange = (e) => {
        this.setState({
            keyword: e.target.value
        });
        this.updateSearchAutocompleteStore(e.target.value);
    }

    updateSearchAutocompleteStore = (value) => {
        this.setState({
            keyword: value
        });
        if (value === "") {
            store.dispatch(clearSearchAutocomplete());
        } else {
            store.dispatch(fetchSearchAutocomplete({
                q: (value).toLowerCase(),
                cat: 'phr',
                language: config.api_lang_map[this.props.i18n.locale]
            }));
        }
    }

    handleAutoCompleteMouseEnter = (e) => {
        e = e || window.event;
        e.preventDefault();
        const acItems = document.querySelectorAll(".search-panel__input-holder .header-search__autocomplet-grp");
        for (let i = 0; i < acItems.length; i++) {
            if (acItems[i].classList.contains('acActive')) {
                acItems[i].classList.remove('acActive');
            }
        }
    }

    handleAutoCompleteSelection = (e) => {
        e = e || window.event;
        if (e.keyCode === 38 || e.keyCode === 40) { // only handle up and down
            e.preventDefault(); // prevent the mouse cursor move to the left
            let no = -1;
            const acItems = document.querySelectorAll(".search-panel__input-holder .header-search__autocomplet-grp");
            console.log(acItems.length)
            for (let i = 0; i < acItems.length; i++) {
                if (acItems[i].classList.contains('acActive')) {
                    no = i;
                }
            }
            switch (e.keyCode) {
                case 38:
                    // up
                    if (no > 0 && acItems[(no - 1)]) {
                        acItems[no].classList.remove('acActive');
                        acItems[(no - 1)].classList.add('acActive');
                        const prevEle = acItems[(no - 1)].getElementsByTagName('a');
                        if (prevEle[0]) {
                            document.getElementById('searchPanelQ').value = (prevEle[0].innerHTML).trim();
                        }
                    }
                    break;
                case 40:
                default:
                    // down
                    if (no < acItems.length && acItems[(no + 1)]) {
                        if (no !== -1) acItems[no].classList.remove('acActive');
                        acItems[(no + 1)].classList.add('acActive');
                        const prevEle = acItems[(no + 1)].getElementsByTagName('a');
                        if (prevEle[0]) {
                            document.getElementById('searchPanelQ').value = (prevEle[0].innerHTML).trim();
                        }
                    }
                    break;
            }
        }

    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.keyword !== "" || document.getElementById('searchPanelQ').value !== "") {
            this.setState({
                keyword: h.whitelistchars((this.state.keyword !== document.getElementById('searchPanelQ').value ? document.getElementById('searchPanelQ').value : this.state.keyword))
            }, () => {
                // settimeout becos of debouce 500 autocompletesearch
                setTimeout(() => {
                    store.dispatch(clearSearchAutocomplete());
                    this.props.history.push(config.pagename_search + '?type=' + h.filterThenEncode(this.state.type) + '&q=' + h.filterThenEncode(this.state.keyword));
                }, 800);
                
            });
        }
    }

    handleReset = (event) => {
        store.dispatch(clearSearchAutocomplete());
        this.setState({
            keyword: ""
        });
        event.preventDefault();
    }

    handleOptionClick = (itemTxt) => (e) => {
        e.preventDefault();
        document.getElementById('searchPanelQ').value = itemTxt;
        store.dispatch(clearSearchAutocomplete());
        this.submitBtn.click();        
    }
    
    handleOptionChange = (e) => {
        const v = e.target.value || '';
        const { searchData = {} } = this.props.getSearchDataReducer || [];
        this.setState({
            type: v
        }, () => {
            const q = searchData.data.q || this.props.keyword;
            this.props.history.push(config.pagename_search + '?type=' + v + '&q=' + h.filterThenEncode(q));
        });
    }

    get_sort_link = (sort) => {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const { searchData = {} } = this.props.getSearchDataReducer || [];
        let searchQuery = h.filterThenEncode(searchData.data.q) || '';
        let searchSorting = searchData.sort || '';
        let searchType = h.filterThenEncode(searchData.type) || '';
        let searchExcIds = searchData.data.excludeDocumentId || '';

        const ex = (searchExcIds !== '') ? '&excIds=' + h.filterThenEncode(searchExcIds): '';
        let sortby = '';
        let sortname = '';

        switch (sort) {
            case 'latest':
            sortby = '&sort=latest';
            sortname = static_content.searchPanel.txt_sorting_latest;
            break;
            case 'oldest':
            sortby = '&sort=oldest';
            sortname = static_content.searchPanel.txt_sorting_oldest;
            break;
            case 'asc':
            sortby = '&sort=asc';
            sortname = static_content.searchPanel.txt_sorting_asc;
            break;
            case 'desc':
            sortby = '&sort=desc';
            sortname = static_content.searchPanel.txt_sorting_desc;
            break;
            case 'relevancy':
            default:
            sortby = '';
            sortname = static_content.searchPanel.txt_sorting_relevancy;
            break;            
        } 
        return <Link key={sort} to={config.pagename_search + '?q='+searchQuery + '&type='+searchType+ex+sortby} className={"search-panel__sorting-item"+(searchSorting===sort? ' is-active' : '')} > { sortname } </Link>
    }
    render() {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const txtSearchPanel = static_content.searchPanel || {};

        const { searchData = {} } = this.props.getSearchDataReducer || [];
        let searchQuery = searchData.data.q || '';
        let searchType = searchData.type || '';

        const { searchTypeName = [] } = static_content || {};
        const handleOptionChange = this.handleOptionChange;

        const sort_relevancy = this.get_sort_link('relevancy');
        const sort_exh = [this.get_sort_link('asc'), this.get_sort_link('desc')];
        const sort_mi = [this.get_sort_link('latest'), this.get_sort_link('oldest')];

        const {search_result = {}} = this.props.metaReducer.data[this.props.i18n.locale] || {};
        const searchTypesElems = Object.keys(searchTypeName || {}).map(function (key, index) {
            let txt = searchTypeName[key];
            let val = key;
            let id = "search-panel-filter-" + index;
            let defaultChecked = (key === searchType) ? true : false;
            
            return (<div key={key} className="search-panel__filter">
                    <input id={id} onChange={handleOptionChange} name="type" type="radio" key={id} id={id} defaultValue={val} checked={defaultChecked ? 'checked' : ''} />
                    <label htmlFor={id}>{txt}<span className="visually-hidden">At Search Page</span></label>
                </div>);
        });



        const { headerSearchReducer } = this.props;
        const {suggestions = {}} = headerSearchReducer.data || {};

        let autoCompleteItemsElems = [];
        
        const _that = this;
        if (suggestions.length) {
        autoCompleteItemsElems = Object.keys(suggestions || {}).map(function (key) {
            let value = suggestions[key];
            let itemTxt = value.keyword;

            return (<div key={key} className="header-search__autocomplet-grp">
            <a onClick={_that.handleOptionClick(itemTxt)} className="header-search__autocomplet-item"> { itemTxt } </a>
            </div>);
        });
        }


        const { getSearchResultReducer } = this.props;
        const searchResultData = getSearchResultReducer.data || {};
        const result_no = this.props.noResult ? 0 : (searchResultData.total || 0);
        let { hasProduct = false} = searchResultData;
        if (this.props.noResult) hasProduct = false;
        const organic_listing = searchResultData.hits || '';

        let resultTxt = static_content.searchPanel.txt_results;
        if (parseInt(result_no, 10) < 2) {
            resultTxt = static_content.searchPanel.txt_result;
        }

        let noResult = !searchQuery || result_no === 0 || !organic_listing;
        return (
            <form onSubmit={this.handleSubmit} onReset={this.handleReset} className="search-panel" autoComplete="off">
			    <h1 className="access-elem">{search_result.h1}</h1>
                <div id="skiptargetholder">
					<span id="skiptarget" title="maincontent" tabIndex="0"></span>
				</div>
                <div className="search-panel__panel">
                    <div className="content-wrapper">
                        <div className="search-panel__wrapper">
                            <div className="search-panel__input-holder">
                                <label htmlFor="searchPanelQ"><span className="visually-hidden">Enter Keywords At Search Page</span></label>
                                <input defaultValue={searchData.searchQuery} id="searchPanelQ" name="q" type="search" placeholder={static_content.searchHints} className="search-panel__input" onKeyDown={this.handleAutoCompleteSelection} onChange={this.handleChange.bind(this)} value={this.state.keyword} autoFocus={true} ref={c => (this._inputSearch = c)} />
                                {autoCompleteItemsElems.length ?
                                    <div className="search-panel__autocomplete" onMouseEnter={this.handleAutoCompleteMouseEnter} onMouseMove={this.handleAutoCompleteMouseEnter}>
                                        {autoCompleteItemsElems}
                                    </div> :
                                    ''}
                                <button title={static_content.search_clear_btn_txt} type="reset" className="search-panel__clear-btn"></button>
                                <button title={static_content.search_submit_btn_txt} type="submit" className="search-panel__input-btn" ref={(el => this.submitBtn = el)}></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-panel__filters">
                    <div className="content-wrapper">
                        <div className="search-panel__filter">
                            <span className="search-panel__filter__title">{static_content.events.txt_filterBy}:</span>
                        </div>
                        {searchTypesElems}
                    </div>
                </div>
                {searchQuery ?
                    <div className="search-panel__list-control">
                        <div className="content-wrapper">
                            <div className="search-panel__list-control-holder">
                                <div className="search-panel__keywords">
                                    <h2 className="search-panel__keywords-title">{txtSearchPanel.txt_keywords}:</h2>
                                    <div className="search-panel__keywords-holder">
                                        <span className="search-panel__keywords-words">{searchQuery}</span>
                                        <small className="search-panel__result-num">( <NumberFormat value={(result_no === 0 ? (hasProduct ? 1 : 0) : result_no)} displayType={'text'} thousandSeparator={true} /> {resultTxt} )</small>
                                    </div>
                                </div>
                                {!noResult && (searchData.type==='market-insights' || searchData.type==='exhibitions-conferences')?
                                    <div className="search-panel__sorting">
                                        <h2 className="search-panel__sorting-title">{txtSearchPanel.txt_sorting}:</h2>
                                        <div className="search-panel__sorting-items">
                                            { sort_relevancy }
                                            { searchData.type === 'exhibitions-conferences' ? sort_exh : null }
                                            { searchData.type === 'market-insights' ? sort_mi : null }
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                 : ''}
            </form>
        )
    }
}
export default withRouter(SearchPanel)