import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import SearchListing from '../components/SearchListing/';

const mapStateToProps = (state, ownProps) => ({
  i18n: state.i18n,
  getSearchDidYouMeanReducer: state.getSearchDidYouMeanReducer,
  ownProps: ownProps,
  getSearchResultReducer: state.getSearchResultReducer,
  getSearchDataReducer: state.getSearchDataReducer,
  preferredData: state.getSearchPreferredListingReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(SearchListing);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;