import React, { Component } from "react";
import store from "../../store/";
import { fetchSourceData } from "../../actions/";
import config from '../../config';
import { fetchWebMetaData } from "../../actions/";

import PageItemSliderContainer from "../../containers/PageItemSliderContainer";
import PageItemSearchContainer from "../../containers/PageItemSearchContainer";
import PageIntroContainer from '../../containers/PageIntroContainer';
import ServicesEOEventList from './ServicesEOEventList';

import ScrollAnimation from 'react-animate-on-scroll';
import { goToAnchor } from 'react-scrollable-anchor';
import MetaHelmet from "../PageItem/MetaHelmet";
import LazyLoad from 'react-lazyload';

export default class ServicesItems extends Component {
	constructor(props) {
		super(props);
		let metaReady = true;
		store.dispatch(fetchSourceData()); // Teamsite CMS
		if (props.metaReducer.data.length <= 0) {
			store.dispatch(fetchWebMetaData());
			metaReady = false;
		}
		this.state = {
			metaReady: metaReady,
			cmsData: {}
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
			setTimeout(() => {
				if (window.utag_data) {
					console.log('Tealium JUMP Sourcing page load Sent:', new Date())
					window.utag_data = {
						"site_subsection": config.tealium.sourcing.subsection,
						"page_type": config.tealium.sourcing.pageType,
						"page_name": config.tealium.sourcing.pageName
					};
					window.enableTealium()
				}
			}, 1000);
		}
	
		if (this.props.pageSourceReducer.isLoading !== prevProps.pageSourceReducer.isLoading && this.props.pageSourceReducer.isLoading === false) {
			this.setState({
				cmsData: this.props.pageSourceReducer.data
			})
		}
	}

	componentDidMount() {
		// Send data to WebTrends server on didMount if this is not landing page
		// if (this.state.metaReady) {
		// 	console.log('JUMP Sourcing WT sent on DidMount Internal Link: ', new Date());
		// 	window.sendWebTrendsData();
		// }
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
			if (window.utag) {
				console.log('Tealium JUMP Sourcing Internal Link Sent:', new Date())
				window.utag.view({
					"site_subsection": config.tealium.sourcing.subsection,
					"page_type": config.tealium.sourcing.pageType,
					"page_name": config.tealium.sourcing.pageName
				});
			}
		}
		const anchor = window.location.hash;
		if (anchor) {
			setTimeout(() => {
				goToAnchor(anchor.replace("#", ''), false)
			}, 800);
		}
	}

	render() {
		// const getCols = (str) => ({
		// 	"0": "full",
		// 	"1": "full",
		// 	"2": "cols",
		// 	default: "full"
		// })[str];

		// const getLinksCols = (str1, str2) =>{
		// 	if (str2 > 4) {
		// 		return "item--full__links--cols";
		// 	} else {
		// 		return "item--full__links--col";
		// 	}
		// };

		const getSliderContainer = (bool) => {
			if (bool) {
				return " item--full__slider--cols";
			} else {
				return " ";
			}
		};

		const getSearchBar = (bool) => {
			if (bool) {
				return " item--search-bar";
			} else {
				return " ";
			}
		};

		const cmsSourceData = this.state.cmsData[this.props.i18n.locale] || {};

		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};

		const map_ary = ['online_marketplace', 'exhibition_online', 'product_magazines', 'small_order_zone'];
		let sub_item = [];

		const { sourcing = {} } = this.props.metaReducer.data[this.props.i18n.locale] || {};

		return (
			<div>
				<PageIntroContainer title={static_content.navLinks.services.big_title + static_content.navLinks.services.small_title} desc={cmsSourceData.header_description} pageCat={"sourcing"} />
				<section data-webtrend-group={config.WTTealiumGroupName.source} className="page-items">
					<MetaHelmet metaData={sourcing} locale={this.props.i18n.locale} />
					<div className="items-container">
						{

							Object.keys(config.services_bg || {}).map(function (key) {
								sub_item = [];

								let anchorId = (key === "1") ? 'exhibitiononline' : "a-" + key;
								let rowHasSlider = "row-container";

								let itemVal = config.services_bg[key];
								let itemBgColor = itemVal.backgroundColor || {};
								let titleColor = key % 2 === 0 ? 'white' : "origin";
								let contentColor = titleColor;
								let itemBgImg = `url(${(itemVal.backgroundImg) ? (config.full_assets + itemVal.backgroundImg) : ""})`;
								let itemBgImgX = "origin";
								let itemBgImgY = "origin";
								let itemTitle = cmsSourceData[map_ary[key] + '_title'];
								let itemDesc = cmsSourceData[map_ary[key] + '_description'];
								let itemLogo = cmsSourceData[map_ary[key] + '_logo'] || "";
								// if (itemVal.itemLogo) {
								// 	itemLogo = config.full_assets + itemVal.itemLogo;
								// }

								let itemSeachBar = key === "0" ? true : false; // Online Marketplace only

								let itemHrefVal = cmsSourceData[map_ary[key] + '_arrow_url'];
								let itemHrefMoreColor = (key === '0') ? 'ghost-white' : 'ghost-orange';

								let quicklinks = cmsSourceData[map_ary[key] + '_quick_links'];
								let itemLinksTitleColor = (key === '0') ? 'white' : 'orange';
								let hyperlinkColor = (key === '0') ? 'white' : 'origin';
								let itemLinksTitle = static_content.title_service_quicklinks;
								let itemLinksVal = quicklinks || {};
								let itemLinksNum = itemLinksVal.length;
								let hasItemLinks = itemLinksVal.length > 0;
								let itemMoreTxt = static_content.btnMore;
								let itemMoreHref = itemHrefVal;

								let item_link = [];
								for (let linkKey in itemLinksVal) {
									let linkVal = itemLinksVal[linkKey];
									let linkTxt = linkVal.quick_link_title;
									let linkHref = linkVal.quick_link_url;
									item_link.push(
										<li key={linkKey}><a href={linkHref} target="_blank" className="item-links__link" style={{ "color": hyperlinkColor }}>{linkTxt}</a></li>
									);
								}

								let itemSlidersVal = cmsSourceData[map_ary[key] + '_container'] || {};
								let showSlider = false;
								if (key === '2') {
									rowHasSlider = rowHasSlider + " row-container--slider";
									showSlider = true;
								}

								if (!showSlider) {
									sub_item.push(
										<LazyLoad once key={key}><div
											className={
												(itemVal["backgroundImg"]) ?
													"item-container item-container--bg item-container--bg--x-" + itemBgImgX + " item-container--bg--y-" + itemBgImgY
													: ((itemVal["backgroundColor"]) ? "item-container item-container--bg--color" : "item-container")
											} style={
												(itemVal["backgroundImg"]) ?
													({ "backgroundImage": itemBgImg })
													: ({ "backgroundColor": itemBgColor })
											}>
											<div className={"item-content " + getSliderContainer(showSlider) + getSearchBar(itemSeachBar)}>
												{
													itemSeachBar && <PageItemSearchContainer />
												}
												{
													// product magazine
													showSlider ? 
													[
														itemTitle ? (<div key={'PM' + key} className={"side-title side-title--" + titleColor}>{itemTitle}</div>) : null,
														itemLogo !== "" ? (<div key={'itemicon' + key} className={"item-icon"}><img src={itemLogo} alt={itemTitle} /></div>) : null,
														<div key={'itemtitle' + key} className={"item-content__title item-content__title--" + titleColor}>{itemTitle}</div>,
														<p key={'itemdesc' + key} className={"item-content__desc item-content__desc--" + contentColor}>{itemDesc}</p>,
														<PageItemSliderContainer key={'itemSlider' + key} sliderObj={itemSlidersVal} />,
														itemHrefVal && itemHrefVal !== '' ?
															<div key={'itemArrow' + key} className="item-more">
																<a href={itemHrefVal} className={"button button--" + itemHrefMoreColor} target="_blank">
																	<span>{static_content.btnLearnMore}</span>
																</a>
															</div> : null
													] :
														[
	
															<div key={'itemLeft' + key} className="item-content__left">
																{
																	itemTitle
																		? (<div className={"side-title side-title--" + titleColor}>{itemTitle}</div>)
																		: null
																}
																{
																	itemLogo !== ""
																		? (<div className={"item-icon"}><img src={itemLogo} alt={itemTitle} /></div>)
																		: null
																}
																<div className={"item-content__title item-content__title--" + titleColor}>{itemTitle}</div>
																<p className={"item-content__desc item-content__desc--" + contentColor}>{itemDesc}</p>
																{
																	itemHrefVal && itemHrefVal !== '' ?
																		<div key={key} className="item-more">
																			<a href={itemHrefVal} className={"button button--" + itemHrefMoreColor} target="_blank">
																				<span>{static_content.btnLearnMore}</span>
																			</a>
																		</div>
																		: null
																}
															</div>,
															<div key={'itemRight' + key} className="item-content__right">
																{
																	key === '1' &&
																	<ServicesEOEventList lists={itemLinksVal} itemHrefVal={itemHrefVal} />
	
																}
																{key !== '1' && hasItemLinks ?
																	<div className={"item-links"}>
																		<div className={"item-links__title item-links__title--" + itemLinksTitleColor}>{itemLinksTitle}</div>
																		{(itemLinksNum < 5)
																			? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																			: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
																		}
																		{itemMoreHref
																			? (<div className={"item-links__more item-links__more--" + itemLinksTitleColor}><a href={itemMoreHref} target="_blank" >{itemMoreTxt} ></a></div>)
																			: null
																		}
																	</div>
																	: null
																}
															</div>
														]
												}
											</div>
										</div></LazyLoad>
									);
								}								

								return (
									<ScrollAnimation key={key} animateIn="fadeIn" animateOnce className={"items-row items-row--full"}>
										<div id={anchorId} className={rowHasSlider}>{sub_item}</div>
									</ScrollAnimation>
								);
							})

						}
					</div>
				</section>
			</div>
		);
	}
}