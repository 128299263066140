import { createStore, applyMiddleware } from 'redux';
//import createLogger from 'redux-logger';
import rootReducer from '../reducers/';
import thunk from 'redux-thunk';
import Cookies from 'js-cookie';
import { createCookieMiddleware } from 'redux-cookie';

//const initialState = Immutable.Map();

const store = createStore(
  rootReducer,
  //initialState,
  applyMiddleware(
    thunk,
    createCookieMiddleware(Cookies)
    //createLogger({ stateTransformer: state => state.toJS() })
  )
);

/*
store.subscribe(function () {
  console.log('store changed', store.getState('locale'));
});
*/


export default store;