import React, { Component } from 'react';

import PodcastPagesContainer from '../../containers/PodcastPagesContainer';

import { Helmet } from "react-helmet";


export default class PodcastPages extends Component {
  
  render() {
    
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'PodcastPages' }} />    
        <PodcastPagesContainer />
      </main>
    );
  }
}
