import React, { Component } from 'react';

import SuccessStoriesItemsContainer from '../../containers/SuccessStoriesItemsContainer';

import { Helmet } from "react-helmet";


export default class Success extends Component {
  
  
  render() {
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'successStories' }} />    
        <SuccessStoriesItemsContainer />
      </main>
    );
  }
}
