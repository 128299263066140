import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import PageItemChildItem from '../components/PageItem/PageItemChildItem';

const mapStateToProps = state => ({
	i18n: state.i18n
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(PageItemChildItem);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;