import React, { Component } from 'react';
import config from '../../config';

export default class LazyImage extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
  }

  componentDidMount() {
    const img = this.props.imgSrc;
    if (img.complete) {
        this.handleImageLoaded();
    }
  }

  handleImageLoaded() {
    if (!this.state.loaded) {
        this.setState({ loaded: true });
    }
  }

  render() {
    return ( 
      <div>
        <img src={config.full_assets+"/images/gif-img-loading.gif"} alt="" className={(this.state.loaded? "loading--close" : "loading--open")}/>
        <img src={this.props.imgSrc} alt={this.props.imgAlt} onLoad={this.handleImageLoaded} className={(this.state.loaded? "image--loaded" : "image--loading")}/>
      </div>
    )
  }
}