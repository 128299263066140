import React, {Component} from 'react';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import { inSensitiveReplaceEM } from '../../util/helper';
import truncate from 'truncate-html';
import LazyLoad from 'react-lazyload';

class ContentTypeGeneral extends Component {
  
  render() {
    const { contentData = {}, q = '', debugMode=''} = this.props;
    const {
      score = 0,
      titleForSeo = '',
      description = '', 
      bodyShortForm = '',
      landingUrl = '',
      media01Url = '',
      media01Alt = '',

    } = contentData;
    let { title = ""} = contentData;
    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese

    title = titleForSeo !== '' ? titleForSeo : title;
    let em_title = inSensitiveReplaceEM(truncate(title,{length: config.char_limit.title}), q);
    let em_desc = inSensitiveReplaceEM(truncate((description || bodyShortForm), {length: config.char_limit.desc }), q);
    
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    
    return (
      <div className="organic-listing__item">
        {        
          title !== "" ? 
          <a href={landingUrl} target="_blank" title={title}>
            <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/></h4> 
          </a> 
          :
          <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/></h4>
        }
        { debugMode === 'Y' &&
          <p>{score}</p>
        }
        <div className="organic-listing__item-row">                  
        {
          media01Url !== '' && 
          <LazyLoad once><div className="organic-listing__img">
            <span className="organic-listing__img-holder">
                <img className="organic-listing__thumb" src={media01Url} alt={media01Alt} title={media01Alt} />
            </span>
          </div></LazyLoad>
        }  
          <div className="organic-listing__detail">
            {em_desc !=='' && <div className="organic-listing__desc"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_desc} /></div>}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ContentTypeGeneral);
