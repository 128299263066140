import React, { Component } from 'react';

export default class PlaceholderComponent extends Component {
  render(){
    return (
      <div className={"loader "+this.props.loadingClass || ''}>
        <div className="content">
          <div className="loading"></div>
          {this.props.loadingTxt && <p className="message">{this.props.loadingTxt}</p> }
        </div>        
      </div>
    )
  }
}