/*
IMPORTANT!! - Becos calendar eventEndDate is only string not ISO date format. then
- Moment is using HK timezone
- If need to check event expire or not, have to add timezone, e.g. ('YYYY-MM-DDTHH:mm:ssZ')
- DO NOT ADD timezone to calendar event date. otherwise display date will be changed according to computer's timezone.
-Now only 2 places to check event its expired or not - `UniqueEventItems` & `checkAtLeast4Events`
*/
import config from '../../config';
import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import store from '../../store/';
import { fetchEventInifiniteScrollData } from '../../actions/';

import ScrollAnimation from 'react-animate-on-scroll';
import InfiniteScroll from "react-infinite-scroll-component";
import Link from '../../util/Link';
import {convertToISODate} from "../../util/helper";

const moment = require('moment-timezone');
moment.tz.setDefault("Asia/Hong_Kong"); // force to display in HK timezone, affect i18n's moment
var sprintf = require("sprintf-js").sprintf;

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};
const disabledDays = [];

export default class HKTDCEvents extends Component {
    constructor(props) {
        super(props);

        let locale = props.i18n.locale;
        const t = moment();

        // default get current month
        store.dispatch(fetchEventInifiniteScrollData(sprintf(config.api_event_calendar, t.format('YYYY'), t.format('YYYY'), t.format('MM'), config.api_lang_map[locale])));
        this.state = {
            moreUrl: config.pagename_events,
            eventIsOutsideHK: 'all',
            hasMore: true,
            items: [],
            IS_maxMonthCount: (12 - t.month()) + 11,
            IS_count: 0,
            calendarCurMonth: moment(new Date()).format()
        }
    }
    fetchMoreData = () => {
        if (this.state.IS_count >= this.state.IS_maxMonthCount) {
            this.setState({ hasMore: false },()=>{
                this.toggleLoader();
            });
            return;
        } else {
            this.setState({ IS_count: (this.state.IS_count+1) },() => {                
                const m = moment().add(this.state.IS_count, 'months');
                // console.log(this.state.IS_count, m.format('YYYY') , m.format('MM'))
                store.dispatch(fetchEventInifiniteScrollData(sprintf(config.api_event_calendar, m.format('YYYY'), m.format('YYYY'), m.format('MM'), config.api_lang_map[this.props.i18n.locale])));
            });
        }
    };

    UniqueEventItems = (items) => {
        let ids = [];
        let newItems = [];
        Object.keys(items || {}).map(function (key) {
            const value = items[key];
            // also filter expired events
            if (moment(convertToISODate(value.eventEndDate)).format('YYYY-MM-DDTHH:mm:ssZ') >= moment().format()) {
                if (ids.indexOf(value.docRefId) === -1) {
                    newItems.push(value);
                }
                ids.push(value.docRefId);
            }
            return ('')
        });

        return newItems;
    }

    componentDidUpdate(prevPros) {
        if (prevPros.eventISReducer !== this.props.eventISReducer) {
            if (this.state.IS_count === 0 && this.state.items.length <= 0 && this.props.eventISReducer.data) {
                if (this.props.eventISReducer.data.length > 0) {
                    // first time                
                    this.setState({
                        items: this.UniqueEventItems(this.props.eventISReducer.data)
                    }, ()=> {
                        if (this.state.IS_count === 0 && this.state.items.length < 4) {
                            // trigger to load more if not enough data to make the scrollbar appear
                            this.fetchMoreData();
                        } else {
                            this.loadMoreOrEnd();
                        }
                    });
                }
                
            } else if (this.props.eventISReducer.error) {
                this.fetchMoreData();
            } else if (!this.props.eventISReducer.isLoading && this.state.IS_count > 0 && this.props.eventISReducer.data) {
                if (this.props.eventISReducer.data.length > 0) {
                    const oL = this.state.items.length;
                    this.setState({
                        items: this.UniqueEventItems(this.state.items.concat(this.props.eventISReducer.data))
                    }, ()=> {
                        if (oL === this.state.items.length) {
                            // check if this month has no new event after concat with previous month then load more
                            this.fetchMoreData();
                        } else this.loadMoreOrEnd();
                        
                    });
                } else {
                    this.fetchMoreData();
                }
            }
        }
    }

    loadMoreOrEnd = () => {
        // ensure HK / OS also contains at least 4 events for UI pretty
        const {
            chk = 0, cos = 0
        } = this.checkAtLeast4Events();
        if (chk < 4 || cos < 4) {
            this.fetchMoreData();
        } else {
            this.toggleLoader();
        }
    }

    checkAtLeast4Events = () => {
        const eventList = this.state.items || {};

        let chk = 0;
        let cos = 0;

        Object.keys(eventList || {}).map(function (key) {
            const value = eventList[key];
            if (value.eventIsOutsideHK === false) {
                chk = chk + 1;
            } else {
                cos = cos + 1;
            }
            return ('')
        });

        return { chk, cos };
    }

    toggleLoader = (display = 'none') => {
        const loader = document.getElementById("loader");
        if (loader) {
            loader.style.display = display;
        }
    }

    handleChangeMonth = (newMonth) => {
        this.toggleLoader('block');
        
        const is_futureM = moment(newMonth).format() > this.state.calendarCurMonth ? true: false;
        
        this.setState({
            calendarCurMonth: moment(newMonth).format(),
        }, () => {
            if (this.state.IS_count < this.state.IS_maxMonthCount && is_futureM) {
                this.fetchMoreData();
            } else {
                this.toggleLoader();
            }
        });
        
    }

    handleDayClick = (day, { sunday, disabled }) => {
        const eventData = this.state.items;
        const toHKDate = convertToISODate(day.toDateString());
        const strDate = moment(toHKDate).startOf('day').format() // reset to 00:00
        let tarElId = "hktdc-events-item--" + this.getTarDateId(strDate, eventData);
        let tarElem = document.getElementById(tarElId);
        if (tarElem) {
            let tarTop = tarElem.offsetTop;
            let listElem = document.getElementsByClassName('infinite-scroll-component');
            this.scrollTo(listElem[0], tarTop, 300);
        }
    }
    getTarDateId(tarDateTS, myArray) {
        const loc = this.state.eventIsOutsideHK;
        for (var i = 0; i < myArray.length; i++) {
            const outsideHK = myArray[i].eventIsOutsideHK;
            const inList = ((outsideHK === false && (loc === 'hk' || loc === 'all')) || (outsideHK === true && (loc === 'os' || loc === 'all')));

            let startDate = moment(convertToISODate(myArray[i].eventStartDate)).format();
            let endDate = moment(convertToISODate(myArray[i].eventEndDate)).format();
            if (myArray[i].eventDisplayMonthYearOnlyInd) {
                // 1st to last day of that month
                startDate = moment(startDate).startOf('month').format();
                endDate = moment(endDate).endOf('month').format();
            }
            if (tarDateTS >= startDate && tarDateTS <= endDate && inList) {
                return myArray[i].docRefId;
            }
        }
    }

    switchHKTDCEvents = (loc = '') => (e) => {
        loc = loc === '' ? e.target.value : loc;
        if (loc === 'past') {
            window.location.href = config.full_events + '/' + this.props.i18n.locale + '/' + config.pastEventUrl;
        } else {
            let filters = document.querySelectorAll('.home-hktdc-events__filter');
            [].forEach.call(filters, function (el) {
                el.classList.remove('is-active');
            });
            let tar = document.getElementById('home-hktdc-events-filter-' + loc);
            if (tar) {
                tar.className += ' is-active';
            }
            let moreUrl = "";
            switch (loc) {
                case 'hk':
                    moreUrl = config.pagename_events + '?location=hk';
                    break;
                case 'os':
                    moreUrl = config.pagename_events + '?location=outsidehk';
                    break;
                default:
                    moreUrl = config.pagename_events;
            }
            this.updateMoreUrl(moreUrl, loc);
        }
        
        e.preventDefault();
    }

    updateMoreUrl(url, loc) {
        this.setState({
            moreUrl: url,
            eventIsOutsideHK: loc
        });
    }

    getDates = (startDate, stopDate) => {
        var dateArray = [];
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            const newDate = convertToISODate(new Date(currentDate).toDateString());
            if (moment(newDate).format('MM-YYYY') === moment(this.state.calendarCurMonth).format('MM-YYYY')) {
                dateArray.push(new Date(currentDate));
            }
            currentDate = moment(currentDate).add(1, 'days').format();
        }
        return dateArray;
    }

    scrollTo = (element, to, duration) => {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    render() {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const { events } = static_content || {};

        const { event_container } = this.props.cmsData.data[this.props.i18n.locale] || {}; // get Teamsite CMS Data
        const {hktdc_event_description} = event_container || {};

        //Intro
        const { introContent = {} } = events || {};

        //upcomingEvents
        let { upcomingEvents = {} } = events || {};
        const getDates = this.getDates;
        let eventDateHK = [];
        let eventDateOS = [];
        

        const calendarItems = this.state.items;

        Object.keys(calendarItems || {}).map(function (key) {
            let value = calendarItems[key];

            let startDate = moment(new Date(moment(convertToISODate(value.eventStartDate)).format('YYYY-MM-DDTHH:mm:ss'))).format();
            let endDate = moment(new Date(moment(convertToISODate(value.eventEndDate)).format('YYYY-MM-DDTHH:mm:ss'))).format();

            // no longer need to check event expired or not, it is handled by UniqueEventItems this.state.items.
            if (value.eventDisplayMonthYearOnlyInd) {
                // 1st to last day of that month
                startDate = moment(new Date(moment(convertToISODate(value.eventStartDate)).startOf('month').format('YYYY-MM-DDTHH:mm:ss'))).format();
                endDate = moment(new Date(moment(convertToISODate(value.eventEndDate)).endOf('month').format('YYYY-MM-DDTHH:mm:ss'))).format();
            }

            if (value.eventIsOutsideHK === false) {
                eventDateHK = eventDateHK.concat(getDates(startDate, endDate));
            } else {
                eventDateOS = eventDateOS.concat(getDates(startDate, endDate));
            }
            return ('')
        });

        let modifiers = {
            hlhk: this.state.eventIsOutsideHK !== 'os' ? eventDateHK : '',
            hlos: this.state.eventIsOutsideHK !== 'hk' ? eventDateOS : ''
        };

        let items = this.state.items;
        let eventIsOutsideHK = this.state.eventIsOutsideHK;
        const curTabTitle = this.state.eventIsOutsideHK === 'all' ? introContent['tabAll'] : introContent['tab'+(this.state.eventIsOutsideHK).toUpperCase()];
        return (
            <section data-webtrend-group={config.WTTealiumGroupName.eventCalendar} className="hktdc-events">
                <ScrollAnimation animateIn="fadeIn" animateOnce className="content-wrapper">
                    <div className="side-title">{introContent['tdcevents']}</div>
                    {/*<h3 className="hktdc-events__tag"><span>{introContent['tagline']}</span></h3>*/}
                    <div className="hktdc-events__row">
                        <ScrollAnimation className="hktdc-events__col hktdc-events__col-full align-center" animateIn="fadeIn" animateOnce>
                            <h2 className="hktdc-events__title">{introContent['tdcevents']}</h2>
                            <p className="hktdc-events__intro">{hktdc_event_description}</p>
                        </ScrollAnimation>
                        
                        <ScrollAnimation className="hktdc-events__col hktdc-events__col-full align-center" animateIn="fadeIn" animateOnce>
                            <div className="home-hktdc-events__filters desktop">
                                <span tabIndex="0" id="home-hktdc-events-filter-all" className="home-hktdc-events__filter is-active" onClick={this.switchHKTDCEvents('all')} onKeyPress={this.switchHKTDCEvents('all')}>{introContent['tabAll']}</span>
                                <span tabIndex="0" id="home-hktdc-events-filter-hk" className="home-hktdc-events__filter" onClick={this.switchHKTDCEvents('hk')} onKeyPress={this.switchHKTDCEvents('hk')}>{introContent['tabHK']}</span>
                                <span tabIndex="0" id="home-hktdc-events-filter-os" className="home-hktdc-events__filter" onClick={this.switchHKTDCEvents('os')} onKeyPress={this.switchHKTDCEvents('os')}>{introContent['tabOS']}</span>
                                <span tabIndex="0" id="home-hktdc-events-filter-past" className="home-hktdc-events__filter" onClick={this.switchHKTDCEvents('past')} onKeyPress={this.switchHKTDCEvents('past')}>{introContent['tabPast']}</span>
                            </div>
                            
                            <div className="home-hktdc-events__filters mobile">
                                <div className="home-hktdc-events__filters-cat-container">
                                    <div className="home-hktdc-events__filters-cat__title">{curTabTitle}</div>
                                    <select value={this.state.eventIsOutsideHK} className="home-hktdc-events__filters-condList" aria-label={curTabTitle} onChange={this.switchHKTDCEvents()} >
                                        <option className="filter-condList__cond" value="all">{introContent['tabAll']}</option>
                                        <option className="filter-condList__cond" value="hk">{introContent['tabHK']}</option>
                                        <option className="filter-condList__cond" value="os">{introContent['tabOS']}</option>
                                        <option className="filter-condList__cond" value="past">{introContent['tabPast']}</option>
                                    </select>
                                </div>
                            </div>
                        </ScrollAnimation>

                        <ScrollAnimation className="hktdc-events__col noSelect" animateIn="fadeIn" animateOnce>
                            <DayPicker
                                disabledDays={disabledDays}
                                initialMonth={new Date(moment().format())}
                                fromMonth={new Date(moment().format())}
                                toMonth={new Date(moment().add(this.state.IS_maxMonthCount, 'months').format())}
                                month={new Date(moment().format())}
                                months={static_content.txt_month}
                                weekdaysShort={static_content.txt_weekdays}
                                modifiers={modifiers}
                                onDayClick={this.handleDayClick}
                                onMonthChange={this.handleChangeMonth}
                            />
                            <div className="hktdc-events__legends">
                                <span className="hktdc-events__legend hktdc-events__legend--hk">{introContent['tabHK']}</span>
                                <span className="hktdc-events__legend hktdc-events__legend--os">{introContent['tabOS']}</span>
                            </div>
                                <div id="loader" className="loader">
                                    <div className="content">
                                        <div className="loading"></div>
                                        <p className="message">{static_content.txt_loading}</p>
                                    </div>        
                                </div>
                        </ScrollAnimation>
                        <ScrollAnimation className="hktdc-events__col hktdc-events__col-55" animateIn="fadeIn" animateOnce>
                            <h2 className="hktdc-events__list-title">{upcomingEvents.title}</h2>
                            <div id="hktdc-events-list" className="hktdc-events__list">
                                {
                                    this.state.items.length > 0 &&
                                    
                                    <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={()=>this.fetchMoreData()}
                                    hasMore={this.state.hasMore}
                                    height={235}
                                    endMessage=""
                                    loader={<p>{static_content.txt_loading}</p>}>
                                    {Object.keys(this.state.items || {}).map(function (key) {
                                        
                                        let value = items[key];

                                        var monthName = static_content.txt_month;
                                        let title = value.title;
                                        let loc = value.regionsForDisplay && (value.regionsForDisplay).join(', ');

                                        // repeat code, optimization is needed
                                        let startDate = convertToISODate(value.eventStartDate) || '';
                                        let endDate = convertToISODate(value.eventEndDate) || '';

                                        let startDateStr = moment(new Date(startDate)).format('DD.MM.YYYY'); // using '.' as seperator
                                            let endDateStr = moment(new Date(endDate)).format('DD.MM.YYYY');

                                            const startDateStr_display = (value.eventDisplayMonthYearOnlyInd) ? moment(new Date(startDate)).format('MM.YYYY'): startDateStr;
                                            const endDateStr_display = (value.eventDisplayMonthYearOnlyInd) ? moment(new Date(endDate)).format('MM.YYYY'): endDateStr;

                                            let startDateArr = startDateStr.split('.');
                                            let endDateArr = endDateStr.split('.');
                                            // end

                                            let startDay = parseInt(startDateArr[0], 10);
                                            let endDay = parseInt(endDateArr[0], 10);

                                            let startMonth = parseInt(startDateArr[1], 10);
                                            let endMonth = parseInt(endDateArr[1], 10);

                                            // let startYear = parseInt(startDateArr[2], 10);
                                            // let endYear = parseInt(endDateArr[2], 10);

                                            let daysDisplay = startDay;
                                            if (startDay !== endDay) {
                                                daysDisplay = startDay + '-' + endDay;
                                            }

                                            let id = "hktdc-events-item--" + value.docRefId;
                                            let classEvent = (value.eventIsOutsideHK === false) ? 'hktdc-events__item hktdc-events__item--hk' : 'hktdc-events__item hktdc-events__item--os';
                                            
                                            return (<div key={'eListR'+key}>

                                            {
                                            ((value.eventIsOutsideHK === false && (eventIsOutsideHK === 'hk' || eventIsOutsideHK === 'all')) ||
                                            (value.eventIsOutsideHK === true && (eventIsOutsideHK === 'os' || eventIsOutsideHK === 'all'))) && 

                                                <a id={id} href={value.landingUrl} className={classEvent} key={key} target="_blank">
                                                    <div className="hktdc-events__date">
                                                    {(startMonth === endMonth) ?
                                                        <div className="hktdc-events__date-holder">
                                                            { !value.eventDisplayMonthYearOnlyInd &&
                                                            <div className="hktdc-events__days">{daysDisplay}</div>
                                                            }
                                                            <div className="hktdc-events__month">{monthName[(startMonth-1)]}</div>
                                                        </div>
                                                    :
                                                        <div className="hktdc-events__date-holder">
                                                            <div className="hktdc-events__days">{!value.eventDisplayMonthYearOnlyInd && startDay+'/'}{startMonth}</div>
                                                            <div className="hktdc-events__days">-{!value.eventDisplayMonthYearOnlyInd && endDay}/{endMonth}</div>
                                                        </div>
                                                    }
                                                    </div>
                                                    <div className="hktdc-events__details">
                                                        <div className="hktdc-events__info">
                                                            <div className="hktdc-events__info-loc">{loc}</div>
                                                            {(startDateStr_display === endDateStr_display) ?
                                                                <div className="hktdc-events__info-item">{startDateStr_display}</div>
                                                                :
                                                                <div className="hktdc-events__info-item">{startDateStr_display} - {endDateStr_display} </div>
                                                            }
                                                        </div>
                                                        <div className="hktdc-events__event-title">{title}</div>
                                                    </div>
                                                </a>
                                            
                                            
                                            }
                                            </div>
                                            );
                                    })}
                                    </InfiniteScroll>
                                }

                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="hktdc-events__row">
                        <div className="button-row">
                            <Link to={this.state.moreUrl} className="button button--right-arr"><span>{introContent['btn']}</span></Link>
                        </div>
                    </div>
                </ScrollAnimation>
            </section>
        );
    }
}
