import React, { Component } from "react";
import store from "../../store";
import MetaHelmet from "../PageItem/MetaHelmet";
import PageIntroContainer from "../../containers/PageIntroContainer";
import NoMatch from "../Layout/NoMatch";
import { fetchAdvantagesThematicPageData } from "../../actions";
import SanitizedHTML from "react-sanitized-html";
import config from "../../config";
import Loading from "../Loading/Loading";
import _ from "lodash";
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import "./hkAdvantages.css";

export default class AdvantagesThematicPage extends Component {
  constructor(props) {
    super(props);
    let metaReady = false;
    store.dispatch(
      fetchAdvantagesThematicPageData(props.match.params.pagename || "")
    );
    this.state = {
      metaReady: metaReady,
    };
  }

  composeTealiumObj() {
    const advantagesThematicPageData =
      this.props.advantagesThematicPageData.data || {};
    const { seoData = {} } =
      advantagesThematicPageData[this.props.i18n.locale] || {};
    const tealiumObj = {
      site_subsection: config.tealium.hkAdvantagePage.subsection,
      page_type: config.tealium.hkAdvantagePage.pageType,
      page_name: seoData.title,
    };
    //console.log(JSON.stringify(tealiumObj, null, 2));
    return tealiumObj;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.allowCookiesStatusReducer.allowCookiesStatus === true &&
      this.props.allowCookiesStatusReducer.allowCookiesStatus !==
        prevProps.allowCookiesStatusReducer.allowCookiesStatus
    ) {
      setTimeout(() => {
        if (window.utag_data) {
          console.log(
            "Tealium Advantages Thematic Page load Sent:",
            new Date()
          );
          window.utag_data = this.composeTealiumObj();
          window.enableTealium();
        }
      }, 1000);
    }

    if (this.props.match.params.pagename !== prevProps.match.params.pagename) {
      // handling: switch between static pages only
      this.setState(
        {
          metaReady: false,
        },
        () => {
          store.dispatch(
            fetchAdvantagesThematicPageData(
              this.props.match.params.pagename || ""
            )
          );
          if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
            setTimeout(() => {
              if (window.utag) {
                console.log(
                  "Tealium Advantages Thematic Page Internal Link 2 Sent:",
                  new Date()
                );
                window.utag.view(this.composeTealiumObj());
              }
            }, 1000);
          }
        }
      );
    }
  }

  componentDidMount() {
    // Send data to WebTrends server on didMount if this is not landing page
    // if (this.state.metaReady) {
    //   console.log('Static Pages WT sent on DidMount Internal Link: ', new Date());
    //   window.sendWebTrendsData();
    // }
    if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
      if (window.utag) {
        console.log(
          "Tealium Advantages Thematic Page Internal Link Sent:",
          new Date()
        );
        window.utag.view(this.composeTealiumObj());
      }
    }
  }

  render() {
    const advantagesThematicPageData =
      this.props.advantagesThematicPageData.data || {};
    const routePathTo = config.subDirectoryPath;
    const pageLocale = this.props.match.params.locale;
    const {
      section1 = {},
      section2 = {},
      section3 = {},
      section4 = {},
      seoData = {},
      displayTime = {},
    } = advantagesThematicPageData[this.props.i18n.locale] || {};
    const currentTime = moment();
    const paramsPagename = this.props.match.params.pagename;
    let colorClass;
    switch (paramsPagename) {
      case "sustainability":
        colorClass = "threeColItem__item-title sus_color";
        break;
      case "wellness":
        colorClass = "threeColItem__item-title wellness_color";
        break;
      case "innovation":
        colorClass = "threeColItem__item-title innovation_color";
        break;
      default:
        colorClass = "threeColItem__item-title";
    }
    console.log({
      section1,
      section2,
      section3,
      section4,
      paramsPagename,
    });

    if (_.isEmpty(advantagesThematicPageData)) {
      return <Loading />;
    }

    //onlineTime and offlineTime checking for each locale in new version or whole DCR in old version
    if (
      (!_.isEmpty(displayTime.onlineTime) &&
        !_.isEmpty(displayTime.offlineTime)) ||
      (!_.isEmpty(advantagesThematicPageData.onlineTime) &&
        !_.isEmpty(advantagesThematicPageData.offlineTime))
    ) {
      const onlineTime = displayTime.onlineTime
        ? moment(displayTime.onlineTime)
        : moment(advantagesThematicPageData.onlineTime);
      const offlineTime = displayTime.offlineTime
        ? moment(displayTime.offlineTime)
        : moment(advantagesThematicPageData.offlineTime);
      const isWithinRange =
        currentTime.isSameOrAfter(onlineTime) &&
        currentTime.isSameOrBefore(offlineTime);

      if (!isWithinRange) {
        if (pageLocale !== "en") {
          return (
            <>
              {pageLocale == "tc" && <Redirect to={`${routePathTo}/tc/`} />}
              {pageLocale == "sc" && <Redirect to={`${routePathTo}/sc/`} />}
            </>
          );
        } else {
          return <NoMatch />;
        }
      }
    }

    const Body1 = ({}) => {
      return (
        <>
          <div className="breadcrumb">
            <div className="breadcrumb__container">
              <div className="breadcrumb__item">
                <a href={section1.url}>{section1.breadcrumb}</a>
              </div>
              <div className="breadcrumb__item-slash">/</div>
              <div className="breadcrumb__item breadcrumb__item--current">
                {section1.headline}
              </div>
            </div>
          </div>
          <div className="heroBanner">
            <div
              className={
                paramsPagename == "innovation"
                  ? "heroBanner__bg"
                  : "heroBanner__bg_sus"
              }
              style={{ backgroundImage: `url('${section1.image}')` }}
            ></div>
            <div className="heroBanner__container">
              <div
                className="heroBanner__title"
                style={{ color: section1.colorCode }}
              >
                <h2>{section1.headline}</h2>
              </div>
              <p
                className="heroBanner__content"
                style={{ color: section1.colorCode }}
              >
                {section1.description}
              </p>
            </div>
            <div className="heroBanner__bg_sus__mobile">
              <div
                className="heroBanner__bg_sus__mobile__bg"
                style={{ backgroundImage: `url('${section1.imageMobile}')` }}
              >
                {paramsPagename == "innovation" && (
                  <div class="heroBanner__bg_sus__mobile__bg__overlay"></div>
                )}
                <div className="heroBanner__title__mobile">
                  <h2>{section1.headline}</h2>
                </div>
              </div>
              <div className="heroBanner__bg_sus__mobile__wording">
                <div className="heroBanner__container__mobile">
                  <p className="heroBanner__content__mobile">
                    {section1.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };

    const Body2 = ({}) => {
      return (
        <>
          <div className="threeColItem">
            <div
              className="threeColItem__bg"
              style={{ backgroundImage: `url('${section2.image}')` }}
            >
              <div className="threeColItem__container">
                {section2.table &&
                  section2.table.length > 0 &&
                  section2.table.map((item, index) => {
                    return (
                      <div className="threeColItem__item" key={index}>
                        <div className="threeColItem__item-icon">
                          <img src={item.image} alt={item.item} />
                        </div>
                        <div className={colorClass}>{item.item}</div>
                        <SanitizedHTML
                          className="threeColItem__item-desc"
                          allowedAttributes={config.allowedAttributes}
                          allowedTags={config.allowedTags}
                          html={item.description}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      );
    };

    const Body3 = ({}) => {
      return (
        <div className="titleImageRow">
          <div className="titleImageRow__quote-icon titleImageRow__quote-icon--top"></div>
          <div className="titleImageRow__container">
            <div className="titleImageRow__title">
              <span>{section3.headline}</span>
              <div className="titleImageRow__title_icon">
                <img
                  src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/wmhhr0xf-hlsmwztj-comma-left.png"
                  alt="comma-left"
                />
              </div>
            </div>
            {section3.table &&
              section3.table.length > 0 &&
              section3.table.map((item, index) => {
                return (
                  <div className="titleImageRow__row" key={index}>
                    <div className="titleImageRow__image">
                      <a
                        href={item.item_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={item.image} alt={item.item} />
                      </a>
                    </div>
                    <div className="titleImageRow__content">
                      <div className="titleImageRow__content-title">
                        <a
                          href={item.item_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>{item.item}</span>
                        </a>
                      </div>
                      <SanitizedHTML
                        className="titleImageRow__content-desc"
                        allowedAttributes={config.allowedAttributes}
                        allowedTags={config.allowedTags}
                        html={item.description}
                      />
                      <div className="titleImageRow__content-link">
                        <a
                          href={item.item_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/sy6qh18-8f9o0k0h-icon-long-arrow%20(1).svg"
                            alt="arrow"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className="titleImageRow__title_icon2">
              <img
                src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/l1aeyktk-4edcok8-comma-right.png"
                alt="comma-right"
              />
            </div>
          </div>
        </div>
      );
    };

    const Body4 = ({}) => {
      return (
        <div className="titleImageSingle">
          <div className="titleImageSingle__container">
            <div className="titleImageSingle__title">{section4.headline}</div>
            <div className="titleImageSingle__row">
              <div className="titleImageSingle__image">
                {section4.itemType &&
                section4.video &&
                section4.itemType == "videoElement" ? (
                  <iframe
                    src={section4.video}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                ) : (
                  <a
                    href={section4.item_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={section4.image} alt={section4.item} />
                  </a>
                )}
              </div>
              <div className="titleImageSingle__content">
                <div className="titleImageSingle__content-title">
                  <a
                    href={section4.item_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{section4.item}</span>
                  </a>
                </div>
                <SanitizedHTML
                  className="titleImageSingle__content-desc"
                  allowedAttributes={config.allowedAttributes}
                  allowedTags={config.allowedTags}
                  html={section4.item_description}
                />
                <div className="titleImageSingle__content-link">
                  <a
                    href={section4.item_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/9zrbgore-ddjo1jr9-icon-long-white-arrow.svg"
                      alt="arrow white"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div>
        {section1.headline ? (
          <main>
            <MetaHelmet metaData={seoData} locale={this.props.i18n.locale} />
            <div>
              <div>
                <section>
                  <Body1 />
                  <Body2 />
                  <Body3 />
                  <Body4 />
                </section>
              </div>
            </div>
          </main>
        ) : (
          this.props.advantagesThematicPageData.error && <NoMatch />
        )}
      </div>
    );
  }
}
