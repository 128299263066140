import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

var sprintf = require("sprintf-js").sprintf;

function requestAdvantagesThematicPageData() {
	return { type: types.REQ_ADVANTAGESTHEMATICPAGE_DATA }
};

function receiveAdvantagesThematicPageData(json) {
	return {
		type: types.RECV_ADVANTAGESTHEMATICPAGE_DATA,
		data: json
	}
};

function receiveAdvantagesThematicPageError(json) {
	return {
		type: types.RECV_ADVANTAGESTHEMATICPAGE_ERROR,
		data: json
	}
};

export function fetchAdvantagesThematicPageData(pagename) {
	return function (dispatch) {
		dispatch(requestAdvantagesThematicPageData());
		console.log(sprintf(config.json_page_AdvantagesThematicPage, pagename))
		return axiosAdapter({
			url: sprintf(config.json_page_AdvantagesThematicPage, pagename)
		})
			.then(function (response) {
				dispatch(receiveAdvantagesThematicPageData(response.data.result.assets[0].content.content));
			})
			.catch(function (error) {
				dispatch(receiveAdvantagesThematicPageError(error));
				//dispatch(pushState(null,'/error'));
			})
	}
};
