import React, { Component } from "react";

export default class PageIntro extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		var _curCat = this.props.pageCat;
		var _object = this.props.metaReducer.data[this.props.i18n.locale] || {};
		var _pageHead = this.props.pageHead || '';
		Object.keys(_object).map(function (key) {
			if(key == _curCat){
				_pageHead = _object[key].h1 || {};
			}
		});
		return (
			<div>
				<h1 className="access-elem">{_pageHead}</h1>
				<section className="sec-intro">
				<div id="skiptargetholder">
					<span id="skiptarget" title="maincontent" tabIndex="0"></span>
				</div>
				<div className="content-wrapper"><h2 className="sec-intro__title">{this.props.title}</h2><p className="sec-intro__desc">{this.props.desc}</p></div>
				</section>
			</div>
		);
	}
}