import * as types from '../constants/actionTypes.js';

const topSearchReducer = function (state = {
    data: 'all',
    error: false
}
    , action = null) {
    switch (action.type) {
        case types.SWITCH_TOP_SEARCH:
            return Object.assign({}, state, { data: action.data, error: false });
        default:
            return state;
    }
};

export default topSearchReducer;