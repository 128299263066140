import { Provider } from 'react-redux';
import React, { Component } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import store from './store/';
import SmartBanner from 'react-smartbanner';
import '../node_modules/react-smartbanner/dist/main.css';

//CSS
import './assets/css/normalize.css';
import './assets/css/swiper.min.css';
import './App.scss';

import LangHandler from './components/LangHandler/';

import Header from './components/Header/';
import Tealium from './components/Header/Tealium';
import SSOStatus from './components/Header/SSOStatus';
import Footer from './components/Footer/';

import Home from './components/Layout/Home';
import Business from './components/Layout/Business';
import StaticPages from './components/Layout/StaticPages';
import FactsheetPages from './components/Layout/FactsheetPages';
import WebcastPages from './components/Layout/WebcastPages';
import AdvantagesMainPage from './components/Layout/AdvantagesMainPage';
import AdvantagesThematicPage from './components/Layout/AdvantagesThematicPage';
import PodcastPages from './components/Layout/PodcastPages';
import EventsItems from './components/EventsItems/EventsItems';
import Services from './components/Layout/Services';
import Insights from './components/Layout/Insights';
import SearchResult from './components/Layout/SearchResult';
import MobileApp from './components/Layout/MobileApp';
import SuccessStories from './components/Layout/SuccessStories';
import NoMatch from './components/Layout/NoMatch';

import AlertBarContainer from './containers/AlertBarContainer';
import PromotionBarContainer from './containers/PromotionBarContainer';
/*import CookiesBarContainer from './containers/CookiesBarContainer'; FS EU Consent style, abandoned*/

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone" 

import { hyperlinkTrack } from './util/clickEvents';

import config from './config';

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

class App extends Component {
  constructor() {
    super();
    this.history = createHistory();    
    this.checkPlatform();    
    this.appRef = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.skipClick = this.skipClick.bind(this);
    this.state = {
      previewDate: new URLSearchParams(window.location.search).get('previewDate'),
      previewDateChange: null
    };
  }


  handleKeyPress(e) {
    let code = e.keyCode || e.which;
    if(code === 13){
      document.getElementsByClassName("skip-link__link")[0].click();
    }
  }

  skipClick(e){
    e.preventDefault();
    scroller.scrollTo('skiptarget', {
      smooth: true,
      delay: 100,
    });
    // const targetElem = document.getElementById('skiptarget');
    // targetElem.focus();
    const targetElem = document.getElementById("skiptarget");
    console.log(targetElem);
    targetElem.focus();
  }

 shouldComponentUpdate(nextProps, nextState) {
    if (this.state.previewDateChange !== nextState.previewDateChange) return true;
    if (this.props.route && this.props.route.path === nextProps.route.path) return false;
    return true;
  }

  checkPlatform = () => {
    // Firefox 1.0+
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    // Safari 3.0+ "[object HTMLElementConstructor]" 
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1+
    var isChrome = !!window.chrome && !!window.chrome.webstore;

    var supportedBrowser = true;
    var classBrowser = "is-other-browser";
    if (isFirefox) {
      classBrowser = "is-firefox";
    } else if (isSafari) {
      classBrowser = "is-safari";
    } else if (isIE) {
      classBrowser = "is-ie";
      if (document.documentMode < 11 ) {
        /// old IE would return version. Edge could not be classified in this block
        supportedBrowser = false;
      }
    } else if (isEdge) {
      classBrowser = "is-edge";
    } else if (isChrome) {
      classBrowser = "is-chrome";
    }
    document.body.classList.add(classBrowser);
  }

  eventPath = (evt) => {
    var path = (evt.composedPath && evt.composedPath()) || evt.path,
      target = evt.target;

    if (path != null) {
      // Safari doesn't include Window, but it should.
      return (path.indexOf(window) < 0) ? path.concat(window) : path;
    }

    if (target === window) {
      return [window];
    }

    function getParents(node, memo) {
      memo = memo || [];
      var parentNode = node.parentNode;

      if (!parentNode) {
        return memo;
      } else {
        return getParents(parentNode, memo.concat(parentNode));
      }
    }

    return [target].concat(getParents(target), window);
  }

  componentDidMount() {
    
    this.appRef.current.addEventListener('click', e => {
      let webTrendReferralURL= null;
      let hyperlinkGroup = null;
      let i=0;
      let epath = this.eventPath(e);
      //Check whether there is A tag in bubbling path
      while (!hyperlinkGroup && i < epath.length) {
        let dataset = epath[i].dataset || {};
        if (webTrendReferralURL) {
          //check hyperlink group 
          if (dataset.webtrendGroup) {
            hyperlinkGroup = dataset.webtrendGroup;
          }
        } else if (epath[i].tagName && epath[i].tagName === "A") {
          webTrendReferralURL=epath[i].href;
          //TODO: some URL could not be captured in this way due to path changed by javascript (E.g. header SSO login)
        }//else continue
        //TODO: possibly need to handle other tag such as button
        i++;
      }
      
      if (webTrendReferralURL && webTrendReferralURL.startsWith('http')) {
         if (!hyperlinkGroup) {
           hyperlinkGroup = "others";
         }
        //Fire WebTrend/TEALIUM Event
        hyperlinkTrack(webTrendReferralURL, hyperlinkGroup);
      } //else return;
    } ,true);

    var link = document.location.href.split('/');
    var _curLink = link[3];
    if(_curLink == "tc"){
      document.getElementsByClassName("skip-link__link")[0].innerHTML = "跳至主要內容";
      document.getElementsByClassName("skip-link__link")[0].setAttribute("title", "跳至主要內容");
    }else if(_curLink == "sc"){
      document.getElementsByClassName("skip-link__link")[0].innerHTML = "跳至主要內容";
      document.getElementsByClassName("skip-link__link")[0].setAttribute("title", "跳至主要內容");
    }else{
      document.getElementsByClassName("skip-link__link")[0].innerHTML = "Skip to main content";
      document.getElementsByClassName("skip-link__link")[0].setAttribute("title", "Skip to main content");
    }
}

  handlePreviewDateChange = () => {
    let previewDate = moment(this.state.previewDate).format("YYYY-MM-DD HH:mm:ss");
    var search_params = new URLSearchParams(window.location.search); 
    search_params.set('previewDate', previewDate);
    window.location.search = search_params.toString();
  }

  updatePreviewDateChange = (date) => {
    this.setState({
      previewDate: date
    });
  }

  render() {
    const handleKeyPress = this.handleKeyPress;
    const skipClick = this.skipClick;
    const hasPreviewDate = new URLSearchParams(window.location.search).get('previewDate') !== null;
    const is_Preview = (process.env.REACT_APP_STAGE).includes('preview');
    const routePathTo = config.subDirectoryPath;

    let previewDateInput;

    if (is_Preview && hasPreviewDate) {
      const previewDate = new URLSearchParams(window.location.search).get('previewDate');
      const placeholderText = previewDate !== '' ? previewDate  : 'Pick a preview date'
      previewDateInput = (
        <div className="previewDateInput_wrapper">
          <DatePicker
          selected={this.state.previewDate}
          onChange={this.updatePreviewDateChange}
          showTimeSelect
          timeIntervals={5}
          timeFormat="HH:mm"
          dateFormat="YYYY-MM-dd HH:mm"
          placeholderText={placeholderText}
          timeCaption="Time"
          popperPlacement = "bottom-start"
          />
          <button
            className = "previewDateInput_wrapper-input"
            onClick={this.handlePreviewDateChange}>Submit
          </button>
        </div>
      )
    }
    return (
      <Provider store={store}>
        <Router history={this.history} basename={routePathTo}>
          <div className="app" ref={this.appRef}>
            <SmartBanner title={'HKTDC'} appMeta={{ ios: 'apple-itunes-app', android: 'google-play-app' }} />
            <div className="app__top">
                 {previewDateInput}
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)?`} render={(props) => <AlertBarContainer {...props} page={"home"} />}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)?`} render={(props) => <PromotionBarContainer {...props} page={"promotion"} />}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)?/${config.pagename_services}`} render={(props) => <AlertBarContainer {...props} page={"source"} />}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)?`} render={ (props) => (window.location.hostname === config.events && !(process.env.REACT_APP_STAGE).includes('preview')) && (<PromotionBarContainer {...props} page={"join"} /> ) }></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_events}`} render={(props) => <PromotionBarContainer {...props} page={"join"} />}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_services}`} render={(props) => <PromotionBarContainer {...props} page={"sourcePromotion"} />}></Route>
                {/*<Route path="/:locale(en|tc|sc)?" component={CookiesBarContainer}></Route> FS EU Consent style, abandoned*/}
            </div>
            <div className="skip-link">
              <a href="#skiptarget" className="skip-link__link" title="Skip to main content" onClick={skipClick} onKeyPress={handleKeyPress}>Skip to main content</a>
            </div>
            <div className="app__main">
              <Route path={`${routePathTo}/:locale(en|tc|sc)?`} component={()=><Tealium ref={(tComponent) => {window.tComponent = tComponent}}/>}></Route>
              <Route path={`${routePathTo}/:locale(en|tc|sc)?`} component={()=><SSOStatus ref={(ssoComponent) => {window.ssoComponent = ssoComponent}}/>}></Route>
              <Route path={`${routePathTo}/:locale(en|tc|sc)?`} component={LangHandler}></Route>
              <Route path={`${routePathTo}/:locale(en|tc|sc)?`} component={Header}></Route>
              <Switch>
                <Route exact path={`${routePathTo}/en`} strict={true} render={props => (
                  <Redirect to={`${routePathTo}/en/${props.location.search}`} />
                )}/>
                <Route exact path={`${routePathTo}/tc`} strict={true} render={props => (
                  <Redirect to={`${routePathTo}/tc/${props.location.search}`} />
                )}/>
                <Route exact path={`${routePathTo}/sc`} strict={true} render={props => (
                  <Redirect to={`${routePathTo}/sc/${props.location.search}`} />
                )}/>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)?`} component={ (window.location.hostname === config.events && !(process.env.REACT_APP_STAGE).includes('preview')) ? ()=><EventsItems pagename="tdcevents"/> : Home}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_business}`} component={Business}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_events}`} component={()=><EventsItems pagename="tdcevents"/>}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_hkevents}`} component={()=><EventsItems pagename="hkevents"/>}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_outsideevents}`} component={()=><EventsItems pagename="osevents"/>}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_serviceTradeEvents}`} component={()=><EventsItems pagename="serviceTradeEvents"/>}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_merchandiseTradeEvents}`} component={()=><EventsItems pagename="merchandiseTradeEvents"/>}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_services}`} component={Services}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_insights}`} component={Insights}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_search}`} component={SearchResult}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_mobileapp}`} component={MobileApp}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/${config.pagename_successStories}`} component={SuccessStories}></Route>  
                
                {/* HK Advantages special routing*/}
                {/* <Route exact path={`${routePathTo}/:locale(en|tc|sc)/:pagename(hkadvantages`} component={StaticPages}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/hkadvantages/:pagename(wellness)`} component={StaticPages}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/hkadvantages/:pagename(innovation)`} component={StaticPages}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/hkadvantages/:pagename(sustainability)`} component={StaticPages}></Route> */}

                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/hkadvantages`} component={AdvantagesMainPage}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/hkadvantages/:pagename`} component={AdvantagesThematicPage}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/s/:pagename`} component={StaticPages}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/factsheet/:pagename`} component={FactsheetPages}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/video/:pagename`} component={WebcastPages}></Route>
                <Route exact path={`${routePathTo}/:locale(en|tc|sc)/podcast/:pagename`} component={PodcastPages}></Route>
                <Route exact path={`${routePathTo}/:path`} render={props => (
                  <Redirect to={`${routePathTo}/en/${props.match.params.path}`} />
                )}/>
                <Route component={NoMatch} />
              </Switch>
              <Route path={`${routePathTo}/:locale(en|tc|sc)?`} component={Footer}></Route>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
