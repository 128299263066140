import * as types from '../../constants/actionTypes.js';

const ssoReadyStatusReducer = function (state = {
    ssoReadyStatus: false,
}
    , action = null) {
    switch (action.type) {
        case types.SSO_READY_STATUS:
            return Object.assign({}, state, { ssoReadyStatus: action.data });
        default:
            return state;
    }
};

export default ssoReadyStatusReducer;