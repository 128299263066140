import config from '../../config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Link from '../../util/Link';
import logo from '../../assets/images/logo.svg';

class HeaderLogo extends Component {
  render() {

    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    
    return (
      <Link to={config.www} className="header-logo">
        <img src={logo} className="header-logo__img" alt={static_content.main_logo_alt} />
        {/* <small className="header-logo__tag">Your Business Our Mission</small> */}
      </Link>
    );
  }
}

const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(HeaderLogo);