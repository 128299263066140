import React, { Component } from 'react';

import FooterBarContainer from '../../containers/FooterBarContainer';
import SitemapContainer from '../../containers/SitemapContainer';
import FooterBottomContainer from '../../containers/FooterBottomContainer';
import ScrollAnimation from 'react-animate-on-scroll';
import ScrollToTop from 'react-scroll-up';
import config from '../../config';


export default class Header extends Component {
  render() {
    return (
      <footer data-webtrend-group={config.WTTealiumGroupName.footer}>
        <FooterBarContainer />
        <ScrollAnimation animateIn="fadeIn" animateOnce className="footer__body">
          <SitemapContainer />
          <FooterBottomContainer location={this.props.location} />
        </ScrollAnimation>
        <ScrollToTop showUnder={160}>
          <span id="backtotop">UP</span>
        </ScrollToTop>
      </footer>
    );
  }
}