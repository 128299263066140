import * as types from '../constants/actionTypes';
import config from '../config';
import {axiosAdapter} from '../util/adapters';

function requestWebMetaData() {
	return { type: types.REQ_WEB_META_DATA }
};

function receiveWebMetaData(json) {
	return {
		type: types.RECV_WEB_META_DATA,
		data: json
	}
};

function receiveWebMetaError(json) {
	return {
		type: types.RECV_WEB_META_ERROR,
		data: json
	}
};

export function fetchWebMetaData() {
	return function (dispatch) {
		dispatch(requestWebMetaData());
		return axiosAdapter({
			url: config.json_seo
		})
		.then(function (response) {
			dispatch(receiveWebMetaData(response.data.result.assets[0].content.Content));
		})
		.catch(function (response) {
			dispatch(receiveWebMetaError(response.data || response.message));
		})
	}
};