import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import SuccessStoriesItems from "../components/SuccessStoriesItems/";
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
	pageSSItemsReducer: state.pageSSItemsReducer,
	cmsData: state.pageSSReducer,
	i18n: state.i18n,
	metaReducer: state.metaReducer,
	allowCookiesStatusReducer: state.allowCookiesStatusReducer,
	getSSFiltersReducer: state.getSSFiltersReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(SuccessStoriesItems);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

const ComponentWithRouter = withRouter(ComponentWithErrorBoundary)

export default ComponentWithRouter;