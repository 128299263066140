import config from '../../config';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import PaginationContainer from '../../containers/PaginationContainer';
import EventListDefault from './EventListDefault';
import EventListOthers from './EventListOthers';
import { stringBytes } from '../../util/helper';

import ScrollableAnchor from 'react-scrollable-anchor';

class PageEventList extends Component {
  constructor(props) {
		super(props);
		const queryData = this.props.queryData.data || {};
		this.state = { 
			q: queryData.q || '',
			industries: queryData.industries || '',
			eventFormat: queryData.eventFormat || '',
			countryRegion: queryData.countryRegion || '',
			year: queryData.year || '',
			location: queryData.location || '',
			page: queryData.p || '',
			keyword: queryData.q || '',
			charError: false
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.queryData !== prevProps.queryData) {
			const queryData = this.props.queryData.data || {};
			this.setState({
				page: queryData.p || '',
				location: queryData.location || '',
				industries: queryData.industries || '',
				eventFormat: queryData.eventFormat || '',
				countryRegion: queryData.countryRegion || '',
				year: queryData.year || '',
				q: queryData.q || ''
			});
		}
	}

	selectFunc = (e, v) => {
		this.setState({
			[v]: e.target.value
		}, () => {
			this.pushURL();
		});		
	}

	currentPageName = (page) => {
		switch (page) {
			case 'hkevents':
			return config.pagename_hkevents;
			case 'osevents':
			return config.pagename_outsideevents;
			case 'serviceTradeEvents':
			return config.pagename_serviceTradeEvents;
			case 'merchandiseTradeEvents':
			return config.pagename_merchandiseTradeEvents;
			case 'tdcevents':
			default:
			return config.urlEvent;
		}
	}

	pushURL = () => {
		let query = [];
		const state = this.state;
		const _props = this.props;
		Object.keys(state).forEach(function (key) {
			if (state[key] && state[key] !== '' && key !== 'keyword' && key!== 'page') {
				if (key === 'location') {
					// only TDCEvents has location filter
					if (_props.page === 'tdcevents') {
						query.push(key + '=' + encodeURIComponent(state[key]));
					}
				} else {
					query.push(key + '=' + encodeURIComponent(state[key]));
				}
			}
		});
		const queryString = query.length ? '?' + query.join('&') : '';

		if (window.location.hostname === config.home || window.location.hostname === config.events) {
			// same domain
			this.props.history.push(this.props.location.pathname + queryString);
		} else {
			// different domain
			window.location.href = config.full_home + '/' + this.props.i18n.locale + '/' + this.currentPageName(this.props.page) + queryString;
		}
	}

	handleSubmit = (event) => {
		const kCount = stringBytes(this.state.keyword);
		if (kCount >= config.search_min_char || kCount === 0) { // empty keyword as reset, special case for join jump page only
			this.setState({
				charError: false,
				q: this.state.keyword
			}, () => {
				this.pushURL();
			});
		} else {
			this.setState({
				charError: true
			});
		}
		
		event.preventDefault();
	}

	handleChange = (event) => {
		this.setState({
			charError: false,
			keyword: event.target.value
		});
	}
	
	renderFilterOption = (v, options, state) => {
		let result = [];
		const params = new URLSearchParams(window.location.search);
		const eventType = params.get('location');

		switch (v) {
			case 'industries':
			result.push(Object.keys(options || {}).map((item, i) =>	<option key={i} className="filter-condList__cond" value={item}>{options[item]['displayStr']}</option>))
			break;
			case 'year':
			const curYear = new Date().getFullYear();
			result.push(<option key="yLast" className="filter-condList__cond" value={(curYear-1)}>{(curYear-1)}</option>)
			result.push(<option key="yCur" className="filter-condList__cond" value={curYear} >{curYear}</option>)
			if (eventType !== 'pastevent')
			result.push(<option key="yNext" className="filter-condList__cond" value={(curYear+1)} >{(curYear+1)}</option>)
			break;
			case 'countryRegion':
				result = Object.keys(options).map((item, i) => {
					if ((state.location === 'hk' && item !== 'Hong-Kong') || 
						(state.location === 'outsidehk' && item === 'Hong-Kong')) {
						return ('');
					}
					return (<option key={'cR'+item} className="filter-condList__cond" value={item} >{options[item]['displayStr']}</option>)				
				});
			break;
			case 'eventFormat':
			default:
			result.push(Object.keys(options || {}).map((i) =>	<option key={i} className="filter-condList__cond" value={i} >{options[i]}</option>))
			break;
		}

		return result;
	}

  render() {
		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
		const state = this.state || {};
		const getSelectVal = this.selectFunc;
		const renderFilterOption = this.renderFilterOption;

		const filterObj = static_content.events.filter || {};
		let event_filter=Object.keys(config.eventsFilter || {}).map(function (i) {
			let v = config.eventsFilter[i];
			let title = state[v] ? ((v === 'year') ? state[v] : ((v === 'eventFormat') ? filterObj[v].options[state[v]]: filterObj[v].options[state[v]]['displayStr'])) : filterObj[v].title;
			return (
				<div key={'catC-'+i} className="events-filter-cat-container">
					<div className="filter-cat__title">{title}</div>
					<select value={state[v] || ''} className="events-filter-condList" onChange={(e) => getSelectVal(e, v)} aria-label={"Event Filter For "+title}>
						<option className="filter-condList__cond" value="">{filterObj[v].title}</option>
						{ renderFilterOption(v, filterObj[v].options, state) }						
					</select>
				</div>
			);
		});

		let totalPage = Math.ceil(this.props.total / config.search_result_per_page) || 1;

    return (
			<div className="page-hktdc-events-list">
			
						
				<div className={"page-events-container"}>
				<ScrollableAnchor id={'list'}>
					<div className={"events-filter"}>
						
						<div className="events-filter__title">{static_content.events.txt_filterBy}</div>
						{event_filter}
						<div className="events-search__input-holder">
						<form onSubmit={this.handleSubmit} autoComplete="off">
							<label htmlFor="events-search"><span className="visually-hidden">{static_content.events.txt_searchHints}</span></label>
							<input id="events-search" name="q" type="search" placeholder={static_content.events.txt_searchHints} className="events-serach__input" onChange={this.handleChange} value={this.state.keyword}/>
							{ this.state.charError &&
                <span className="events-search__input-Error">{static_content.searchCharErrorTxt}</span>
              }
							<button title={static_content.event_search_submit_btn_txt} type="submit" className="events-search__input-btn"></button>
							</form>
						</div>
					</div>
					</ScrollableAnchor>
					{
						this.props.isLoading ? <div className={"events-list-conatiner"}> {static_content.txt_loading} </div> : (

						 this.props.total !== 0 ? 
							( this.props.page === 'tdcevents' ?
							<EventListDefault eventResult={this.props.eventResult} total={this.props.total} location={state.location}/>
							:
								<EventListOthers eventResult={this.props.eventResult} total={this.props.total} location={state.location} page={this.props.page}/> 
							)
							:
							<div className={"events-list-conatiner"}>{static_content.events.txt_noresult}</div>
						
						)
					}
				</div>
				
						
				{ !this.props.isLoading ? <PaginationContainer goToTop={'list'} totalPage={totalPage} searchData={this.props.queryData} pathname={this.currentPageName(this.props.page)}/> : null }
			</div>
		);
  }
}
export default withRouter(PageEventList)