export const jumbotronClick = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Jumbotron %s - %s (%s)",
    "event_category": "image",
    "event_click": "1",
    "site_url": "/virtual/PHR/Jumbotron/%s.htm",
    "link_category": "Jumbotron",
    "link_name": "%s - %s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/Jumbotron/%s.htm",
    "ti": "PHR: Jumbotron %s - %s (%s)"
  }
}

export const MF = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Market Focus %s - %s (%s)",
    "event_category": "link",
    "event_click": "1",
    "site_url": "/virtual/PHR/focus/%s.htm",
    "link_category": "Market Focus",
    "link_name": "%s - %s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/focus/%s.htm",
    "ti": "PHR: Market Focus %s - %s (%s)"
  }
}

export const event_btn_download = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Jump Page Events %s- HKTDC Event listing (All Apply booth & Download form buttons)%s (%s)",
    "event_category": "link",
    "event_click": "1",
    "site_url": "/virtual/PHR/events/download%s.htm",
    "link_category": "Events Tab",
    "link_name": "%sApply Booth / Download Form Links%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/events/download%s.htm",
    "ti": "PHR: Jump Page Events %s- HKTDC Event listing (All Apply booth & Download form buttons)%s (%s)"
  }
}

export const event_btn_register = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Jump Page Events %s- HKTDC Event listing (All Registration buttons)%s (%s)",
    "event_category": "link",
    "event_click": "1",
    "site_url": "/virtual/PHR/events/registration%s.htm",
    "link_category": "Events Tab",
    "link_name": "%sRegistration Links%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/events/registration%s.htm",
    "ti": "PHR: Jump Page Events %s- HKTDC Event listing (All Registration buttons)%s (%s)"
  }
}

export const event_btn_enquire = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Jump Page Events %s- HKTDC Event listing (All Enquire buttons)%s (%s)",
    "event_category": "link",
    "event_click": "1",
    "site_url": "/virtual/PHR/events/enquire%s.htm",
    "link_category": "Events Tab",
    "link_name": "%sEnquire Links%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/events/enquire%s.htm",
    "ti": "PHR: Jump Page Events %s- HKTDC Event listing (All Enquire buttons)%s (%s)"
  }
}

export const header_search_btn = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Header%s - Search Button %s(%s)",
    "event_category": "button",
    "event_click": "1",
    "site_url": "/virtual/PHR/header/search%s.htm",
    "link_category": "Header",
    "link_name": "%sSearch%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/header/search%s.htm",
    "ti": "PHR: Header%s - Search Button %s(%s)"
  }
}

export const footer_survey_btn = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Footer%s - Survey Button %s(%s)",
    "event_category": "button",
    "event_click": "1",
    "site_url": "/virtual/PHR/footer/survey%s.htm",
    "link_category": "Footer",
    "link_name": "%sSurvey%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/footer/survey%s.htm",
    "ti": "PHR: Footer%s - Survey Button %s(%s)"
  }
}

export const searchResult = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Search keyword by search category (%s)",
    "event_category": "search",
    "event_click": "1",
    "site_url": "/virtual/PHR/search/result.htm",
    "link_category": "Search",
    "link_name": "%s",
    "search_keyword": "%s", 
    "search_category": "%s", 
    "search_results": "%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/search/result.htm",
    "ti": "PHR: Search keyword by search category (%s)",
    "oss": "%s", 
    "oss_r": "%s", 
    "search_cat": "%s"
  }
}

export const searchResult_displayViewMore = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Search result - Display view more <keyword> results from suppliers (%s)",
    "event_category": "search",
    "event_click": "1",
    "site_url": "/virtual/PHR/search/result.htm",
    "link_category": "Search More",
    "link_name": "%s",
    "search_keyword": "%s",
    "search_category": "%s",
    "search_results": "%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/search/result.htm",
    "ti": "PHR: Search result - Display view more <keyword> results from suppliers (%s)",
    "oss": "%s",
    "oss_r": "%s",
    "search_cat": "%s"
  }
}

export const searchResult_displayViewMoreClicked = {
  TEALIUM: {
    "event_action": "click",
    "event_name": "link_click",
    "event_title": "PHR: Search result - Click view more <keyword> results from suppliers (%s)",
    "event_category": "link",
    "event_click": "1",
    "site_url": "/virtual/PHR/search/result.htm",
    "link_category": "Search More Click",
    "link_name": "%s",
    "search_keyword": "%s",
    "search_category": "%s",
    "search_results": "%s"
  },
  WT: {
    "dcsuri": "/virtual/PHR/search/result.htm",
    "ti": "PHR: Search result - Click view more <keyword> results from suppliers (%s)",
    "oss": "%s",
    "oss_r": "%s",
    "search_cat": "%s"
  }
}