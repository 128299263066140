import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import MainBanner from '../components/MainBanner/MainBanner';

const mapStateToProps = state => ({
  cmsData: state.mainEventCalendarReducer,
  slides: state.mainJumbotronEventsSlidesReducer,
  allowCookiesStatusReducer: state.allowCookiesStatusReducer,
  i18n: state.i18n,
  metaReducer: state.metaReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(MainBanner);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;