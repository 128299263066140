import { Component } from 'react';
import store from '../../store/';
import { AllowCookiesStatus } from '../../actions/';

export default class Tealium extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allowCookie: false
    }
  }

  updateAllowCookie = () =>{
    if (window.allowCookie && !this.state.allowCookie) {
      this.setState({
        allowCookie: window.allowCookie
      }, () => {
        store.dispatch(AllowCookiesStatus(window.allowCookie));
      });
    }
  }
  render(){
    return ('');
  }
}