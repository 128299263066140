import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

function requestSuccessStoriesFilters() {
	return { type: types.REQ_SS_FILTERS_DATA }
};

function receiveSuccessStoriesFilters(json) {
	return {
		type: types.RECV_SS_FILTERS_DATA,
		data: json
	}
};

function receiveSuccessStoriesError(json) {
	return {
		type: types.RECV_SS_FILTERS_ERROR,
		data: json
	}
};

export function fetchSuccessStoriesFilters(data) {
	return function (dispatch) {
		dispatch(requestSuccessStoriesFilters());
		return axiosAdapter({
			url: config.api_search_ss_filters,
			params: data
		})
			.then(function (response) {
				dispatch(receiveSuccessStoriesFilters(response.data));
			})
			.catch(function (response) {
				dispatch(receiveSuccessStoriesError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};