import React, { Component } from 'react';

import FactsheetPagesContainer from '../../containers/FactsheetPagesContainer';

import { Helmet } from "react-helmet";


export default class FactsheetPages extends Component {
  
  render() {
    
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'FactsheetPages' }} />    
        <FactsheetPagesContainer />
      </main>
    );
  }
}
