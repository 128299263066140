import React, { Component } from 'react';
import config from '../../config';
import SanitizedHTML from 'react-sanitized-html';
import LazyLoad from 'react-lazyload';
import { imgOrientationClass } from '../../util/helper';

export default class PageItemChildItem extends Component {

  addClasstoImage = (e) => {
    const img = e.currentTarget;
    img.className += imgOrientationClass(img);
  }

  render() {
    const _this = this;
    const getChildCol = (num) => {
      if (num > 0) {
        if (num > 1) {
          return " item-child-item--cols";
        } else {
          return " item-child-item--col";
        }
      } else {
        return "";
      }
    };

    let keyId = this.props.curId;
    let cmsItems = this.props.childItem;
    let arrowurl = this.props.arrowUrl;

    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};

    let childTitleColor = (keyId==='2'?'origin':'white') || "origin";
    let childContentColor = childTitleColor;

    let childItemNum = cmsItems.length;

    let child_items = (cmsItems.length > 0) &&
    Object.keys(cmsItems || {}).map(function (childItemKey) {

        let childItemImg = cmsItems[childItemKey].article_image;
        let childItemImgAltText = cmsItems[childItemKey].article_image_alt_text
        let childItemTitle = cmsItems[childItemKey].article_title;
        let childItemDesc = cmsItems[childItemKey].article_description || '';
        let childItemHrefVal = cmsItems[childItemKey].article_url;
        let childItemHrefArrowColor = keyId === '2' ? 'orange' : 'white';
        let childItemHrefTxt = static_content.btnDetail;

        let researchImage = keyId === '3' ? ' fillImg' : '';

        let image;
        let title;
        let content;
        let detailsLink;

        if(childItemImg) {
          image = (
            <LazyLoad once><div className={"child-item__img"+researchImage}><a target="_blank" href={childItemHrefVal}><img src={childItemImg} alt={childItemImgAltText} onLoad={(e)=>_this.addClasstoImage(e)} /></a></div></LazyLoad>
          );
        }

        if(childItemHrefTxt) {
          detailsLink = (
            <a target="_blank" href={childItemHrefVal} className={"child-item__link child-item__link--" + childItemHrefArrowColor}>
              {childItemHrefTxt}
            </a>
          );
        }

        if(!childItemDesc) {
          title = (
            <div className={"child-item__title child-item__title--" + childTitleColor}>
              <a target="_blank" href={childItemHrefVal} className={"child-item__title child-item__title--" + childTitleColor}>{childItemTitle}</a>
              {detailsLink}
            </div>
          );
        } else {
          title = (
            <div className={"child-item__title child-item__title--" + childTitleColor}>
              <a target="_blank" href={childItemHrefVal} className={"child-item__title child-item__title--" + childTitleColor}>{childItemTitle}</a>
            </div>
          );
        }
        
        if(childItemDesc) {
          content = (
            <div className="child-item-content">
              <span className={"child-item__desc child-item__desc--" + childContentColor}><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={ childItemDesc } /></span>
              {detailsLink}
            </div>
          );
        }

        return (
          <div key={childItemKey} className="child-item-container">
            {image}
            {title}
            {content}
          </div>
        );
      });

    return (
      <div className={"item-child-item " + getChildCol(childItemNum)}>
        <div className={"child-items-container"}>
          {child_items}
        </div>
        
        {
          keyId === '3' ?
														
          <div className="child-more-item">
            <div className="item-more">
              <a target="_blank" href={arrowurl} className={"button button--ghost-white"}>
                <span>{static_content.btnLearnMore}</span>
              </a>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}