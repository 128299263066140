import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

var sprintf = require("sprintf-js").sprintf;

function requestAdvantagesMainPageData() {
	return { type: types.REQ_ADVANTAGESMAINPAGE_DATA }
};

function receiveAdvantagesMainPageData(json) {
	return {
		type: types.RECV_ADVANTAGESMAINPAGE_DATA,
		data: json
	}
};

function receiveAdvantagesMainPageError(json) {
	return {
		type: types.RECV_ADVANTAGESMAINPAGE_ERROR,
		data: json
	}
};

export function fetchAdvantagesMainPageData() {
	return function (dispatch) {
		dispatch(requestAdvantagesMainPageData());
		return axiosAdapter({
			url: sprintf(config.json_page_AdvantagesMainPage)
		})
			.then(function (response) {
				dispatch(receiveAdvantagesMainPageData(response.data.result.assets[0].content.content));
			})
			.catch(function (response) {
				dispatch(receiveAdvantagesMainPageError(response.data  || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
