import React, { Component } from 'react';
import config from '../../config';
import ScrollAnimation from 'react-animate-on-scroll';
import Link from '../../util/Link';
import FollowUs from '../Common/FollowUs';
import LazyLoad from 'react-lazyload';

export default class ChannelBar extends Component {
    
    render() {

        const {mainpage_channelBar = {}, youtube_link = '', share_wechat=''} = this.props.i18n.translations[this.props.i18n.locale] || {};

        return (
            <section data-webtrend-group={config.WTTealiumGroupName.channelBar} className="channel-bar">
                <ScrollAnimation className="content-wrapper" animateIn="fadeIn" animateOnce>
                    <div className="channel-bar__item">
                        <div className="app-intro">
                            <LazyLoad once><div className="app-intro__icon">
                                <img src={config.full_assets+mainpage_channelBar.appIcon} alt={mainpage_channelBar.appTitle} title={mainpage_channelBar.appTitle} />
                            </div></LazyLoad>
                            <div className="app-intro__intro">
                                <h3 className="app-intro__title">{mainpage_channelBar.appTitle}</h3>
                                <p className="app-intro__desc">{mainpage_channelBar.appDesc}</p>
                            </div>
                            <div className="app-intro__btn">
                                <Link to={config.pagename_mobileapp} className="button button--s button--right-arr" onClick={() => {
                                    document.querySelector('html').scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start'
                                    });
                                }}>
                                    <span>{mainpage_channelBar.appBtnTxt}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="channel-bar__item">
                        <LazyLoad once><FollowUs className="button-share__holder--big" titleFollow={mainpage_channelBar.shareTxt} weChatTxt={share_wechat} youtube_link={youtube_link} /></LazyLoad>
                    </div>
                </ScrollAnimation>
            </section>
        );
    }
}
