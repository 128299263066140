import * as types from '../constants/actionTypes';
import {axiosAdapter} from '../util/adapters';


function requestEventInifiniteScrollData() {
	return {
		type: types.REQ_EVENTS_IS_DATA
	}
};

function receiveEventInifiniteScrollData(json) {
	return {
		type: types.RECV_EVENTS_IS_DATA,
		data: json
	}
};

function receiveEventInifiniteScrollError(json) {
	return {
		type: types.RECV_EVENTS_IS_ERROR,
		data: json
	}
};

export function fetchEventInifiniteScrollData(url) {
	return function (dispatch) {
		dispatch(requestEventInifiniteScrollData());
		return axiosAdapter({
			url: url
		})
			.then(function (response) {
				dispatch(receiveEventInifiniteScrollData(response.data));
			})
			.catch(function (response) {
				dispatch(receiveEventInifiniteScrollError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
