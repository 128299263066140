import React, { Component } from "react";
import store from "../../store/";
import { fetchSuccessStoriesData, fetchWebMetaData, fetchSuccessStoriesItemsData } from "../../actions/";
import config from '../../config';
import PageIntroContainer from '../../containers/PageIntroContainer';
import PaginationContainer from '../../containers/PaginationContainer';
import SuccessStoriesFilters from "./SuccessStoriesFilters";
import MetaHelmet from "../PageItem/MetaHelmet";
import truncate from 'truncate-html';
import LazyLoad from 'react-lazyload';
import ScrollableAnchor from 'react-scrollable-anchor';

export default class SuccessStoriesItems extends Component {
	constructor(props) {
		super(props);
		let metaReady = true;
		this.getSelectVal = this.getSelectVal.bind(this);
		store.dispatch(fetchSuccessStoriesData()); // Teamsite CMS
		const queryData = this.prepareQueryData(this.props.location.search);
		store.dispatch(fetchSuccessStoriesItemsData(queryData.parseData));

		if (props.metaReducer.data.length <= 0) {
			store.dispatch(fetchWebMetaData());
			metaReady = false;
		}
		let params = new URLSearchParams(this.props.location.search);

		this.state = {
			page: params.get('p') || '',
			queryData: queryData,
			industries: params.get('industries') || '',
			regions: params.get('regions') || '',
			themes: params.get('themes') || '',
			metaReady: metaReady
		};
	}

	prepareQueryData(data){
		let paramsString = data;
		let params = new URLSearchParams(paramsString);
		let page = params.get('p') || '1';
		let industries = params.get('industries') || '';
		let regions = params.get('regions') || '';
		let themes = params.get('themes') || '';

		let queryData = {
			data: {
				"industries": industries,
				"regions": regions,
				"themes": themes,
				"language": config.api_lang_map[this.props.i18n.locale],
				"p": (parseInt(page, 10)).toString()
			},
			parseData: {
				"industries": industries,
				"regions": regions,
				"themes": themes,
				"language": config.api_lang_map[this.props.i18n.locale],
				"sort": '-publishDate',
				"offset": (parseInt(page, 10)).toString(),
				"limit": config.ss_per_page
			}
		}

		// The api only returns all non-null values, which means you may have to check whether the fields are undefined.
		if (industries === '') delete queryData.parseData.industries;
		if (regions === '') delete queryData.parseData.regions;
		if (themes === '') delete queryData.parseData.themes;
		return queryData;
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== prevProps.location.search) {
			let queryData = this.prepareQueryData(this.props.location.search);
			this.setState({
				queryData: queryData
			});
			store.dispatch(fetchSuccessStoriesItemsData(queryData.parseData));
		}

		if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
			setTimeout(() => {
				if (window.utag_data) {
					console.log('Tealium JUMP SS page load Sent:', new Date())
					window.utag_data = {
						"site_subsection": config.tealium.jumpSS.subsection,
						"page_type": config.tealium.jumpSS.pageType,
						"page_name": config.tealium.jumpSS.pageName
					};
					window.enableTealium()
				}
			}, 1000);
		}
		
	}

	componentDidMount(){
		// Send data to WebTrends server on didMount if this is not landing page
		// if (this.state.metaReady) {
		// 		console.log('JUMP SS WT sent on DidMount Internal Link: ', new Date());
		// 		window.sendWebTrendsData();
		// }
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
				if (window.utag) {
					console.log('Tealium JUMP SS Internal Link Sent:', new Date())
					window.utag.view({
						"site_subsection": config.tealium.jumpSS.subsection,
						"page_type": config.tealium.jumpSS.pageType,
						"page_name": config.tealium.jumpSS.pageName
					});
				}
		}
	}
	// sendWebTrend = (l) => {
	// 	if (l > 0 && !this.state.metaReady) {
	// 		// ensure only send once
	// 		this.setState({
	// 			metaReady: true
	// 		}, () => {
	// 			setTimeout(() => {
	// 				console.log('JUMP SS WT sent in render page load: ', new Date());
	// 				window.sendWebTrendsData();
	// 			}, 500);
	// 		})
	// 	}
	// }

	getSelectVal(e,v) {
		this.setState({
			[v]: e.target.value
		}, () => {
			this.pushURL();
		})
	}

	pushURL = () => {
		let query = [];
		const state = this.state;
		Object.keys(state).forEach(function (key) {
			if (state[key] && state[key] !== '' && key !== 'page' && key !== 'queryData' && key!=='metaReady') {
				query.push(key + '=' + encodeURIComponent(state[key]));
			}
		});
		const queryString = query.length ? '?' + query.join('&') : '';
		this.props.history.push(this.props.location.pathname + queryString);
	}

	render() {
    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese
		const cmsData = this.props.cmsData.data[this.props.i18n.locale] || {};

		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};

		const state = this.state;
		const getValFunc = this.getSelectVal;
		const { data = {}, isLoading = false} = this.props.pageSSItemsReducer || {};
		const { hits = {}, total = 0 } = data || {};

		let story_list = Object.keys(hits || {}).map(function (storyKey) {
				let storyVal = hits[storyKey];
				let storyDesc = storyVal.description || storyVal.bodyShortForm || '';
				const title = truncate(storyVal.title,{length: config.char_limit.ss_title});
				storyDesc = truncate(storyDesc,{length: config.char_limit.ss_desc});

				/* PHR-584 - Display media02 image in success stories jumping page */
				const imgSrc = storyVal.media02Url ? storyVal.media02Url : (storyVal.media01Url || '');
				const imgAlt = storyVal.media02Alt ? storyVal.media02Alt : storyVal.media01Alt;

				/* PHR-545
				const industries = storyVal.industries || [];
				const regions = storyVal.regionsForDisplay || [];

				const tags = industries.concat(regions);
				let story_tags = Object.keys(tags || []).map(function (tagKey) {
						return (
							<div key={tagKey} className="story-tag">
								<div className={"tag__link"}>{tags[tagKey]}</div>
							</div>
						)
					});*/
				return (
					<div key={storyKey} className={"story-container"}>
					{
						imgSrc !== '' && 
						<LazyLoad once><div className={"story-img"}><a href={storyVal.landingUrl} target="_blank"><img src={imgSrc} alt={imgAlt} title={imgAlt} /></a></div></LazyLoad>
					}						
						<div className={"story-title"}><a href={storyVal.landingUrl} target="_blank">{title}</a></div>
						<div className={"story-desc"}>{storyDesc} <a href={storyVal.landingUrl} target="_blank">{static_content.btnDetail}</a></div>
						<div className={"story-tags"}>{/* (PHR-545: div is needed for spacing) story_tags*/}</div>
					</div>
				);
			});

		let curPage = this.state.page || '';
		let totalPage = Math.ceil(total / config.ss_per_page) || 1;
		if (!curPage) { curPage = 1 };
		if (!totalPage) { totalPage = 1 };

		const {success_stories = {}} = this.props.metaReducer.data[this.props.i18n.locale] || {};

		return (
			<div>
				<PageIntroContainer title={static_content.title_successStories} desc={cmsData.header_description} pageCat={"success_stories"}/>
				<ScrollableAnchor id={'list'}>
				<section data-webtrend-group={config.WTTealiumGroupName.pageSS} className={"sec-page-story"}>
					<MetaHelmet metaData={success_stories} locale={this.props.i18n.locale}  />
					
					<SuccessStoriesFilters industries={this.state.industries} themes={this.state.themes} regions={this.state.regions} getValFunc={getValFunc}/>
					
					
					{
						isLoading ? <div className={"story-list loading"}>{static_content.txt_loading}</div> : (

						 total !== 0 ? 
							
								<div className={"story-list"}>{story_list}</div>
							:
							<div className={"story-list"}>{static_content.events.txt_noresult}</div>
						
						)
					}
					
					{ (!isLoading) && <PaginationContainer goToTop={'list'} totalPage={totalPage} searchData={this.state.queryData} pathname={config.pagename_successStories}/> }
				</section>
				</ScrollableAnchor>
			</div>
		);
	}
}