import * as types from "../../constants/actionTypes";
import config from "../../config";
import { axiosAdapter } from "../../util/adapters";

var sprintf = require("sprintf-js").sprintf;

function requestPodcastPagesData() {
  return { type: types.REQ_PODCASTPAGES_DATA };
}

function receivePodcastPagesData(json) {
  return {
    type: types.RECV_PODCASTPAGES_DATA,
    data: json,
  };
}

function receivePodcastPagesError(json) {
  return {
    type: types.RECV_PODCASTPAGES_ERROR,
    data: json,
  };
}

export function fetchPodcastPagesData(pagename) {
  return function (dispatch) {
    dispatch(requestPodcastPagesData());
    let apiLink = sprintf(config.api_podcast, pagename);
    return axiosAdapter({
      url: apiLink,
    })
      .then(function (response) {
        dispatch(
          receivePodcastPagesData(
            response.data.result.assets[0].content.content
          )
        );
      })
      .catch(function (response) {
        dispatch(receivePodcastPagesError(response.data || response.message));
        //dispatch(pushState(null,'/error'));
      });
  };
}
