import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

function requestEvents() {
	return { type: types.REQ_EVENTS_DATA }
};

function receiveEvents(json) {
	return {
		type: types.RECV_EVENTS_DATA,
		data: json
	}
};

function receiveEventsError(json) {
	return {
		type: types.RECV_EVENTS_ERROR,
		data: json
	}
};



export function getEvents(data) {
	return function (dispatch) {
		dispatch(requestEvents());
		return axiosAdapter({
			url: config.api_search_events,
			params:data		
		})
		.then(function (response) {
			dispatch(receiveEvents(response.data));
		})
		.catch(function (response) {
			dispatch(receiveEventsError(response.data || response.message));
			//dispatch(pushState(null,'/error'));
		})
	}
};