/* "Mobile App" static page.*/

import config from '../../config';
import React, { Component } from 'react';
import store from '../../store/';
import { fetchPageMobileAppData, fetchWebMetaData } from '../../actions/';
import PageIntroContainer from '../../containers/PageIntroContainer';
import SanitizedHTML from 'react-sanitized-html';
import MetaHelmet from "../PageItem/MetaHelmet";
import LazyLoad from 'react-lazyload';

export default class AppIntro extends Component {
    constructor(props) {
        super(props);
        let metaReady = true;
        store.dispatch(fetchPageMobileAppData()); // Teamsite CMS
        if (props.metaReducer.data.length <= 0) {
            store.dispatch(fetchWebMetaData());
            metaReady = false;
        }
        this.state = {
            metaReady: metaReady
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
            setTimeout(() => {
                if (window.utag_data) {
                    console.log('Tealium Mobile App page load Sent:', new Date())
                    window.utag_data = {
                        "site_subsection": config.tealium.mobileApp.subsection,
                        "page_type": config.tealium.mobileApp.pageType,
                        "page_name": config.tealium.mobileApp.pageName
                    };
                    window.enableTealium()
                }
            }, 1000);
        }
    
    }

    componentDidMount(){
        // Send data to WebTrends server on didMount if this is not landing page
        // if (this.state.metaReady) {
        //     console.log('Mobile App WT sent on DidMount Internal Link: ', new Date());
        //     window.sendWebTrendsData();
        // }
        if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
            if (window.utag) {
                console.log('Tealium Mobile App Internal Link Sent:', new Date())
                window.utag.view({
                    "site_subsection": config.tealium.mobileApp.subsection,
                    "page_type": config.tealium.mobileApp.pageType,
                    "page_name": config.tealium.mobileApp.pageName
                });
            }
        }
    }
    // sendWebTrend = (l) => {
    //     if (l > 0 && !this.state.metaReady) {
    //         // ensure only send once
    //         this.setState({
    //             metaReady: true
    //         }, () => {
    //             setTimeout(() => {
    //                 console.log('Mobile App WT sent in render page load: ', new Date());
    //                 window.sendWebTrendsData();
    //             }, 500);
    //         })
    //     }
    // }
    render() {
        const cmsData = this.props.cmsData.data[this.props.i18n.locale] || {};
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};

        let itemElems = [];
        if (cmsData.mobile_app_container) {
            for (let key in cmsData.mobile_app_container) {
            
                let cmsItem = cmsData.mobile_app_container[key];
                let big_icon = cmsItem.app_icon;
                let app_name = cmsItem.app_name;
                let big_thumb = cmsItem.screenshot_image;
                const big_thumb_alt_text = cmsItem.screenshot_image_alt_text;
                let title = cmsItem.title;
                let description = cmsItem.description
                let txt_color = key==='0'? 'black' : 'white';
                let background_color = key === '0' ? '#ffede5' : '';
                // let background_image = key === '0' ? '' : '/images/mobile-app/bg-mobile-app-'+(parseInt(key, 10)+1)+'.jpg';
                let background_image = (cmsItem.background_image) ? cmsItem.background_image : '';
                let background_image_display = 'url(' + background_image + ')';
                let ios_link = cmsItem.ios_link;
                let android_link = cmsItem.android_link;
                let other_links = cmsItem.other_links ? cmsItem.other_links.other_link : null;
                let imgFocusX = '50%';
                let imgFocusY = '50%';
                let links_title = static_content.btnDownload;

                let itemClassName = "app-sec__item";
                if (txt_color) {
                    itemClassName += " app-sec__item--" + txt_color;
                }

                itemElems.push(<LazyLoad once offset={100} key={key}><div className={itemClassName} style={{ "backgroundColor": background_color, "backgroundImage": background_image_display, "backgroundPositionX": imgFocusX + "%", "backgroundPositionY": imgFocusY + "%" }}>
                    <div className="app-sec__inner">
                        <LazyLoad once><div className="app-sec__img">
                            <img src={big_thumb} alt={big_thumb_alt_text} title={big_thumb_alt_text}/>
                        </div></LazyLoad>
                        <div className="app-sec__detail">
                            <img className="app-sec__icon" src={big_icon} alt="" />
                            <h3 className="app-sec__big-title">{app_name}</h3>
                            <div className="app-sec__detail-items">
                                <div className="app-sec__detail-item">
                                    {title !== ''&&<h4 className="app-sec__detail-title">{title}</h4>}
                                    <div className="app-sec__detail-desc"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={ description } /></div>
                                </div>
                            </div>
                            <div className="app-sec__links">
                                <h4 className="app-sec__links-title">{links_title}</h4>
                                {ios_link !== "" ? (
                                    <a href={ios_link} className="app-sec__link" target="_blank">
                                        <img className="app-sec__link-icon" src={config.full_assets+"/images/mobile-app/icon-apple-"+(key==="0"?"black":"white")+".svg"} alt={"IOS Download Image For "+links_title} />
                                        <span>iOS</span>
                                    </a>
                                ) : null }
                                {android_link !== "" ? (
                                    <a href={android_link} className="app-sec__link" target="_blank">
                                        <img className="app-sec__link-icon" src={config.full_assets+"/images/mobile-app/icon-android-"+(key==="0"?"black":"white")+".svg"} alt={"Android Download Image For "+links_title} />
                                        <span>Android</span>
                                    </a>
                                ) : null }
                            </div>
                            {Array.isArray(other_links) && (
                                <div className='app-sec__other-links'>
                                    {other_links.map(link => (
                                        <a href={link.other_app_link} target='_blank' className='app-sec__other-links-item'>
                                            <img src={link.other_app_icon} />
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div></LazyLoad>);
            }

        }
        const {mobile_app = {}} = this.props.metaReducer.data[this.props.i18n.locale] || {};

        return (
            <div>
                <PageIntroContainer title={static_content.title_mobileapp} desc={cmsData.header_description} pageCat={"mobile_app"}/>
                <div data-webtrend-group={config.WTTealiumGroupName.mobile} className="app-sec">
                    <MetaHelmet metaData={mobile_app} locale={this.props.i18n.locale}  />
                    <div className="app-sec__items">
                        {itemElems}
                    </div>
                </div>
            </div>
        )
    }
}
