import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ConformanceContainer from '../../containers/ConformanceContainer';
import Link from '../../util/Link';
import config from '../../config';
import { version } from '../../../package.json';
import FollowUs from '../Common/FollowUs';
import LazyLoad from 'react-lazyload';

class FooterBottom extends Component {

  constructor(props) {
    super(props);
    this.state = {
      curURL: window.location.href
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        curURL: window.location.href
      })
    }
  }

  render() {
    const that = this;
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const serverTime = new Date();
    const { footerBottom = {} } = static_content || {};

    const logo = footerBottom.logo;
    const logoMobile = footerBottom.logoMobile;
    const links = footerBottom.links;
    const tncLinks = footerBottom.tncLinks;
    const langLinks = config.langLinks;
    const titleFollow = footerBottom.title_follow;
    const copyRight = footerBottom.copyRight;
    const copyRightTitle = (footerBottom.copyRightTitle).replace('%CURRENTYEAR%', serverTime.getFullYear());
    let logoW = 0;
    let logoH = 0;
    let logoMobileW = 0;
    let logoMobileH = 0;

    // Version string for non PROD environment.
    let versionElement = (null);
    //TODO: store static "production" in enum instead of plain text
    if (process.env.REACT_APP_STAGE !== "build_prod") {
      versionElement = (
        <div className="footer-bottom__row">
          <p className="">
            Version : {version}
          </p>
        </div>
      )
    } //else no need

    switch (this.props.i18n.locale) {
      case "sc":
      case "tc":
        logoW = 71;
        logoH = 71;
        break;
      default:        
        logoW = 135;
        logoH = 70;
        break;
    }

    return (
      <div className="footer-bottom">
        <div className="content-wrapper">
          <Link to={config.www} className="footer-bottom__logo" title={static_content.main_logo_alt}> {/*need change to target blank*/}
            <span className="visually-hidden">{static_content.main_logo_alt}</span>
            <LazyLoad once><img src={config.full_home + logo} width={logoW} height={logoH} className="footer-bottom__logo-img-dt" alt={static_content.main_logo_alt} /></LazyLoad>
            <LazyLoad once><img src={config.full_home + logoMobile} className="footer-bottom__logo-img-mb" alt={static_content.main_logo_alt} /></LazyLoad>
          </Link>
          <ConformanceContainer />
          <div className="footer-bottom__row">
            {Object.keys(links || {}).map(function (key) {
              let value = links[key];
              let txt = value.txt;
              let href = value.href;
              if (value.is_contact) {
                href += encodeURIComponent(that.state.curURL);
              }
              let hideInMobile = value.hideInMobile || '';
              let mobClass = (hideInMobile === "true") ? " footer-bottom__item--hidden-mb" : "";

              // let isTextsize = href.indexOf('/textsize')>-1;
              let isInternalLink = (href.substr(0, 4) !== 'http') ? true : false;
              return (
                <div key={key} className={"footer-bottom__item" + mobClass}>
                  {(isInternalLink) ?
                    <Link to={href} key={key} className="footer-bottom__link">{txt}</Link>
                    :
                    <a target="_blank" rel="noopener noreferrer" href={href} className="footer-bottom__link">{txt}</a>
                  }
                </div>
              );
            })}
          </div>
          <div className="footer-bottom__row footer-bottom__row--lang">
            {Object.keys(langLinks || {}).map(function (key) {
              let value = langLinks[key];
              let txt = value.txt;
              let href = value.href;
              let hideInMobile = value.hideInMobile || '';
              let mobClass = (hideInMobile === "true") ? " footer-bottom__item--hidden-mb" : "";
              let isInternalLink = (href.substr(0, 4) !== 'http') ? true : false;
              return (
                <div key={key} className={"footer-bottom__item" + mobClass}>
                  {(isInternalLink) ?
                    <Link to={href} key={key} className="footer-bottom__link">{txt}</Link>
                    :
                    <a target="_blank" rel="noopener noreferrer" href={href} className="footer-bottom__link">{txt}</a>
                  }
                </div>
              );
            })}
          </div>
          <div className="footer-bottom__row">
            {Object.keys(tncLinks || {}).map(function (key) {
              let value = tncLinks[key];
              let txt = value.txt;
              let href = value.href;
              let hideInMobile = value.hideInMobile || '';
              let mobClass = (hideInMobile === "true") ? " footer-bottom__item--hidden-mb" : "";
              let isInternalLink = (href.substr(0, 4) !== 'http') ? true : false;
              return (
                <div key={key} className={"footer-bottom__item" + mobClass}>
                  {(isInternalLink) ?
                    <Link to={href} key={key} className="footer-bottom__link">{txt}</Link>
                    :
                    <a target="_blank" rel="noopener noreferrer" href={href} className="footer-bottom__link">{txt}</a>
                  }
                </div>
              );
            })}
          </div>
          <FollowUs titleFollow={titleFollow} weChatTxt={static_content.share_wechat} youtube_link={static_content.youtube_link} />
          <div className="footer-bottom__row">
            <p className="footer-bottom__copyright">
              <span className="footer-bottom__copyright-title">{copyRightTitle}</span>
              {copyRight}
            </p>
          </div>
          {versionElement}
        </div>
      </div>
    );
  }
}
export default withRouter(FooterBottom);