import config from '../config';

const addDomain = (location = {}, lang = 'en') => {

    // construct URL
    function _URLQueryString(that) {
        let q = that.q;
        if (that.is_Preview) {
            const previewDate = new URLSearchParams(window.location.search).get('previewDate') || '';
            if (previewDate || (window.location.search).includes('previewDate')) {
                let search_params = new URLSearchParams(that.q);
                search_params.set('previewDate', previewDate);
                q = '?' + search_params.toString();
            }
        }
        if (that.p === config.www) {
            // HKTDC logo hyperlink (Header & Footer)
            return (that.lang === 'en' && !that.is_Preview ? '/' : that.fl) + q;
        } else if (that.dd === config.full_home) {
            return (that.url.includes(that.lang + '/') ? '/' : that.fl) + that.p + q;
        } else {
            return (that.lang === 'en' ? '/' : that.fl) + q;
        }
    }

    // determine has domain or not and which domain name
    function _domainDestination(that) {
        let domain = config.full_home;

        if (((that.url).includes(config.pagename_events) || (that.url).includes(config.urlEvent)) && !that.is_Preview) {
            domain = config.full_events;
        }
        if ((that.url).includes(config.www) && that.q === '') {
            domain = config.full_www;
        }

        return domain;
    }

    // determine should include domain name to URL or not
    function _is_UnderSameDomain(url) {
        // <Link> 'to' props (url): there is no domain included when the URL is under HOME domain.

        if (url.includes(window.location.hostname)) {
            // only event + www 'URL' contain domain name in <LINK>. 
            // Check if url contains current hostname then `domain name (full path)` should not be added to addDomain
            // URL under home domain never falls into this condition
            return true;
        } else if (url.includes(config.pagename_events) && window.location.hostname === config.events) {
            // event pages
            // https://event.hktdc.com/
            // https://event.hktdc.com/?location=hk
            // https://event.hktdc.com/?location=outsidehk
            return true;
        } else if (url.includes(config.www) || url.includes(config.urlEvent) || url.includes(config.pagename_events)) {
            // handle www and event domain only: 
            // URL destination is www or event but current hostname is different
            return false;
        } else if (window.location.hostname !== config.home) {
            // this is HOME's URL but under different hostname 
            return false;
        } else {
            // this is HOME's URL and under same hostname
            return true;
        }
    }

    const url = location.pathname;
    const pathname = location.pathname + location.search;
    const formatedLang = '/' + lang + '/';
    const is_Preview = (process.env.REACT_APP_STAGE).includes('preview');
    const is_UnderSameDomain = _is_UnderSameDomain(url);
    const domainDestination = _domainDestination({url: url, is_Preview: is_Preview, q: location.search});
    const URLQueryString = _URLQueryString({dd: domainDestination, url: url, lang: lang, fl: formatedLang, p: location.pathname, q: location.search, is_Preview: is_Preview});

    const prefixURL = is_UnderSameDomain ? config.subDirectoryPath : domainDestination;

    if (url.includes(config.urlEvent) || (window.location.hostname === config.events && url === '/' && !is_Preview)) {
        // special handling for event pagination links
        return url.includes(lang + '/') || (pathname.replace(config.urlEvent, ''));
    } else {
        return prefixURL + URLQueryString;
    }
}

export default addDomain;