import React, { Component } from 'react';
import Link from '../../util/Link';
import config from '../../config';

export default class Sitemap extends Component {
  
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content

    const sitemapCols = static_content.navLinks;
    const cmsNavLinks = this.props.headerFooterReducer.data[this.props.i18n.locale] || {}; // get Teamsite CMS Data
    const { main_menu_5_container = [] }= cmsNavLinks.footer || [];

    let count = 1;

    return (
      <div className="sitemap" key="sitemap">
        <div className="content-wrapper">
          <div className="sitemap__row">
            {Object.keys(sitemapCols || {}).map(function (key) {
              let value = sitemapCols[key];
              let headStrong = value.big_title;
              let headTxt = value.small_title;
              let headHref = config['pagename_'+key];
              let {sub_menu_container = {}, right_quick_links_container = {} } = cmsNavLinks['navigation_menu_0' + count] || {}; // Teamsite CMS
              
              count++;

              let childs = [];

              switch (key){
                case "events":
                // Join event page, special handling while the links are static                
                if (value.links) {
                  childs.push(<Link key="sitemaplinkhktdc" to={config.pagename_events} className="sitemap__link">{value.links[0]}</Link>);
                  childs.push(<Link key="sitemaplinkhk" to={config.pagename_events+'?location=hk'} className="sitemap__link">{value.links[1]}</Link>);
                  childs.push(<Link key="sitemaplinkoutsidehk" to={config.pagename_events+'?location=outsidehk'} className="sitemap__link">{value.links[2]}</Link>);
                  childs.push(<Link key="link_eventspastevent" to={config.pagename_events+'?location=pastevent'} className="sitemap__link">{value.links[3]}</Link>);
                }     
                break;
                default:
                for (let subkey in sub_menu_container) {
                  let childVal = sub_menu_container[subkey];
                  let txt = childVal.sub_menu_title;
                  let href = childVal.sub_menu_url || childVal.sub_menu_title_link;

                  childs.push(<a key={'link1'+subkey} href={href} className="sitemap__link" target="_blank">{txt}</a>);
                }
                for (let subkeyR in right_quick_links_container) {
                  let childVal = right_quick_links_container[subkeyR];
                  let txt = childVal.quick_link_title;
                  let href = childVal.quick_link_url;

                  childs.push(<a key={'link2'+subkeyR} href={href} className="sitemap__link" target="_blank">{txt}</a>);
                }
                break;
              }
              
              return (
                <div key={'col'+key} className="sitemap__col">
                  <Link to={headHref} className="sitemap__head"><strong>{headStrong}</strong>{headTxt}</Link>      
                  {childs}
                </div>
              );
            })}
            { /* COLUMN 5th */    }
            <div className="sitemap__col">
              <div className="sitemap__quicklinks">
                {Object.keys(main_menu_5_container || {}).map(function (key) {
                  let value = main_menu_5_container[key];
                  let txt = value.sub_menu_title;
                  let href = value.sub_menu_url;
                  return (
                    <a key={key} href={href} className="sitemap__quicklink" target="_blank">{txt}</a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}