import * as types from '../../constants/actionTypes.js';

const announcementReducer = function (state = {
    isLoading: false,
    data: [],
    error: false
}
    , action = null) {
    switch (action.type) {
        case types.RECV_ANNOUNCEMENT_ERROR:
            return Object.assign({}, state, { isLoading: false, data: action.data, error: true});
        case types.RECV_ANNOUNCEMENT_DATA:
            return Object.assign({}, state, { isLoading: false, data: action.data, error: false });
        case types.REQ_ANNOUNCEMENT_DATA:
            return Object.assign({}, state, { isLoading: true, error: false });
        default:
            return state;
    }
};

export default announcementReducer;