import * as types from '../../constants/actionTypes.js';

const pageStaticPagesReducer = function (state = {
    isLoading: false,
    data: [],
    error: false
}
    , action = null) {
    switch (action.type) {
        case types.RECV_STATICPAGES_ERROR:
            return Object.assign({}, state, { isLoading: false, data: action.data, error: true });
        case types.RECV_STATICPAGES_DATA:
            return Object.assign({}, state, { isLoading: false, data: action.data, error: false });
        case types.REQ_STATICPAGES_DATA:
            return Object.assign({}, state, { isLoading: true, error: false });
        default:
            return state;
    }
};

export default pageStaticPagesReducer;