import { withRouter } from 'react-router-dom'
import config from '../../config';
import React, { Component } from 'react';
import store from '../../store/';
import { fetchTopSearchData } from '../../actions/';
// import Link from '../../util/Link';
import * as h from '../../util/helper';

var sprintf = require("sprintf-js").sprintf;

class PageItemSearch extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.state = { keyword: '', 'showSearchAutocomplete': false, charError: false, type: 'Product-Search' };
    store.dispatch(fetchTopSearchData()); // Teamsite CMS
  }

  handleChange = (e) => {
    this.setState({
      charError: false,
      keyword: e.target.value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.keyword !== "") {
      if (h.stringBytes(this.state.keyword) >= config.search_min_char) {
        // this.setState({
        //   keyword: h.whitelistchars(this.state.keyword)
        // });
        /* PHR-564 - Direct to sourcing search result, don't use window.open in case it is blocked in most modern browsers.*/
        // this.props.history.push(config.pagename_search + '?type=suppliers&q=' + h.filterThenEncode(this.state.keyword));
        h.createAelement(sprintf(config.sourcejump_searchurl, config.sourcejumpsearch_lang_map[this.props.i18n.locale || 'en'], this.state.type, h.filterThenEncode(this.state.keyword)));
      } else {
        this.setState({
          charError: true
        });
      }
    }
  }

  handleOptionChange = (e) => {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const type = e.target.value;
    this.setState({
      type: e.target.value
    }, () => {
      document.getElementById("page-search").placeholder = `${static_content.searchTxt}${static_content['search'+type.replace('-Search', '')]}`;
    });
  }

  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const type = this.state.type;
    return (
      <div className="item-search">
        <div className="item-search__panel">
          <form
            onSubmit={this.handleSubmit}
            className="item-search__wrapper"
            autoComplete="off"
          >
            <div className="item-search__input-holder">
              <div className="item-search__filters">
                <div className="item-search__filter">
                  <input
                    id="item-search-filter-product"
                    tabindex="-1"
                    name="type"
                    type="radio"
                    value="Product-Search"
                    defaultChecked={(type==='Product-Search'?  true : false)}
                    onChange={this.handleOptionChange}
                  />
                  <label for="item-search-filter-product">
                    {static_content.searchProduct.trim()}<span className="visually-hidden">{static_content.searchProduct.trim()}</span>
                  </label>
                </div>
                <div className="item-search__filter">
                  <input
                    id="item-search-filter-service"
                    tabindex="-1"
                    name="type"
                    type="radio"
                    value="Service-Search"
                    defaultChecked={(type==='Service-Search'?  true : false)}
                    onChange={this.handleOptionChange}
                  />
                  <label for="item-search-filter-service">
                    {static_content.searchService.trim()}<span className="visually-hidden">{static_content.searchService.trim()}</span>
                  </label>
                </div>
                <div className="item-search__filter">
                  <input
                    id="item-search-filter-supplier"
                    tabindex="-1"
                    name="type"
                    type="radio"
                    value="Supplier-Search"
                    defaultChecked={(type==='Supplier-Search'?  true : false)}
                    onChange={this.handleOptionChange}
                  />
                  <label for="item-search-filter-supplier">
                    {static_content.searchSupplier.trim()}<span className="visually-hidden">{static_content.searchSupplier.trim()}</span>
                  </label>
                </div>
              </div>
              <label htmlFor="page-search">
                <span className="visually-hidden">
                  {static_content.hidden_source_search_bar_label}
                </span>
              </label>
              <input
                id="page-search"
                name="q"
                type="search"
                placeholder={`${static_content.searchTxt}${static_content.searchProduct}`}
                className="item-search__input"
                onChange={this.handleChange.bind(this)}
                value={this.state.keyword}
              />

              {this.state.charError && (
                <span className="item-search__input-Error">
                  {static_content.searchCharErrorTxt}
                </span>
              )}
              <button
                title={static_content.source_search_submit_btn_txt}
                type="submit"
                className="item-search__input-btn"
              ></button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default withRouter(PageItemSearch)