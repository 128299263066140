import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

function requestSuccessStoriesData() {
	return { type: types.REQ_SS_DATA }
};

function receiveSuccessStoriesData(json) {
	return {
		type: types.RECV_SS_DATA,
		data: json
	}
};

function receiveSuccessStoriesError(json) {
	return {
		type: types.RECV_SS_ERROR,
		data: json
	}
};

export function fetchSuccessStoriesData() {
	return function (dispatch) {
		dispatch(requestSuccessStoriesData());
		return axiosAdapter({
			url: config.json_page_ss
		})
			.then(function (response) {
				dispatch(receiveSuccessStoriesData(response.data.result.assets[0].content.Content));
			})
			.catch(function (response) {
				dispatch(receiveSuccessStoriesError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};


// Get SS Items

function requestSuccessStoriesItemsData() {
	return { type: types.REQ_SSITEMS_DATA }
};

function receiveSuccessStoriesItemsData(json) {
	return {
		type: types.RECV_SSITEMS_DATA,
		data: json
	}
};

function receiveSuccessStoriesItemsError(json) {
	return {
		type: types.RECV_SSITEMS_ERROR,
		data: json
	}
};

export function fetchSuccessStoriesItemsData(data) {
	return function (dispatch) {
		dispatch(requestSuccessStoriesItemsData());
		return axiosAdapter({
			url: config.api_search_ss,
			params:data
		})
			.then(function (response) {
				dispatch(receiveSuccessStoriesItemsData(response.data));
			})
			.catch(function (response) {
				dispatch(receiveSuccessStoriesItemsError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};