import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import MainNav from '../components/Header/MainNav';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
  headerFooterReducer: state.headerFooterReducer,
  i18n: state.i18n
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(MainNav);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

const ComponentWithRouter = withRouter(ComponentWithErrorBoundary)

export default ComponentWithRouter;