import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

var sprintf = require("sprintf-js").sprintf;

function requestStaticPagesData() {
	return { type: types.REQ_STATICPAGES_DATA }
};

function receiveStaticPagesData(json) {
	return {
		type: types.RECV_STATICPAGES_DATA,
		data: json
	}
};

function receiveStaticPagesError(json) {
	return {
		type: types.RECV_STATICPAGES_ERROR,
		data: json
	}
};

export function fetchStaticPagesData(pagename) {
	return function (dispatch) {
		dispatch(requestStaticPagesData());
		return axiosAdapter({
			url: sprintf(config.json_page_staticPages, pagename)
		})
			.then(function (response) {
				dispatch(receiveStaticPagesData(response.data.result.assets[0].content.Content));
			})
			.catch(function (response) {
				dispatch(receiveStaticPagesError(response.data  || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
