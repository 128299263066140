import * as types from '../constants/actionTypes';
import {axiosAdapter} from '../util/adapters';

function requestSearchPreferredListing() {
	return { type: types.REQ_SEARCH_PREFERRED_LISTING }
};

function receiveSearchPreferredListing(json) {
	return {
		type: types.RECV_SEARCH_PREFERRED_LISTING,
		data: json
	}
};

function receiveSearchPreferredListingError(json) {
	return {
		type: types.RECV_SEARCH_PREFERRED_LISTING_ERROR,
		data: json
	}
};

export function getSearchPreferredListing(url,data) {
	return function (dispatch) {
		dispatch(requestSearchPreferredListing());
		return axiosAdapter({
			url: url,
			params:data
		})
			.then(function (response) {
				dispatch(receiveSearchPreferredListing(response.data));
			})
			.catch(function (response) {
				dispatch(receiveSearchPreferredListingError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};