import * as types from '../../constants/actionTypes';
import {axiosAdapter} from '../../util/adapters';


function requestAnnouncementData() {
	return { type: types.REQ_ANNOUNCEMENT_DATA }
};

function receiveAnnouncementData(json) {
	return {
		type: types.RECV_ANNOUNCEMENT_DATA,
		data: json
	}
};

function receiveAnnouncementError(json) {
	return {
		type: types.RECV_ANNOUNCEMENT_ERROR,
		data: json
	}
};

export function fetchAnnouncementData(url) {
	return function (dispatch) {
		dispatch(requestAnnouncementData());
		return axiosAdapter({
			url: url
		})
			.then(function (response) {
				const assets = (((response || {}).data || {}).result || {}).assets || [];
				dispatch(receiveAnnouncementData(assets.map(asset => {
					return {
						id: asset.id,
						...asset.content.Content,
						latestModifiedDate: new Date(asset.metadata.LatestModifiedDate).getTime()
					}
				})));
			})
			.catch(function (response) {
				dispatch(receiveAnnouncementError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
