import React, { Component } from 'react';
import { fetchMainSSData } from '../../actions/';
import config from '../../config';
import store from '../../store/';
import ScrollAnimation from 'react-animate-on-scroll';
import Link from '../../util/Link';
import PlaceholderComponent from '../Common/PlaceholderComponent';

export default class SuccessStories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderInit: false,
            mousePause: false
        };
        store.dispatch(fetchMainSSData()); // Teamsite CMS
    }

    togglePause = () =>{
        this.setState({mousePause: !this.state.mousePause});
    }

    initSwiper(_this) {
        let mainBanner = document.getElementById("success-stories-slider");		
		if (mainBanner && mainBanner.swiper) {
			mainBanner.swiper.destroy(false, false);
		}
        var autoplayTime = 5000;
        var swiper = new window.Swiper(mainBanner, {
            spaceBetween: 50,
            slidesPerView: 3,
            slidesPerGroup: 3,
            loop: true,
            //effect: 'fade',
            watchSlidesProgress:true,
            watchSlidesVisibility: true,
            simulateTouch: true,
            autoplay: {
                delay: autoplayTime,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.success-stories__btn-next',
                prevEl: '.success-stories__btn-prev',
            },
            pagination: {
                el: '.success-stories__pagination',
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                1240: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                }
            },
            // Disable preloading of all images
            preloadImages: false,
            // Enable lazy loading
            lazy: {
                preloaderClass: 'ss'
            }
            /*
            ,
            fadeEffect: {
                crossFade: true
            }
            */
        });
        swiper.on('init', function () {
            swiper.update();
        });
    }

    componentDidUpdate() {

        let _this = this;
        let _thisInitSwiper = this.initSwiper;
        if (!_this.state.sliderInit && !_this.props.cmsData.isLoading) {
            _thisInitSwiper(_this);
            _this.setState({
                sliderInit: true
            });
        }

        if (typeof document.getElementById("success-stories-slider").swiper !== "undefined") {
            if (this.state.mousePause) {
                document.getElementById("success-stories-slider").swiper.autoplay.stop();
            } else {
                document.getElementById("success-stories-slider").swiper.autoplay.start();
            }
        }
    }

    render() {

        const {success_stories_container} = this.props.cmsData.data[this.props.i18n.locale] || {};
        const staticContent = this.props.i18n.translations[this.props.i18n.locale] || {};

        const item_slider = Object.keys(success_stories_container || {}).map(function (key) {
            const value = success_stories_container[key];
            return (
                <div key={key} className="success-stories__slide swiper-slide">
                    <div className="success-stories__img">
                        <a href={value.url} target="_blank" title={value.alt_text}>
                            <img key={"ssimg-"+key} data-src={value.thumbnail} alt={value.alt_text} className="swiper-lazy"/>
                            <PlaceholderComponent loadingClass="ss" loadingTxt={staticContent.txt_loading}/>
                        </a>
                    </div>
                    <div className="success-stories__info">
                        <h3 className="success-stories__info-title"><a href={value.url} target="_blank">{value.title}</a></h3>
                        <p className="success-stories__info-desc"><span>{value.description} </span><a className="success-stories__info-href" href={value.url} target="_blank">{staticContent.btnDetail}</a></p>
                    </div>
                </div>  
            );
        });

        return (
            <section data-webtrend-group={config.WTTealiumGroupName.mainSS} className="success-stories">
                <ScrollAnimation className="content-wrapper" animateIn="fadeIn" animateOnce>
                    <div className="side-title">{staticContent.title_successStories}</div>
                    <h2 className="success-stories__title">{staticContent.title_successStories}</h2>
                    <div className="success-stories__top">
                        <div id="success-stories-slider" className="success-stories__slider">
                            <div className={this.state.mousePause ? 'swiper-wrapper pause-active' : 'swiper-wrapper'}>
                                {this.props.cmsData.isLoading ? <div className="main-banner__loading">{staticContent.txt_loading}</div> : '' }
                                {item_slider}
                            </div>
                        </div>
                        <div className="success-stories__control">
                            <div tabIndex="0" className={this.state.mousePause ? 'success-stories__slider-pause is-active' : 'success-stories__slider-pause'} onClick={this.togglePause} onKeyPress={this.togglePause}>
                                <div className="pause-button"><span className="visually-hidden">{staticContent.pause_btn}</span></div>
                            </div>
                            <button title={staticContent.slider_button_prev} tabIndex="0" onClick={e => { e.preventDefault();}} className="success-stories__btn-prev">{staticContent.slider_button_prev}</button>
                            <button title={staticContent.slider_button_next} tabIndex="0" onClick={e => { e.preventDefault();}} className="success-stories__btn-next">{staticContent.slider_button_next}</button> 
                            <div className="success-stories__pagination"></div>
                        </div>
                    </div>
                    <div className="success-stories__bottom">
                        <div className="success-stories__btn-row">
                            <Link to={config.pagename_successStories} className="button button--right-arr"><span>{staticContent.btnMore}</span></Link>
                        </div>
                    </div>
                </ScrollAnimation>
            </section>
        );
    }
}
