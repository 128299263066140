import * as types from '../constants/actionTypes.js';

const getSearchDataReducer = function (state = {
    searchData: []}
    , action = null) {
    switch (action.type) {
        case types.GET_SEARCH_DATA:
            return Object.assign({}, state, {
                searchData: action.data
            });
        default:
            return state;
    }
};

export default getSearchDataReducer;