// @flow
import * as React from "react";
import { PureComponent } from "react";
import config from "../config";

export default class AdsysBanner extends PureComponent {
  adapLang(lang) {
    const langObj = {
      en: "en_gb",
      tc: "zh_hant",
      sc: "zh_hans",
    };
    return langObj[lang];
  }

  componentDidMount() {
    const initAdGlare = (lang) => {
      console.log("initAdGlare");
      console.log(
        `${config.adGlare_host}?${config.adGlare_id}&keywords=${this.adapLang(
          lang
        )}`
      );

      console.log(config);
      (function (r, d, u) {
        const s = d.createElement(r);
        s.async = true;
        s.setAttribute("data-cfasync", false);
        u += `&cb=${Date.now()}${Math.random()}`;
        s.src = u;
        const n = d.getElementsByTagName(r)[0];
        n.parentNode.insertBefore(s, n);
      })(
        "script",
        document,
        `${config.adGlare_host}?${config.adGlare_id}&keywords=${this.adapLang(
          lang
        )}`
      );
    };

    initAdGlare(this.props.lang);
  }

  componentDidUpdate() {
    console.log("updateAdGlare");
    window.AdGlare.API.Refresh(config.adGlare_id, {
      keywords: this.adapLang(this.props.lang),
    });
  }

  render() {
    return <div id={`zone${config.adGlare_id}`} />;
  }
}
