import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

function requestMFData() {
	return { type: types.REQ_MAINPAGE_MF_DATA }
};

function receiveMFData(json) {
	return {
		type: types.RECV_MAINPAGE_MF_DATA,
		data: json
	}
};

function receiveMFError(json) {
	return {
		type: types.RECV_MAINPAGE_MF_ERROR,
		data: json
	}
};

export function fetchMFData() {
	return function (dispatch) {
		dispatch(requestMFData());
		return axiosAdapter({
			url: config.json_main_mf
		})
			.then(function (response) {
				dispatch(receiveMFData(response.data.result.assets[0].content.Content));
			})
			.catch(function (response) {
				dispatch(receiveMFError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
