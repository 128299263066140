import * as types from '../constants/actionTypes.js';

const getSearchPreferredListingReducer = function (state = {
    isLoading: false,
    data: [],
    error: false
}
    , action = null) {
    switch (action.type) {
        case types.RECV_SEARCH_PREFERRED_LISTING_ERROR:
            return Object.assign({}, state, { isLoading: false, data: action.data, error: true });
        case types.RECV_SEARCH_PREFERRED_LISTING:
            const data = action.data;
            const adsys = (data && data.adsysList) ? data.adsysList : [];
            const preferred = (data && data.preferredList) ? data.preferredList : [];
            return Object.assign({}, state, { isLoading: false, data: adsys.concat(preferred), error: false });
        case types.REQ_SEARCH_PREFERRED_LISTING:
            return Object.assign({}, state, { isLoading: true, error: false });
        default:
            return state;
    }
};

export default getSearchPreferredListingReducer;