import React, { Component } from "react";
import { fetchStartData } from "../../actions/";
import config from '../../config';
import store from '../../store/';
import { fetchWebMetaData } from "../../actions/";

import PageIntroContainer from '../../containers/PageIntroContainer';

import ScrollAnimation from 'react-animate-on-scroll';
import SanitizedHTML from 'react-sanitized-html';
import MetaHelmet from "../PageItem/MetaHelmet";
import LazyLoad from 'react-lazyload';

export default class BusinessItems extends Component {
	constructor(props) {
		super(props);
		let metaReady = true;
		store.dispatch(fetchStartData()); // Teamsite CMS
		if (props.metaReducer.data.length <= 0) {
			store.dispatch(fetchWebMetaData());
			metaReady = false;
		}
		this.state = {
			metaReady: metaReady
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
			setTimeout(() => {
				if (window.utag_data) {
					console.log('Tealium JUMP START page load Sent:', new Date())
					window.utag_data = {
						"site_subsection": config.tealium.start.subsection,
						"page_type": config.tealium.start.pageType,
						"page_name": config.tealium.start.pageName
					};
					window.enableTealium()
				}
			}, 1000);
		}
	
	}

	componentDidMount(){
		// Send data to WebTrends server on didMount if this is not landing page
		// if (this.state.metaReady) {
		// 		console.log('JUMP Start WT sent on DidMount Internal Link: ', new Date());
		// 		window.sendWebTrendsData();
		// }
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
				if (window.utag) {
					console.log('Tealium JUMP Start Internal Link Sent:', new Date())
					window.utag.view({
						"site_subsection": config.tealium.start.subsection,
						"page_type": config.tealium.start.pageType,
						"page_name": config.tealium.start.pageName
					});
				}
		}
	}
	// sendWebTrend = (l) => {
	// 	if (l > 0 && !this.state.metaReady) {
	// 		// ensure only send once
	// 		this.setState({
	// 			metaReady: true
	// 		}, () => {
	// 			setTimeout(() => {
	// 				console.log('JUMP Start WT sent in render page load: ', new Date());
	// 				window.sendWebTrendsData();
	// 			}, 500);
	// 		})
	// 	}
	// }

	render() {
		const getCols = (str) => ({
			"1": "cols",
			"2": "full",
			"3": "cols",
			"5": "full",
			default: "full"
		})[str];

		const getLinksCols = (str1, str2) => {
			if(str1%3===0){
				if (str2 > 4) {
					return "item--full__links--cols";
				} else {
					return "item--full__links--col";
				}
			} else {
				return "item--cols__links";
			}
		}
		const {header_description, blocks_container} = this.props.cmsData.data[this.props.i18n.locale] || {};

		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};
		let sub_item = [];
				
		const {business = {}} = this.props.metaReducer.data[this.props.i18n.locale] || {};

		return (
			<div>		
				<PageIntroContainer title={static_content.navLinks.business.big_title+static_content.navLinks.business.small_title} desc={header_description} pageCat={"business"}/>
				<section data-webtrend-group={config.WTTealiumGroupName.start} className="page-items">
				<MetaHelmet metaData={business} locale={this.props.i18n.locale}  />
					<div className="items-container">
								{Object.keys(blocks_container || {}).map(function (sbKey) {
									// console.log(blocks_container[sbKey])
									if (sbKey === '2' || sbKey === '3' || sbKey === '5') sub_item = [];
									let cmsItemVal = blocks_container[sbKey]; // CMS Data
									let itemTitle = cmsItemVal.title;
									let itemDesc = cmsItemVal.description;
									let itemBgImgX = sbKey === '5' ? '100': 'origin';
									let itemBgImgY = 'origin';
									let titleColor = sbKey === '2' ? 'orange' : sbKey === '5' ? 'origin' : 'white';
									let contentColor = sbKey === '2' ? 'origin' : titleColor;
									let itemBg = config.business_bg[sbKey] || {};
									let itemBgColor = itemBg.backgroundColor || {};
									let itemBgImg = `url(${(itemBg.backgroundImg)? (config.full_assets + itemBg.backgroundImg) : ""})`;
									let itemLogo = cmsItemVal.logo_image || "";


									let itemHrefVal = cmsItemVal.arrow_url;
									let itemHrefMoreColor = (sbKey === '2' || sbKey === '5') ? 'ghost-orange' : 'ghost-white';

									let itemLinksTitleColor = sbKey === '5' ? 'orange' : titleColor;
									let hyperlinkColor = contentColor;
									let itemLinksTitle = static_content.title_quicklinks;
									let itemLinksVal = cmsItemVal.quick_links_container;
									let itemLinksNum = itemLinksVal.length;
									let hasItemLinks = itemLinksNum>0;

									let item_link = Object.keys(itemLinksVal || {}).map(function (linkKey) {
										let linkVal = itemLinksVal[linkKey];
										let linkTxt = linkVal.quick_link_title;
										let linkHref = linkVal.quick_link_url;
										return (
											<li key={linkKey}><a href={linkHref} target="_blank" className="item-links__link" style={{ "color": hyperlinkColor }}>{linkTxt}</a></li>
										);
									});
									
									sub_item.push(
										<LazyLoad once key={'bitem'+sbKey}><div
											className={
												(itemBg.backgroundImg) ?
												"item-container item-container--bg item-container--bg--x-"+itemBgImgX+" item-container--bg--y-"+itemBgImgY
												: ((itemBg.backgroundColor) ? "item-container item-container--bg--color" : "item-container")
											} style={
												(itemBg.backgroundImg) ?
												({ "backgroundImage": itemBgImg })
												: ({ "backgroundColor": itemBgColor})
											}>
												<div className={"item-content "+getLinksCols(sbKey, itemLinksNum)}>
												{
														// 2 Blocks in a row
														['0','1','3','4'].indexOf(sbKey) > -1 ? [
															<div key={'0'+sbKey} className={"side-title"}>{itemTitle}</div>,
															itemLogo !== "" ? (<div className={"item-icon"}><img src={itemLogo} alt={itemTitle}/></div>) : null,
															<div key={'1'+sbKey} className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>,															
															<div key={'3'+sbKey} className={"item-content__desc item-content__desc--"+contentColor}>
																<SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={ itemDesc } />
															</div>,
															cmsItemVal.tips_title !== "" && cmsItemVal.tips_description !== "" ? (<div className="item-tips"><div className={"item-tips__title item-tips__title--"+titleColor}>{cmsItemVal.tips_title}</div><div className="item-tips__desc"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={ cmsItemVal.tips_descriptions } /></div></div>) : null,
															hasItemLinks ?
																<div key={'4'+sbKey} className={"item-links"}>
																	<div className={"item-links__title item-links__title--"+itemLinksTitleColor}>{itemLinksTitle}</div>
																	{(getCols(sbKey)==='full') 
																		? ((itemLinksNum < 5)
																			? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																			: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
																		)
																		: ((itemLinksNum < 3)
																			? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																			: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
																		)
																	}
																</div> : null,
															itemHrefVal && itemHrefVal!=='' ?
																<div key={'44'+sbKey} className="item-more">
																	<a href={itemHrefVal} className={"button button--" + itemHrefMoreColor} target="_blank">
																		<span>{static_content.btnLearnMore}</span>
																	</a>
																</div> : null
														] :
														[
															<div key={'00'+sbKey} className="item-content__left">
															{
																itemTitle
																? <div className={"side-title"}>{itemTitle}</div>
																: null
															}
															{
																itemLogo !== ""
																? (<div className={"item-icon"}><img src={itemLogo} alt={itemTitle}/></div>)
																: null
															}
															<div key={'11'+sbKey} className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>
															<div className={"item-content__desc item-content__desc--"+contentColor}>
																<SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={ itemDesc } />
															</div>
														</div>,
														<div key={'33'+sbKey} className="item-content__right">															
															{hasItemLinks?
																<div className={"item-links"}>
																	<div className={"item-links__title item-links__title--"+itemLinksTitleColor}>{itemLinksTitle}</div>
																	{(itemLinksNum < 5)
																			? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																			: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
																	}
																	
																</div>
															: null
															}
														</div>,
															itemHrefVal && itemHrefVal!=='' ?
															<div key={'444'+sbKey} className="item-more">
																<a href={itemHrefVal} className={"button button--" + itemHrefMoreColor} target="_blank">
																	<span>{static_content.btnLearnMore}</span>
																</a>
															</div>
															: null
														
															]
												}
												</div>
											</div>
											</LazyLoad>
									);
									return(
										(sbKey !== '0' && sbKey !== '4') ? 
											<ScrollAnimation key={sbKey} animateIn="fadeIn" animateOnce className={"items-row items-row--"+getCols(sbKey)}>
												<div className="row-container business-item">{sub_item}</div>
											</ScrollAnimation>
										: null
									);
								})
								}
							
					</div>
				</section>
			</div>
		);
	}
}