import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import QRCode from 'qrcode.react';

class FollowUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weChatModalIsOpen: false
        };
    }

    onOpenModal = () => {
        this.setState({ weChatModalIsOpen: true });
    };

    onCloseModal = () => {
        this.setState({ weChatModalIsOpen: false });
    };

    render() {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content

        const sprintf = require("sprintf-js").sprintf;
        const alt_fb = sprintf(static_content.followus_alt, static_content.media_alt.Facebook);
        const alt_tw = sprintf(static_content.followus_alt, static_content.media_alt.Twitter);
        const alt_lk = sprintf(static_content.followus_alt, static_content.media_alt.Linkedin);
        const alt_wb = sprintf(static_content.followus_alt, static_content.media_alt.Weibo);
        const alt_wc = sprintf(static_content.followus_alt, static_content.media_alt.Wechat);
        const alt_yt = sprintf(static_content.followus_alt, static_content.media_alt.Youtube);
        const alt_yk = sprintf(static_content.followus_alt, static_content.media_alt.Youku);
        return (
            <div className={"button-share__holder " + this.props.className}>
                <div className="button-share__title">{this.props.titleFollow}</div>
                <div className="button-share__btn-holder">
                    <a target="_blank" href="https://www.facebook.com/hktdc.hk/" className="button-share button-share--fb" rel="noopener noreferrer" title={alt_fb}><span className="visually-hidden">{alt_fb}</span></a>
                    <a target="_blank" href="https://twitter.com/hktdc" className="button-share button-share--tw" rel="noopener noreferrer" title={alt_tw}><span className="visually-hidden">{alt_tw}</span></a>
                    <a target="_blank" href="https://www.linkedin.com/company/hong-kong-trade-development-council/?originalSubdomain=hk" className="button-share button-share--ln" rel="noopener noreferrer" title={alt_lk}><span className="visually-hidden">{alt_lk}</span></a>
                    <a target="_blank" href="https://www.weibo.com/hktdc" className="button-share button-share--wb" rel="noopener noreferrer" title={alt_wb}><span className="visually-hidden">{alt_wb}</span></a>
                    <button title={alt_wc} onClick={this.onOpenModal} className="button-share button-share--wc"><span className="visually-hidden">{alt_wc}</span></button>
                    <a target="_blank" href={this.props.youtube_link} className="button-share button-share--yt" rel="noopener noreferrer" title={alt_yt}><span className="visually-hidden">{alt_yt}</span></a>
                    <a target="_blank" href="https://www.youku.com/profile/index?uid=UMTI4ODMxMzQ3Mg==" className="button-share button-share--yk" rel="noopener noreferrer" title={alt_yk}><span className="visually-hidden">{alt_yk}</span></a>
                    <Modal open={this.state.weChatModalIsOpen} onClose={this.onCloseModal} center>
                        <div className={"button-share__wcqr"}>
                            <QRCode value="http://weixin.qq.com/r/ty_Dm73EIeBerfn493ql" />
                            <p className="button-share__wcqr-txt">{this.props.weChatTxt}</p>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    i18n: state.i18n
  });
  
export default connect(mapStateToProps, null, null, {
    pure: true
})(FollowUs);