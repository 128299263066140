import { Component } from 'react';
import store from '../../store/';
import { SSOReadyStatus } from '../../actions/';

export default class SSOStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssoReady: false,
    };
  }

  createSSOClient = async () => {
    console.log(
      process.env.REACT_APP_SSO_REDIRECT_URI,
      process.env.REACT_APP_SSO_BFF_CLIENT_ID
    );
    window.ssoClient = await window.sso.createSSOClient({
      redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
      bff_client_id: process.env.REACT_APP_SSO_BFF_CLIENT_ID,
    });
  };

  initSSOClient() {
    console.log('sso client init')
    this.createSSOClient();
  }

  updateSSOReady = () => {
    console.log('SSO updateSSOReady', window.ssoReady, this.state.ssoReady);
    if (window.ssoReady && !this.state.ssoReady) {
      console.log('SSO BEFORE SET STATE', window.ssoReady);
      this.setState(
        {
          ssoReady: window.ssoReady,
        },
        () => {
          console.log('SSO DISPATCH', window.ssoReady);
          store.dispatch(SSOReadyStatus(window.ssoReady));
        }
      );
    }
  };

  render() {
    return '';
  }
}
