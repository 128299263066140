import React from 'react';
import style from './Loading.module.scss'; // Import css modules stylesheet as styles

const Loading = ({ msg, ...props }) => {
  return (
    <div className={style.loader}>
      <div className={style.content}>
        <div className={style.loading} />
        {/* <p className={style.message}>Loading</p> */}
      </div>
    </div>
  );
};

export default Loading;
