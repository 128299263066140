import config from '../../config';
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import store from "../../store/";
import { fetchJoinData, getEvents } from "../../actions/";
import { fetchWebMetaData } from "../../actions/";
import { connect } from 'react-redux';

import PageAdsContainer from '../../containers/PageAdsContainer';
import PageMultiSliderContainer from "../../containers/PageMultiSliderContainer";
import PageEventListContainer from "../../containers/PageEventListContainer";
import MoreEventsCatContainer from '../../containers/MoreEventsCatContainer';
import PageIntroContainer from '../../containers/PageIntroContainer';
import EventsFilter from './EventsFilter';

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";
import 'url-search-params-polyfill';
import MetaHelmet from "../PageItem/MetaHelmet";

class EventsItems extends Component {
	constructor(props) {
		super(props);
		let metaReady = true;
		if (props.metaReducer.data.length <= 0) {
			store.dispatch(fetchWebMetaData());
			metaReady = false;
		}
		const queryData = this.prepareQueryData(this.props.location.search);
		store.dispatch(fetchJoinData()); // Teamsite CMS
		store.dispatch(getEvents(queryData.parseData));
		this.state = {
			title: queryData.data.location,
			tabsShow: (this.props.pagename === 'tdcevents' ? true : false),
			curCat: this.props.pagename,
			queryData: queryData,
			metaReady: metaReady
		};
	}

	componentDidMount() {
		// Send data to WebTrends server on didMount if this is not landing page
		// if (this.state.metaReady) {
		// 	console.log('Event WT sent on DidMount Internal Link: ', new Date());
		// 	window.sendWebTrendsData();
		// }
		console.log('pagename', this.props.pagename, 'osevents')
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
			if (window.utag) {
				console.log('Tealium Event Internal Link Sent:', new Date())
				window.utag.view({
					"site_subsection": this.props.pagename === 'hkevents' ? config.tealium.hkEvents.subsection : this.props.pagename === 'osevents' ? config.tealium.hkOutsideEvents.subsection : config.tealium.events.subsection,
					"page_type": this.props.pagename === 'hkevents' ? config.tealium.hkEvents.pageType : this.props.pagename === 'osevents' ? config.tealium.hkOutsideEvents.pageType : config.tealium.events.pageType,
					"page_name": this.props.pagename === 'hkevents' ? config.tealium.hkEvents.pageName : this.props.pagename === 'osevents' ? config.tealium.hkOutsideEvents.pageName : config.tealium.events.pageName,
				});
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.search !== prevProps.location.search) {
			let queryData = this.prepareQueryData(this.props.location.search);
			this.setState({
				queryData: queryData,
				title: queryData.data.location
			});
			store.dispatch(getEvents(queryData.parseData));
			// Send data to WebTrends server
			// window.sendWebTrendsData();
			// console.log('Event is changed WT sent: ', new Date());
		}
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
			setTimeout(() => {
				if (window.utag_data) {
					console.log('Tealium Event page load Sent:', new Date())
					window.utag_data = {
						"site_subsection": this.props.pagename === 'hkevents' ? config.tealium.hkEvents.subsection : this.props.pagename === 'osevents' ? config.tealium.hkOutsideEvents.subsection : config.tealium.events.subsection,
					"page_type": this.props.pagename === 'hkevents' ? config.tealium.hkEvents.pageType : this.props.pagename === 'osevents' ? config.tealium.hkOutsideEvents.pageType : config.tealium.events.pageType,
					"page_name": this.props.pagename === 'hkevents' ? config.tealium.hkEvents.pageName : this.props.pagename === 'osevents' ? config.tealium.hkOutsideEvents.pageName : config.tealium.events.pageName,
					};
					window.enableTealium()
				}
			}, 1000);
		}
		if (this.props.metaReducer.isLoading !== prevProps.metaReducer.isLoading && this.props.metaReducer.isLoading === false) {
			// Meta data
			const { hktdcevent = {}, moreeventshk = {}, moreeventsoutside = {}, serviceTradeEvents = {}, merchandiseTradeEvents = {} } = this.props.metaReducer.data[this.props.i18n.locale] || {};
			let pageMeta = '';
			switch (this.props.pagename) {
				case 'osevents':
					pageMeta = moreeventsoutside;
					break;
				case 'hkevents':
					pageMeta = moreeventshk;
					break;
				case 'serviceTradeEvents':
					pageMeta = serviceTradeEvents;
					break;
				case 'merchandiseTradeEvents':
					pageMeta = merchandiseTradeEvents;
					break;
				default:
					pageMeta = hktdcevent;
					break;
			}
		}
	}
	sendWebTrend = (l) => {
		if (l > 0 && !this.state.metaReady) {
			// ensure only send once
			this.setState({
				metaReady: true
			}, () => {
				setTimeout(() => {
					console.log('Event WT sent in render page load: ', new Date());
					window.sendWebTrendsData();
				}, 500);
			})
		}
	}

	prepareQueryData(data) {
		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
		let paramsString = data;
		let params = new URLSearchParams(paramsString);
		let page = params.get('p') || '1';
		let searchQuery = params.get('q') || '';
		let industries = params.get('industries') || '';
		let eventFormat = params.get('eventFormat') || '';
		let countryRegion = params.get('countryRegion') || '';
		let year = params.get('year') || '';
		let location = params.get('location') ? (params.get('location') === 'hk' || params.get('location') === 'outsidehk' || params.get('location') === 'pastevent' ? params.get('location') : '') : '';
		let organizers = (this.props.pagename !== 'tdcevents') ? '' : 'hktdc, hktdc-participate';
		if (this.props.pagename === 'hkevents') {
			location = 'hk';
		}
		if (this.props.pagename === 'osevents') {
			location = 'outsidehk';
		}
		let eventDataSourceCode;
		switch (this.props.pagename) {
			case 'serviceTradeEvents':
				eventDataSourceCode = 'SP'; break;
			case 'merchandiseTradeEvents':
				eventDataSourceCode = 'PP';
				break;
			default:
				eventDataSourceCode = '';
				break;
		}

		let queryData = {
			data: {
				"q": searchQuery.trim(),
				"industries": industries,
				"eventFormat": eventFormat,
				"countryRegion": countryRegion,
				"location": location === '' ? 'all' : location,
				"year": year,
				"language": config.api_lang_map[this.props.i18n.locale],
				"p": (parseInt(page, 10)).toString()
			},
			parseData: {
				"q": searchQuery,
				"organizers": organizers,
				"industries": (static_content.events.filter.industries.options[industries]) ? static_content.events.filter.industries.options[industries]['queryStr'] : '',
				"formats": eventFormat !== '' ? static_content.events.eventFormat_map[eventFormat].join(', ') : '',
				"regions": (static_content.events.filter.countryRegion.options[countryRegion]) ? static_content.events.filter.countryRegion.options[countryRegion]['queryStr'] : '',
				"fromEventEndDate": new Date(),
				"isOutsideHk": location === 'hk' ? false : true,
				"fromEventStartDate": (year === '' ? '' : new Date(`${year}-01-01T00:00:00`).toISOString()),
				"toEventEndDate": location === 'pastevent' ? new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999)).toISOString() : (year === '' ? '' : new Date(`${year}-12-31T23:59:59`).toISOString()),
				"language": config.api_lang_map[this.props.i18n.locale],
				"offset": (parseInt(page, 10)).toString(),
				"sort": location === 'pastevent' ? '-eventEndDate' : 'eventStartDate',
				"limit": config.search_result_per_page,
				"eventDataSourceCode": eventDataSourceCode
			}
		}

		// The api only returns all non-null values, which means you may have to check whether the fields are undefined.
		if (searchQuery === '') delete queryData.parseData.q;
		if (organizers === '') delete queryData.parseData.organizers;
		if (industries === '') delete queryData.parseData.industries;
		if (eventFormat === '') delete queryData.parseData.formats;
		if (countryRegion === '') delete queryData.parseData.regions;
		if (location === '' || location === 'pastevent') delete queryData.parseData.isOutsideHk;
		if (year === '') {
			delete queryData.parseData.fromEventStartDate;
			if (location === 'pastevent') {
				delete queryData.parseData.fromEventEndDate;
			} else {
				delete queryData.parseData.toEventEndDate;
			}
		} else {
			delete queryData.parseData.fromEventEndDate;
		}
		if (eventDataSourceCode === '') delete queryData.parseData.eventDataSourceCode;
		
		const is_Preview =  (process.env.REACT_APP_STAGE).includes('preview');
		//rebuild query data if in cms preview mode 
		if (is_Preview){
 
			const cmsParseData  =  {};
			const cmsAPIQuery =  [];
			
			const cmsAPIMax  = queryData.parseData.limit;
			const cmsAPIStart = (queryData.parseData.offset-1) *  cmsAPIMax;
			const cmsAPIProject  = "/default/main/trade-event";
			const cmsAPIFormat  =  "json";
			const cmsAPIIncludeDCRContent =  "true";
			const cmsAPIContext = "/default/main/trade-event/WORKAREA/default";
			const cmsAPISameOrigin  =  "true";
			const cmsAPISort  =   "";
			cmsParseData.max  =   cmsAPIMax;
			cmsParseData.start =  cmsAPIStart;
			cmsParseData.project = cmsAPIProject;
			cmsParseData.format = cmsAPIFormat;
			cmsParseData.includeDCRContent = cmsAPIIncludeDCRContent;
			cmsParseData.context = cmsAPIContext;
			cmsParseData.sameOrigin = cmsAPISameOrigin;
			cmsParseData.sort  =   (queryData.parseData.sort === "-eventEndDate")?("-end_date"):("+start_date");

			const convertToUTC = (datetime) =>{
				const output  = (new Date(datetime).getTime() - 8*60*60*1000);
				return output;
			}
	
			cmsAPIQuery.push('(availableLanguages:'  + this.props.i18n.locale +  ')');
			cmsAPIQuery.push('(eventStatus:Y)');
			
			//check online and offine time for events. 
			cmsAPIQuery.push('(offlineTime:>'  + convertToUTC(queryData.parseData.fromEventEndDate) +  ')');
			cmsAPIQuery.push('(onlineTime:<'  +  convertToUTC(queryData.parseData.fromEventEndDate) +  ')');
			
			
			
			/* removed since metadata organiser_id does not exist in DCT yet
			if (queryData.parseData.organizers && queryData.parseData.organizers === 'hktdc, hktdc-participate'){
				 //ograniser_id 1=hktdc;2=non-hktdc;3=hktdc-participate
				cmsAPIQuery.push('(NOT organiser_id:2)');
			}
			*/
			
			//fromEventEndDate, return records with end_date > fromEventEndDate
			if (queryData.parseData.fromEventEndDate){
				cmsAPIQuery.push('(end_date:>' + convertToUTC(queryData.parseData.fromEventEndDate)  +   ')');
			}
			

			if (queryData.parseData.fromEventStartDate  && queryData.parseData.toEventEndDate ) {
				//applied only if year filter is enabled, return records with end_date > fromEventStartDate and start_date < toEventEndDate
				cmsAPIQuery.push('(end_date:>' + convertToUTC(queryData.parseData.fromEventStartDate)  +   ')');
				cmsAPIQuery.push('(start_date:<'  +  convertToUTC(queryData.parseData.toEventEndDate)  +   ')');
			} else {
				//applied if location = pastevent
				if (queryData.parseData.toEventEndDate){
					cmsAPIQuery.push('(end_date:<' + convertToUTC(queryData.parseData.toEventEndDate)  +   ')');
				}
					
			}
		 
			//determine the records is outside HK or not by searching string "Hong Kong" in location metadata
			if (queryData.parseData.isOutsideHk === true){
				cmsAPIQuery.push('(NOT location:Hong Kong)');
			}else if (queryData.parseData.isOutsideHk === false){
				cmsAPIQuery.push('(location:Hong Kong)');
			}

			//return records with location metadata contains specified value
			if (queryData.parseData.regions  &&   this.props.i18n.translations['en'] .events.filter.countryRegion["options"][countryRegion]["queryStr"] ){
				cmsAPIQuery.push('(location:' +    this.props.i18n.translations['en'] .events.filter.countryRegion["options"][countryRegion]["queryStr"] + ')');
			}
			
			//return records with event_format metadata contains specified value
			if (queryData.parseData.formats  &&   this.props.i18n.translations['en'] .events.filter.eventFormat["options"][eventFormat]){
				switch(eventFormat){
					case 'ConferenceForum':
						cmsAPIQuery.push('((event_format:Conference) OR (event_format:Forum))');
					break;
					case 'Exhibition':
						cmsAPIQuery.push('(event_format:Exhibition)');
					break;
					case 'HongKongPavilion':
						cmsAPIQuery.push('(event_format:Pavilion)');
					break;
					case 'SeminarWorkshop':
						cmsAPIQuery.push('((event_format:Seminar) OR (event_format:Workshop))');
					break;
					case 'TradeMission':
						cmsAPIQuery.push('(event_format:Mission)');
					break;
					case 'Others':
						cmsAPIQuery.push('(event_format:Others)');
					break;
				}
			}

			//return records with industry metadata contains specified value
			if (queryData.parseData.industries  &&   this.props.i18n.translations['en'] .events.filter.industries["options"][industries]['queryStr']   ){
				cmsAPIQuery.push('(industry:'  +  this.props.i18n.translations['en'] .events.filter.industries["options"][industries]['queryStr']  + ')');
			}

			//handle eventDataSource specified cases
			if (queryData.parseData.eventDataSourceCode){
				 switch (queryData.parseData.eventDataSourceCode){
					 case 'SP':
						cmsAPIQuery.push('(datasource:18)');
					 break;
					 case 'PP':
						cmsAPIQuery.push('(datasource:12)');
					 break;
				 }
			}    

			
			cmsParseData.q = "(" +  cmsAPIQuery.join(" AND ") + ")";			
			queryData.parseData = cmsParseData;
			
		}
		
		return queryData;
	}
	
	convertCMSPreviewJSON(json){
		 if(((process.env.REACT_APP_STAGE).includes('localhost') || (process.env.REACT_APP_STAGE).includes('preview') )  &&  json.result) {
			const phrAPI = {};
			phrAPI.hits  = [];
			const locale  = this.props.i18n.locale;

			function genStartDate(sTimeStamp){
				var sDate =   new Date( sTimeStamp +     8*60*60*1000 );
				var newDate = (sDate.getYear()*1 +  1900)   + "-"  + (sDate.getMonth()*1+1) + "-" +   sDate.getDate() ;
				var HKDateTime = new Date( new Date(newDate).getTime() -  24*60*60*1000 ) ;
				var outYear  = (HKDateTime.getYear() +  1900);
				var outMonth  =  (HKDateTime.getMonth() +1);
				var outDate  =  (HKDateTime.getDate());
				var out  =  outYear + "-" + outMonth.toString().padStart(2,"0") + "-" +  outDate.toString().padStart(2,"0") + "T16:00:00.000Z";
				return out;
			}

			function genEndDate(eTimeStamp){
				var eDate =   new Date( eTimeStamp +     8*60*60*1000 );
				var newDate = (eDate.getYear()*1 +  1900)   + "-"  + (eDate.getMonth()*1+1) + "-" +   eDate.getDate() ;
				var HKDateTime = new Date( new Date(newDate).getTime()   ) ;
				var outYear  = (HKDateTime.getYear() +  1900);
				var outMonth  =  (HKDateTime.getMonth() +1);
				var outDate  =  (HKDateTime.getDate());
				var out  =  outYear + "-" + outMonth.toString().padStart(2,"0") + "-" +  outDate.toString().padStart(2,"0") + "T15:59:59.999Z";
				return out;
			}

			json.result.assets.map((item)=>{
				try{
					const hitsObject  =  {};
					//handle addToCalendarInd
					(item.content.content[locale].event_details.various_buttons.event_calendar_container.event_calendar_link == 'http://')?(hitsObject.addToCalendarInd  =   false):(hitsObject.addToCalendarInd  =   true);
					hitsObject.addToCalendarUrl  =   item.content.content[locale].event_details.various_buttons.event_calendar_container.event_calendar_link;
					//handle bodyShortForm
					hitsObject.bodyShortForm   = item.content.content[locale].core_information.sub_title;
					//handle contentType , seems all are eventsch
					hitsObject.contentType = "eventsch";
					//handle description
					hitsObject.description = item.content.content[locale].core_information.article_at_glance;
					//handle displayCity
					const tempDisplayCity   =  [...item.content.content[locale].core_information.display_city.split(",") , ...item.content.content[locale].core_information.display_gbaCity.split(",")];
					hitsObject.displayCity = tempDisplayCity ; 
					hitsObject.displayCityForDisplay    =    tempDisplayCity.join(",");
					//handle docRefId
					hitsObject.docRefId  =  item.content.content.metadata.id; 
					//handle eventApplyBoothInd
					(item.content.content[locale].event_details.various_buttons.apply_booth_container.apply_booth_url == 'http://')?(hitsObject.eventApplyBoothInd =   false):(hitsObject.eventApplyBoothInd =   true);
					hitsObject.eventApplyBoothUrl  =   item.content.content[locale].event_details.various_buttons.apply_booth_container.apply_booth_url;
					//handle eventDataSource , seems all are emptyString
					hitsObject.eventDataSource  =  '';
					//handle eventDisplayMonthYearOnlyInd 
					hitsObject.eventDisplayMonthYearOnlyInd  =    item.content.content.metadata.display_month_year  == 'N' ?false:true;
					//handle eventDownloadFormInd
					hitsObject.eventDownloadFormInd  =  (item.content.content[locale].event_details.various_buttons.download_form_container.download_form_control == 'Y')?true:false;
					hitsObject.eventDownloadFormUrl  =  item.content.content[locale].event_details.various_buttons.download_form_container.path;
					//handle eventEnquiryInd
					hitsObject.eventEnquiryInd =  item.content.content[locale].event_details.various_buttons.enquire_container.enquire_control == 'N'?false:true;
					//handle eventEnquiryUrl
					hitsObject.eventEnquiryUrl = item.content.content[locale].event_details.various_buttons.enquire_container.enquire_url;
					//handle eventExhibitorListInd
					hitsObject.eventExhibitorListInd = item.content.content[locale].event_details.various_buttons.exhibitor_list_container.exhibitor_list_control == 'N'?false:true;
					//handle eventExhibitorListUrl
					hitsObject.eventExhibitorListUrl = item.content.content[locale].event_details.various_buttons.exhibitor_list_container.exhibitor_list_url;
					//handle eventFairCatalogueInd
					hitsObject.eventFairCatalogueInd = item.content.content[locale].event_details.various_buttons.fair_catalog_container.fair_catalog_ind == 'N'? false:true;
					//handle eventFairCatalogueUrl
					hitsObject.eventFairCatalogueUrl = item.content.content[locale].event_details.various_buttons.fair_catalog_container.fair_catalog_link;
					//handle eventFairCatalogueMonth
					hitsObject.eventFairCatalogueMonth = item.content.content[locale].event_details.various_buttons.fair_catalog_container.fair_catalog_month.toString();
					//handle eventFairCatalogueYear
					hitsObject.eventFairCatalogueYear = item.content.content[locale].event_details.various_buttons.fair_catalog_container.fair_catalog_year.toString();
					//handle eventFairCode
					hitsObject.eventFairCode =  item.content.content.metadata.fair_code;
					//handle eventFairEOInd
					hitsObject.eventFairEOInd  = item.metadata.eo_site  == '1'?false:true;
					//handle eventFormats
					hitsObject.eventFormats = item.content.content[locale].core_information.display_event_format.split("|");
					//handle eventIsOutsideHK
					hitsObject.eventIsOutsideHK = item.metadata.location.includes("Hong Kong")?false:true;
					//handle eventOnlineInd
					hitsObject.eventOnlineInd  =  item.content.content.general_bottom.event_status == 'Y'?true:false;
					//handle eventOrganizer
					const orgArr  =   [];
					if (Array.isArray(item.content.content[locale].event_details.organiser_information.organiser_container)){
						item.content.content[locale].event_details.organiser_information.organiser_container.map((org)=>{
							orgArr.push(org.organiser_name)
						})
					} else {
						orgArr.push(item.content.content[locale].event_details.organiser_information.organiser_container.organiser_name) 
					}
					hitsObject.eventOrganizer = orgArr;
					//handle eventOrganizerCode, 1 = hktdc ; 2 = non-hktdc ; 3 = hktdc-participate
					hitsObject.eventOrganizerCode  = (item.content.content.metadata.organiser_id  == 1 )?("hktdc"):((item.content.content.metadata.organiser_id  == 3 )?("hktdc-participate"):("non-hktdc")); 
					//handle eventPreRegInd , Y/N/C
					hitsObject.eventPreRegInd = item.content.content[locale].event_details.various_buttons.pre_register_container.pre_register_control == 'Y'? true:false;
					//handle eventPreRegUrl
					hitsObject.eventPreRegUrl = item.content.content[locale].event_details.various_buttons.pre_register_container.pre_register_url;
					//handle eventShowInMarketplaceApp
					hitsObject.eventShowInMarketplaceApp = item.metadata.show_in_mobile_app == '1'? false:true;
					//handle  eventStartDate  , seems ignore time part, force to be 16:00:00.000
					hitsObject.eventStartDate =  genStartDate(parseInt(item.metadata.start_date));
					//handle  eventEndDate  , seems ignore time part, force to be 15:59:59.9999 
					hitsObject.eventEndDate = genEndDate(parseInt(item.metadata.end_date));
					//handle eventVideoUrl
					hitsObject.eventVideoUrl = item.content.content[locale].core_information.eventVideoUrl;
					//handle industries
					hitsObject.industries = item.content.content[locale].core_information.display_industry.split("|");
					//handle keywords
					hitsObject.keywords = item.content.content[locale].core_information.keyword;
					//handling landingUrl, if link_choic = "Y", use fact sheet, else use official website
					const useFactSheet  = item.content.content[locale].core_information.link_choice == 'Y'?true:false;
					if (useFactSheet){
						hitsObject.landingUrl  = config.full_www +  "/" + locale  + "/factsheet/"  +  item.path.split("/")[item.path.split("/").length - 1].replace(".xml",""); 
					}else{
						hitsObject.landingUrl  =  item.content.content[locale].core_information.official_website_url;
					}
					//handling  language
					hitsObject.language =  locale;
					//handling media01Alt
					hitsObject.media01Alt = item.content.content[locale].core_information.event_name;
					//handling media01Url
					hitsObject.media01Url = item.content.content.event_index_photo.path;
					//handling online time
					hitsObject.onlineDate = item.content.content.general_bottom.online_time.replace(" ","T") +  ".000Z";
					//handling offline time
					hitsObject.offlineDate = item.content.content.general_bottom.offline_time.replace(" ","T") +  ".000Z";
					//handling publicVisitorEventUrl
					hitsObject.publicVisitorEventUrl  =  item.content.content[locale].event_details.various_buttons.public_visitors_container.public_visitors_link;
					//handling publicVisitorEventUrlInd
					hitsObject.publicVisitorEventUrlInd =  item.content.content[locale].event_details.various_buttons.public_visitors_container.public_visitors_link == 'http://' ? false:true;
					//handling regions
					hitsObject.regions =  item.content.content[locale].core_information.display_region.split("|");
					//handling regionsForDisplay
					hitsObject.regionsForDisplay = item.content.content[locale].core_information.display_location.split("|");
					//handling resourceType , seems always use 'event'
					hitsObject.resourceType = 'event';
					//handling searchGroup , seems always use 'exhconf'
					hitsObject.searchGroup = 'exhconf';
					//handling siteCode , seems always use 'all'
					hitsObject.siteCode =  'all';
					//handling title
					hitsObject.title = item.content.content[locale].core_information.window_title;
					//handling vmsProjectCode
					hitsObject.vmsProjectCode = item.content.content.metadata.vmsProjectCode.toString().padStart(3, '0');
					//handling vmsProjectYear
					hitsObject.vmsProjectYear = item.content.content.metadata.vmsProjectYear.toString();
					phrAPI.hits.push(hitsObject);
				} catch (e){
					console.error(e);
				}
			})

			phrAPI.total = json.total;
			return phrAPI; 
		} else {
			return json; 
		}
	}

	render() {
		const cmsData = this.props.cmsData.data[this.props.i18n.locale] || {}; // CMS Data, highlight events
		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
		const clickState = this.state;

		//Intro
		const { tabsTitle = {} } = static_content.events.introContent || {};

		// Meta data
		const { hktdcevent = {}, moreeventshk = {}, moreeventsoutside = {}, serviceTradeEvents = {}, merchandiseTradeEvents = {} } = this.props.metaReducer.data[this.props.i18n.locale] || {};
		let pageMeta = '';
		switch (this.props.pagename) {
			case 'osevents':
				pageMeta = moreeventsoutside;
				break;
			case 'hkevents':
				pageMeta = moreeventshk;
				break;
			case 'serviceTradeEvents':
				pageMeta = serviceTradeEvents;
				break;
			case 'merchandiseTradeEvents':
				pageMeta = merchandiseTradeEvents;
				break;
			default:
				pageMeta = hktdcevent;
				break;
		}

		// get event data start
		const eventsData = this.convertCMSPreviewJSON(this.props.getEventsReducer.data) || {};
		const { total = 0, hits } = eventsData;
		let curPage = this.state.queryData.data.p;
		if (!curPage) { curPage = 1 };

		const { feature_events_container = [] } = cmsData;

		return (

			<main>
				<Helmet bodyAttributes={{ 'data-page': 'events' }} />

				<div data-webtrend-group={config.WTTealiumGroupName.join}>
					<PageIntroContainer title={static_content.navLinks.events.big_title + static_content.navLinks.events.small_title} desc={cmsData.header_description} pageCat={"hktdcevent"} />
					<ScrollAnimation animateIn="fadeIn" animateOnce className="page-hktdc-events">
						<MetaHelmet metaData={pageMeta} locale={this.props.i18n.locale} />
						<div className="page-hktdc-events-slider">
							{this.props.cmsData.isLoading ? static_content.txt_loading : ''}
							{(clickState.tabsShow && feature_events_container.length > 0) &&
								<PageMultiSliderContainer cmsData={feature_events_container} loadingTxt={static_content.txt_loading} />
							}
						</div>

						<div className="content-wrapper"><h3 className="sec-intro__title page-hktdc-events__heading">{static_content.events.tableHeading}</h3></div>

						{clickState.tabsShow &&
							<div className="page-hktdc-events-intro">
								<div className="side-title">{static_content.events.introContent['label']}</div>
								{ /* <div className="page-hktdc-events__title">Remove title in PHR-413</div>*/}
								<EventsFilter tabsTitle={tabsTitle} title={clickState.title} locale={this.props.i18n.locale} />
							</div>}
						<PageEventListContainer catTitle={clickState.title} eventResult={hits} total={total} queryData={this.state.queryData} page={this.props.pagename} isLoading={this.props.getEventsReducer.isLoading} />
						<MoreEventsCatContainer curCat={clickState.curCat} />
					</ScrollAnimation>
				</div>
				<PageAdsContainer />
			</main>

		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	cmsData: state.pageJoinReducer,
	metaReducer: state.metaReducer,
	getEventsReducer: state.getEventsReducer,
	i18n: state.i18n,
	allowCookiesStatusReducer: state.allowCookiesStatusReducer
});

export default withRouter(connect(mapStateToProps, null, null, {
	pure: true
})(EventsItems));