import React, { Component } from 'react';
import AccordionContainer from '../../containers/AccordionContainer';

export default class SideTabsItem extends Component {
    
    render() {
        function toggleClass(key) {
            let tarId = 'side-tab-' + key;
            let stItems = document.querySelectorAll(".side-tabs__item");
            let tarElem = document.getElementById(tarId);
            [].forEach.call(stItems, function (el) {
                el.classList.remove("is-active");
            });
            tarElem.classList.add("is-active");

            //trigger window resize
            if (typeof (Event) === 'function') {
                window.dispatchEvent(new Event('resize'));
            } else {
                let evt = window.document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
            }
        };

        let qnaitems = this.props.qnaitems || {};
        return (
            <div id={'side-tab-' + this.props.itemkey} className='side-tabs__item'>
                <a onClick={toggleClass.bind(null, this.props.itemkey)} className="side-tabs__tab">{this.props.title}</a>
                <div className="side-tabs__content">
                    <h3 className="side-tabs__item-title">{this.props.title}</h3>
                    {Object.keys(qnaitems || {}).map(function (key) {
                        let value = qnaitems[key];
                        return (
                            <AccordionContainer key={key} item={value} />
                        );
                    })}
                </div>
            </div>
        )
    }
}
