import React, { Component } from 'react';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';

export default class Accordion extends Component {
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
        this.state = {
            active: false,
        };
    }

    updateDimensions() {
        var contentEl = document.querySelectorAll(".side-tabs__item.is-active .side-tabs__content");
        if(contentEl.length){
            var contentElHeight = contentEl[0].clientHeight;

            var tabEl = document.querySelectorAll(".side-tabs__tab");
            var tabHeight = 0;
            var i = 0;
            for (i = 0; i < tabEl.length; ++i) {
                tabHeight += tabEl[i].clientHeight;
            }
            tabHeight += 60;
    
            var tarHeight = contentElHeight;
            if (tarHeight < tabHeight) {
                tarHeight = tabHeight;
            }
            var tarContent = document.querySelectorAll(".side-tabs__items");
            tarContent[0].style.height = tarHeight + 'px';
        }
    }
    toggleClass(e) {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
        e.preventDefault();
    };
    componentDidMount() {
        this.updateDimensions();
    }
    componentDidUpdate() {
        this.updateDimensions();
    }
    render() {
        const item = this.props.item || {};
        
        return (
            <div className={this.state.active ? 'accordion is-active' : 'accordion'}>
                <span tabIndex="0" onClick={this.toggleClass} onKeyPress={this.toggleClass} className="accordion__btn">{item['question']}</span>
                <div className="accordion__content">
                    <SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={ item['answer'] } />
                </div>
            </div>
        )
    }
}
