import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default class FooterBar extends Component {
  
  render() {
    const { subscribeNow } = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content

    return (
        <ScrollAnimation animateIn="fadeIn" animateOnce className="footer-bar">
          <span>
            <h2 className="footer-bar__title">{subscribeNow['title']}</h2>
            <p className="footer-bar__desc">{subscribeNow['desc']}</p>
          </span>
          <a className="footer-bar__button button button--s button--whiteOrange button--right-arr" href={subscribeNow['href']} target="_blank"><span>{subscribeNow.btnTxt}</span></a>
        </ScrollAnimation>
    );
  }
}