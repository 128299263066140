import React, { Component } from 'react';
import config from '../../config';
import { Helmet } from "react-helmet";
import {addProtocol} from '../../util/helper';

var sprintf = require("sprintf-js").sprintf;

export default class MetaHelmet extends Component {
  
  render() {
    const metaData = this.props.metaData || {};
    const is_mainPage = this.props.is_mainPage || false;
    let pageUrl = (is_mainPage) ? config.full_www + '/%s' : (window.location.href.split('?')[0]).replace((this.props.locale || 'en') + '/', '%s');
    const is_pageSearch = this.props.is_pageSearch || false;

    let meta = [];

    // CMS meta data
    if (metaData.title && metaData.title !== '') {
      meta.push(<title key="title">{metaData.title}</title>);
      meta.push(<meta key="name" itemprop="name" content={metaData.title} />);
      meta.push(<meta key="og:title" property="og:title" content={metaData.title} />);
    }
    if (metaData.description && metaData.description !== '') {
      meta.push(<meta key="description" name="description" content={metaData.description} />);
      meta.push(<meta key="description" itemprop="description" content={metaData.description} />);
      meta.push(<meta key="twitter:card" name="twitter:card" content={metaData.description} />);
      meta.push(<meta key="og:description" property="og:description" content={metaData.description} />);
    }
    if (metaData.image && metaData.image !== '') {
      meta.push(<meta key="image" itemprop="image" content={addProtocol(metaData.image)} />);
      meta.push(<meta key="og:image" property="og:image" content={addProtocol(metaData.image)} />);
    }
    if (metaData.image_alt && metaData.image_alt !== '') {
      meta.push(<meta key="og:image:alt" property="og:image:alt" content={metaData.image_alt} />);
    }
    
    meta.push(<meta key="meta-twitter:site" name="twitter:site" content={window.location.href} />);
    meta.push(<meta key="meta-og:url" property="og:url" content={window.location.href} />);

    if (metaData.type && metaData.type !== '') {
      meta.push(<meta key="og:type" property="og:type" content={metaData.type} />);
    }
    if (metaData.sitename && metaData.sitename !== '') {
      meta.push(<meta key="og:site_name" property="og:site_name" content={metaData.sitename} />);
    }
    if (metaData.keywords && metaData.keywords !== '') {
      meta.push(<meta key="keywords" name="keywords" content={metaData.keywords} />);
    }
    if (metaData.industry && metaData.industry !== '') {
      meta.push(<meta key="industry" name="industry" content={metaData.industry} />);
    }
    if (metaData.region && metaData.region !== '') {
      meta.push(<meta key="region" name="region" content={metaData.region} />);
    }

    // Google hreflang
    const query = is_pageSearch ? '?' +window.location.href.split('?')[1] : '';
    meta.push(<link key="alt_x-default" rel="alternate" hreflang="x-default" href={sprintf(pageUrl, 'en/') + query} />)
    meta.push(<link key="alt_en" rel="alternate" hreflang="en" href={sprintf(pageUrl, 'en/') + query} />)
    meta.push(<link key="alt_hk" rel="alternate" hreflang="zh-hk" href={sprintf(pageUrl, 'tc/') + query} />)
    meta.push(<link key="alt_cn" rel="alternate" hreflang="zh-cn" href={sprintf(pageUrl, 'sc/') + query} />)

    // Canonical
    meta.push(<link key="canonical" rel="canonical" href={window.location.href} />);

    // robot meta tag
    if (is_pageSearch) {
      meta.push(<meta key="robots-noindex" name="robots" content="noindex" />);
    } else {
      meta.push(<meta key="robots" name="robots" content="noodp, noydir" />);
    }

    // Google Sitelinks searchbox & Knowledge Graphic, main page only
    if (is_mainPage) {
      meta.push(
        <script key="main_sitelinks" type="application/ld+json">{`
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            "url": "`+window.location.protocol+`//`+config.www+`",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "`+window.location.protocol+`//`+config.home+`/search?type=all&q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
        `}
        </script>
      )

      // Knowledge Graphic
      meta.push(
        <script key="kGraphic" type="application/ld+json">{`
          { "@context" : "http://schema.org",
            "@type" : "Organization",
            "legalName" : "Hong Kong Trade Development Council",
            "url": "`+window.location.protocol+`//`+config.www+`",
            "contactPoint" : [{
              "@type" : "ContactPoint",
              "telephone" : "(852)1830668",
              "contactType" : "customer service"
            }],
            "logo": "`+window.location.protocol+`//`+config.home+`/images/logo-with-tag.jpg",
            "sameAs" : [ "http://www.facebook.com/hktdc",
              "http://www.twitter.com/hktdc",
              "https://www.youtube.com/user/hktdc",
              "https://www.linkedin.com/company/hong-kong-trade-development-council/?originalSubdomain=hk"]
          }
        `}
        </script>
      );
    }
    meta.push(<meta key="fbapp_id" property="fb:app_id" content="404998779998238"/>);

    // Next/prev page is handled in pagination.js

    return(      
      <Helmet>
        {meta}
      </Helmet>
    )
  }
}