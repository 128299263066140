import React, {Component} from 'react';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import { inSensitiveReplaceEM } from '../../util/helper';
import truncate from 'truncate-html';
import LazyLoad from 'react-lazyload';

class ContentTypeProduct extends Component {
  
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const { q='', debugMode='', contentData = {}} = this.props;
    const {
      score = 0,
      description = '',
      bodyShortForm = '',
      landingUrl = '',
      media01Url = '',
      media01Alt = '',
      media02Url = '',
      media02Alt = '',
      media03Url = '',
      media03Alt = '',
      titleForSeo = ''
    } = contentData;
    let { title = ""} = contentData;

    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese

    title = titleForSeo !== '' ? titleForSeo : title;
    const desc = description !== '' ? description : bodyShortForm;
    let em_desc = inSensitiveReplaceEM(truncate(desc,{length: config.char_limit.desc}), q);
    let em_title = inSensitiveReplaceEM(truncate(title,{length: config.char_limit.title}), q);
    
    return (
      <div className="organic-listing__item">
        {        
          title !== "" ? 
          <a href={landingUrl} target="_blank" title={title}>
            <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/></h4> 
          </a> 
          :
          <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/></h4>
        }
        { debugMode === 'Y' &&
          <p>{score}</p>
        }
        <div className="organic-listing__item-row">
        { (media01Url !== '' || media02Url !== '' || media03Url !== '') && 
          <div className="organic-listing__img">
          { media01Url!== '' &&
            <LazyLoad once><span className="organic-listing__img-holder">
              <a href={landingUrl} target="_blank"><img className="organic-listing__thumb" src={media01Url} alt={media01Alt} title={media01Alt}/></a>
            </span></LazyLoad>
          }
          { media02Url!== '' &&
            <LazyLoad once><span className="organic-listing__img-holder">
              <a href={landingUrl} target="_blank"><img className="organic-listing__thumb" src={media02Url} alt={media02Alt} title={media02Alt}/></a>
            </span></LazyLoad>
          }
          { media03Url!== '' &&
            <LazyLoad once><span className="organic-listing__img-holder">
              <a href={landingUrl} target="_blank"><img className="organic-listing__thumb" src={media03Url} alt={media03Alt} title={media03Alt}/></a>
            </span></LazyLoad>
          }
          </div>
        }
          <div className="organic-listing__detail">
            <div className="organic-listing__desc">{<SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_desc}/>}</div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ContentTypeProduct);
