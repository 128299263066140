import React, { Component } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import config from '../../config';
import Link from '../../util/Link';
import * as h from '../../util/helper';
import { Helmet } from "react-helmet";

export default class Pagination extends Component {
    
    getPageHref(tarPage) {
        if (this.props.searchData) {
            const pathname = this.props.pathname || config.pagename_search;
            if (this.props.pathname) {
                // not search
                let query = [];
                const data = this.props.searchData.data || {};
                Object.keys(data).forEach(function (key) {
                    if (data[key] && data[key] !== '' && key !== 'language') {
                        if (key==='p') {
                            query.push(key+'='+tarPage);
                        } else if (key === 'location') {
                            // only TDCEvents has location filter
                            if (pathname === config.urlEvent) {
                                query.push(key + '=' + h.filterThenEncode(data[key]));
                            }
                        } else {
                            query.push(key + '=' + h.filterThenEncode(data[key]));
                        }                        
                    }
                });
                const queryString = query.length ? '?' + query.join('&') : '';
                return pathname + queryString;
            } else {
                let excIds = (this.props.searchData.type === 'all' && tarPage !== 1 && (this.props.excIds).length > 0) ? '&excIds=' + h.filterThenEncode((this.props.excIds).join(',')) : '';
                let sort = (this.props.searchData.sort !== '') ? '&sort=' + this.props.searchData.sort : '';
                return pathname + '?type=' + this.props.searchData.type + '&q=' + h.filterThenEncode(this.props.searchData.data.q) + sort + '&p=' + tarPage + excIds;
            }
        }
        return '#'; 
        
    }
    render() {
        const { searchData = {} } = this.props;
        const queryData = searchData.data || {};
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        
        let page = queryData.offset || queryData.p || 1;
        let total_page = this.props.totalPage || 1;
        
        if (this.props.pathname !== config.pagename_successStories) {
            if (total_page > config.max_result_page) total_page = config.max_result_page; // PHR-431
        }        

        page = parseInt(page, 10);
        total_page = parseInt(total_page, 10);
        if (page > total_page) {
            page = total_page;
        }

        let prevPage = page * 1 - 1;
        let nextPage = page * 1 + 1;

        let prevPageHref = this.getPageHref(prevPage);
        let nextPageHref = this.getPageHref(nextPage);
        

        let start_page = page * 1 - 5;
        if (start_page < 1) {
            start_page = 1;
        }

        let end_page = start_page + 9;
        if (end_page > total_page) {
            let end_exceed = end_page - total_page;
            end_page = total_page;
            start_page = start_page - end_exceed;
            if (start_page < 1) {
                start_page = 1;
            }
        }

        //console.log('start_page:' + start_page);
        let pageElems = []
        let len = end_page;
        let i;
        for (i = start_page; i <= len; i++) {
            let pageHref = this.getPageHref(i);
            let pageClass = "pagination__page";
            if (i === page) {
                pageClass += " is-active";
            }
            pageElems.push(<Link key={i} to={pageHref} className = {pageClass} gotoanchor={this.props.goToTop || 'top'}> {i} </Link>);
        }
        
        let metaData = [];
        let domain = config.home;
        let pathLocale = this.props.i18n.locale + '/';
        if (this.props.pathname && this.props.pathname === config.urlEvent) {
            domain = config.events;
            if (this.props.i18n.locale === 'en') pathLocale = '';
        }
        if ((page === 1 && total_page !== 1) || (page > 1 && total_page !== page)) {
            // first page, not only 1 page, or not first page but also not last page
            metaData.push(<link key={'metanext'+page} rel ="next" href={window.location.protocol+`//`+domain+'/'+ pathLocale +((this.getPageHref(page+1)).replace(config.urlEvent, ''))}/>)
        }
        if (page !== 1) {
            metaData.push(<link key={'metaprev'+page} rel ="prev" href={window.location.protocol+`//`+domain+'/'+ pathLocale +((this.getPageHref(page-1)).replace(config.urlEvent, ''))}/>)
        }

        let paginate =  total_page !== 1 ? [
            page > 1 && <Link gotoanchor={this.props.goToTop || 'top'} key={"prev"+page} to={prevPageHref} className = "pagination__btn-prev" > {static_content.searchResult.txt_prev} </Link>,
            <div className="pagination__pages" key={"pagination"+page}>
                {pageElems}
            </div>,
            page < total_page && <Link gotoanchor={this.props.goToTop || 'top'} key={'next'+page} to={nextPageHref} className = "pagination__btn-next" > {static_content.searchResult.txt_next} </Link>] : []

        return (
            <ScrollAnimation animateIn="fadeIn" animateOnce className="pagination">
                {metaData.length >0 &&
                <Helmet>
                    {metaData}
                </Helmet>
                }
                
                { paginate }
            </ScrollAnimation>
        )
    }
}
