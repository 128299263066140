import axios from 'axios';

/*
 * Churix 26/11/2018
 * Wrap axios with Portal Home default options
 */
function axiosAdapter(options){
	var updatedOptions = options;
	const url = options.url;
	if (url.search(window.location.host) >= 0) {
		//Origin is added to query string for same origin request, 
		//so as to get rid of the CORS cache issue in back button (PHR-426)
		var params = options.params || {};
		params.sameOrigin = "true";
		updatedOptions.params = params;
	} //else just use the original input

	const defaultOptions = {
		timeout : 30000, 
		method : 'get', 
		responseType : 'json'
	};
	const axiosOptions = Object.assign({}, defaultOptions, updatedOptions);
    return axios(axiosOptions);
}

export {axiosAdapter};