import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import HeaderSearch from '../components/Header/HeaderSearch';

const mapStateToProps = state => ({
  headerSearchReducer: state.headerSearchReducer,
  cmstopSearchReducer: state.cmstopSearchReducer,
  topSearchReducer: state.topSearchReducer,
  i18n: state.i18n
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(HeaderSearch);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;