
import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import { eventInifiniteScrollReducer } from './eventReducer.js'; // main event calendar
import headerSearchReducer from './headerSearchReducer.js'; // autocomplete suggestion
import getSearchResultReducer from './getSearchResultReducer.js'; // search result
import metaReducer from './metaReducer.js';
import topSearchReducer from './topSearchReducer.js';

import headerFooterReducer from './common/headerFooterReducer.js';
import announcementReducer from './common/announcementReducer.js';
import announcementPromotionReducer from './common/announcementPromotionReducer.js';
import cmstopSearchReducer from './common/cmstopSearchReducer.js';
import seoDataReducer from './common/seoDataReducer.js';
import allowCookiesStatusReducer from './common/allowCookiesStatusReducer.js';
import ssoReadyStatusReducer from './common/ssoReadyStatusReducer.js';

import mainEventCalendarReducer from './mainpage/mainEventCalendarReducer.js';
import mainJumbotronEventsSlidesReducer from './mainpage/mainJumbotronEventsSlidesReducer.js';
import mainMFReducer from './mainpage/mainMFReducer.js';
import mainSSReducer from './mainpage/mainSSReducer.js';
import mainHelpReducer from './mainpage/mainHelpReducer.js';

import pageStaticPagesReducer from './jumppages/pageStaticPagesReducer.js';
import pageSSReducer from './jumppages/pageSSReducer.js';
import pageSSFiltersReducer from './jumppages/pageSSFiltersReducer.js';
import pageSSItemsReducer from './jumppages/pageSSItemsReducer.js';
import pageStartReducer from './jumppages/pageStartReducer.js';
import pageJoinReducer from './jumppages/pageJoinReducer.js';
import pageSourceReducer from './jumppages/pageSourceReducer.js';
import pageExploreReducer from './jumppages/pageExploreReducer.js';
import pageMobileAppReducer from './jumppages/pageMobileAppReducer.js';
import getEventsReducer from './jumppages/getEventsReducer.js';
import getSearchDidYouMeanReducer from './getSearchDidYouMeanReducer.js';
import getSearchDataReducer from './getSearchDataReducer.js';
import getSearchPreferredListingReducer from './getSearchPreferredListingReducer.js';

import pageFactsheetPagesReducer from './factsheet/pageFactsheetPagesReducer.js';
import pageWebcastPagesReducer from './webcast/pageWebcastPagesReducer.js';
import pagePodcastPagesReducer from './podcast/pagePodcastPagesReducer.js';
import pageAdvantagesMainPageReducer from './jumppages/pageAdvantagesMainPageReducer.js';
import pageAdvantagesThematicPageReducer from './jumppages/pageAdvantagesThematicPageReducer.js';

const rootReducer = combineReducers({
    eventInifiniteScrollReducer,
    headerSearchReducer,
    getSearchResultReducer,
    metaReducer,
    i18n: i18nReducer,
    headerFooterReducer,
    announcementReducer,
    announcementPromotionReducer,
    topSearchReducer,
    cmstopSearchReducer,
    seoDataReducer,
    allowCookiesStatusReducer,
    ssoReadyStatusReducer,
    mainJumbotronEventsSlidesReducer,
    mainEventCalendarReducer,
    mainMFReducer,
    mainSSReducer,
    mainHelpReducer,
    pageSSReducer,
    pageSSFiltersReducer,
    pageSSItemsReducer,
    pageStartReducer,
    pageJoinReducer,
    pageSourceReducer,
    pageExploreReducer,
    pageMobileAppReducer,
    pageStaticPagesReducer,
    getSearchDidYouMeanReducer,
    getSearchDataReducer,
    getSearchPreferredListingReducer,
    getEventsReducer,
    pageFactsheetPagesReducer,
    pageWebcastPagesReducer,
    pagePodcastPagesReducer,
    pageAdvantagesMainPageReducer,
    pageAdvantagesThematicPageReducer
});

export default rootReducer;