import React, { Component } from 'react';
import BtnShareContainer from "../../containers/BtnShareContainer";
import { Helmet } from "react-helmet";
import CommonHeaderLinksContainter from '../../containers/CommonHeaderLinksContainter';
import config from '../../config';

export default class TopBar extends Component {
    constructor(props) {
        super(props);
        this.handleFocus = this.handleFocus.bind(this);
        this.state = {
            hover: false
        };
    }

    handleFocus = () => {
        this.setState({hover: false})
    }

    handleKey = e => {
        this.setState({hover: true})
    }

    handleMouseEnter = () => {
        this.setState({hover: true})
    }

    handleMouseLeave = () => {
        this.setState({hover: false})
    }

    render() {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const is_Preview = (process.env.REACT_APP_STAGE).includes('preview');

        let langTxt = static_content.lang || {};
        let langEN = langTxt['en'] || {};
        let langTC = langTxt['tc'] || {};
        let langSC = langTxt['sc'] || {};

        let { i18n } = this.props || {};
        let lang = i18n.locale;
        let localeName = {
            'en': 'en',
            'tc': 'zh-hk',
            'sc': 'zh-cn'
        };

        let langElems = [];
        let rootPath = window.location.protocol + '//' + window.location.host;
        let currentURL = rootPath + (this.props.currentUrl || '');
        let langCurrPath = "/"+lang+"/";
        let langLink1Path = currentURL.replace(rootPath,rootPath+"/tc");
        let langLink2Path = currentURL.replace(rootPath,rootPath+"/sc");
        if(currentURL.indexOf(langCurrPath)>0){
            langLink1Path = currentURL.replace(langCurrPath,"/tc/");
            langLink2Path = currentURL.replace(langCurrPath,"/sc/");
        }
        let langLink1Txt = langTC['txt_short'];
        let langLink2Txt = langSC['txt_short'];
        if (lang === 'tc') {
            langLink1Path = currentURL.replace(langCurrPath,"/en/");
            langLink2Path = currentURL.replace(langCurrPath,"/sc/");
            langLink1Txt = langEN['txt_short'];
            langLink2Txt = langSC['txt_short'];
        } else if (lang === 'sc') {
            langLink1Path = currentURL.replace(langCurrPath,"/en/");
            langLink2Path = currentURL.replace(langCurrPath,"/tc/");
            langLink1Txt = langEN['txt_short'];
            langLink2Txt = langTC['txt_short'];
        }
        
        if (!is_Preview) {
            langLink1Path = langLink1Path.replace(/en\/$/, ''); // ensure no /en/ in all root path
        }
        
        langElems.push(
            <div key={'tbar'} className="top-bar__holder" onFocus={ this.handleFocus }  >
                <a href={langLink1Path} className="top-bar__link">
                    <span className="top-bar__txt top-bar__lang">{langLink1Txt}</span>
                </a>
                <a href={langLink2Path} className="top-bar__link">
                    <span className="top-bar__txt top-bar__lang">{langLink2Txt}</span>
                </a>
            </div>
        );

        let pressStyle;
        if (this.state.hover) {
            pressStyle = {display: "block"}
        } else {
            pressStyle = {display: "none"}
        }

        return (
            <nav className="top-bar" data-webtrend-group={config.WTTealiumGroupName.header}>
                <CommonHeaderLinksContainter action={this.handleFocus}/>
                <div className="top-bar__holder" tabIndex="0" onFocus={this.handleKey} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <div className="top-bar__link">
                        <div className="top-bar__subholder" style={pressStyle}>
                            <BtnShareContainer devType={static_content.for_page || {}}/>
                        </div>
                        <span className="top-bar__icon top-bar__icon--share" title={static_content.title_share}></span>
                    </div>
                </div>
                {langElems}
                <Helmet htmlAttributes={{ lang: localeName[lang] }}/>
                <Helmet>
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                </Helmet>
            </nav>
        );
    }
}