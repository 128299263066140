import React, { Component } from 'react';
import store from '../../store/';
import config from '../../config';
import {
  fetchEventCalendarData,
  fetchJumbotronEventsSlidesData,
  fetchWebMetaData
} from '../../actions/';
import BtnShareContainer from "../../containers/BtnShareContainer";
import VideoCover  from 'react-video-cover';
import LazyImage  from '../../containers/LazyImage';
import MetaHelmet from "../PageItem/MetaHelmet";
import { eventAction } from '../../util/clickEvents';

export default class MainBanner extends Component {
  constructor(props) {
    super(props);
    let metaReady = true;
    store.dispatch(fetchJumbotronEventsSlidesData()); // Teamsite CMS
    store.dispatch(fetchEventCalendarData()); // Teamsite CMS
    if (props.metaReducer.data.length <= 0) {
      store.dispatch(fetchWebMetaData());
      metaReady = false;
    }
    this.state = {
        mainBannerInit: false,
        metaReady: metaReady,
        mousePause: false
    };
  }

  togglePause = () =>{
    this.setState({mousePause: !this.state.mousePause});
  }

  playCurrentVideo() {
    /*
     * A refactored method created for playing the video jumbotron, based on the original video playing approach developed by FS
     */
    let vids = document.querySelectorAll('.swiper-slide-active video');
    if(vids.length){
      vids[0].play();
    }
  }

  initSwiper(_this) {
    var thisElem = document.getElementById("main-banner-slider");
    if (thisElem && thisElem.swiper) {
      thisElem.swiper.destroy(false, false);
    }
    var mainBanner = document.getElementById("main-banner-slider");
    var autoplayTime = 6000;
    if (mainBanner) {
      if (mainBanner.swiper) {
        mainBanner.swiper.destroy(true, true);
      }
    }
    var swiper = new window.Swiper(mainBanner, {
      init: false, 
      spaceBetween: 20,
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: false,
      effect: 'fade',
      simulateTouch: true,
      autoHeight: true,
      autoplay: {
        delay: autoplayTime,
        disableOnInteraction: false,
      },
      fadeEffect: {
        crossFade: true
      }
    });
    swiper.on('init', function () {
      _this.playCurrentVideo();
    }).on('slideChangeTransitionEnd', function () {
      _this.updateThumb(swiper);
    }).on('slideChangeTransitionStart', function () {
      //reset all video time to zero
      let vids = document.querySelectorAll('.main-banner__slide  video');
      for (var i=0;i<vids.length;i++) {
        vids[i].currentTime = 0;
      }
    }).init();
  }
  updateThumb(swiper){
    let thumbs = document.querySelectorAll('.main-banner__thumb');
    [].forEach.call(thumbs, function (el) {
      el.classList.remove('is-active');
    });

    let activeId = swiper.activeIndex;
    if (activeId > thumbs.length) {
      activeId = 1;
    }
    activeId--;

    // console.log(activeId);
    let tar = thumbs[activeId+1];
    if (tar) {
      tar.className += ' is-active';
    }

    this.playCurrentVideo();
  }
  componentDidMount() {
    let _this = this;
    _this.setState({
      mainBannerInit: false
    });
    //console.log('###### test main banner1');

    if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
      console.log('Tealium Home Internal Link Sent:', new Date() )
      if (window.utag)
      window.utag.view({ "site_subsection" : config.tealium.home.subsection, "page_type" : config.tealium.home.pageType, "page_name": config.tealium.home.pageName });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log('###### test main banner2');
    let _this = this;
    let _thisInitSwiper = this.initSwiper;
    let thumbs = document.querySelectorAll('.main-banner__thumb');
    if(!_this.state.mainBannerInit&&thumbs.length>1){
      setTimeout(() => {
        _thisInitSwiper(_this);
      }, 300);
      [].forEach.call(thumbs, function (el) {
        el.classList.remove('is-active');
      });
      
      let tar = thumbs[0];
      if (tar) {
      tar.className += ' is-active';
      }
      _this.setState({
        mainBannerInit: true
      });
    }

    if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
      setTimeout(() => {
        console.log('Tealium Home page load Sent:', new Date())
        window.utag_data = {
          "site_subsection": config.tealium.home.subsection,
          "page_type": config.tealium.home.pageType,
          "page_name": config.tealium.home.pageName
        };
        window.enableTealium()
      }, 1000);
    }


    if(typeof document.getElementById("main-banner-slider").swiper !== "undefined"){
      if(this.state.mousePause){
        document.getElementById("main-banner-slider").swiper.autoplay.stop();
      }else{
        document.getElementById("main-banner-slider").swiper.autoplay.start();
      }
    }
  }

  toMainBannerSlide = (key) => (e) => {
    var thisElem = document.getElementById("main-banner-slider");
    if (thisElem && thisElem.swiper) {
      thisElem.swiper.slideTo(parseInt(key, 10)); // PHR-581
    }
    e.preventDefault();
  }
  render() {
    const { jumbotron_container } = this.props.slides.data[this.props.i18n.locale] || {}; // get Teamsite CMS Data
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const locale = this.props.i18n.locale || 'en';
    const {main_page = {}} = this.props.metaReducer.data[this.props.i18n.locale] || {};
    const _that = this;
    console.log("jumbotron_container:", jumbotron_container)
    return (
      <section data-webtrend-group={config.WTTealiumGroupName.mainBanner} className="main-banner">
        <MetaHelmet metaData={main_page} is_mainPage={true} locale={this.props.i18n.locale} />
        <h1 className="access-elem">{main_page.h1}</h1>
        { /* PHR-538 <div className="main-banner__scrolldown"></div> */}
        <div id="main-banner-slider" className="main-banner__slider">
          <div className={this.state.mousePause ? 'swiper-wrapper pause-active' : 'swiper-wrapper'}>
            {this.props.cmsData.isLoading ? <div className="main-banner__loading">{static_content.txt_loading}</div> : '' }
            {Object.keys(jumbotron_container || {}).map(function (key) {
              console.log("key",key)
              let value = jumbotron_container[key];

              let title = value.headline;
              let subTitle = value.sub_headline_1;
              let subTitle2 = value.sub_headline_2;
              let desc = value.sub_headline_description;
              let vid = value.video_path_url;
              let href = value.landing_url;
              let img = value.image;
              let imgMobile = value.imageMobile;
              let bg = `url("${img}")`;
              let bgMobile = `url("${imgMobile}")`;
              let imgFocusX = value.image_focus_x || '';
              let imgFocusY = 50;
              let interval = value.interval||6000;
              const videoOptions = {
                src: vid,
                //autoplay: "true",
                //loop: "true",
                // forceFallback:true,
                // onFallbackDidMount:function(){
                //   alert('fullback');
                // },
                muted: true,
                playsInline: true,
                "webkit-playsinline": "true"
              };
              return (
                <div key={'key-' + key} className="main-banner__slide swiper-slide" data-swiper-autoplay={interval}>
                  {vid === '' ?
                    <>
                      <a
                        href={href}
                        target="_blank"
                        className="main-banner__img"
                        style={{
                          "backgroundImage": bg,
                          "backgroundPositionX": imgFocusX + "%",
                          "backgroundPositionY": imgFocusY + "%",
                          "backgroundRepeat": "no-repeat"
                        }}
                        onClick={() => eventAction('jumbotronClick', (parseInt(key, 10) + 1), subTitle, locale)}
                      >
                        <LazyImage imgSrc={img} imgAlt={title} />
                      </a>
                      <a
                        href={href}
                        target="_blank"
                        className="main-banner__img_mobile"
                        style={{
                          "backgroundImage": imgMobile ? bgMobile : bg,
                          "backgroundPositionX": imgFocusX + "%",
                          "backgroundPositionY": imgFocusY + "%",
                          "backgroundSize": "cover",
                          "backgroundRepeat": "no-repeat"
                        }}
                        onClick={() => eventAction('jumbotronClick', (parseInt(key, 10) + 1), subTitle, locale)}
                      >
                      </a>
                    </>
                    :
                    <a
                      href={href}
                      target="_blank"
                      className="main-banner__vid"
                      onClick={() => eventAction('jumbotronClick', (parseInt(key, 10) + 1), subTitle, locale)}
                    >
                      <span className="visually-hidden">{static_content.hidden_button_label}</span>
                      <VideoCover
                        videoOptions={videoOptions}
                      />
                    </a>
                  }


                  <div className="main-banner__txt">
                    <a href={href} target="_blank" onClick={()=>eventAction('jumbotronClick', (parseInt(key, 10)+1), subTitle, locale)}><h2 className="main-banner__title">{title}</h2></a>
                    <div className="main-banner__detail">
                      <a href={href} target="_blank" onClick={()=>eventAction('jumbotronClick', (parseInt(key, 10)+1), subTitle, locale)}>
                        <h3 className="main-banner__detail-title">{subTitle}</h3>
                        <h3 className="main-banner__detail-subHeading">{subTitle2}</h3>
                      </a>
                      <p className="main-banner__detail-desc">{desc}</p>
                      <BtnShareContainer  pageUrl={href}  pageShareTitle={title} devType={title}/>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div tabIndex="0" className={this.state.mousePause ? 'main-banner__slider-pause is-active' : 'main-banner__slider-pause'} onClick={this.togglePause} onKeyPress={this.togglePause}>
            {/* <label htmlFor="playpause"></label> */}
            <div className="pause-button"><span className="visually-hidden">{static_content.pause_btn}</span></div>
          </div>
        </div>
        <div className="main-banner__thumbs">
          <div className="content-wrapper">
            {Object.keys(jumbotron_container || {}).map(function (key) {
              const value = jumbotron_container[key];
              const img = value.image;
              const bg = `url("${img}")`;
              return (
                <span
                  tabIndex="0"
                  key={'key-' + key}
                  onClick={_that.toMainBannerSlide(key)}
                  onKeyPress={_that.toMainBannerSlide(key)}
                  className="main-banner__thumb"
                  style={{ "backgroundImage": bg }}
                ></span>
              );
            })}
          </div>
        </div>

      </section>
    );
  }
}