import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';
import { isDateBetweenDateRange, currentDate } from '../../util/helper';

function requestJoinData() {
	return { type: types.REQ_JOIN_DATA }
};

function receiveJoinData(json) {
	return {
		type: types.RECV_JOIN_DATA,
		data: json
	}
};

function receiveJoinError(json) {
	return {
		type: types.RECV_JOIN_ERROR,
		data: json
	}
};

function filterByDate(data, date) {
	let massageData = Object.assign({}, data);
	for (const item in massageData) {
		if(data[item].feature_events_container) {
			let container = massageData[item].feature_events_container;
			let featureEvents = container.filter(v=> isDateBetweenDateRange(v.online_date, v.offline_date, date));
			massageData[item].feature_events_container = featureEvents;
		}
	}
	return massageData
}

export function fetchJoinData() {
	return function (dispatch) {
		dispatch(requestJoinData());
		return axiosAdapter({
			url: config.json_page_join
		})
			.then(function (response) {
				let responseData = response.data.result.assets[0].content.Content;;
				let filteredData = filterByDate(responseData, currentDate());
				return filteredData
			})
			.then(function (data) {
				dispatch(receiveJoinData(data));
			})
			.catch(function (response) {
				dispatch(receiveJoinError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
