import React, { Component } from 'react';
import { connect } from 'react-redux';

import TopbarContainer from '../../containers/TopbarContainer';
import HeaderLogo from './HeaderLogo';
import MainNavContainer from '../../containers/MainNavContainer';
import HeaderSearchContainer from '../../containers/HeaderSearchContainer';

class Header extends Component {  
  toggleMenu = (id) => (e) => {
    var navSubholder = document.querySelectorAll('.nav-link__subholder');
    [].forEach.call(navSubholder, function (el) {
      el.classList.remove('is-active');
    });

    var holderId = document.getElementById(id);
    holderId.className += ' is-active';

    document.body.classList.toggle('mob-menu-open');
    e.preventDefault();
  }

  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    return (
      <header>
        
      <div className="header__top-overlay"><span className="visually-hidden">Header Search top overlay</span></div>
        <div className="header__top">
          <div className="header__wrapper">
            <div className="header__item">
              <TopbarContainer currentUrl={this.props.location.pathname + this.props.location.search}/>
            </div>
          </div>
        </div>
        <div className="header__main">
          <div className="header__wrapper">
            <HeaderLogo />
            <div className="header__item">
              <MainNavContainer currentUrl={this.props.location.pathname + this.props.location.search} />
            </div>
            <div className="header__right">
              <HeaderSearchContainer />
              <span title="Share" tabIndex="0" className="header__mob-share" onClick={this.toggleMenu('mob-share')} onKeyPress={this.toggleMenu('mob-share')}></span>
              <span title="Language" tabIndex="0" className="header__mob-lang" onClick={this.toggleMenu('mob-lang')} onKeyPress={this.toggleMenu('mob-lang')}></span>
              <span title="Menu" tabIndex="0" className="header__mob-menu" onClick={this.toggleMenu('mob-menu')} onKeyPress={this.toggleMenu('mob-menu')}></span>
            </div>
            <div className="header__right close">
              <span title="Close" tabIndex="0" onClick={this.toggleMenu('mob-menu')} onKeyPress={this.toggleMenu('mob-menu')} className="header__mob-close"></span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(Header);