import * as types from '../constants/actionTypes';
import config from '../config';
import {axiosAdapter} from '../util/adapters';

function requestSearchDidYouMean() {
	return { type: types.REQ_SEARCH_DIDYOUMEAN }
};

function receiveSearchDidYouMean(json) {
	return {
		type: types.RECV_SEARCH_DIDYOUMEAN,
		data: json
	}
};

function receiveSearchDidYouMeanError(json) {
	return {
		type: types.RECV_SEARCH_DIDYOUMEAN_ERROR,
		data: json
	}
};

export function getSearchDidYouMean(data) {
	return function (dispatch) {
		dispatch(requestSearchDidYouMean());
		return axiosAdapter({
			url: config.api_search_didyoumean,
			params:data
		})
			.then(function (response) {
				dispatch(receiveSearchDidYouMean(response.data));
			})
			.catch(function (response) {
				dispatch(receiveSearchDidYouMeanError(response.data || response.message));
			})
	}
};