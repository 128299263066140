import React, { Component } from "react";
import store from "../../store/";
import config from '../../config';
import { fetchExploreData, fetchWebMetaData } from "../../actions/";
import PageIntroContainer from '../../containers/PageIntroContainer';
import PageItemChildItem from "../../containers/PageItemChildItem";
import ScrollAnimation from 'react-animate-on-scroll';
import MetaHelmet from "../PageItem/MetaHelmet";
import LazyLoad from 'react-lazyload';

export default class InsightsItems extends Component {
	constructor(props) {
		super(props);
		let metaReady = true;
		store.dispatch(fetchExploreData()); // Teamsite CMS
		if (props.metaReducer.data.length <= 0) {
			store.dispatch(fetchWebMetaData());
			metaReady = false;
		}
		this.state = {
			metaReady: metaReady
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
			setTimeout(() => {
				if (window.utag_data) {
					console.log('Tealium JUMP Explore page load Sent:', new Date())
					window.utag_data = {
						"site_subsection": config.tealium.explore.subsection,
						"page_type": config.tealium.explore.pageType,
						"page_name": config.tealium.explore.pageName
					};
					window.enableTealium()
				}
			}, 1000);
		}
		
	}

	componentDidMount() {
		// Send data to WebTrends server on didMount if this is not landing page
		// if (this.state.metaReady) {
		// 	console.log('JUMP Explore WT sent on DidMount Internal Link: ', new Date());
		// 	window.sendWebTrendsData();
		// }
		if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
			if (window.utag) {
				console.log('Tealium JUMP Explore Internal Link Sent:', new Date())
				window.utag.view({
					"site_subsection": config.tealium.explore.subsection,
					"page_type": config.tealium.explore.pageType,
					"page_name": config.tealium.explore.pageName
				});
			}
		}
	}
	// sendWebTrend = (l) => {
	// 	if (l > 0 && !this.state.metaReady) {
	// 		// ensure only send once
	// 		this.setState({
	// 			metaReady: true
	// 		}, () => {
	// 			setTimeout(() => {
	// 				console.log('JUMP Explore WT sent in render page load: ', new Date());
	// 				window.sendWebTrendsData();
	// 			}, 500);
	// 		})
	// 	}
	// }

	render() {    
		const getCols = (str) => ({
			"0": "cols",
			"1": "cols",
			"2": "full",
			"3": "full",
			"4": "cols",
			"5": "cols",
			"6": "full",
			default: "full"
		})[str];
	
		const getLinksCols = (str1, str2) =>{
			if(str1<2){
				if(str2>4)		{
					return "item--full__links--cols";
				}else{
					return "item--full__links--col";
				}
			}else{
				return "";
			}
		};

		// const getSliderContainer = (bool) => {
		// 	if(bool){
		// 		return " item--full__slider--cols";
		// 	}else{
		// 		return "";
		// 	}
		// };

		const getChildContainer = (num) => {
			if(num > 0){
				if(num > 1){
					return " item--full__child--cols";
				}else{
					return " item--full__child--col";
				}
			}else{
				return "";
			}
		};

		const cmsData = this.props.cmsData.data[this.props.i18n.locale] || {};

		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};

		const map_ary = ['merchandise_trade', 'service_trade', 'belt_and_road', 'research', 'hkmb', 'coi', 'asiaipexchange'];

		let sub_item = [];

		const {explore = {}} = this.props.metaReducer.data[this.props.i18n.locale] || {};
		
		return (
			<div>
				<PageIntroContainer title={static_content.navLinks.insights.big_title+static_content.navLinks.insights.small_title} desc={cmsData.header_description} pageCat={"explore"}/>
				<section data-webtrend-group={config.WTTealiumGroupName.explore} className="page-items">
					<MetaHelmet metaData={explore} locale={this.props.i18n.locale}  />
					<div className="items-container">
						{Object.keys(config.explore_bg || {}).map(function (key) {
							if (key === '2' || key === '3' || key === '4' || key === '6') { sub_item = []; }

							let rowHasChild = "row-container";

							
									let itemVal = config.explore_bg[key];
									let itemBgColor = itemVal.backgroundColor || {};
									let titleColor = key === '3' || key === '4' ? 'white' : "origin";
									let contentColor = titleColor;
									let itemBgImg = `url(${(itemVal.backgroundImg)? (config.full_assets + itemVal.backgroundImg) : ""})`;
									let itemBgImgX = "origin";
									let itemBgImgY = "origin";
									let itemTitle = cmsData[map_ary[key] + '_title'];
									let itemDesc = cmsData[map_ary[key] + '_description'];
									let itemLogo = cmsData[map_ary[key] + '_logo'] || "";

									let itemHrefVal = cmsData[map_ary[key] + '_arrow_url'];
									let itemHrefMoreColor = key === '4' ? 'ghost-white' : 'ghost-orange';

									let quicklinks = cmsData[map_ary[key] + '_quick_links_container'];
									let itemLinksTitleColor = 'orange';
									let hyperlinkColor = 'origin';
									let itemLinksTitle = static_content.title_quicklinks;
									let itemLinksVal = quicklinks || {};
									let itemLinksNum = itemLinksVal.length;
									let hasItemLinks = itemLinksNum>0;

									let item_link = Object.keys(itemLinksVal || {}).map(function (linkKey) {
										let linkVal = itemLinksVal[linkKey];
										let linkTxt = linkVal.quick_link_title;
										let linkHref = linkVal.quick_link_url;
										return (<li key={linkKey}><a href={linkHref} target="_blank" className="item-links__link" style={{ "color":hyperlinkColor }}>{linkTxt}</a></li>);
									});

									// let itemChildItem = itemVal.childItem || {};
									let childItemArr = cmsData[map_ary[key] + '_article_container'] || {};
									if (Object.keys(childItemArr).length > 0 && !Array.isArray(childItemArr)) { // check if its not array
										childItemArr = [childItemArr]; // make it array
									}
									let childItemNum = childItemArr.length;
									if (childItemNum > 0) {
										rowHasChild = rowHasChild + " row-container--child"
									}

									sub_item.push(
										<LazyLoad once key={key}><div 
										className={
											(itemVal["backgroundImg"]) ?
											"item-container item-container--bg item-container--bg--x-"+itemBgImgX+" item-container--bg--y-"+itemBgImgY
											: ((itemVal["backgroundColor"]) ? "item-container item-container--bg--color" : "item-container")
										} style={
											(itemVal["backgroundImg"]) ?
											({ "backgroundImage": itemBgImg })
											: ({ "backgroundColor": itemBgColor})
										}>
											<div className={"item-content "+getLinksCols(key, itemLinksNum)+getChildContainer(childItemNum)}>
											{
												// 2 blocks in a row
												['0','1','4','5'].indexOf(key) > -1 ? [
													itemLogo !== "" ? (<div key={'itemIcon'+key} className={"item-icon"}><img src={itemLogo} alt={itemTitle}/></div>) : null,
													<div key={'itemTitle'+key} className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>,															
													<p key={'itemDesc'+key} className={"item-content__desc item-content__desc--"+contentColor}>{ itemDesc }</p>,
													hasItemLinks ?
														<div key={'itemLinks'+key} className={"item-links"}>
															<div className={"item-links__title item-links__title--"+itemLinksTitleColor}>{itemLinksTitle}</div>
															{(getCols(key)==="full") 
																? ((itemLinksNum < 5)
																	? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																	: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
																)
																: ((itemLinksNum < 3)
																	? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																	: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
																)
															}
														</div> : null ,
													itemHrefVal && itemHrefVal!=='' ?
													<div key={'itemArrow'+key} className="item-more">
														<a href={itemHrefVal} className={"button button--" + itemHrefMoreColor} target="_blank">
															<span>{static_content.btnLearnMore}</span>
														</a>
													</div> : null
												] : 
												// BnR item
												key === '2' ? [
													<div key={'l'+key} className="item-content__left">
														<div className="item-content__sub1">
														{
															itemTitle
															? (<div className={"side-title side-title--"+titleColor}>{itemTitle}</div>)
															: null
														}
													{
														itemLogo !== ""
														? (<div className={"item-icon"}><img src={itemLogo} alt={itemTitle}/></div>)
														: null
													}
													<div className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>
													<p className={"item-content__desc item-content__desc--"+contentColor}>{itemDesc }</p>
													</div>
													<div className="item-content__sub2">
														{hasItemLinks?
															<div className={"item-links"}>
																<div className={"item-links__title item-links__title--"+itemLinksTitleColor}>{itemLinksTitle}</div>
																{(itemLinksNum < 5)
																		? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																		: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
																}
																
															</div>
														: null
														}
													</div>
												</div>,
												<div key={'r'+key} className="item-content__right">	
													<PageItemChildItem childItem={childItemArr} curId={key} arrowUrl={itemHrefVal}/> 
												</div>,
												itemHrefVal && itemHrefVal!=='' ?
												<div key={'itemArrow'+key} className="item-more">
													<a href={itemHrefVal} className={"button button--" + itemHrefMoreColor} target="_blank">
														<span>{static_content.btnLearnMore}</span>
													</a>
												</div>
												: null
													
												] :
												// Research
												key === '3' ? [
													<div key={'sideTitle'+key} className={"side-title side-title--"+titleColor}>{itemTitle}</div>,
													itemLogo!=='' ? <div key={'itemIcon'+key} className={"item-icon"}><img src={itemLogo} alt={itemTitle}/></div> : null,
													<div key={'contentTitle'+key} className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>,
													<p key={'contentDesc'+key} className={"item-content__desc item-content__desc--"+contentColor}>{itemDesc}</p>,
													hasItemLinks ? <div className={"item-links"}>
														<div className={"item-links__title item-links__title--"+itemLinksTitleColor}>{itemLinksTitle}</div>
														{(getCols(key)==="full") 
															? ((itemLinksNum < 5)
																? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
															)
															: ((itemLinksNum < 3)
																? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
															)
														}
													</div>
												: null,
												rowHasChild ? 
													<PageItemChildItem key={'pC'+key} childItem={childItemArr} curId={key} arrowUrl={itemHrefVal}/>
													: null
												] :
												// Normal item
												[
													<div key={'cl'+key} className="item-content__left">
													{
														itemTitle
														? (<div className={"side-title side-title--"+titleColor}>{itemTitle}</div>)
														: null
													}
													{
														itemLogo !== ""
														? (<div className={"item-icon"}><img src={itemLogo} alt={itemTitle}/></div>)
														: null
													}
													<div className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>
													<p className={"item-content__desc item-content__desc--"+contentColor}>{itemDesc }</p>
												</div>,
												<div key={'cR'+key} className="item-content__right">															
													{hasItemLinks?
														<div className={"item-links"}>
															<div className={"item-links__title item-links__title--"+itemLinksTitleColor}>{itemLinksTitle}</div>
															{(itemLinksNum < 5)
																	? (<ul className={"item-links-content item-links-content--col"}>{item_link}</ul>)
																	: (<ul className={"item-links-content item-links-content--cols"}>{item_link}</ul>)
															}
															
														</div>
													: null
													}
												</div>,
												itemHrefVal && itemHrefVal!=='' ?
												<div key={'itemArrow'+key} className="item-more">
													<a href={itemHrefVal} className={"button button--" + itemHrefMoreColor} target="_blank">
														<span>{static_content.btnLearnMore}</span>
													</a>
												</div> : null
													]
											}
											</div>
										</div></LazyLoad>
									);
									
							return(
								(key !== '0' && key !== '4') ?
								<ScrollAnimation key={key} animateIn="fadeIn" animateOnce className={"items-row items-row--"+getCols(key)}>
								<div className={rowHasChild + " " + (	key === '2' ? "bnr-item" : "insights-item")}>{sub_item}</div></ScrollAnimation>
								: null
							);
						})} 
					</div>
				</section>
			</div>
		);
	}
}