import * as WTT from '../constants/WTTealiumConfig';

var sprintf = require("sprintf-js").sprintf;

export const eventAction = (eventAction, pos, title, locale) => {
  if (WTT[eventAction]) {
    const _script = WTT[eventAction] || {};

    // // WT
    // if (window._tdctag) {
    //   window._tdctag.dcsMultiTrack(
    //     'DCS.dcsuri', sprintf(_script.WT.dcsuri, pos),
    //     'WT.ti', sprintf(_script.WT.ti, pos, title, locale.toUpperCase()),
    //     'WT.prdclick', '1',
    //     'WT.y', 'y',
    //     'DCSext.cg_hktdc_category', '',
    //     'DCSext.cg_section', '',
    //     'DCSext.cg_subsection', '',
    //     'DCSext.cg_language', '',
    //     'DCSext.ssouid', ''
    //   );
    // }

    // Tealium
    if (window.utag) {
      window.utag.link({
        "event_action": _script.TEALIUM.event_action,
        "event_name": _script.TEALIUM.event_name,
        "event_title": sprintf(_script.TEALIUM.event_title, pos, title, locale.toUpperCase()),
        "event_category": _script.TEALIUM.event_category,
        "event_click": _script.TEALIUM.event_click,
        "site_url": sprintf(_script.TEALIUM.site_url, pos),
        "link_category": _script.TEALIUM.link_category,
        "link_name": sprintf(_script.TEALIUM.link_name, pos, title),
      });
    }
  }  
}

export const searchEventAction = (actionName, locale, keyword, searchGroup, noOfResult) => {
  if (WTT[actionName]) {
    const _script = WTT[actionName] || {};

    // Tealium
    if (window.utag) {
      window.utag.link({
        "event_action": _script.TEALIUM.event_action,
        "event_name": _script.TEALIUM.event_name,
        "event_title": sprintf(_script.TEALIUM.event_title, locale.toUpperCase()),
        "event_category": _script.TEALIUM.event_category,
        "event_click": _script.TEALIUM.event_click,
        "site_url": _script.TEALIUM.site_url,
        "link_category": _script.TEALIUM.link_category,
        "link_name": sprintf(_script.TEALIUM.link_name, searchGroup),
        "search_keyword": sprintf(_script.TEALIUM.search_keyword, keyword),
        "search_category": sprintf(_script.TEALIUM.search_category, searchGroup),
        "search_results": sprintf(_script.TEALIUM.search_results, noOfResult)
      });
    }

    // WT
    // if (window._tdctag) {
    //   window._tdctag.dcsMultiTrack(
    //     'DCS.dcsuri', _script.WT.dcsuri,
    //     'WT.ti', sprintf(_script.WT.ti, locale.toUpperCase()),
    //     "WT.oss", sprintf(_script.WT.oss, keyword),
    //     "WT.oss_r", sprintf(_script.WT.oss_r, noOfResult),
    //     "WT.search_cat", sprintf(_script.WT.search_cat, searchGroup),
    //     'WT.prdclick', '1',
    //     'WT.y', 'y',
    //     'DCSext.cg_hktdc_category', '',
    //     'DCSext.cg_section', '',
    //     'DCSext.cg_subsection', '',
    //     'DCSext.cg_language', '',
    //     'DCSext.ssouid', ''
    //   );
    // }
  }  
}

export const hyperlinkTrack = (href, wtGroup) => {
  console.log('hyperlinkTrack', href, extractHostname(href), wtGroup)
  const curURL = window.location.href;
  let locale = 'EN';
  if (curURL.indexOf('/tc/') !== -1) {
    locale = 'TC';
  } else if (curURL.indexOf('/sc/') !== -1) {
    locale = 'SC';
  }

  // // WT
  // if (window._tdctag) {
  //   window._tdctag.dcsMultiTrack(
  //     'DCS.dcsuri', '/virtual/PHR/home/referral.htm',
  //     'WT.ti', 'PHR: Referral to sub-portal (' + locale + ')',
  //     'WT.refurl', href, 
  //     'WT.refdomain', extractHostname(href), 
  //     'WT.clicksection', wtGroup, 
  //     'WT.prdclick', '1', 
  //     'WT.y', 'y', 
  //     'DCSext.cg_hktdc_category', '', 
  //     'DCSext.cg_section', '', 
  //     'DCSext.cg_subsection', '', 
  //     'DCSext.cg_language', '', 
  //     'DCSext.ssouid', ''
  //   );
  // }

  // Tealium
  if (window.utag) {
    window.utag.link({
      "event_action": "click",
      "event_name": "link_click",
      "event_title": "PHR: Referral to sub-portal (" + locale + ")",
      "event_category": "link",
      "event_click": "1",
      "site_url": "/virtual/PHR/home/referral.htm",
      "link_category": wtGroup,
      "link_name": href,
      "referral_domain": extractHostname(href)
    });

  }
}

// ref: https: //stackoverflow.com/questions/8498592/extract-hostname-name-from-string
const extractHostname = (url) => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}