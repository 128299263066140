import React, { Component } from 'react';
import MainBannerContainer from '../../containers/MainBannerContainer';
import HKTDCEventsContainer from '../../containers/HKTDCEventsContainer';
import MarketFocusContainer from '../../containers/MarketFocusContainer';
import SuccessStoriesContainer from '../../containers/SuccessStoriesContainer';
import HowCanWeHelpContainer from '../../containers/HowCanWeHelpContainer';
import ChannelBarContainer from '../../containers/ChannelBarContainer';

import { Helmet } from "react-helmet";

import ScrollAnimation from 'react-animate-on-scroll';

export default class Home extends Component {

  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'home', 'class': 'homepage' }} />
        <div id="skiptargetholder">
          <span id="skiptarget" title="maincontent" tabIndex="0"></span>
        </div>
        <MainBannerContainer />
        <HKTDCEventsContainer />
        <ScrollAnimation className="sec-break" animateIn="fadeIn" animateOnce />
        <MarketFocusContainer />
        <SuccessStoriesContainer />
        <HowCanWeHelpContainer />
        <ChannelBarContainer />
      </main>
    );
  }
}
