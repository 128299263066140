import React, {Component} from 'react';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import { inSensitiveReplaceEM } from '../../util/helper';
import truncate from 'truncate-html';

class ContentTypePreferredList extends Component {
  
  render() {
    const { q='', contentData = {}} = this.props;
    const { description = "", titleForSeo = "", landingUrl = ""} = contentData;
    let { title = ""} = contentData;
    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese
    
    title = titleForSeo !== '' ? titleForSeo : title;
    const em_title = inSensitiveReplaceEM(truncate(title,{length: config.char_limit.title}), q);
    const em_desc = inSensitiveReplaceEM(truncate(description, {length: config.char_limit.desc }), q);

    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    
    return (
      <div className="feature-listing__item">
          {
            title !== "" ? 
            <a href={landingUrl} target="_blank" title={title}>
              <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/></h4> 
            </a> 
            :
            <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/></h4>
          }
        <div className="feature-listing__item-row">
          <div className="feature-listing__detail">
          <div className="feature-listing__desc"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_desc} /></div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ContentTypePreferredList);
