import React, { Component } from "react";
import AdGlareBanner from "../../util/AdGlareBanner";

export default class PageAds extends Component {
  render() {
    const lang = this.props.i18n.locale || "en";

    return (
      <section className="sec-page-ads">
        <div className="ads-container">
          <div className="ad-container">
            <div className="ad-container__img"> 
              <AdGlareBanner lang={lang} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
