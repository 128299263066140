import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetStyle = (props) => {
  const { stylesheet = '', name = 'customStylesheet' } = props;

  return (
    <Helmet>
      {stylesheet && (
        <style name={name} type="text/css">
          {stylesheet}
        </style>
      )}
    </Helmet>
  );
};

export default HelmetStyle;
