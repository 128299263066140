import React, {Component} from 'react';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import { formatEventDate, inSensitiveReplaceEM } from '../../util/helper';
import truncate from 'truncate-html';
import LazyLoad from 'react-lazyload';

class ContentTypeEventSch extends Component {
  
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const { q='', debugMode='', contentData = {}} = this.props;
    const {
      score = 0,
      description = '',
      bodyShortForm = '',
      regionsForDisplay = [],
      landingUrl = '',
      media01Url = '',
      media01Alt = '',
      titleForSeo = '',

      eventStartDate = '',
      eventEndDate = '',
      eventDisplayMonthYearOnlyInd = false,

      eventApplyBoothInd = false,
      eventApplyBoothUrl = '',
      eventDownloadFormInd = false,
      eventDownloadFormUrl = '',

      eventPreRegInd = false,
      eventPreRegUrl = '',

      eventExhibitorListInd = false,
      eventExhibitorListUrl = '',

      eventFairCatalogueInd = false,
      eventFairCatalogueUrl = '',

      eventEnquiryInd = false,
      eventEnquiryUrl = '',

      addToCalendarInd = false, 
      addToCalendarUrl = '',

      publicVisitorEventUrlInd = false,
      publicVisitorEventUrl = '',

    } = contentData;
    let { title = ""} = contentData;

    var I18n = require('react-redux-i18n').I18n;
    var moment = require('moment-timezone');
    moment.tz.setDefault("Asia/Hong_Kong"); // force to display in HK timezone, affect i18n's moment
    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese

    title = titleForSeo !== '' ? titleForSeo : title;
    const desc = description !== '' ? description : bodyShortForm;
    let em_desc = inSensitiveReplaceEM(truncate(desc,{length: config.char_limit.desc}), q);
    let em_title = inSensitiveReplaceEM(truncate(title,{length: config.char_limit.title}), q);

    let regionTags = [];
    for (let key in regionsForDisplay) {
      regionTags.push(<SanitizedHTML key={key} className="organic-listing__info" allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={truncate(regionsForDisplay[key],{length: config.char_limit.region})}/>);
    }

    // event date format start
    const dateFormat = eventDisplayMonthYearOnlyInd ? static_content.dateFormat.short : static_content.dateFormat.long;
    const eStartD = eventStartDate;
    const eEndD = eventEndDate;
    const startDateAry = (moment(eStartD).format('DD-MM-YYYY')).split('-');
    const endDateAry = (moment(eEndD).format('DD-MM-YYYY')).split('-');

    let eventDate = formatEventDate(startDateAry, endDateAry, eventDisplayMonthYearOnlyInd, eStartD, eEndD, static_content.dateFormat, dateFormat, this.props.i18n.locale);

    return (
      <div className="organic-listing__item">
        {        
          title !== "" ? 
          <a href={landingUrl} target="_blank" title={title}>
            <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/></h4> 
          </a> 
          :
          <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/></h4>
        }
        { debugMode === 'Y' &&
          <p>{score}</p>
        }
        <div className="organic-listing__item-row">        
        {
          media01Url !== '' && 
          <LazyLoad once><div className="organic-listing__img">
            <span className="organic-listing__img-holder">
                <img className="organic-listing__thumb" src={media01Url} alt={media01Alt} title={media01Alt} />
            </span>
          </div></LazyLoad>
        }
          <div className="organic-listing__detail">
            <div className="organic-listing__infos">
              {regionTags}
              <div className="organic-listing__info">
              { eventDate }
              </div>
            </div>
          {
            em_desc !== '' &&
            <div className="organic-listing__desc">{<SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_desc}/>}</div>
          }  
          <div className="organic-listing__btns">
            {
              (eventApplyBoothInd || eventDownloadFormInd) && <a href={eventApplyBoothUrl || eventDownloadFormUrl} target="_blank" className="organic-listing__btn" title={static_content.searchResult.txt_applyboothdownloadform}>{static_content.searchResult.txt_applyboothdownloadform}</a>
            }
            {
              eventPreRegInd && <a href={eventPreRegUrl} target="_blank" className="organic-listing__btn" title={static_content.searchResult.txt_register}>{static_content.searchResult.txt_register}</a>
            }
            {
              eventExhibitorListInd && <a href={eventExhibitorListUrl} target="_blank" className="organic-listing__btn" title={static_content.searchResult.txt_exhList}>{static_content.searchResult.txt_exhList}</a>
            }
            {
              eventFairCatalogueInd && <a href={eventFairCatalogueUrl} target="_blank" className="organic-listing__btn" title={static_content.searchResult.txt_fairCat}>{static_content.searchResult.txt_fairCat}</a>
            }
            {
              eventEnquiryInd && <a href={eventEnquiryUrl} target="_blank" className="organic-listing__btn" title={static_content.searchResult.txt_enquire}>{static_content.searchResult.txt_enquire}</a>
            }
            {
              addToCalendarInd && <a href={addToCalendarUrl} target="_blank" className="organic-listing__btn" title={static_content.searchResult.txt_addToCalendar}>{static_content.searchResult.txt_addToCalendar}</a>
            }
            {
              publicVisitorEventUrlInd && <a href={publicVisitorEventUrl} target="_blank" className="organic-listing__btn" title={static_content.searchResult.txt_publishVisitors}>{static_content.searchResult.txt_publishVisitors}</a>
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ContentTypeEventSch);
