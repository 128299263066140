import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../config';
import ScrollAnimation from 'react-animate-on-scroll';
import { eventAction } from '../../util/clickEvents';
import LazyLoad from 'react-lazyload';
import { formatEventDate } from '../../util/helper';

class EventListDefault extends Component {

	findObjects = (obj, targetValue) => {
		let result = [];
		for (let prop in obj) {
			if (obj[prop].includes(targetValue)) {
				result.push(prop);
			}
		}
		return result;
	}

	render() {
		var moment = require('moment-timezone');
		const locale = this.props.i18n.locale || 'en';
		moment.tz.setDefault("Asia/Hong_Kong"); // force to display in HK timezone, affect i18n's moment
		const static_content = this.props.i18n.translations[locale] || {}; // local content

		const eventHeaderList = static_content.events.eventListHeader || {};
		const findObjects = this.findObjects;
		let event_list_header = Object.keys(eventHeaderList || {}).map(function (i) {
			return (
				<div key={'evl' + i} className={"more-events-list-header__elem"}>{eventHeaderList[i]}</div>
			);
		});

		const { eventResult = {}, location = "" } = this.props;
		let event_list = [];
		Object.keys(eventResult || {}).map(function (eventKey) {
			const eVal = eventResult[eventKey];

			// event date format start
			const dateFormat = eVal.eventDisplayMonthYearOnlyInd ? static_content.dateFormat.short : static_content.dateFormat.long;
			const eStartD = eVal.eventStartDate;
			const eEndD = eVal.eventEndDate;
			const startDateAry = (moment(eStartD).format('DD-MM-YYYY')).split('-');
			const endDateAry = (moment(eEndD).format('DD-MM-YYYY')).split('-');

			let eventDate = formatEventDate(startDateAry, endDateAry, eVal.eventDisplayMonthYearOnlyInd, eStartD, eEndD, static_content.dateFormat, dateFormat, locale);

			let imageUrl = '';
			let imageAlt = '';
			if (eVal.media01Url && eVal.media01Url !== '') {
				imageUrl = eVal.media01Url;
				imageAlt = eVal.media01Alt;
			} else {
				const eF = eVal.eventFormats || {};
				let uniq = [...new Set(eF)];
				let eFAry = Object.keys(uniq).map(function (i) {
					return (findObjects(static_content.events.eventFormat_map, eVal.eventFormats[i]))
				});
				eFAry = [].concat.apply([], eFAry);
				let uniqFormats = [...new Set(eFAry)];

				let eFMap = [];
				for (let x in uniqFormats) {
					eFMap.push((config.eventFormat).indexOf(uniqFormats[x]));
				}
				imageUrl = config.full_assets + config.eventFormat_image[config.eventFormat[(eFMap.sort())[0]]];
				imageAlt = eVal.media01Alt || eVal.title;
				//console.log('config.eventFormat[(eFMap.sort())[0]]', config.eventFormat[(eFMap.sort())[0]])
			}

			const HTML_applyBooth = location !== "pastevent" && eVal.eventApplyBoothInd ? <a href={eVal.eventApplyBoothUrl} className="more-event-list__elem more-event-list__elem--apply" target="_blank"><div className="elem-img"><img src={config.full_assets + "/images/events/icons/icon-event-apply.png"} alt={static_content.events.txt_apply} /></div><div className="elem__desc">{static_content.events.txt_apply}</div></a> : null;

			const HTML_downloadForm = location !== "pastevent" && eVal.eventDownloadFormInd ? <a href={eVal.eventDownloadFormUrl} className="more-event-list__elem more-event-list__elem--form" target="_blank"><div className="elem-img"><img src={config.full_assets + "/images/events/icons/icon-event-exhibitor.png"} alt={static_content.events.txt_download} /></div><div className="elem__desc">{static_content.events.txt_downloadForm}</div></a> : null;

			const HTML_eventPreReg = location !== "pastevent" && eVal.eventPreRegInd ? <a href={eVal.eventPreRegUrl} className="more-event-list__elem more-event-list__elem--regist" target="_blank" onClick={() => eventAction('event_btn_register', '', '', locale)}><div className="elem-img"><img src={config.full_assets + "/images/events/icons/icon-event-register.png"} alt={static_content.events.txt_register} /></div><div className="elem__desc">{static_content.events.txt_register}</div></a> : null;

			const HTML_publicVisitorEventUrl = eVal.publicVisitorEventUrlInd ? <a href={eVal.publicVisitorEventUrl} className="more-event-list__elem more-event-list__elem--form" target="_blank"><div className="elem-img"><img src={config.full_assets + "/images/events/icons/icon-event-public-visitors.png"} alt={static_content.events.txt_publishVisitors} /></div><div className="elem__desc">{static_content.events.txt_publishVisitors}</div></a> : null;

			// const HTML_eventExhibitorList = eVal.eventExhibitorListInd ? <a href={eVal.eventExhibitorListUrl} className="more-event-list__elem more-event-list__elem--exhibit" target="_blank"><div className="elem-img"><img src={config.full_assets+"/images/events/icons/icon-event-exhibitor.png"} alt={static_content.events.txt_exhList}/></div><div className={"more-list-event__mobile-elem elem__desc"}>{static_content.events.txt_exhList}</div></a> : null;

			// const HTML_eventFairCatalogue = eVal.eventFairCatalogueInd ? <a href={eVal.eventFairCatalogueUrl} className="more-event-list__elem more-event-list__elem--fairCat" target="_blank"><div className="elem-img"><img src={config.full_assets+"/images/events/icons/icon-fair-cat.png"} alt={static_content.events.txt_fairCat}/></div><div className={"more-list-event__mobile-elem elem__desc"}>{static_content.events.txt_fairCat}</div><div className={"more-event-list__desktop-elem"}>{eVal.eventFairCatalogueMonth+' '+eVal.eventFairCatalogueYear}</div></a> : null;

			const HTML_enquiry = eVal.eventEnquiryInd ? <a href={eVal.eventEnquiryUrl} className={"more-event-list__elem more-event-list__elem--enquire"} onClick={() => eventAction('event_btn_enquire', '', '', locale)} target="_blank"><div className={"elem-img"}><img src={config.full_assets + "/images/events/icons/icon-event-enquire.png"} alt={static_content.events.txt_enquire} /></div><div className={"elem__desc"}>{static_content.events.txt_enquire}</div></a> : null;

			const HTML_addToCalendar = eVal.addToCalendarInd ? <a href={eVal.addToCalendarUrl} className={"more-event-list__elem more-event-list__elem--enquire"} target="_blank"><div className={"elem-img"}><img src={config.full_assets + "/images/events/icons/icon-event-calendar.png"} alt={static_content.events.txt_addToCalendar} /></div><div className={"elem__desc"}>{static_content.events.txt_addToCalendar}</div></a> : null;

			const HTML_eventVideo = eVal.eventVideoUrl && !['', 'http://', 'https://'].includes(eVal.eventVideoUrl) ? <a href={eVal.eventVideoUrl} className={"more-event-list__elem more-event-list__elem--video"} target="_blank"><div className={"elem-img"}><img src={config.full_assets + "/images/events/icons/icon-event-video.svg"} alt={static_content.events.txt_eventVideo} width={"25"} /></div><div className={"elem__desc"}>{static_content.events.txt_eventVideo}</div></a> : null;

			event_list.push(
				<ScrollAnimation animateIn="fadeIn" animateOnce className={"more-event-list-container"} key={'eventlist' + eventKey}>

					<div className={"more-event-list-left-container"}>

						<LazyLoad once><div className={"more-event-list__elem more-event-list__elem--img"}>
							<LazyLoad once><a target="_blank" href={eVal.landingUrl}><img src={imageUrl} alt={imageAlt} title={imageAlt} /></a></LazyLoad>
						</div></LazyLoad>
						<div className="more-event-list__elem more-event-list__elem--title"><a target="_blank" href={eVal.landingUrl}>{eVal.title}</a></div>
						<div className={"more-event-list__elem more-event-list__elem--date"}>{eventDate}</div>
						<div className="clearfix"></div>
						<div className={"more-event-list__elem more-event-list__elem--details"}><div className={"more-list-event__mobile-elem"}>{static_content.events.eventListHeader[3]}</div>{eVal.eventFormats && (eVal.eventFormats).join(', ')}</div>
						<div className={"more-event-list__elem more-event-list__elem--area"}><div className={"more-list-event__mobile-elem"}>{static_content.events.eventListHeader[4]}</div>{eVal.regionsForDisplay && (eVal.regionsForDisplay).join(', ')}</div>
					</div>
					<div className={"more-event-list-right-container"}>
						{HTML_applyBooth}
						{HTML_downloadForm}
						{HTML_eventPreReg}
						{HTML_publicVisitorEventUrl}
						{/*HTML_eventExhibitorList*/}
						{/*HTML_eventFairCatalogue*/}
						{HTML_enquiry}
						{HTML_addToCalendar}
						{HTML_eventVideo}
					</div>
				</ScrollAnimation>
			);
			return ('');
		});

		return (
			<div className={"more-events-list-conatiner list-events-container"}>
				<div className={"more-events-list-header"}>
					{event_list_header}
					<div className={"more-events-list-header__elem"}></div>
				</div>
				{event_list}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
	pure: true
})(EventListDefault);