import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import Accordion from '../components/Accordion/';

const mapStateToProps = (state, ownProps) => ({
  i18n: state.i18n,
  item: ownProps.item
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(Accordion);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;



