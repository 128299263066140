import React, { Component } from 'react';
import { fetchMFData } from '../../actions/';
import LazyLoad from 'react-lazyload';
import store from '../../store/';
import ScrollAnimation from 'react-animate-on-scroll';
import { goToAnchor } from 'react-scrollable-anchor';
import { eventAction } from '../../util/clickEvents';
import config from '../../config';

export default class MarketFocus extends Component {
    constructor(props) {
        super(props);
        store.dispatch(fetchMFData()); // Teamsite CMS
        this.focusListItems = [];
        this.state = {
            marketIndex: 0,
        };
    }
    componentDidMount() {
        initSwiper();
        function initSwiper() {
            var thisElem = document.getElementById("market-focus-slider");
            var swiper = new window.Swiper(thisElem, {
                spaceBetween: 0,
                slidesPerView: 1,
                slidesPerGroup: 1,
                //loop: true,
                //effect: 'fade',
                paginationClickable: true,
                autoplayDisableOnInteraction: false,
                simulateTouch: false,
                autoplay: false,
                allowTouchMove:false,
                autoHeight: true,
                /*
                fadeEffect: {
                    crossFade: true
                }
                */
            });
            swiper.on('init', function () {
                swiper.update();
                swiper.slideNext();
            });
        }
        const anchor = window.location.hash;
        if (anchor) {
            setTimeout(() => {
                goToAnchor(anchor.replace("#", ''), false)
            }, 800);
        }
    }
    
    toMFDetail = (tarId, num) => {
        let _this = this;
        this.setState({
            marketIndex: num
        }, ()=> {
            document.body.style.overflowY = 'hidden';
            document.body.classList.add('market-focus-open');
            const overlay = document.getElementById("market-focus-overlay");
            if(!overlay) {
                const newOverlay = document.createElement('div');
                newOverlay.setAttribute("id", "market-focus-overlay");
                document.body.appendChild(newOverlay);  
            }

            let thisElem = document.getElementById("market-focus-slider");
            if (thisElem && thisElem.swiper) {
                let mfDetails = document.querySelectorAll(".mf-detail");
                let tarElem = document.getElementById(tarId);
                if (tarElem) {
                    [].forEach.call(mfDetails, function (el) {
                        el.classList.remove("is-active");
                    });
                    tarElem.classList.add("is-active");
                    thisElem.swiper.slideTo(1);

                    document.querySelector('#market-focus-slider').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                    
                    setTimeout(() => {
                        _this.closeSwiper.focus();
                        const mfSlider = document.querySelector("#market-focus-slider");
                        const mfSliderOffsetHeight = mfSlider.offsetHeight;
                        if (window.innerHeight < mfSliderOffsetHeight) {
                            document.body.style.overflowY = 'auto';
                        }
                    }, 500);                
                }
            }
        });         
    }

    closeMFDetail = () => {
        //stop the iframe video background music
        let activeKey = this.getActiveMFDKey();
        let targetItemId = "mf-detail-" + activeKey;
        let tarElem = document.getElementById(targetItemId);
        let tarIframe = tarElem.querySelector("iframe");
        if(tarIframe){
            tarIframe.src = tarIframe.src;
        } 

        document.body.classList.remove('market-focus-open');
        const overlay = document.getElementById("market-focus-overlay")
        overlay.parentNode.removeChild(overlay);

        let _this = this;
        var thisElem = document.getElementById("market-focus-slider");
        if (thisElem && thisElem.swiper) {
            thisElem.swiper.slideNext();
            thisElem.swiper.slidePrev();
            thisElem.swiper.slideTo(0);
            if (_this.focusListItems !== undefined && _this.focusListItems.length !== 0) {
                _this.focusListItems[_this.state.marketIndex].focus();
            }
        }     
        document.body.style.overflowY = 'auto';   
    }

    getActiveMFDKey = () => {
        var activeMFDetails = document.querySelectorAll(".mf-detail.is-active");
        var key = parseInt(activeMFDetails[0].getAttribute('data-key'), 10);
        return key;
    }

    toPrevMFDetail = () => {
        //stop the iframe video background music
        let activeKey = this.getActiveMFDKey();
        let targetItemId = "mf-detail-" + activeKey;
        let tarElem = document.getElementById(targetItemId);
        let tarIframe = tarElem.querySelector("iframe");
        if(tarIframe){
            tarIframe.src = tarIframe.src;
        } 

        var mfDetails = document.querySelectorAll(".mf-detail");
        var mfDetailsEndKey = mfDetails.length - 1;
        activeKey--
        if (activeKey < 0) {
            activeKey = mfDetailsEndKey;
        }
        let tarId = "mf-detail-" + activeKey;
        this.toMFDetail(tarId, activeKey);
    }

    toNextMFDetail = () => {
        //stop the iframe video background music
        let activeKey = this.getActiveMFDKey();
        let targetItemId = "mf-detail-" + activeKey;
        let tarElem = document.getElementById(targetItemId);
        let tarIframe = tarElem.querySelector("iframe");
        if(tarIframe){
            tarIframe.src = tarIframe.src;
        } 

        var mfDetails = document.querySelectorAll(".mf-detail");
        var mfDetailsEndKey = mfDetails.length - 1;
        activeKey++
        if (activeKey > mfDetailsEndKey) {
            activeKey = 0;
        }
        let tarId = "mf-detail-" + activeKey;
        this.toMFDetail(tarId, activeKey);
    }
    render() {
        const locale = this.props.i18n.locale || 'en';
        const {header_description, market_focus_items_container} = this.props.cmsData.data[this.props.i18n.locale] || {};
        const staticContent = this.props.i18n.translations[this.props.i18n.locale] || {};
        const _that = this;

        return (
            <section data-webtrend-group={config.WTTealiumGroupName.marketFocus} className="market-focus" id="MarketFocus">
                <ScrollAnimation className="market-focus__holder" animateIn="fadeIn" animateOnce>
                    <div className="content-wrapper">
                        <div className="side-title">{staticContent.title_marketFocus}</div>
                        <div className="market-focus__intro">
                            <h2 className="market-focus__intro-title">{staticContent.title_marketFocus}</h2>
                            <p className="market-focus__intro-desc">{header_description}</p>
                        </div>
                    </div>
                    <div id="market-focus-slider" className="market-focus__content">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className={"market-focus__items market-focus__items"+Object.keys(market_focus_items_container || {}).length}>
                                    <div className="content-wrapper">
                                        {Object.keys(market_focus_items_container || {}).map(function (key, index) {
                                            const value = market_focus_items_container[key];
                                            const title = value.title;
                                            const background = "url(" + value.thumbnail + ")";
                                            const target = "mf-detail-" + key;
                                            return (
					                            <span tabIndex="0" key={'mf-' + key} data-tar={target} onClick={(e)=>{_that.toMFDetail(target, index); eventAction('MF', (parseInt(key, 10)+1), title, locale);e.preventDefault();}} onKeyPress={(e)=>{_that.toMFDetail(target, index); eventAction('MF', (parseInt(key, 10)+1), title, locale);e.preventDefault();}} ref={(item) => { _that.focusListItems[index] = item;}} className="market-focus__item"> 
                                                    <LazyLoad once offset={400}><span className="market-focus__bg" style={{ "backgroundImage": background }}></span></LazyLoad>
                                                    <span className="market-focus__info">
                                                        <h3 className="market-focus__title">{title}</h3>
                                                        <span className="market-focus__arrow"><span className="visually-hidden">{staticContent.hidden_button_label}</span></span>
                                                    </span>
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide" tabIndex="-1">
                                <div className="market-focus__details">
                                    {Object.keys(market_focus_items_container || {}).map(function (key) {
                                        let value = market_focus_items_container[key].detail;
                                        let title = value.theme_title;
                                        let desc = value.theme_description;
                                        let detailBG = "url(" + value.background_image + ")";
                                        let target = "mf-detail-" + key;

                                        let quickLinksTitle = staticContent.title_quicklinks; // use static

										//either json object or array
										const quickLinksSource  =  value.common_quick_links_container;
										
										const quickLinksList = Array.isArray(quickLinksSource)?(
										quickLinksSource.map((link) =>
                                            <li key={'mf-quicklink-' + new Date().getTime() + 100 * Math.random()}><a href={link.quick_link_url} className="mf-detail__link" target="_blank"><span>{link.quick_link_title}</span></a></li>
                                        )
										):(
										 quickLinksSource &&  (
											<li key={'mf-quicklink-' + new Date().getTime() + 100 * Math.random()}><a href={quickLinksSource.quick_link_url} className="mf-detail__link" target="_blank"><span>{quickLinksSource.quick_link_title}</span></a></li>)
										);
                                        let detailBox3LinkList = '';
                                        if (Array.isArray(value.other_quick_links_container)) {
                                            detailBox3LinkList = value.other_quick_links_container.map((link) =>
                                                <li key={'mf-linklist-' + new Date().getTime() + 100 * Math.random()}><a href={link.quick_link_url} className="mf-detail__link" target="_blank"><span>{link.quick_link_title}</span></a></li>
                                            );
                                        } else {
                                            detailBox3LinkList = <li key={'mf-linklist-' + new Date().getTime() + 100 * Math.random()}><a href={value.other_quick_links_container.quick_link_url} className="mf-detail__link" target="_blank"><span>{value.other_quick_links_container.quick_link_title}</span></a></li>;
                                        }

                                        
                                        return (
                                            <div key={'mfd-' + key} data-key={key} id={target} className="mf-detail">
                                                <div className="mf-detail__top">
                                                    <LazyLoad once><span className="mf-detail__top-bg" style={{ "backgroundImage": detailBG }}></span></LazyLoad>
                                                    <div className="mf-detail__top-control">
                                                        <div className="content-wrapper">
                                                            <span tabIndex="0" onClick={(e)=>{_that.closeMFDetail();e.preventDefault();}} onKeyPress={(e)=>{_that.closeMFDetail();e.preventDefault();}} className="mf-detail__btn-close" ref={(close) => { _that.closeSwiper = close; }}></span> 
                                                        </div>
                                                    </div>
                                                    <div className="content-wrapper">
                                                        <div className="mf-detail__control">
                                                            <span tabIndex="0" onClick={(e)=>{_that.toPrevMFDetail();e.preventDefault();}} onKeyPress={(e)=>{_that.toPrevMFDetail();e.preventDefault();}} className="mf-detail__btn-prev"></span> 
                                                            <span tabIndex="0" onClick={(e)=>{_that.toNextMFDetail();e.preventDefault();}} onKeyPress={(e)=>{_that.toNextMFDetail();e.preventDefault();}} className="mf-detail__btn-next"></span> 
                                                        </div>
                                                        <div className="mf-detail__intro">
                                                            <h2 className="mf-detail__intro-title">{title}</h2>
                                                            <p className="mf-detail__intro-desc">{desc}</p>
                                                        </div>
														{
															quickLinksSource &&	(
															 <div className="mf-detail__quicklinks">
																<h3 className="mf-detail__quicklinks-title">{quickLinksTitle}</h3>
																<ul className="mf-detail__list">
																	{quickLinksList}
																</ul>
															</div>
															)
														}
                                                    </div>
                                                </div>
                                                <div className="mf-detail__boxes">
                                                    <div className="content-wrapper">
                                                        <div className="mf-detail__box mf-detail__box--1">
                                                        {
                                                            value.main_article_event_image !== '' && value.main_article_event_video_iframe === '' &&
                                                            <LazyLoad once><div className="mf-detail__box-img"><a href={value.main_article_event_url} target="_blank"><img src={value.main_article_event_image} alt={value.main_article_event_image_alt_text} /></a></div></LazyLoad>
                                                        }
                                                        {
                                                            value.main_article_event_video_iframe !== '' &&
                                                            <LazyLoad once>
                                                                <div className="mf-detail__box-img" dangerouslySetInnerHTML={{ __html: value.main_article_event_video_iframe }}></div>
                                                            </LazyLoad>
                                                        }                                                            
                                                            {/*<h3 className="mf-detail__box-label">{detailBox1Label}</h3>*/}
                                                            <a href={value.main_article_event_url} target="_blank"><h4 className="mf-detail__box-title" dangerouslySetInnerHTML={{ __html: value.main_article_event_title }}></h4></a>
                                                            <p className="mf-detail__box-desc">{value.main_article_event_description}</p>
                                                            <div className="mf-detail__box-control">
                                                                <a href={value.main_article_event_url} className="mf-detail__btn-arrow" target="_blank"><span className="visually-hidden">{staticContent.hidden_button_label}</span></a>
                                                            </div>
                                                        </div>
                                                        <div className="mf-detail__box mf-detail__box--2">
                                                            <h3 className="mf-detail__box-label">{value.other_article_event_title}</h3>
                                                            <p className="mf-detail__box-desc">{value.other_article_event_description_1}</p>
                                                            <p className="mf-detail__box-date">{value.other_article_event_description_2}</p>
                                                            <div className="mf-detail__box-control">
                                                                <a href={value.other_article_event_url} className="mf-detail__btn-arrow" target="_blank"><span className="visually-hidden">{staticContent.hidden_button_label}</span></a>
                                                            </div>
                                                        </div>
                                                        <div className="mf-detail__box mf-detail__box--3">
                                                            <ul className="mf-detail__list">
                                                                {detailBox3LinkList}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </section>
        );
    }
}
