import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";

import * as logger from "./util/logger";

const envStage = process.env.REACT_APP_STAGE;

if (envStage.indexOf("_prod") >= 0) {
  logger.disableLogger();
}

ReactDOM.render(<App />, document.getElementById("root"));
//registerServiceWorker();
unregister();
