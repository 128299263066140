import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavLinkUtils from '../../util/NavLinkUtils';
import Link from '../../util/Link';
import { fetchHeaderFooterData } from '../../actions/';
import config from '../../config';
import CommonHeaderLinksContainter from '../../containers/CommonHeaderLinksContainter';
import LazyLoad from 'react-lazyload';

//translate
import store from '../../store/';
import BtnShareContainer from "../../containers/BtnShareContainer";


class Mainnav extends Component {
  constructor(props) {
    super(props);
    store.dispatch(fetchHeaderFooterData()); // Teamsite CMS
    this.handleClick = this.handleClick.bind(this);
    this.handleHover = this.handleHover.bind(this);
    this.backSubMenu = this.backSubMenu.bind(this);
    this.toggleSubMenu = this.toggleSubMenu.bind(this);
    this.state = { isHovered: false, onSubmenu: false };
  }
  
  handleClick() {
    var windowJsWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowJsWidth > 991) {
      this.setState({
        isHovered: false
      });
    } else{
      document.body.classList.remove('mob-menu-open');
    }
  }
  handleHover(id) {
    var windowJsWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowJsWidth > 991) {
      this.setState({
        isHovered: id
      });
    }
  }

  backSubMenu() {
    this.setState({ onSubmenu: false });
    var activeItem = document.querySelector('.nav-link.is-open');
    if (activeItem) { activeItem.classList.remove("is-open") }
  }

  toggleSubMenu(key) {
    this.setState({ onSubmenu: true });
    var tarId = "nav-link-" + key;
    var tar = document.getElementById(tarId);
    if (tar) { tar.classList.add('is-open'); }
  }

  render() {
    const backFun = this.backSubMenu;
    const toggleSubMenu = this.toggleSubMenu;
    let hoverItem = this.state.isHovered;
    let handleClick = this.handleClick;
    let handleHover = this.handleHover;

    function closeMenu(){
      document.body.classList.remove('mob-menu-open');
    }
    

    const cmsNavLinks = this.props.headerFooterReducer.data[this.props.i18n.locale]; // get Teamsite CMS Data
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content

    const { mapname = {}} = config;

    const { topBar, navLinks } = static_content || {};

    /* Copy from TopBar.js */
    let langTxt = static_content.lang || {};
    let langEN = langTxt.en || {};
    let langTC = langTxt.tc || {};
    let langSC = langTxt.sc || {};

    
    const { i18n } = this.props || {};
    const lang = i18n.locale;

    let langElems = [];
    let rootPath = window.location.protocol + '//' + window.location.host;
    const is_Preview = (process.env.REACT_APP_STAGE).includes('preview');
    let currentURL = rootPath + (this.props.currentUrl || '');
    let langCurrPath = "/" + lang + "/";
    let langLink1Path = currentURL.replace(rootPath, rootPath + "/tc");
    let langLink2Path = currentURL.replace(rootPath, rootPath + "/sc");
    
    let langTitle = langTxt.title_en;
    if(currentURL.indexOf(langCurrPath)>0){
        langLink1Path = currentURL.replace(langCurrPath,"/tc/");
        langLink2Path = currentURL.replace(langCurrPath,"/sc/");
    }
    let langLink1Txt = langTC['txt_long'];
    let langLink2Txt = langSC['txt_long'];
    if (lang === 'tc') {
        langLink1Path = currentURL.replace(langCurrPath,"/en/");
        langLink2Path = currentURL.replace(langCurrPath,"/sc/");
        langLink1Txt = langEN['txt_long'];
        langLink2Txt = langSC['txt_long'];
        langTitle = langTxt.title_tc;
    } else if (lang === 'sc') {
        langLink1Path = currentURL.replace(langCurrPath,"/en/");
        langLink2Path = currentURL.replace(langCurrPath,"/tc/");
        langLink1Txt = langEN['txt_long'];
        langLink2Txt = langTC['txt_long'];
        langTitle = langTxt.title_sc;
    }

    if (!is_Preview) {
      langLink1Path = langLink1Path.replace(/en\/$/, ''); // ensure no /en/ in all root path
    }

    langElems.push(
      <div key="mob-lang" id="mob-lang" className="nav-link__subholder">
        <div className="mob-lang__headlink">{langTitle}</div>
        <a href={langLink1Path} className="mob-lang__link">
          <span>{langLink1Txt}</span>
        </a>
        <a href={langLink2Path} className="mob-lang__link">
          <span>{langLink2Txt}</span>
        </a>
      </div>
    );


    return (
      <nav id="nav-link-holder" className={this.state.onSubmenu ? "nav-link__holder on-submenu" : "nav-link__holder"}>
        <div id="mob-share" className="nav-link__subholder">
          <BtnShareContainer devType={static_content.for_mobile || {}}/>
        </div>
        {langElems}
        <div id="mob-menu" className="nav-link__subholder">
          <div className="header__submenu-back-container">
            <span tabIndex="0" onClick={(e) => {backFun();e.preventDefault();}} onKeyPress={(e) => {backFun();e.preventDefault();}} className="header__submenu-back">{topBar.txt_back}</span>
          </div>
          {this.props.headerFooterReducer.isLoading ? static_content.txt_loading : ''}
          
          {Object.keys(mapname || {}).map(function (key) {
            if (cmsNavLinks) {
            let value = navLinks[mapname[key]];
            let big_title = value['big_title']; // use static content
            let small_title = value['small_title']; // use static content
            let href = config['pagename_' + mapname[key]]; // internal link
            let {introImgUrl=''} = value; 
            if (key === '1') {
              href = (window.location.hostname === config.events && !(process.env.REACT_APP_STAGE).includes('preview')) ? '' : href;
            }
            let cmssubmenu = cmsNavLinks['navigation_menu_0' + (parseInt(key, 10) + 1)] || {}; // Teamsite CMS
            
            let keyId = "nav-link-" + key;
            
            // `btns` Source only
            let submenu_intro_btns_elems = [];
            if (cmssubmenu.btn_1_desc) submenu_intro_btns_elems.push(<a target="_blank" key="sbKey-0" href={cmssubmenu.btn_1_url} className="button button--s button--orange">{cmssubmenu.btn_1_desc}</a>);
            if (cmssubmenu.btn_2_desc) submenu_intro_btns_elems.push(<a target="_blank" key="sbKey-1" href={cmssubmenu.btn_2_url} className="button button--s button--orange">{cmssubmenu.btn_2_desc}</a>);
            // END `btns`

            // `Links` Join events page only
            let submenu_intro_links_elems = [];
            if (value.links) {
              submenu_intro_links_elems.push(<Link key="link_events" to={config.pagename_events} className="submenu__headlink">{value.links[0]}</Link>);
              submenu_intro_links_elems.push(<Link key="link_eventshk" to={config.pagename_events+'?location=hk'} className="submenu__headlink">{value.links[1]}</Link>);
              submenu_intro_links_elems.push(<Link key="link_eventsoutsidehk" to={config.pagename_events+'?location=outsidehk'} className="submenu__headlink">{value.links[2]}</Link>);
              submenu_intro_links_elems.push(<Link key="link_eventspastevent" to={config.pagename_events+'?location=pastevent'} className="submenu__headlink">{value.links[3]}</Link>);
            }     
            
            
            // END `Links`

            //----END Submenu Intro----//

            //----Submenu Items----//
            let submenu_items_elems = [];
            if (key === "0") {
              // Start submenu quick links              
              Object.keys(cmssubmenu.sub_menu_container || {}).map(function (titleKey) {
                let headlinks_elems = [];
                let subitems = cmssubmenu.sub_menu_container[titleKey];
                headlinks_elems.push(<a target="_blank" key={'sColKey1-' + titleKey} href={subitems.sub_menu_url} className="submenu__headlink">{subitems.sub_menu_title}</a>);
                
                let links_elems = [];
                // due to LIVESITE return object instead of array when only 1 record. Transform into array if necessary
                const quicklinks = Array.isArray(subitems.quick_links_container) ? subitems.quick_links_container : [subitems.quick_links_container];
                links_elems = (quicklinks).map(function (subsub, quicklinkKey) {
                  return (<a target="_blank" key={'sColKey2-' + quicklinkKey} href={subsub.quick_link_url} className="submenu__link">{subsub.quick_link_title}</a>);
                });
                submenu_items_elems.push(<div key={'sColKey-' + titleKey} className="submenu__col">{headlinks_elems}{links_elems}</div>);

                return ('')
              });
            } else if (key === "1") {
              submenu_items_elems.push(
                <div key={'sColKey-events'} className="submenu__col submenu__event-mob">
                  <Link key='sColKey1-events' to={config.pagename_events} className="submenu__headlink">{value.links[0]}</Link>
                  <Link key='sColKey1-eventshk' to={config.pagename_events+'?location=hk'} className="submenu__headlink">{value.links[1]}</Link>
                  <Link key='sColKey1-eventsoutsidehk' to={config.pagename_events+'?location=outsidehk'} className="submenu__headlink">{value.links[2]}</Link>
                  <Link key='sColKey1-eventspastevent' to={config.pagename_events+'?location=pastevent'} className="submenu__headlink">{value.links[3]}</Link>
                </div>
              );
            } else if (key === "2" || key === "3") {
              // Source & Explore submenu quick links              
              Object.keys(cmssubmenu.sub_menu_container || {}).map(function (titleKey) {
                let headlinks_elems = [];
                let subitems = cmssubmenu.sub_menu_container[titleKey];
                headlinks_elems.push(<a target="_blank" key={'sColKey1-' + titleKey} href={subitems.sub_menu_url || subitems.sub_menu_title_link} className="submenu__headlink">{subitems.sub_menu_title}</a>);
                let links_elems = [];
                // due to LIVESITE return object instead of array when only 1 record. Transform into array if necessary
                const quicklinks = Array.isArray(subitems.quick_links_container) ? subitems.quick_links_container : [subitems.quick_links_container];
                links_elems = (quicklinks).map(function (subsub, quicklinkKey) {
                  return (<a target="_blank" key={'sColKey2-' + quicklinkKey} href={subsub.quick_link_url} className="submenu__link">{subsub.quick_link_title}</a>);
                });
                subitems.sub_menu_more_url!==''&&links_elems.push(<a target="_blank" key={'sColKey3-' + titleKey} href={subitems.sub_menu_more_url} className="submenu__col-more">{static_content.btnMore}</a>);
                submenu_items_elems.push(<div key={'sColKey-' + titleKey} className="submenu__col">{headlinks_elems}{links_elems}</div>);
                return('')
              });
              let rightLinks = [];
              // due to LIVESITE return object instead of array when only 1 record. Transform into array if necessary
              const rightquicklinks = Array.isArray(cmssubmenu.right_quick_links_container) ? cmssubmenu.right_quick_links_container : [cmssubmenu.right_quick_links_container];
              rightLinks = (rightquicklinks).map(function (subitems, rtitleKey) {
                return (<a target="_blank" key={'sColKey1-' + rtitleKey} href={subitems.quick_link_url} className="submenu__headlink">{subitems.quick_link_title}</a>);
              });
              submenu_items_elems.push(<div key={'sColKey-right'} className="submenu__col">{rightLinks}</div>);
            }


            //----Submenu Events----//
            let submenu_events_elems = [];
            if (key === "1") {
              // Join Event sub menu              
              let submenu_events = cmssubmenu.feature_event_container;
              submenu_events_elems = Object.keys(submenu_events || {}).map(function (sEventKey) {
                let childVal = submenu_events[sEventKey];

                let img = childVal.event_thumbnail;
                let img_alt = childVal.event_thumbnail_alt_text;
                let title = childVal.event_name;
                let desc = childVal.event_description;
                let label = childVal.event_location;
                let date = childVal.event_date;
                let href = childVal.event_url;

                return (
                <div key={'submenuevent'+sEventKey} className="submenu__event">
                  {img !== '' ?
                    <LazyLoad once><div className="submenu__event-img">
                      <a key={'sEventKey-' + sEventKey} href={href} target="_blank">
                        <img src={img} alt={img_alt} />
                      </a>
                    </div></LazyLoad>
                    : ''}
                  <a key={'sEventKey-' + sEventKey} href={href} target="_blank">
                    <div className="submenu__event-title">{title}</div>                  
                  </a>
                  <p className="submenu__event-desc">{desc}</p>
                    <div className="submenu__event-info">
                      <p className="submenu__event-label">{label}</p>
                      {(label && date) ? <div className="submenu__event-break"></div> : null}
                      <p className="submenu__event-date">{date}</p>
                    </div>
                </div>);
              });
            }
            

            //----END Submenu Events----//

            //----Submenu More----//
            let navClass = hoverItem === keyId ? "nav-link is-hover" : "nav-link";
            //----END Submenu More----//

            let intro_img = (cmssubmenu.header_image && cmssubmenu.header_image !== '' ?
                            <LazyLoad once><img className="submenu__intro-img" src={cmssubmenu.header_image} alt={cmssubmenu.header_image_alt_text} title={cmssubmenu.header_image_alt_text} /></LazyLoad>
                            :
                            <div className="submenu__intro-img" alt={cmssubmenu.header_image_alt_text} title={cmssubmenu.header_image_alt_text}></div>);

            return (
              <div data-webtrend-group={config.WTTealiumGroupName.header} key={'key-' + key} id={keyId} className={navClass} onMouseEnter={handleHover.bind(null, keyId)} onMouseLeave={handleHover.bind(null, false)}>
              { (href === '') ?
                <NavLinkUtils exact to={href} onClick={handleClick.bind(null, keyId)} className="nav-link__link is-active" lang={lang} onFocus={handleHover.bind(null, "-1")}>
                  <strong>{big_title}</strong>{small_title}
                </NavLinkUtils>
                :
                <NavLinkUtils to={href} onClick={handleClick.bind(null, keyId)} activeClassName="is-active" className="nav-link__link" lang={lang} onFocus={handleHover.bind(null, "-1")}>
                  <strong>{big_title}</strong>{small_title}
                </NavLinkUtils>
              }
                <span tabIndex="0" onClick={(e) => {toggleSubMenu(key);e.preventDefault();}} onKeyPress={(e) => {toggleSubMenu(key);e.preventDefault();}} className="nav-link__mob-toggle"></span>
                <div data-webtrend-group={config.WTTealiumGroupName.submenu} className="submenu">
                  <div className="submenu__wrapper">
                    <div className="submenu__body">
                      <div className="submenu__intro">
                        <Link to={href} onClick={() => {handleHover(0); closeMenu()}} onFocus={handleHover.bind(null, keyId)}>
                          <div className="submenu__intro-title">
                            <strong>{big_title}</strong>{small_title}
                          </div>
                        </Link>
                        <p className="submenu__intro-desc">{cmssubmenu.header_description}</p>
                        {
                          key === '2' ?
                          <a href={introImgUrl} target="_blank">
                          <span className="visually-hidden">{big_title+small_title}</span>
                            {intro_img}
                          </a>
                          :
                          <Link to={href} onClick={() => {handleHover(0); closeMenu()}}>                
                          <span className="visually-hidden">{big_title+small_title}</span>       
                            {intro_img}
                          </Link>
                        }                        
                        <div className="submenu__intro-btns">{submenu_intro_btns_elems}</div>
                        <div className="submenu__intro-links">{submenu_intro_links_elems}</div>
                      </div>
                      <div className={`submenu__items${submenu_items_elems.length > 6 && key === '0' ? ' submenu__items--4col' : ''}`}>
                        {submenu_items_elems}
                        {submenu_events_elems}
                      </div>
                    </div>
                    <div className="submenu__bar">
                      <NavLinkUtils to={href} className="submenu__more-link" onFocus={handleHover.bind(null, keyId)} onClick={() => {handleHover(0); closeMenu()}}>{static_content.btnLearnMore}</NavLinkUtils>
                    </div>
                  </div>
                </div>
              </div>);
            }
            return ('')
          })}

          <div className="top-bar top-bar--mob" data-webtrend-group={config.WTTealiumGroupName.header}>
            <CommonHeaderLinksContainter/>
          </div>
        </div>
      </nav>
    );
  }

  static propTypes = {
    location: PropTypes.object.isRequired
  }
}
export default Mainnav;