import React, { Component } from 'react';
import store from '../../store/';
import MetaHelmet from "../PageItem/MetaHelmet";
import PageIntroContainer from '../../containers/PageIntroContainer';
import NoMatch from '../Layout/NoMatch';
import { fetchStaticPagesData } from '../../actions/';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import Loading from "../Loading/Loading";
import _ from 'lodash';
import moment from "moment-timezone";

export default class StaticPages extends Component {
  constructor(props) {
    super(props);
    let metaReady = false;
    store.dispatch(fetchStaticPagesData(props.match.params.pagename || ''));
    // if (props.staticPagesData.data.length <= 0) {
    //   store.dispatch(fetchStaticPagesData(props.match.params.pagename || ''));
    //   metaReady = false;
    // }
    this.state = {
      metaReady: metaReady
    };
  }
  
  composeTealiumObj() {
    const staticPagesData = this.props.staticPagesData.data || {};
    const { seoData = {}} = staticPagesData[this.props.i18n.locale] || {};
    const tealiumObj = {
      site_subsection: config.tealium.staticPages.subsection,
      page_type: config.tealium.staticPages.pageType,
      page_name: seoData.title
    };
    // console.log(JSON.stringify(tealiumObj, null, 2));
    return tealiumObj;
  }

  componentDidUpdate(prevProps) {
    if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
      setTimeout(() => {
        if (window.utag_data) {
          console.log('Tealium Static Pages load Sent:', new Date())
          window.utag_data = this.composeTealiumObj();
          window.enableTealium()
        }
      }, 1000);
    }
   
    
    if (this.props.match.params.pagename !== prevProps.match.params.pagename) {
      // handling: switch between static pages only
      this.setState({
        metaReady: false
      }, ()=> {
        store.dispatch(fetchStaticPagesData(this.props.match.params.pagename || ''));
        if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
          setTimeout(() => {
            if (window.utag) {
              console.log('Tealium Static Pages Internal Link 2 Sent:', new Date())
              window.utag.view(this.composeTealiumObj());
            }
          }, 1000);
        }
      });
    }
  }

  componentDidMount() {
    // Send data to WebTrends server on didMount if this is not landing page
    // if (this.state.metaReady) {
    //   console.log('Static Pages WT sent on DidMount Internal Link: ', new Date());
    //   window.sendWebTrendsData();
    // }
    if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
      if (window.utag) {
        console.log('Tealium Static Pages Internal Link Sent:', new Date())
        window.utag.view(this.composeTealiumObj());
      }
    }
  }


  render() {
    const staticPagesData = this.props.staticPagesData.data || {};
    const { pageData = {}, seoData = {}, displayTime = {} } = staticPagesData[this.props.i18n.locale] || {};
    const currentTime = moment();

    if(_.isEmpty(staticPagesData)){
      return <Loading />
    }

    //onlineTime and offlineTime checking for each locale in new version or whole DCR in old version
    if((!_.isEmpty(displayTime.onlineTime) && !_.isEmpty(displayTime.offlineTime)) || (!_.isEmpty(staticPagesData.onlineTime) && !_.isEmpty(staticPagesData.offlineTime))){
      const onlineTime = displayTime.onlineTime? moment(displayTime.onlineTime) : moment(staticPagesData.onlineTime);
      const offlineTime = displayTime.offlineTime? moment(displayTime.offlineTime) : moment(staticPagesData.offlineTime);
      const isWithinRange = currentTime.isSameOrAfter(onlineTime) && currentTime.isSameOrBefore(offlineTime);
      if(!isWithinRange){
          return <NoMatch />
      }
    }
    
    return ( 
      <div>
        {
        (pageData.header_title) ?
        <main>
        <PageIntroContainer title={pageData.header_title} pageHead={seoData.h1 || ''}/>
        <MetaHelmet metaData={seoData} locale={this.props.i18n.locale}  />
        <div>
          <section className="sec-intro__detail">
            <SanitizedHTML className="sec-intro__detail-wrapper" allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={ pageData.page_body || '' } />
          </section>
        </div>
      </main>
        : (this.props.staticPagesData.error) && <NoMatch />
      }
      </div>
    )
  }
}