/*
 * Churix Ho, 30/07/2018
 * A shared React Component to handle common header links, including SSO options
 * About HKTDCMedia RoomMy HKTDCLogin / Register
 */

import React, { Component } from 'react';
import config from "../../config";

export default class CommonHeaderLinks extends Component {
    constructor(props) {
        super(props);
        this.state = { ssoStatus : null }
    }

    componentDidUpdate(prevProps) {
        if (this.props.ssoReadyStatusReducer.ssoReadyStatus && (this.props.ssoReadyStatusReducer.ssoReadyStatus !== prevProps.ssoReadyStatusReducer.ssoReadyStatus)) {
            if (window.ssoClient) {
                console.log('didupdate')
                // SSO Ready~
                this.setState({
                    ssoStatus: true
                })
            }
        }
    }

    // componentDidMount() {
    //     if (this.props.ssoReadyStatusReducer.ssoReadyStatus && window.ssoClient) {
    //     console.log('didmount')
    //       // SSO Ready~
    //       this.handleSetSSOStatus();
    //     }
    // }
   
    /* SSO script moved to index.html */
    /*componentWillMount() {
        var aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = config.sso_client;
        //console.log("Attach js", config.sso_client)
        
        document.head.appendChild(aScript);
        let _instance = this;
        aScript.onload = function() {
            //console.log("Attached js", window.ssoClient.getAccessToken())
            _instance.setState({ ssoStatus: window.ssoClient.getAccessToken() || null });
        };
    }*/

    _getSSOLangCode() {
        const lang = this.props.i18n.locale || 'en';
        return config.sso_lang_map[lang];
    }

    handleLoginClick(e) {
        e.preventDefault();
        if (window.ssoClient) {
            window.ssoClient.login({
                lang: this._getSSOLangCode(),
                redirect_uri: window.location.href
            });
        }
    }

    handleEditProfileClick = async (e) => {
        e.preventDefault();
        if (window.ssoClient) {
            await window.ssoClient.accessProfile({
                lang: this._getSSOLangCode(),
                newWindow: 'true',
            });
        }
    }

    handleLogoutClick = async (e) => {
        e.preventDefault();
        if (window.ssoClient) {
            await window.ssoClient.logout(window.location.href);
        }
    }

    handleRegisterClick(e) {
        if (window.ssoClient) {
            window.ssoClient.register({
                lang: this._getSSOLangCode(),
                redirect_uri: window.location.href
            });
        }        
        e.preventDefault();
    }

    
    render() {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const link_myhktdc = config.myhktdc_link.replace('%LANG%', config.myhktdc_lang_map[this.props.i18n.locale || 'en']);
        let { topBar = {} } = static_content || {};

        // TODO: using extraneous span to wrap the elements atm. Use of Fragments is preferred
        // ref: https://reactjs.org/blog/2017/11/28/react-v16.2.0-fragment-support.html
        return (
            <span onFocus={ this.props.action }>
                {
                    Object.keys(topBar['quick_links'] || {}).map(function (key) {
                        let val = topBar['quick_links'][key];
                        let txt = val.txt;
                        let href = val.href;

                        return (
                            <div key={'tQL'+key} className="top-bar__holder">
                                <a href={href} className="top-bar__link" target="_blank">
                                    <span className="top-bar__txt">{txt}</span>
                                </a>
                            </div>
                        )
                    })
                }
                <div className="top-bar__holder">
                    <a href={link_myhktdc} className="top-bar__link" target="_blank">
                        <span className="top-bar__txt">{topBar.title_myhktdc}</span>
                    </a>
                </div>
                <div className="top-bar__holder">
                    <div className="top-bar__link">
                        <span className="top-bar__icon top-bar__icon--login"></span>
                        {
                            this.state.ssoStatus === null ?
                                <span>
                                    <span className="top-bar__txt" tabIndex="0" onClick={this.handleLoginClick.bind(this)} onKeyPress={this.handleLoginClick.bind(this)}>{topBar.title_login}</span> / <span className="top-bar__txt" tabIndex="0" onClick={this.handleRegisterClick.bind(this)} onKeyPress={this.handleRegisterClick.bind(this)}>{topBar.title_register}</span>
                                </span>
                                :
                                <span>
                                    <span className="top-bar__txt" tabIndex="0" onClick={this.handleEditProfileClick.bind(this)} onKeyPress={this.handleEditProfileClick.bind(this)}>{topBar.title_edit_profile}</span>&nbsp;&nbsp;&nbsp;
                                    <span className="top-bar__txt" tabIndex="0" onClick={this.handleLogoutClick.bind(this)} onKeyPress={this.handleLogoutClick.bind(this)}>{topBar.title_logout}</span>
                                </span>
                        }
                    </div>
                </div>
            </span>
        );
    }
}