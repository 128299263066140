import React, { Component } from 'react';
import config from '../../config';
import Link from '../../util/Link';

export default class MoreEventsCat extends Component {
  render() {   
		
		const cmsData = this.props.cmsData.data[this.props.i18n.locale] || {}; // CMS Data
		const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content

		let curCat =this.props.curCat;
		let { moreEvents = {} } = static_content.events || {};
		let more_events_item = Object.keys(moreEvents[curCat] || {}).map(function (events) {
			let itemVal = moreEvents[curCat][events] || {};
			let itemBgColor = '#fbfbfb';
			let titleColor = "origin";
			let contentColor = "origin";
			let itemTitle = itemVal.title;
			let itemDesc = cmsData[itemVal.cat + '_description']; // CMS

			let itemHrefVal = config[itemVal.href];
			let itemHrefArrowColor = 'orange';

			return (
				<div key={'eventcat'+events} className="item-container item-container--bg--color" style={ ({ "backgroundColor": itemBgColor}) }>
					<div className={"item-content"}>
						<div className={"item-content__title item-content__title--"+titleColor}>{itemTitle}</div>
						<p className={"item-content__desc item-content__desc--"+contentColor}>{itemDesc}</p>
						{itemHrefVal ?
							<div className="item-more">
								<Link to={itemHrefVal} className={"button button--ghost-orange"}>
									<span>{static_content.btnLearnMore}</span>
								</Link>
							</div>
						: null
						}
					</div>
				</div>
			);
		});
		
    return (
      <div className={"items-row items-row--cols"}><div className="row-container">{more_events_item}</div></div>
    );
  }
}