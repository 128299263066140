import React, {Component} from 'react';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import { inSensitiveReplaceEM } from '../../util/helper';
import truncate from 'truncate-html';
import LazyLoad from 'react-lazyload';

class ContentTypeAdsysList extends Component {
  
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const content = this.props.contentData || {};
    const q = this.props.searchData.data.q || "";
    const {
      buyNowUrl = '',
      companyName = '',
      companyNob = [],
      companyUpcomingEventInd = false,
      companyUrl = '',
      contactUsUrl = '',
      getLatestPriceUrl = '',
      imageUrl = '',
      enlargedImageUrl = '',
      minimumOrderQuantity = '',
      prdLeadTime = '',
      productUrl = '',
      refPrice = '',
      regions = [],
      smallOrderInd = false,
      titleForSeo = ''
    } = content;
    let { title = ""} = content;

    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese
    title = titleForSeo !== '' ? titleForSeo : title;
    let em_companyName = inSensitiveReplaceEM(truncate(companyName,{length: config.char_limit.companyName}), q);
    let em_title = inSensitiveReplaceEM(truncate(title,{length: config.char_limit.title}), q);

    let regionTags = [];
    for (let key in regions) {
      regionTags.push(<SanitizedHTML key={key} className="feature-listing__info" allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={truncate(regions[key],{length: config.char_limit.region})}/>);
    }

    let companyNobTags = "";
    for (let key in companyNob) {
      companyNob[key] = truncate(companyNob[key],{length: config.char_limit.nob});
    }
    companyNobTags = <SanitizedHTML className="feature-listing__info" allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={companyNob.join(', ')}/>
    
    return (
      <div className="feature-listing__item">
      
          {
            title !== "" ? 
            <a href={productUrl} target="_blank" title={title}>
              <h4 className="feature-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/></h4> 
            </a> 
            :
            <h4 className="feature-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/></h4>
          }
          <div className="feature-listing__item-row">
          {
            imageUrl !== '' &&
            <div className="feature-listing__img">
              <LazyLoad once><a href={productUrl} target="_blank">
                <img className="feature-listing__thumb" src={imageUrl} alt={title} title={title}/>
              </a></LazyLoad>
              <div className="feature-listing__pop">
                <a href={productUrl} target="_blank">
                  <img src={enlargedImageUrl !== '' ? enlargedImageUrl : imageUrl} alt={title} title={title}/>
                </a>
                <div className="feature-listing__popcap">
                  <div className="feature-listing__popcap-title">                  
                  {
                    title !== "" ? 
                    <a href={productUrl} target="_blank" title={title}>
                      <SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/>
                    </a> 
                    :
                    <SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/>
                  }
                  </div>
                  <div className="feature-listing__popcap-btns">
                    { contactUsUrl!=='' && <a target="_blank" href={contactUsUrl} className="feature-listing__btn" title={static_content.searchResult.txt_contact_us}>{static_content.searchResult.txt_contact_us}</a> }
                    { buyNowUrl!=='' && <a target="_blank" href={buyNowUrl} className="feature-listing__btn" title={static_content.searchResult.txt_buy_now}>{static_content.searchResult.txt_buy_now}</a>}
                    { getLatestPriceUrl!== '' && <a target="_blank" href={getLatestPriceUrl} className="feature-listing__btn" title={static_content.searchResult.txt_get_latest_price}>{static_content.searchResult.txt_get_latest_price}</a>}
                  </div>
                </div>
              </div>
            </div>
          }
            
            <div className="feature-listing__detail">
              <div className="feature-listing__item-head">
                <a href={companyUrl} target="_blank" title={title}><h5 className="feature-listing__item-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_companyName}/></h5></a>
                <LazyLoad once><div className="feature-listing__note-icons">
                  { companyUpcomingEventInd && <a href={companyUrl} target="_blank" title={static_content.searchResult.alt_meetus}><div className="feature-listing__icon feature-listing__icon--booth" alt={static_content.searchResult.alt_meetus} title={static_content.searchResult.alt_meetus}><span className="visually-hidden">{static_content.searchResult.alt_meetus}</span></div></a> }
                  { smallOrderInd && <div className="feature-listing__icon feature-listing__icon--small-order" alt={static_content.searchResult.alt_soz} title={static_content.searchResult.alt_soz}><span className="visually-hidden">{static_content.searchResult.alt_soz}</span></div> }
                </div></LazyLoad>
              </div>
              <div className="feature-listing__infos">
                {regionTags}
                {companyNobTags}
              </div>
              <div className="feature-listing__specs">
                { minimumOrderQuantity!=='' && <div className="feature-listing__spec">{(static_content.searchResult.txt_min_order).replace('%STRING%', minimumOrderQuantity)}</div> }
                { refPrice!=='' && <div className="feature-listing__spec">{(static_content.searchResult.txt_ref_price).replace('%STRING%', refPrice)}</div>}
                { prdLeadTime!=='' && <div className="feature-listing__spec">{(static_content.searchResult.txt_pro_leadTime).replace('%STRING%', prdLeadTime)}</div>}
              </div>
              
              <div className="feature-listing__btns">
                { contactUsUrl!=='' && <a target="_blank" href={contactUsUrl} className="feature-listing__btn" title={static_content.searchResult.txt_contact_us}>{static_content.searchResult.txt_contact_us}</a> }
                { buyNowUrl!=='' && <a target="_blank" href={buyNowUrl} className="feature-listing__btn" title={static_content.searchResult.txt_buy_now}>{static_content.searchResult.txt_buy_now}</a>}
                { getLatestPriceUrl!== '' && <a target="_blank" href={getLatestPriceUrl} className="feature-listing__btn" title={static_content.searchResult.txt_get_latest_price}>{static_content.searchResult.txt_get_latest_price}</a>}
              </div>
            </div>
          </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ContentTypeAdsysList);
