import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../config';
import { eventAction } from '../../util/clickEvents';
import { formatEventDate } from '../../util/helper';

class EventListOthers extends Component {

	render() {
		var I18n = require('react-redux-i18n').I18n;
		var moment = require('moment-timezone');
		const locale = this.props.i18n.locale || 'en';
		moment.tz.setDefault("Asia/Hong_Kong"); // force to display in HK timezone, affect i18n's moment
		const static_content = this.props.i18n.translations[locale] || {}; // local content

		const eventHeaderList = static_content.events.eventListHeader_others || {};
		let event_list_header = Object.keys(eventHeaderList || {}).map(function (i) {
			return (
				<div key={i} className={"more-events-list-header__elem"}>{eventHeaderList[i]}</div>
			);
		});

		const { eventResult = {}, location = "" } = this.props;
		let event_list = Object.keys(eventResult || {}).map(function (eventKey) {
			let eVal = eventResult[eventKey];

			// event date format start
			const dateFormat = eVal.eventDisplayMonthYearOnlyInd ? static_content.dateFormat.short : static_content.dateFormat.long;
			const eStartD = eVal.eventStartDate;
			const eEndD = eVal.eventEndDate;
			const startDateAry = (moment(eStartD).format('DD-MM-YYYY')).split('-');
			const endDateAry = (moment(eEndD).format('DD-MM-YYYY')).split('-');

			let eventDate = formatEventDate(startDateAry, endDateAry, eVal.eventDisplayMonthYearOnlyInd, eStartD, eEndD, static_content.dateFormat, dateFormat, locale);

			const HTML_applyBooth = location !== "pastevent" && eVal.eventApplyBoothInd ? <a href={eVal.eventApplyBoothUrl} className="more-event-list__elem more-event-list__elem--apply" target="_blank" onClick={() => eventAction('event_btn_download', '', '', locale)}><div className="elem-img"><img src={config.full_assets + "/images/events/icons/icon-event-apply.png"} alt={static_content.events.txt_apply} /></div><div className="elem__desc">{static_content.events.txt_apply}</div></a> : null;

			const HTML_downloadForm = location !== "pastevent" && eVal.eventDownloadFormInd ? <a href={eVal.eventDownloadFormUrl} className="more-event-list__elem more-event-list__elem--form" target="_blank" onClick={() => eventAction('event_btn_download', '', '', locale)}><div className="elem-img"><img src={config.full_assets + "/images/events/icons/icon-event-exhibitor.png"} alt={static_content.events.txt_download} /></div><div className="elem__desc">{static_content.events.txt_downloadForm}</div></a> : null;

			const HTML_eventPreReg = location !== "pastevent" && eVal.eventPreRegInd ? <a href={eVal.eventPreRegUrl} className="more-event-list__elem more-event-list__elem--regist" target="_blank" onClick={() => eventAction('event_btn_register', '', '', locale)}><div className="elem-img"><img src={config.full_assets + "/images/events/icons/icon-event-register.png"} alt={static_content.events.txt_register} /></div><div className="elem__desc">{static_content.events.txt_register}</div></a> : null;

			const HTML_enquiry = eVal.eventEnquiryInd ? <a href={eVal.eventEnquiryUrl} className={"more-event-list__elem more-event-list__elem--enquire"}><div className={"elem-img"} target="_blank" onClick={() => eventAction('event_btn_enquire', '', '', locale)}><img src={config.full_assets + "/images/events/icons/icon-event-enquire.png"} alt={static_content.events.txt_enquire} /></div><div className={"elem__desc"}>{static_content.events.txt_enquire}</div></a> : null;

			const HTML_addToCalendar = eVal.addToCalendarInd ? <a href={eVal.addToCalendarUrl} className={"more-event-list__elem more-event-list__elem--enquire"}><div className={"elem-img"} target="_blank"><img src={config.full_assets + "/images/events/icons/icon-event-calendar.png"} alt={static_content.events.txt_addToCalendar} /></div><div className={"elem__desc"}>{static_content.events.txt_addToCalendar}</div></a> : null;

			const HTML_eventVideo = eVal.eventVideoUrl && !['', 'http://', 'https://'].includes(eVal.eventVideoUrl) ? <a href={eVal.eventVideoUrl} className={"more-event-list__elem more-event-list__elem--video"} target="_blank"><div className={"elem-img"}><img src={config.full_assets + "/images/events/icons/icon-event-video.svg"} alt={static_content.events.txt_eventVideo} width={"25"} /></div><div className={"elem__desc"}>{static_content.events.txt_eventVideo}</div></a> : null;

			return (
				<div key={eventKey} className={"more-event-list-container"}>
					<div className={"more-event-list-left-container"}>
						<div className={"more-event-list__elem more-event-list__elem--date"}><div className={"more-list-event__mobile-elem"}>{static_content.events.eventListHeader_others[0]}</div>{eventDate}</div>
						<div className={"more-event-list__elem more-event-list__elem--title"}><div className={"more-list-event__mobile-elem"}>{static_content.events.eventListHeader_others[1]}</div><a target="_blank" href={eVal.landingUrl}>{eVal.title}</a></div>
						<div className={"more-event-list__elem more-event-list__elem--details"}><div className={"more-list-event__mobile-elem"}>{static_content.events.eventListHeader_others[2]}</div>{eVal.eventFormats && (eVal.eventFormats).join(', ')}</div>
						<div className={"more-event-list__elem more-event-list__elem--area"}><div className={"more-list-event__mobile-elem"}>{static_content.events.eventListHeader_others[3]}</div>{eVal.regionsForDisplay && (eVal.regionsForDisplay).join(', ')}</div>
						<div className={"more-event-list__elem more-event-list__elem--organiser"}><div className={"more-list-event__mobile-elem"}>{static_content.events.eventListHeader_others[4]}</div>{eVal.eventOrganizer && (eVal.eventOrganizer).join(', ')}</div>
					</div>
					<div className={"more-event-list-right-container"}>
						{HTML_applyBooth}
						{HTML_downloadForm}
						{HTML_eventPreReg}
						{HTML_enquiry}
						{HTML_addToCalendar}
						{HTML_eventVideo}
					</div>
				</div>
			);
		});
		return (
			<div>
				<div className={"events-list-title"}>{static_content.events.introContent[this.props.page]}</div>

				<div className={"more-events-list-conatiner"}>
					<div className={"more-events-list-header"}>
						{event_list_header}
						<div className={"more-events-list-header__elem"}></div>
					</div>
					{event_list}
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
	pure: true
})(EventListOthers);