import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

function requestStartData() {
	return { type: types.REQ_START_DATA }
};

function receiveStartData(json) {
	return {
		type: types.RECV_START_DATA,
		data: json
	}
};

function receiveStartError(json) {
	return {
		type: types.RECV_START_ERROR,
		data: json
	}
};

export function fetchStartData() {
	return function (dispatch) {
		dispatch(requestStartData());
		return axiosAdapter({
			url: config.json_page_start
		})
			.then(function (response) {
				dispatch(receiveStartData(response.data.result.assets[0].content.Content));
			})
			.catch(function (response) {
				dispatch(receiveStartError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
