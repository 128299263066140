import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageIntroContainer from '../../containers/PageIntroContainer';

import { Helmet } from 'react-helmet';
import config from '../../config';

class NoMatch extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
      console.log('tealium working')
      if (window.utag) {
        console.log('Tealium No Match Page Internal Link Sent:', new Date());
        window.utag.view({
          site_subsection: config.tealium.notFoundPage.subsection,
          page_type: config.tealium.notFoundPage.pageType,
          page_name: config.tealium.notFoundPage.pageName,
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.allowCookiesStatusReducer.allowCookiesStatus === true &&
      this.props.allowCookiesStatusReducer.allowCookiesStatus !==
        prevProps.allowCookiesStatusReducer.allowCookiesStatus
    ) {
      setTimeout(() => {
        if (window.utag_data) {
          console.log('Tealium No Match Page Internal Link Sent:', new Date());
          window.utag_data = {
            site_subsection: config.tealium.notFoundPage.subsection,
            page_type: config.tealium.notFoundPage.pageType,
            page_name: config.tealium.notFoundPage.pageName,
          };
          window.enableTealium()
        }
      }, 1000);
    }
  }

  render() {
    const static_content =
      this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': '404' }} />
        <PageIntroContainer
          title={static_content.pagenotfound.title}
          desc={static_content.pagenotfound.desc}
        />
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n,
  allowCookiesStatusReducer: state.allowCookiesStatusReducer,
});

export default connect(mapStateToProps, null, null, {
  pure: true,
})(NoMatch);
