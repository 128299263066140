import config from '../../config';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import SearchListingContainer from '../../containers/SearchListingContainer';

import store from '../../store/';
import { getSearchResult, getSearchData, getSearchPreferredListing, fetchWebMetaData } from '../../actions/';

import { Helmet } from "react-helmet";
import * as h from '../../util/helper';
import 'url-search-params-polyfill';
import MetaHelmet from "../PageItem/MetaHelmet";

import { searchEventAction } from '../../util/clickEvents';

class SearchResult extends Component {
  constructor(props) {
    super(props);
    let metaReady = true;
    if (props.metaReducer.data.length <= 0) {
      store.dispatch(fetchWebMetaData());
      metaReady = false;
    }
    const initSearchData = this.retrieveSearchData(this.props.location.search);
    store.dispatch(getSearchData(initSearchData));
    this.state = {
      searchData: initSearchData,
      metaReady: metaReady,
      tealiumCookieStatus: this.props.allowCookiesStatusReducer.allowCookiesStatus || false,
      tealiumAdsysListStatus: false,
      tealiumSearchResultStatus: false
    };
  }
  retrieveSearchData(data){
    let paramsString = data;
    let params = new URLSearchParams(paramsString);
    let debugMode = params.get('rl') || '';
    debugMode = debugMode === 'Y' ? debugMode : '';
    let searchType = h.checkType(params.get('type'));
    let searchQuery = params.get('q') || '';
    let searchSorting = params.get('sort') || 'relevancy';
    let parseSearchSorting = (config.search_api_sort_map[searchSorting] ? config.search_api_sort_map[searchSorting] : '');
    let searchExcids = params.get('excIds') || '';
    let page = params.get('p') || '1';
    const cache = (params.get('cache') === 'false') ? false : true; 

    let searchData = {
      type: searchType,
      sort: searchSorting,
      debugMode: debugMode,
      data: {
        "q": searchQuery.trim(),
        "sort": searchSorting,
        "language": config.api_lang_map[this.props.i18n.locale],
        "offset": (parseInt(page, 10)).toString(),
        "excludeDocumentId": searchExcids
      },
      parseData: {
        "q": searchQuery,
        "sort": parseSearchSorting,
        "language": config.api_lang_map[this.props.i18n.locale],
        "offset": (parseInt(page, 10)).toString(),
        "excludeDocumentId": searchExcids,
        "limit": config.search_result_per_page
      }
    }
    if(!cache) {
      const timestamp = +new Date;
      searchData.data.t = timestamp;
      searchData.parseData.t = timestamp;
    }
    if (parseSearchSorting === '') delete searchData.parseData.sort;
    if (searchExcids === '') delete searchData.parseData.excludeDocumentId;
    return searchData;
  }
  getPreferredListing(){
    const params = new URLSearchParams(this.props.location.search);
    const searchQuery = (params.get('q')) || '';
    const searchType = h.checkType(params.get('type'));
    const cache = (params.get('cache') === 'false') ? false : true; 
    let page = (params.get('p')) || '1';
    page = (parseInt(page,10)).toString();
    const data = {
      'q': searchQuery,
      'language': config.api_lang_map[this.props.i18n.locale],
      'limit': config.search_prefer_limit,
      'classes': config.search_adsys_class[this.props.i18n.locale],
      'searchGroup': config.search_prefer_group_map[searchType]
    }
    if(!cache) data.t = +new Date
    if (searchType ==='all') delete data.searchGroup;
    if (searchType !== 'all' && searchType !== 'suppliers') delete data.classes;
    if (page === '1') {
      store.dispatch(getSearchPreferredListing(config.api_search_preferredListing, data));
    }
  }
  componentDidMount() {
    document.body.classList.remove('search-panel-open');
    const json = config['api_search_' + this.state.searchData.type] || config.api_search_all;    
    if (h.stringBytes(this.state.searchData.data.q) >= config.search_min_char) {
      store.dispatch(getSearchResult(json, this.state.searchData.parseData));
      this.getPreferredListing();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      let searchData = this.retrieveSearchData(this.props.location.search)
      this.setState({
        searchData: searchData
      }, () => {
        store.dispatch(getSearchData(searchData));
        document.body.classList.remove('search-panel-open');
        if (h.stringBytes(this.state.searchData.data.q) >= config.search_min_char) {
          const json = config['api_search_' + searchData.type] || config.api_search_all;
          store.dispatch(getSearchResult(json, searchData.parseData));
          this.getPreferredListing();
        }
      });      
    }
    if (this.props.getSearchResultReducer.isLoading === false && prevProps.getSearchResultReducer.isLoading === true) {
      this.setState({
        tealiumSearchResultStatus: true
      });
    }
    if (this.props.AdsysListReducer.isLoading === false && prevProps.AdsysListReducer.isLoading === true) {
      this.setState({
        tealiumAdsysListStatus: true
      });
    }
    if (this.props.allowCookiesStatusReducer.allowCookiesStatus === true && (this.props.allowCookiesStatusReducer.allowCookiesStatus !== prevProps.allowCookiesStatusReducer.allowCookiesStatus)) {
      this.setState({
        tealiumCookieStatus: true
      });
    }
    
    if (this.state.tealiumAdsysListStatus !== prevState.tealiumAdsysListStatus || 
        this.state.tealiumCookieStatus !== prevState.tealiumCookieStatus ||
        this.state.tealiumSearchResultStatus !== prevState.tealiumSearchResultStatus) {
      if (this.state.tealiumAdsysListStatus && this.state.tealiumCookieStatus && this.state.tealiumSearchResultStatus) {
        // all true - do
        this.sendWebDataInternalLink();
      }
    }
    
  }
  prepareTealiumProductData = () => {
    const {data = {}, isLoading = false, error = false} = this.props.AdsysListReducer || {};
    const searchData = this.retrieveSearchData(this.props.location.search);
    let productData = { ids: [], titles: [], total: 0, category: '', keyword: ''};
    if (data.length > 0 && !isLoading && !error) {
      Object.keys(data || {}).map(function (key) {
        const v = data[key];
        if (v && v.productId) {
          productData.ids.push(v.productId);
          productData.titles.push(v.title);
        }
        return ('')
      });
    }
    if (this.props.getSearchResultReducer.data) {
      productData.total = this.props.getSearchResultReducer.data.total || 0;
      productData.hasProduct = this.props.getSearchResultReducer.data.hasProduct || false;
      productData.category = searchData.type || '';
      productData.keyword = searchData.data.q || '';
    }
    return productData;
  }
  sendWebDataInternalLink = () => {
    this.setState({      
      tealiumAdsysListStatus: false,
      tealiumSearchResultStatus: false
    }, ()=>{
      // window.sendWebTrendsData();
      
      if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
        const pData = this.prepareTealiumProductData();
        if (!window.utag_sent) {
          window.utag_sent = true;
          if (window.utag_data) {
            console.log('Tealium Search Result page load Sent:', new Date())
            window.utag_data = {
              "site_subsection": config.tealium.search.subsection,
              "page_type": config.tealium.search.pageType,
              "page_name": config.tealium.search.pageName
            };
            if (pData) {
              window.utag_data["product_impression_id"] = pData.ids.join(', ');
              window.utag_data["product_impression_name"] = pData.titles.join(', ');
              window.utag_data["search_category"] = pData.category;
              window.utag_data["search_keyword"] = pData.keyword;
              window.utag_data["search_results"] = pData.total;
            }
            window.enableTealium()
          }
        } else {
          if (window.utag) {
            console.log('Tealium Search Result Internal Link Sent:', new Date())
            let uData = {
              "site_subsection": config.tealium.search.subsection,
              "page_type": config.tealium.search.pageType,
              "page_name": config.tealium.search.pageName
            };
            if (pData) {
              uData["product_impression_id"] = pData.ids.join(', ');
              uData["product_impression_name"] = pData.titles.join(', ');
              uData["search_category"] = pData.category;
              uData["search_keyword"] = pData.keyword;
              uData["search_results"] = pData.total;
            }
            window.utag.view(uData);
          }
        }
        if (pData) {
          searchEventAction('searchResult', this.props.i18n.locale, pData.keyword, pData.category, pData.total);
          if (pData.hasProduct) {
            searchEventAction('searchResult_displayViewMore', this.props.i18n.locale, pData.keyword, pData.category, pData.total);
          }
        }
      }
    });
    
  }
  render() {
    const {search_result = {}} = this.props.metaReducer.data[this.props.i18n.locale] || {};
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'search' }} />    
        <MetaHelmet metaData={search_result} locale={this.props.i18n.locale} is_pageSearch={true} />
        <SearchListingContainer searchData={this.state.searchData} keyword={this.state.searchData.data.q} metaData={search_result}/>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n,
  getSearchDataReducer: state.getSearchDataReducer,
  metaReducer: state.metaReducer,
  allowCookiesStatusReducer: state.allowCookiesStatusReducer,
  AdsysListReducer: state.getSearchPreferredListingReducer,
  getSearchResultReducer: state.getSearchResultReducer
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(SearchResult);