import React, { Component } from 'react';

import InsightsItemsContainer from '../../containers/InsightsItemsContainer';
import PageAdsContainer from '../../containers/PageAdsContainer';

import { Helmet } from "react-helmet";


export default class Insights extends Component {
  render() {
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'insights' }} />     
        <InsightsItemsContainer />
        <PageAdsContainer/>
      </main>
    );
  }
}
