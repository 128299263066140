import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import FactsheetPages from "../components/FactsheetPages/";
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => ({
	factsheetPagesData: state.pageFactsheetPagesReducer,
	i18n: state.i18n,
	allowCookiesStatusReducer: state.allowCookiesStatusReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(FactsheetPages);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

const ComponentWithRouter = withRouter(ComponentWithErrorBoundary)

export default ComponentWithRouter;