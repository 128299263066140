import { Component } from 'react';

import store from '../../store/';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import translationsObject from '../../translations/index.js';

import config from '../../config';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.initLang();
    window.locationServiceEndpoint = config.locationServiceEndPoint;
  }
  componentDidMount() {
    this.reloadCookieConsentPopup(false, this.getCurLang());
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.reloadCookieConsentPopup(false, this.getCurLang());
    }
    if (this.props.match.params.locale !== prevProps.match.params.locale) {
      this.initLang();
    }
  }
  getCurLang() {
    const { match } = this.props;
    const { params } = match || '';
    return params.locale || 'en';
  }
  initLang() {
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(translationsObject));
    store.dispatch(setLocale(this.getCurLang()));
  }
  reloadCookieConsentPopup(isAdditionalMsg, lang) {
    if (window.reloadPopup) {
      setTimeout(() => {
        window.reloadPopup(isAdditionalMsg, lang); // EU Consent, reload when lang is changed
      }, 2000);
    }
  }
  render() {
    return ('');
  }
}