import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

function requestSourceData() {
	return { type: types.REQ_SOURCE_DATA }
};

function receiveSourceData(json) {
	return {
		type: types.RECV_SOURCE_DATA,
		data: json
	}
};

function receiveSourceError(json) {
	return {
		type: types.RECV_SOURCE_ERROR,
		data: json
	}
};

export function fetchSourceData() {
	return function (dispatch) {
		dispatch(requestSourceData());
		return axiosAdapter({
			url: config.json_page_source
		})
			.then(function (response) {
				dispatch(receiveSourceData(response.data.result.assets[0].content.Content));
			})
			.catch(function (response) {
				dispatch(receiveSourceError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};
