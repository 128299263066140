import React, { useEffect, useState } from "react";
import Overlay from "react-overlay-component";
import config from '../../config';
import Loading from "../Loading/Loading";

const OverlayVideo = () => {

    const [isOpen, setOverlay] = useState(false);
    const [url ,setUrl] = useState("");
    const [loading ,setLoading] = useState(true);
    const closeOverlay = () => {
        setOverlay(false);
        setUrl("");
        setLoading(false);
    };

    const configs = {
        animate: true,
        clickDismiss: true,
        escapeDismiss: true,
        showCloseIcon: true,
        focusOutline: true,
    };
  

    useEffect(() => {
     
        const interval = setInterval(() => {
            let video = document.querySelectorAll(".overlay-video-element");
            for (var i = 0; i < video.length; i++) {
                (function (i) {
                    video[i].onclick = function () { 
                        setOverlay(true); 
                        setUrl(video[i].dataset.url);
                    };
                })(i);
            }
        }, 200);
        return () => clearInterval(interval);
    }, []);


    return (
        <div>
            <Overlay configs={configs} isOpen={isOpen} closeOverlay={closeOverlay}>
            <div className="video-onload">
            <div className="loadingImg">{loading? <Loading /> : null}</div>
                <iframe className="overlay-video-size" src={url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </Overlay>
        </div>
    );
};

export default OverlayVideo;