import React, { Component } from 'react';
import store from '../../store/';

import FeatureListing from './FeatureListing';
import OrganicListing from './OrganicListing';
import PaginationContainer from '../../containers/PaginationContainer';
import SearchPanelContainer from '../../containers/SearchPanelContainer';

import { getSearchDidYouMean } from '../../actions/';
import Link from '../../util/Link';
import config from '../../config';
import * as h from '../../util/helper';
import { searchEventAction } from '../../util/clickEvents';

var sprintf = require("sprintf-js").sprintf;

export default class ResultList extends Component {
    constructor(props) {
        super(props);
        if (h.stringBytes(this.props.keyword) >= config.search_min_char) {
            store.dispatch(getSearchDidYouMean({'q':this.props.keyword}));
        }        
        this.state = {
            excIds: (this.props.searchData.data.excludeDocumentId ? (this.props.searchData.data.excludeDocumentId).split(',') : [])
        };
    }
    componentDidUpdate(prevProps) {        
        if (this.props.getSearchResultReducer !== prevProps.getSearchResultReducer) {
            this.pushExcIds();
        }
        if (this.props.getSearchDataReducer !== prevProps.getSearchDataReducer) {
            if (this.props.getSearchDataReducer.searchData) {
                if (h.stringBytes(this.props.getSearchDataReducer.searchData.data.q) >= config.search_min_char) {
                    store.dispatch(getSearchDidYouMean({'q':this.props.getSearchDataReducer.searchData.data.q}));
                }
            }            
        }
    }
    pushExcIds() {
        const { data = {} } = this.props.getSearchResultReducer;
        const { hits = {} } = data;
        const { hasProduct = false} = data;
        let ids = [];
        Object.keys(hits || {}).map(function (key) {
            if (hasProduct) {
                if (parseInt(key, 10) < (parseInt(config.search_result_per_page, 10) - 1)) {
                    ids.push(hits[key].id);
                }                
            } else {
                ids.push(hits[key].id);
            }
            return ('')
        });
        if (this.props.getSearchDataReducer.searchData.type === 'all' && this.props.getSearchDataReducer.searchData.data.offset === '1') {
            this.setState({
                excIds: ids
            });
        }
    }
    render() {
        const { getSearchResultReducer = {} } = this.props;
        const { preferredData = {} } = this.props;
        
        const { searchData = {} } = this.props.getSearchDataReducer || [];
        
        const { data = {} } = getSearchResultReducer || {};
        
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const tooLessWord = h.stringBytes(searchData.data.q) < config.search_min_char;

        const totalPage = Math.ceil(data.total / config.search_result_per_page) || 1;
        const hasProduct = tooLessWord ? false : data.hasProduct || false;
        const total = data.total || 0;
        let organicListingData = data.hits || {};
        if (hasProduct && searchData.data.offset === '1') organicListingData.slice(0, (parseInt(config.search_result_per_page, 10) - 1));
        let topFeatureListData = [];
        let bottomFeatureListData = [];
        
        if (searchData.data.offset === '1') {
            if (preferredData.data.length > 4) {
                topFeatureListData = preferredData.data.slice(0, 4);
                bottomFeatureListData = preferredData.data.slice(4, 6);
            } else {
                topFeatureListData = preferredData.data;
                bottomFeatureListData = [];
            }
        }

        let noResultSuggestionItems = [];
        const { suggestions = {} } = this.props.getSearchDidYouMeanReducer.data || {};

        if (suggestions.length > 0) {
            noResultSuggestionItems = Object.keys(suggestions || {}).map(function (tsKey) {
                let childVal = suggestions[tsKey];
                return (<Link key={tsKey} to={config.pagename_search + '?q='+h.filterThenEncode(childVal) } className="search-nores__suggestion"> { childVal } </Link>)
            });
        }

        let noResult = getSearchResultReducer.isLoading || (!topFeatureListData.length && !organicListingData.length) || tooLessWord;

        const {metaData = {}} = this.props || {};

        const sourceJumpURL = hasProduct && sprintf(config.sourcejump_searchurl, config.sourcejumpsearch_lang_map[this.props.i18n.locale || 'en'], 'Product-Search', h.filterThenEncode(searchData.data.q));
        
        return (
            <div>                
                <SearchPanelContainer keyword={searchData.data.q} type={searchData.type} noResult={noResult} />
                <div className="search-listing">
                    {noResult ?
                        <div className="search-nores">
                            <div className="search-nores__txt">
                            {
                                getSearchResultReducer.isLoading ?
                                    <span>{static_content.txt_loading}</span>
                                : 
                                hasProduct ?
                                    <div className="organic-listing__more">{(static_content.searchResult.txt_view_more_result_suppliers).replace('%KEYWORD%', searchData.data.q)}
                                        <a href={sourceJumpURL} className="organic-listing__more-link" target="_blank" onClick={()=>searchEventAction('searchResult_displayViewMoreClicked', this.props.i18n.locale, searchData.data.q, searchData.type, total)}> </a>
                                    </div>
                                :
                                    searchData.data.q === "" ?
                                        <span>{static_content.searchPanel.txt_enter_keywords}</span>
                                    :
                                        tooLessWord ?
                                        <span>{static_content.searchCharErrorTxt}</span>
                                        :
                                        <div>
                                            {static_content.searchPanel.txt_no_result} <span className="search-panel__keywords-words">{searchData.data.q}</span><br />
                                            { noResultSuggestionItems.length > 0 &&
                                                <div>{static_content.searchPanel.txt_no_suggest}: {noResultSuggestionItems}</div>
                                            }
                                        </div>
                            }
                                
                            </div>
                        </div>
                        :
                        <div>
                        
                            <FeatureListing listData={topFeatureListData} searchData={searchData} />
                            <OrganicListing listData={organicListingData} hasProduct={hasProduct} searchData={searchData} total={total}/>
                            <FeatureListing listData={bottomFeatureListData} searchData={searchData} />
                            <PaginationContainer searchData={searchData} totalPage={totalPage} excIds={this.state.excIds}/>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
