import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import QRCode from 'qrcode.react';


export default class buttonShare extends Component {
  constructor(props) {
    super(props);
    this.shareWebsite = this.shareWebsite.bind(this);
    this.PopupCenter = this.PopupCenter.bind(this);
    this.state = {
      open: false
    };
  }

  getShareUrl() {
    return this.props.pageUrl || window.location.href; // use current URL with Lang, this.props.pageUrl
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  shareWebsite(channel) {

    var pageShareTitle = encodeURIComponent(this.props.pageShareTitle || document.getElementsByTagName("title")[0].innerHTML);
    var href = "";
    var type = 'default';
    var pageUrl = encodeURIComponent(this.getShareUrl());
    // var $windowCount = 0;
    switch (channel) {
      case 'email':
        href = "mailto:?subject=" + pageShareTitle + "&body=" + pageShareTitle + '%0A' + pageUrl;
        break;
      case 'wa':
        let l = this.props.i18n.locale === "tc" ? "zh_tw" : this.props.i18n.locale === "sc" ? "zh_cn" : "en"

        /*
        By Mark Wong, PHR-115
        Since the ie is not support https://wa.me/whatsappphonenumber/?text=urlencodedtext, Firefox shows error too.
        By using https://web.whatsapp.com/send?text=<msg>, the problem will be resolved. Ref: https://webapps.stackexchange.com/questions/106145/whatsapp-link-with-phone-number-doesnt-open-whatsapps-web-app 
        */
        if (document.body.classList.contains("is-ie") || document.body.classList.contains("is-firefox")) {
          href = "https://web.whatsapp.com/send?l=" + l + "&text=" + pageShareTitle + ": " + pageUrl;
        }
        else {
          href = "https://api.whatsapp.com/send?l=" + l + "&text=" + pageShareTitle + ": " + pageUrl;
        }
        break;
      case 'fb':
        href = "https://www.facebook.com/sharer/sharer.php?u=" + pageUrl;
        break;
      case 'tw':
        href = "http://twitter.com/share?text=" + pageShareTitle + ": &url=" + pageUrl;
        break;
      case 'ln':
        href = "https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl + "&title=" + pageShareTitle + "&source=LinkedIn";
        break;
      case 'wb':
        href = "http://service.weibo.com/share/share.php?url=" + pageUrl + "&title=" + pageShareTitle;
        break;
      case 'fbm':
        href = "http://www.facebook.com/dialog/send?app_id=404998779998238&link=" + pageUrl + "&redirect_uri=" + encodeURIComponent(window.location.href);
        break;
      default:
        break;
    }
    if (channel === 'email') {
      window.location.href = href;
    } else {
      // this.PopupCenter(href, "shareWindow_" + $windowCount, 600, 500, type);
      this.PopupCenter(href, '_blank', 600, 500, type);
    }
    //$windowCount++;
  }
  PopupCenter(url, title, w, h, type) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
    var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

    // Puts focus on the newWindow
    if (window.focus) {
      newWindow.focus();
    }
  }
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const devType = this.props.devType || '';
    const { open } = this.state;
    return (
      <div className="button-share__holder">
        <div className="button-share__title">{static_content.title_share}:</div>
        <div className="button-share__btn-holder">
          <button title={static_content.share_email.replace('%devType%', devType)} tabIndex="0" onClick={this.shareWebsite.bind(null, 'email')} className="button-share button-share--email">
            <span className="visually-hidden">
              {static_content.share_email.replace('%devType%', devType)}
            </span>
          </button>
          <button title={static_content.share_whatsapp.replace('%devType%', devType)} tabIndex="0" onClick={this.shareWebsite.bind(null, 'wa')} className="button-share button-share--wa">
            <span className="visually-hidden">
              {static_content.share_whatsapp.replace('%devType%', devType)}
            </span>
          </button>
          <button title={static_content.share_fb_msg.replace('%devType%', devType)} tabIndex="0" onClick={this.shareWebsite.bind(null, 'fbm')} className="button-share button-share--fbm">
            <span className="visually-hidden">
              {static_content.share_fb_msg.replace('%devType%', devType)}
            </span>
          </button>
          <button title={static_content.share_wechat.replace('%devType%', devType)} tabIndex="0" onClick={this.onOpenModal} className="button-share button-share--wc">
            <span className="visually-hidden">
              {static_content.share_wechat.replace('%devType%', devType)}
            </span>
          </button>
          <button title={static_content.share_fb.replace('%devType%', devType)} tabIndex="0" onClick={this.shareWebsite.bind(null, 'fb')} className="button-share button-share--fb">
            <span className="visually-hidden">
            </span>
          </button>
          <button title={static_content.share_twitter.replace('%devType%', devType)} tabIndex="0" onClick={this.shareWebsite.bind(null, 'tw')} className="button-share button-share--tw">
            <span className="visually-hidden">
            </span>
          </button>
          <button title={static_content.share_lkd.replace('%devType%', devType)} tabIndex="0" onClick={this.shareWebsite.bind(null, 'ln')} className="button-share button-share--ln">
            <span className="visually-hidden">
              {static_content.share_lkd.replace('%devType%', devType)}
            </span>
          </button>
          <button title={static_content.share_weibo.replace('%devType%', devType)} tabIndex="0" onClick={this.shareWebsite.bind(null, 'wb')} className="button-share button-share--wb">
            <span className="visually-hidden">
              {static_content.share_weibo.replace('%devType%', devType)}
            </span>
          </button>
          <Modal open={open} onClose={this.onCloseModal} center>
            <div className="button-share__wcqr">
              <QRCode value={this.getShareUrl()} />
              <p className="button-share__wcqr-txt">
                {static_content.share_wechat}</p>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}