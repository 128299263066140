import React, { Component } from 'react';

import StaticPagesContainer from '../../containers/StaticPagesContainer';

import { Helmet } from "react-helmet";


export default class StaticPages extends Component {
  
  
  render() {
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'StaticPages' }} />    
        <StaticPagesContainer />
      </main>
    );
  }
}
