import config from "../../config";
import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";
import store from "../../store";
import { fetchAnnouncementPromotionData } from "../../actions";
import { getCookie, setCookie, removeCookie } from "redux-cookie";
import { currentDate, isDateBetweenDateRange } from "../../util/helper";
import HTMLParser from "html-react-parser";
import OverlayVideo from "../OverlayVideo";
import _ from "lodash";

export default class PromotionBar extends Component {
  constructor(props) {
    super(props);
    const url = config[`json_announcement_${props.page}`];
    const page = props.page;
    store.dispatch(fetchAnnouncementPromotionData(url)); // Teamsite CMS
    
    this.cookiePrefix = `hktdc_AlertLatestModifiedDate_${props.page}_`;
    this.state = {
      showAlertBar: true
    };
    this.closeAlertBar = this.closeAlertBar.bind(this);
  }
  getAnnouncementCookie(id) {
    return store.dispatch(
      getCookie(`${this.cookiePrefix}${id}`)
    );
  }
  closeAlertBar() {
    this.setState({
      showAlertBar: false
    });
    this.props.cmsData.data.forEach(item => {
      if (!item.shownOnRefresh) {
        store.dispatch(
          setCookie(`${this.cookiePrefix}${item.id}`, item.latestModifiedDate)
        );
      } else {
        if (this.getAnnouncementCookie(item.id)) {
          store.dispatch(
            removeCookie(`${this.cookiePrefix}${item.id}`)
          );
        }
      }
      return;
    });
  }
  render() {
    const closeAlertBar = this.closeAlertBar;
    const static_content = (this.props.i18n.translations || {})[this.props.i18n.locale] || {};
    const announcementList = [];
    const cmsData = this.props.cmsData.data;

    const compare = (a, b) => {
      if (a.endTime > b.endTime) return 1;
      if (a.endTime < b.endTime) return -1;
      return 0;
    };

    for (const item of this.props.cmsData.data) {
      if (item.displayOnPage !== this.props.page) continue;
      const currentLatestModifiedDate = item.shownOnRefresh ? "" : (this.getAnnouncementCookie(item.id) || "");

      // The date from the this.props.cmsData is the UTC format
      const serverTime = currentDate();
      const eachContent = item[this.props.i18n.locale] || {}; // get Teamsite CMS Data locale first

      let startTimeString = item["start-time"] || "";
      let endTimeString = item["end-time"] || "";
      if(!_.isEmpty(eachContent["start-time"]) && !_.isEmpty(eachContent["end-time"] )){
          startTimeString = eachContent["start-time"];
          endTimeString = eachContent["end-time"];
      }

      const isValidDate = isDateBetweenDateRange(
        startTimeString,
        endTimeString,
        serverTime
      );

      const isShow = isValidDate ?
        (!currentLatestModifiedDate || (Number(currentLatestModifiedDate) !== item.latestModifiedDate))
        : false;

      const txtContent = item[this.props.i18n.locale] || {}; // get Teamsite CMS Data
      const title = txtContent.title || "";
      const desc = txtContent.content || "";

      announcementList.push({
        title,
        desc,
        endTime: new Date(endTimeString).getTime(),
        startRealTime: new Date(startTimeString),
        endRealTime: new Date(endTimeString),
        isShow
      });
    }

    announcementList.sort(compare); // sort announcements by ascending end time
    console.log("[Massaged data] =>");
    console.log(JSON.stringify(announcementList, null, 2));

    const isDisplayAnnouncement = Array.isArray(announcementList) && (announcementList.length !== 0) && !announcementList.every(item => !item.isShow);  // determine if array is empty, or all items hidden

    let barClass = "alert-bar " + this.props.page;
    let barClassForBody = "show";
    if (!this.state.showAlertBar || !isDisplayAnnouncement) {
      barClass = "alert-bar is-hidden";
      barClassForBody = "not-show";
    }

    return (
      <div className={barClass}>
      <OverlayVideo/>
        {isDisplayAnnouncement && (
          <div className="content-wrapper">
            <div className="alert-bar__holder">
              <div className="alert-bar__list">
                {
                  announcementList.map((item, i) => {
                    return item.isShow && (
                      <div className="alert-bar__row" key={i}>
                        <div className="alert-bar__title">{item.title}</div>
                        <div className="alert-bar__desc">{HTMLParser(item.desc)}</div>
                      </div>
                    )
                  })
                }
              </div>
              <div className="alert-bar__control">
                <button
                  title={static_content.hidden_button_announcement_close_title}
                  onClick={closeAlertBar}
                  className="alert-bar__close"
                >
                  <span className="visually-hidden">{static_content.hidden_button_announcement_close_label}</span>
                </button>
              </div>
            </div>
          </div>
        )}
        <Helmet bodyAttributes={{ "data-alert-bar": barClassForBody }} />
      </div>
    );
  }
}
