import React, { Component } from 'react';
import { connect } from 'react-redux';

class ErrorBoundaryMessage extends Component {
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    
    return (
      <h4>{static_content.txt_error}</h4>
    );
  }
}

const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ErrorBoundaryMessage);