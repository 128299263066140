import * as types from '../../constants/actionTypes.js';

const allowCookiesStatusReducer = function (state = {
    allowCookiesStatus: false,
}
    , action = null) {
    switch (action.type) {
        case types.ALLOW_COOKIES_STATUS:
            return Object.assign({}, state, { allowCookiesStatus: action.data });
        default:
            return state;
    }
};

export default allowCookiesStatusReducer;