import React, { Component } from "react";
import { connect } from 'react-redux';

import store from "../../store/";
import { getEvents } from "../../actions/";
import c from '../../config';

import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../ErrorBoundaryMessage';
import { currentDate } from '../../util/helper';


class ServicesEOEventList extends Component {

  constructor(props) {
    super(props);

    const parseData = {
      'organizers': 'hktdc,hktdc-participate',
      'fromEventEndDate': currentDate(),
      'language': c.api_lang_map[this.props.i18n.locale || 'en'],
      'offset': '1',
      'sort': 'eventStartDate',
      'limit': 200,
      'eventFairCode': c.sourcePageFairCode
    }

    store.dispatch(getEvents(parseData));

    this.state = {
      upcoming: [],
      moreEvents: []
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.getEventsReducer.isLoading !== prevProps.getEventsReducer.isLoading && this.props.getEventsReducer.isLoading === false) {   
      const { data = {} } = this.props.getEventsReducer;
      const { total = 0, hits } = data;
      if (total !== 0) {
        this.prepareHTML(this.props.lists, hits);
      } else {
        this.prepareHTML(this.props.lists, []);
      }
    } else if (this.props.getEventsReducer.isLoading === false && this.props.getEventsReducer.error !== prevProps.getEventsReducer.error && this.props.getEventsReducer.error === true) {
      this.prepareHTML(this.props.lists, []);
    }

    if (this.props.lists !== prevProps.lists && this.state.moreEvents.length === 0 && this.props.getEventsReducer.isLoading === false) {
        const {
          data = {}
        } = this.props.getEventsReducer;
        const {
          total = 0, hits
        } = data;
        if (total !== 0) {
          this.prepareHTML(this.props.lists, hits);
        } else {
          this.prepareHTML(this.props.lists, []);
        }
    }
  }

  prepareHTML = (cmsData, qData) => {
    let faircodes = [];

    for (let k in qData) {
      if (k<2){ // max 2 upcoming events only
        const event = qData[k];
        faircodes.push(event.eventFairCode);
        this.prepareUpcomingHTML(event.eventFairCode, cmsData, event.media01Url);
      }  
    }
    this.prepareMoreEventsHTML(faircodes, cmsData);
  }

  prepareUpcomingHTML = (faircode, cmsData, image = '') => {
    for (let k in cmsData) {
      const item = cmsData[k];
      let pUpcoming = this.state.upcoming;
      if (item.quick_link_fair_code === faircode) {
        pUpcoming.push(
            <li key={k} className={pUpcoming.length===0?'upcoming':'upcoming item-links__desktopOnly'}>
              <a href={item.quick_link_url} target="_blank" className="item-links__link">
              <img src={image} title={item.quick_link_title} alt={item.quick_link_title} />{item.quick_link_title}</a>
            </li>            
          );
        this.setState({
          upcoming: pUpcoming
        })
      }
    }
  }

  prepareMoreEventsHTML = (faircodes, cmsData) => {
    let pMoreEvents = this.state.moreEvents;
    let mE = [];
    for (let k in cmsData) {
      const item = cmsData[k];
      const code1 = faircodes[0] || '';
      const code2 = faircodes[1] || '';
      if (item.quick_link_fair_code === code2) {
        mE.push(
            <li key={k} className='item-links__mobileonly'>
              <a href={item.quick_link_url} target="_blank" className="item-links__link">
              {item.quick_link_title}</a>
            </li>            
          )
      } else if (item.quick_link_fair_code !== code1) {
        pMoreEvents.push(
            <li key={k}>
              <a href={item.quick_link_url} target="_blank" className="item-links__link">
              {item.quick_link_title}</a>
            </li>            
          )
      }
    }
    this.setState({
      moreEvents: mE.concat(pMoreEvents)
    })
  }

  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};
    return(
      <div className="item-content__right-item-links-row">     
      
        <div className="item-links">
          <div className="item-links__title item-links__title--orange">{static_content.title_service_upcoming}</div>
          <ul className={"item-links-content item-links-content--col"}>
            { this.state.upcoming }
          </ul>
        </div>

        
        <div className="item-links">
          <div className="item-links__title item-links__title--orange">{static_content.title_service_moreevents}</div>
          <ul className={"item-links-content item-links-content--col"}>
            { this.state.moreEvents }
          </ul>
          { this.props.itemMoreHref && 
            <div className={"item-links__more item-links__more--orange"}><a href={this.props.itemMoreHref} target="_blank" >{static_content.btnMore} ></a></div>
          }
        </div>
      </div>)
  }
 }
  
const mapStateToProps = (state) => ({
  i18n: state.i18n,
  getEventsReducer: state.getEventsReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(ServicesEOEventList);


const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;