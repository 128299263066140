import * as types from '../../constants/actionTypes';
import config from '../../config';
import {axiosAdapter} from '../../util/adapters';

function requestTopSearchData() {
	return { type: types.REQ_TOP_SEARCH_DATA }
};

function receiveTopSearchData(json) {
	return {
		type: types.RECV_TOP_SEARCH_DATA,
		data: json
	}
};

function receiveTopSearchError(json) {
	return {
		type: types.RECV_TOP_SEARCH_ERROR,
		data: json
	}
};

export function fetchTopSearchData() {
	return function (dispatch) {
		dispatch(requestTopSearchData());
		return axiosAdapter({
			url: config.json_topsearch
		})
			.then(function (response) {
				dispatch(receiveTopSearchData(response.data.result.assets[0].content.Content));
			})
			.catch(function (response) {
				dispatch(receiveTopSearchError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};

export function switchTopSearch(id) {
	return {
		type: types.SWITCH_TOP_SEARCH,
		data: id
	}
};
