/* "react-router-dom": "^4.2.2", */
import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import invariant from "invariant";
import { createLocation } from "history";
import addDomain from '../util/domainlinkUtils';
import { goToTop, goToAnchor } from 'react-scrollable-anchor'
import {str2Domain} from '../util/helper';

const isModifiedEvent = event =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

/**
 * The public API for rendering a history-aware <a>.
 */
class Link extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    target: PropTypes.string,
    replace: PropTypes.bool,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    innerRef: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  };

  static defaultProps = {
    replace: false
  };

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        createHref: PropTypes.func.isRequired
      }).isRequired
    }).isRequired
  };

  handleClick = event => {
    if (this.props.onClick) this.props.onClick(event);

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !this.props.target && // let browser handle "target=_blank" etc.
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();

      const { history } = this.context.router;
      const { replace } = this.props;
      const href = event.currentTarget.getAttribute('href');
      const gotoanchor = this.props.gotoanchor || 'top';

      const domain = str2Domain(href);
      if (domain && str2Domain(href) !== window.location.hostname) {
        window.location.href = href;
      } else {
        if (replace) {
          history.replace(href);
        } else {
          history.push(href);
        }
        gotoanchor !== 'top' ? goToAnchor(gotoanchor) : goToTop();
      }
    }
  };

  render() {
    const { replace, to, innerRef, i18n, dispatch, ...props } = this.props; // eslint-disable-line no-unused-vars
    invariant(
      this.context.router,
      "You should not use <Link> outside a <Router>"
    );

    invariant(to !== undefined, 'You must specify the "to" property');

    const { history } = this.context.router;
    const location =
      typeof to === "string"
        ? createLocation(to)/*createLocation(to, null, null, history.location) // let domainlinkUtils handle /lang/ */
        : to;

    let innerLink = true;    
    
    let href = addDomain(location, this.props.i18n.locale); // make it full path if under different subdomain name
    
    if (href.substr(0, 4) === 'http') {
      innerLink = false;
    }
    const onClick = innerLink ? { 'onClick': this.handleClick } : '';

    return (
      <a {...props} {...onClick} href={href} ref={innerRef} />
    );
  }
}

const mapStateToProps = state => ({
  i18n: state.i18n
});


export default connect(mapStateToProps, null, null, {
  pure: true
})(Link);