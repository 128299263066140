import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import LazyImage from '../components/LazyImage/';

/*const mapStateToProps = (state, ownProps) => ({
  
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(LazyImage);
*/
const ConnectedComponent = connect(null, null, null, {
  pure: true
})(LazyImage);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;