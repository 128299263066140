import config from '../config';
import escapeStringRegexp from 'escape-string-regexp';
import moment from "moment-timezone";
import { isObject, isArray } from 'lodash';


export const debounce = (func, wait, immediate) => {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export const inSensitiveReplaceEM = (str, find) => {
    var reg = new RegExp('(' + escapeStringRegexp(find) + ')', 'gi');
    return str.replace(reg, '<em>$1</em>');
}

export const whitelistchars = (str='') => {
    let nStr = (str.toString()).trim();
    return nStr;
}

export const enUrl = (str = '') => {
    let nStr = ("" + str).toString().trim();
    return nStr !== '' ? encodeURIComponent(nStr) : nStr;
}

export const deUrl = (str = '') => {
    let nStr = ("" + str).toString().trim();
    return nStr !== '' ? decodeURIComponent(nStr) : nStr;
}

export const filterThenEncode = (str = '') => {
    let nStr = ("" + str).toString().trim();
    return nStr !== '' ? enUrl(whitelistchars(nStr)) : nStr;
}

export const decodeThenFilter = (str = '') => {
    let nStr = ("" + str).toString().trim();
    return nStr !== '' ? whitelistchars(enUrl(nStr)) : nStr;
}

export const toLower = (str = '') => {
    return ("" + str).toString().trim().toLowerCase();
}

export const checkType = (str = '') => {
    let nStr = decodeThenFilter(str);
    if ((config.searchTypes).indexOf(nStr) !== -1) {
        return nStr;
    }
    return 'all';
}

export const str2Domain = (str) => {
    let httpMatches = str.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    if (httpMatches) {
        return httpMatches[1];
    }

    let matches = str.match(/^\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    if (matches) {
        return matches[1];
    }
    return undefined;
}

export const addProtocol = (str) => {
    if (str.substring(0, 2) === '//') {
        return window.location.protocol+str;
    }
    return str;
}

export const createAelement = (url, target='_blank') => {
    var element = document.createElement("a");

    element.setAttribute("href", url)
    element.setAttribute("target", target)
    document.body.appendChild(element); //<- append to body
    element.click();
    element.parentNode.removeChild(element);
}

export const formatEventDate = (startDateAry, endDateAry, displaynoday, eStartD, eEndD, dateFormatAry, defaultFormat, locale) => {
    var I18n = require('react-redux-i18n').I18n;
    let eventDate = '';

    // require fine tuning below 
    if (startDateAry[2] !== endDateAry[2]) {
        // different year, type 1, e.g. 30 Dec 2018 - 3 Jan 2019				
        if (!displaynoday) {
            // show day, D MMM YYYY - D MMM YYYY
            eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.YYYYMMMD }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.YYYYMMMD });
        } else {
            // don't show day, MMM YYYY - MMM YYYY
            eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.YYYYMMM }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.YYYYMMM });
        }
    } else if (startDateAry[1] !== endDateAry[1]) {
        // Same year, different month, type 2 e.g. 30 Nov - 3 Dec 2018

        if (locale === 'en') {
            if (!displaynoday) {
                // show day, DD MMM - DD MMM YYYY
                eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.MMMD }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.YYYYMMMD });
            } else {
                // don't show day, MMM - MMM YYYY
                eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.MMM }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.YYYYMMM });
            }
        } else {
            // Chinese
            if (!displaynoday) {
                // show day, YYYY年M月D日 - M月D日
                eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.YYYYMMMD }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.MMMD });
            } else {
                // don't show day, YYYY年M月 - M月
                eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.YYYYMMM }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.MMM });
            }
        }
    } else if (startDateAry[0] !== endDateAry[0]) {
        // same month, different day ONLY, type 3 e.g. 2 - 3 Dec 2018
        if (locale === 'en') {
            if (!displaynoday) {
                // show day, D - D MMM YYYY
                eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.D }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.YYYYMMMD });
            } else {
                // don't show day, MMM YYYY
                eventDate = I18n.l(eEndD, { dateFormat: dateFormatAry.YYYYMMM });
            }
        } else {
            // Chinese
            if (!displaynoday) {
                // show day, YYYY年M月D日 - D日
                eventDate = I18n.l(eStartD, { dateFormat: dateFormatAry.YYYYMMMD_c }) + ' - ' + I18n.l(eEndD, { dateFormat: dateFormatAry.D });
            } else {
                // don't show day, YYYY年M月
                eventDate = I18n.l(eEndD, { dateFormat: dateFormatAry.YYYYMMM });
            }
        }
    } else {
        // SAME DAY
        eventDate = I18n.l(eEndD, { dateFormat: defaultFormat });
    }
    // END event date format

    return eventDate;
}

export const stringBytes = (c) => {
    let n = c.length,
        s;
    let len = 0;
    for (let i = 0; i < n; i++) {
        s = c.charCodeAt(i);
        while (s > 0) {
            len++;
            s = s >> 8;
        }
    }
    return len;
}

export const imgOrientationClass = (img, ratio=2) => {
    // ratio is (width) : when height = 1, i.e. explore page research image 360x180 = 2:1
    // not return actual orientation, it determines CSS Class
    let orientation = '';

    if (img.naturalWidth > img.naturalHeight && (img.naturalWidth / img.naturalHeight) >= ratio) {
        orientation = 'landscape';
    } else if (img.naturalWidth < img.naturalHeight) {
        orientation = 'portrait';
    } else {
        orientation = 'even';
    }

    return orientation;
}

export const currentDate = () => {
    let previewDate = new URLSearchParams(window.location.search).get('previewDate') || null;
    return (previewDate)? moment(previewDate).toDate() : moment().toDate();
}

// Parsing Date from CMS, most likely using UTC Datetime such as 2018-01-01 12:00:00
export const momentUTCDate = (dateString) => {
    return moment.tz(dateString, 'UTC').toDate();
}

export const isDateBetweenDateRange = (minDateString, maxDateString, currentDateString)=> {
    let compareDate = moment(currentDateString).toDate();
    let minDate = momentUTCDate(minDateString);
    let maxDate = momentUTCDate(maxDateString);
    return (compareDate >= minDate && compareDate <= maxDate)
}

export const isIsoDate = (str) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString() === str;
}

export const convertToISODate = (str) => {
    if (isIsoDate(str)) return str;
    else return momentUTCDate(str);
}

export const forceToArray = (data) => {
    if (isArray(data)) {
      return data;
    }
    if (isObject(data) && !Object.values(data).every((x) => x === null || x === '')) {
      return [data];
    }
    return null;
};
  