
/*
 * action types for Page Items Data
 */
export const RECV_ITEMS_ERROR = 'RECV_ITEMS_ERROR';
export const REQ_ITEMS_DATA = 'REQ_ITEMS_DATA';
export const RECV_ITEMS_DATA = 'RECV_ITEMS_DATA';

/*
 * action types for Header Search
 */
export const RECV_SEARCH_AUTOCOMPLETE_ERROR = 'RECV_SEARCH_AUTOCOMPLETE_ERROR';
export const REQ_SEARCH_AUTOCOMPLETE_DATA = 'REQ_SEARCH_AUTOCOMPLETE_DATA';
export const RECV_SEARCH_AUTOCOMPLETE_DATA = 'RECV_SEARCH_AUTOCOMPLETE_DATA';

export const SWITCH_TOP_SEARCH = 'SWITCH_TOP_SEARCH';


/*
 * action types for Search Result
 */
export const RECV_SEARCH_RESULT_ERROR = 'RECV_SEARCH_RESULT_ERROR';
export const REQ_SEARCH_RESULT = 'REQ_SEARCH_RESULT';
export const RECV_SEARCH_RESULT = 'RECV_SEARCH_RESULT';

/*
 * action types for Web Meta
 */
export const RECV_WEB_META_ERROR = 'RECV_WEB_META_ERROR';
export const REQ_WEB_META_DATA = 'REQ_WEB_META_DATA';
export const RECV_WEB_META_DATA = 'RECV_WEB_META_DATA';

/*
 * action types for Teamsite Header and footer
 */
export const RECV_HEADER_FOOTER_ERROR = 'RECV_HEADER_FOOTER_ERROR';
export const REQ_HEADER_FOOTER_DATA = 'REQ_HEADER_FOOTER_DATA';
export const RECV_HEADER_FOOTER_DATA = 'RECV_HEADER_FOOTER_DATA';


/*
 * action types for Teamsite Announcement
 */
export const RECV_ANNOUNCEMENT_ERROR = 'RECV_ANNOUNCEMENT_ERROR';
export const REQ_ANNOUNCEMENT_DATA = 'REQ_ANNOUNCEMENT_DATA';
export const RECV_ANNOUNCEMENT_DATA = 'RECV_ANNOUNCEMENT_DATA';

export const RECV_ANNOUNCEMENT_PROMOTION_ERROR = 'RECV_ANNOUNCEMENT_PROMOTION_ERROR';
export const REQ_ANNOUNCEMENT_PROMOTION_DATA = 'REQ_ANNOUNCEMENT_PROMOTION_DATA';
export const RECV_ANNOUNCEMENT_PROMOTION_DATA = 'RECV_ANNOUNCEMENT_PROMOTION_DATA';


/*
 * action types for Teamsite Top Search
 */
export const RECV_TOP_SEARCH_ERROR = 'RECV_TOP_SEARCH_ERROR';
export const REQ_TOP_SEARCH_DATA = 'REQ_TOP_SEARCH_DATA';
export const RECV_TOP_SEARCH_DATA = 'RECV_TOP_SEARCH_DATA';


/*
 * action types for Teamsite SEO Data
 */
export const RECV_SEO_ERROR = 'RECV_SEO_ERROR';
export const REQ_SEO_DATA = 'REQ_SEO_DATA';
export const RECV_SEO_DATA = 'RECV_SEO_DATA';


/*
 * action types for Teamsite Main Page - Event Calendar
 */
export const RECV_EVENT_CALENDAR_ERROR = 'RECV_EVENT_CALENDAR_ERROR';
export const REQ_EVENT_CALENDAR_DATA = 'REQ_EVENT_CALENDAR_DATA';
export const RECV_EVENT_CALENDAR_DATA = 'RECV_EVENT_CALENDAR_DATA';

/*
 * action types for Teamsite Main Page - Jumobotron slides
 */
export const RECV_JUMOBOEVENTS_SLIDES_ERROR = 'RECV_JUMOBOEVENTS_SLIDES_ERROR';
export const REQ_JUMOBOEVENTS_SLIDES_DATA = 'REQ_JUMOBOEVENTS_SLIDES_DATA';
export const RECV_JUMOBOEVENTS_SLIDES_DATA = 'RECV_JUMOBOEVENTS_SLIDES_DATA';


/*
 * action types for Teamsite Main Page - Market Focus
 */
export const RECV_MAINPAGE_MF_ERROR = 'RECV_MAINPAGE_MF_ERROR';
export const REQ_MAINPAGE_MF_DATA = 'REQ_MAINPAGE_MF_DATA';
export const RECV_MAINPAGE_MF_DATA = 'RECV_MAINPAGE_MF_DATA';


/*
 * action types for Teamsite Main Page - Success Stories
 */
export const RECV_MAINPAGE_SS_ERROR = 'RECV_MAINPAGE_SS_ERROR';
export const REQ_MAINPAGE_SS_DATA = 'REQ_MAINPAGE_SS_DATA';
export const RECV_MAINPAGE_SS_DATA = 'RECV_MAINPAGE_SS_DATA';


/*
 * action types for Teamsite Main Page - Help
 */
export const RECV_MAINPAGE_HELP_ERROR = 'RECV_MAINPAGE_HELP_ERROR';
export const REQ_MAINPAGE_HELP_DATA = 'REQ_MAINPAGE_HELP_DATA';
export const RECV_MAINPAGE_HELP_DATA = 'RECV_MAINPAGE_HELP_DATA';


/*
 * action types for Teamsite Success Stories Page
 */
export const RECV_SS_ERROR = 'RECV_SS_ERROR';
export const REQ_SS_DATA = 'REQ_SS_DATA';
export const RECV_SS_DATA = 'RECV_SS_DATA';


/*
 * action types for Teamsite Success Stories Page's Filter options
 */
export const RECV_SS_FILTERS_ERROR = 'RECV_SS_FILTERS_ERROR';
export const REQ_SS_FILTERS_DATA = 'REQ_SS_FILTERS_DATA';
export const RECV_SS_FILTERS_DATA = 'RECV_SS_FILTERS_DATA';


/*
 * action types for SEARCH API Success Stories Page
 */
export const RECV_SSITEMS_ERROR = 'RECV_SSITEMS_ERROR';
export const REQ_SSITEMS_DATA = 'REQ_SSITEMS_DATA';
export const RECV_SSITEMS_DATA = 'RECV_SSITEMS_DATA';


/*
 * action types for Teamsite Start Page
 */
export const RECV_START_ERROR = 'RECV_START_ERROR';
export const REQ_START_DATA = 'REQ_START_DATA';
export const RECV_START_DATA = 'RECV_START_DATA';


/*
 * action types for Teamsite Join Page
 */
export const RECV_JOIN_ERROR = 'RECV_JOIN_ERROR';
export const REQ_JOIN_DATA = 'REQ_JOIN_DATA';
export const RECV_JOIN_DATA = 'RECV_JOIN_DATA';


/*
 * action types for Teamsite Source
 */
export const RECV_SOURCE_ERROR = 'RECV_SOURCE_ERROR';
export const REQ_SOURCE_DATA = 'REQ_SOURCE_DATA';
export const RECV_SOURCE_DATA = 'RECV_SOURCE_DATA';


/*
 * action types for Teamsite Explore
 */
export const RECV_EXPLORE_ERROR = 'RECV_EXPLORE_ERROR';
export const REQ_EXPLORE_DATA = 'REQ_EXPLORE_DATA';
export const RECV_EXPLORE_DATA = 'RECV_EXPLORE_DATA';


/*
 * action types for Teamsite Mobile App
 */
export const RECV_MOBILEAPP_ERROR = 'RECV_MOBILEAPP_ERROR';
export const REQ_MOBILEAPP_DATA = 'REQ_MOBILEAPP_DATA';
export const RECV_MOBILEAPP_DATA = 'RECV_MOBILEAPP_DATA';


/*
 * action types for Search No Result - Did you mean
 */
export const RECV_SEARCH_DIDYOUMEAN_ERROR = 'RECV_SEARCH_DIDYOUMEAN_ERROR';
export const REQ_SEARCH_DIDYOUMEAN = 'REQ_SEARCH_DIDYOUMEAN';
export const RECV_SEARCH_DIDYOUMEAN = 'RECV_SEARCH_DIDYOUMEAN';


/*
 * action types for Search Data
 */
export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';


/*
 * action types for Search Preferred Listing
 */
export const RECV_SEARCH_PREFERRED_LISTING_ERROR = 'RECV_SEARCH_PREFERRED_LISTING_ERROR';
export const REQ_SEARCH_PREFERRED_LISTING = 'REQ_SEARCH_PREFERRED_LISTING';
export const RECV_SEARCH_PREFERRED_LISTING = 'RECV_SEARCH_PREFERRED_LISTING';


/*
 * action types for Event Pages
 */
export const RECV_EVENTS_ERROR = 'RECV_EVENTS_ERROR';
export const REQ_EVENTS_DATA = 'REQ_EVENTS_DATA';
export const RECV_EVENTS_DATA = 'RECV_EVENTS_DATA';
/*
 * action types for Event Infinite Scroll Pages
 */
export const RECV_EVENTS_IS_ERROR = 'RECV_EVENTS_IS_ERROR';
export const REQ_EVENTS_IS_DATA = 'REQ_EVENTS_IS_DATA';
export const RECV_EVENTS_IS_DATA = 'RECV_EVENTS_IS_DATA';

export const ALLOW_COOKIES_STATUS = 'ALLOW_COOKIES_STATUS';

export const SSO_READY_STATUS = 'SSO_READY_STATUS';


/*
 * action types for Teamsite Static Pages
 */
export const RECV_STATICPAGES_ERROR = 'RECV_STATICPAGES_ERROR';
export const REQ_STATICPAGES_DATA = 'REQ_STATICPAGES_DATA';
export const RECV_STATICPAGES_DATA = 'RECV_STATICPAGES_DATA';

/*
 * action types for Teamsite Factsheet Pages
 */
export const RECV_FACTSHEETPAGES_ERROR = 'RECV_FACTSHEETPAGES_ERROR';
export const REQ_FACTSHEETPAGES_DATA = 'REQ_FACTSHEETPAGES_DATA';
export const RECV_FACTSHEETPAGES_DATA = 'RECV_FACTSHEETPAGES_DATA';

/*
 * action types for Teamsite Webcast Pages
 */
export const RECV_WEBCASTPAGES_ERROR = 'RECV_WEBCASTPAGES_ERROR';
export const REQ_WEBCASTPAGES_DATA = 'REQ_WEBCASTPAGES_DATA';
export const RECV_WEBCASTPAGES_DATA = 'RECV_WEBCASTPAGES_DATA';

/*
 * action types for Teamsite Podcast Pages
 */
export const RECV_PODCASTPAGES_ERROR = 'RECV_PODCASTPAGES_ERROR';
export const REQ_PODCASTPAGES_DATA = 'REQ_PODCASTPAGES_DATA';
export const RECV_PODCASTPAGES_DATA = 'RECV_PODCASTPAGES_DATA';

/*
 * action types for Advantage Main Page
 */
export const RECV_ADVANTAGESMAINPAGE_ERROR = 'RECV_ADVANTAGESMAINPAGE_ERROR';
export const REQ_ADVANTAGESMAINPAGE_DATA = 'REQ_ADVANTAGESMAINPAGE_DATA';
export const RECV_ADVANTAGESMAINPAGE_DATA = 'RECV_ADVANTAGESMAINPAGE_DATA';

/*
 * action types for Advantage Thematic Page
 */
export const RECV_ADVANTAGESTHEMATICPAGE_ERROR = 'RECV_ADVANTAGESTHEMATICPAGE_ERROR';
export const REQ_ADVANTAGESTHEMATICPAGE_DATA = 'REQ_ADVANTAGESTHEMATICPAGE_DATA';
export const RECV_ADVANTAGESTHEMATICPAGE_DATA = 'RECV_ADVANTAGESTHEMATICPAGE_DATA';