import React, {Component} from 'react';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import { inSensitiveReplaceEM } from '../../util/helper';
import truncate from 'truncate-html';
import LazyLoad from 'react-lazyload';

class ContentTypeCompany extends Component {
  
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const { q='', debugMode='', contentData = {}} = this.props;
    const {
      isOeFeatureEffective = false,
      score = 0,
      description = '',
      resourceType = '',
      companyProductServiceRange = '',
      companyNob = [],
      companyUpcomingEventInd = false,
      landingUrl = '',
      contactUsUrl = '',
      media01Url = '',
      media01Alt = '',
      regionsForDisplay = [],
      sriLabel = '',
      titleForSeo = ''
    } = contentData;
    let { title = ""} = contentData;

    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese
    title = titleForSeo !== '' ? titleForSeo : title;

    let em_desc = '';
    if (resourceType === 'empcompany') {
      if (companyProductServiceRange !== '') {
        em_desc = "<strong>" + static_content.searchResult.txt_productServiceRange + "</strong>" + inSensitiveReplaceEM(truncate(companyProductServiceRange,{length: config.char_limit.desc}), q);
      }
    } else {
      em_desc = description !== '' && inSensitiveReplaceEM(truncate(description,{length: config.char_limit.desc}), q);
    }
    
    let em_title = inSensitiveReplaceEM(truncate(title,{length: config.char_limit.title}), q);

    let regionTags = [];
    for (let key in regionsForDisplay) {
      regionTags.push(<SanitizedHTML key={key} className="organic-listing__info" allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={truncate(regionsForDisplay[key],{length: config.char_limit.region})}/>);
    }

    let companyNobTags = "";
    for (let key in companyNob) {
      companyNob[key] = truncate(companyNob[key],{length: config.char_limit.nob});
    }
    companyNobTags = companyNob.length ? <SanitizedHTML className="organic-listing__info" allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={companyNob.join(', ')}/> : null;

    const exhIcon = isOeFeatureEffective && <div className="search-listing__bookmarkRibbon"><span><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchRibbonExhibitor}/></span></div>;

    const sriIcon = sriLabel !== '' && <div className={`search-listing__sriLabel-${sriLabel.toLowerCase()}`}></div>;

    let countBadge = 0;
    countBadge = exhIcon && countBadge+1;
    countBadge = sriIcon && countBadge+1;

    let isBadgeClass = '';
    if (countBadge === 1) {
      isBadgeClass = ' is1Badge';
    } else if (countBadge === 2) {
      isBadgeClass = ' is2Badge';
    }
    return (
      <div className="organic-listing__item">
        {
          (exhIcon || sriIcon) && <div className="search-listing__icons">{exhIcon}{sriIcon}</div>
        }
        {        
          title !== "" ? 
          <a href={landingUrl} target="_blank" title={title}>
            <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/></h4> 
          </a> 
          :
          <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/></h4>
        }        
        { debugMode === 'Y' &&
          <p>{score}</p>
        }
      {
        companyUpcomingEventInd &&
        <LazyLoad once><div className="organic-listing__note-icons">
          <a href={landingUrl} target="_blank" title={static_content.searchResult.alt_meetus}><div className="organic-listing__icon organic-listing__icon--booth"></div></a>
        </div></LazyLoad>
      }
        
        <div className={`organic-listing__item-row${isBadgeClass}`}>
        {
          media01Url !== '' && 
          <LazyLoad once><div className="organic-listing__img">
            <span className="organic-listing__img-holder">
                <img className="organic-listing__thumb" src={media01Url} alt={media01Alt} title={media01Alt} />
            </span>
          </div></LazyLoad>
        }          
          <div className="organic-listing__detail">
          {
            (regionTags.length > 0 || companyNobTags) &&
            <div className="organic-listing__infos">
              {regionTags}
              {companyNobTags}
            </div>
          }
            {
              em_desc !== '' &&
              <div className="organic-listing__desc">{<SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_desc}/>}</div>
            }
              
            <div className="organic-listing__btns"><a target="_blank" href={contactUsUrl} className="organic-listing__btn" title={static_content.searchResult.txt_contact_us}>{static_content.searchResult.txt_contact_us}</a></div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ContentTypeCompany);
