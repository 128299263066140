import * as types from '../constants/actionTypes';
import config from '../config';
import {axiosAdapter} from '../util/adapters';

function requestSearchAutocompleteData() {
	return { type: types.REQ_SEARCH_AUTOCOMPLETE_DATA }
};

function receiveSearchAutocompleteData(json) {
	return {
		type: types.RECV_SEARCH_AUTOCOMPLETE_DATA,
		data: json
	}
};

function receiveSearchAutocompleteError(json) {
	return {
		type: types.RECV_SEARCH_AUTOCOMPLETE_ERROR,
		data: json
	}
};

export function fetchSearchAutocomplete(data) {
	return function (dispatch) {
		dispatch(requestSearchAutocompleteData());
		return axiosAdapter({
			url: config.api_search_auto_complete,
			params: data
		})
			.then(function (response) {
				dispatch(receiveSearchAutocompleteData(response.data));
			})
			.catch(function (response) {
				dispatch(receiveSearchAutocompleteError(response.data || response.message));
				//dispatch(pushState(null,'/error'));
			})
	}
};

export function clearSearchAutocomplete() {
	return function (dispatch) {
		dispatch(requestSearchAutocompleteData());
		return dispatch(receiveSearchAutocompleteData({"suggestions":[]}));
	}
};