import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import 'url-search-params-polyfill';

class EventsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { curCat: this.props.title };
  }
  clickSwitchFunc = (e,title='') => {
    const selected = title!==''?title:e.target.value;
    let location = '';
    switch (selected) {
      case 'hk':
        location = 'hk';
        break;
      case 'outsidehk':
        location = 'outsidehk';
        break;
      case 'pastevent':
        location = 'pastevent';
        break;
      default:
        location = 'all';
        break;
    }
    this.setState({
      curCat: location
    }, () => {
      this.props.history.push('?location=' + location);
    });
    
    
  }
  render() {
    const {tabsTitle = {}, title = ""} = this.props; 
    const clickSwitchFunc = this.clickSwitchFunc;

		let tabs_title=Object.keys(tabsTitle || {}).map(function (tabsKey, i) {
			let tagTitle = tabsTitle[tabsKey];
			return (<a key={tabsKey} className={title===tabsKey ? "page-hktdc-events__filter is-active page-hktdc-events__filter--"+tabsKey : "page-hktdc-events__filter page-hktdc-events__filter--"+tabsKey} onClick={(e) => {clickSwitchFunc(e,tabsKey)}}>{tagTitle}</a>);
		});
    
    return (      
      <div>        
        <div className="page-hktdc-events__filters desktop">
          {tabs_title}	
        </div> 
        <div className="page-hktdc-events__filters mobile">
          <div className="page-hktdc-events__filters-cat-container">
            <div className="page-hktdc-events__filters-cat__title">{tabsTitle[this.state.curCat]}</div>
            <select value={this.state.curCat} className="page-hktdc-events__filters-condList" aria-label={tabsTitle[this.state.curCat]} onChange={(e) => this.clickSwitchFunc(e)} >
            {
              Object.keys(tabsTitle || {}).map(function (tabsKey, i) {
              let tagTitle = tabsTitle[tabsKey];
              return (<option key={i} className="filter-condList__cond" value={tabsKey}>{tagTitle}</option>);
            })
            }
            </select>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(EventsFilter)