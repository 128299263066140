import React, {Component} from 'react';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized-html';
import config from '../../config';
import { inSensitiveReplaceEM } from '../../util/helper';
import truncate from 'truncate-html';
import LazyLoad from 'react-lazyload';

class ContentTypeArticle extends Component {
  render() {
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const { q='', debugMode='', contentData = {}} = this.props;
    const { 
      score = 0,
      titleForSeo = '',
      description = '',
      bodyShortForm = '',
      landingUrl = "", 
      media01Url = '', 
      media01Alt = '',
      publishDate = '',
      media02Url = '', 
      media02Alt = '',
      resourceType = ''
    } = contentData;
    let { title = "" } = contentData;
    var I18n = require('react-redux-i18n').I18n;
    truncate.setup({stripTags: true, decodeEntities: true}) // stripTags: clear the format, decodeEntities: for chinese

    const date = (publishDate) ? [static_content.txt_publishDate + ' ' + I18n.l(publishDate, { dateFormat: static_content.dateFormat.long })] : "";
    
    title = titleForSeo !== '' ? titleForSeo : title;
    let em_title = inSensitiveReplaceEM(truncate(title,{length: config.char_limit.title}), q);
    let em_desc = inSensitiveReplaceEM(truncate((description || bodyShortForm), {length: config.char_limit.desc }), q);

    let mediaUrl = media01Url;
    let mediaAlt = media01Alt;
    if ((resourceType === config.search_resourceType.success || resourceType === config.search_resourceType.hkmb) && media02Url !== '') {
      mediaUrl = media02Url;
      mediaAlt = media02Alt;
    }

    return (
      <div className="organic-listing__item">
        {
          title !== "" ? 
          <a href={landingUrl} target="_blank" title={title}>
            <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_title}/></h4> 
          </a> 
          :
          <h4 className="organic-listing__item-big-title"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={static_content.searchResult.txt_no_title}/></h4>
        }
        
        { debugMode === 'Y' &&
          <p>{score}</p>
        }
        
        <div className="organic-listing__item-row">          
        {
          mediaUrl !== '' && 
          <LazyLoad once><div className="organic-listing__img">
            <span className="organic-listing__img-holder">
                <img className="organic-listing__thumb" src={mediaUrl} alt={mediaAlt} title={mediaAlt} />
            </span>
          </div></LazyLoad>
        }  
        
          <div className="organic-listing__detail">
            
          {date !== '' &&             
            <div className="organic-listing__infos">
              <div className="organic-listing__info">{date}</div>
            </div>
          }
            {em_desc !=="" && <div className="organic-listing__desc"><SanitizedHTML allowedAttributes={config.allowedAttributes} allowedTags={config.allowedTags} html={em_desc}/></div>}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
  pure: true
})(ContentTypeArticle);
