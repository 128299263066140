import { withRouter } from 'react-router-dom'
import config from '../../config';
import React, { Component } from 'react';
import store from '../../store/';
import { fetchSearchAutocomplete, clearSearchAutocomplete, switchTopSearch, fetchTopSearchData } from '../../actions/';
import Link from '../../util/Link';
import * as h from '../../util/helper';
import { eventAction } from '../../util/clickEvents';

var i;

class HeaderSearch extends Component {
  constructor(props) {
    super(props);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.updateSearchAutocompleteStore = h.debounce(this.updateSearchAutocompleteStore, 500);
    this.state = { keyword: '', type: 'all', 'showSearchAutocomplete': false, charError: false };
    store.dispatch(fetchTopSearchData()); // Teamsite CMS
  }

  toggleSearch(e) {    
    e.preventDefault();
    document.body.classList.toggle('search-panel-open');
    if (document.body.classList.contains('search-panel-open')) {
      eventAction('header_search_btn', '', '', this.props.i18n.locale || 'en');
      this._input.focus();
      store.dispatch(clearSearchAutocomplete());
    }
  }

  handleChange = (e) => {
      this.setState({
          charError: false,
          keyword: e.target.value
      });
      this.updateSearchAutocompleteStore(e.target.value);
  }

  updateSearchAutocompleteStore = (value) => {
      this.setState({
          keyword: value
      });
      if (value !== "") {
          store.dispatch(fetchSearchAutocomplete({
              q: (value).toLowerCase(),
              cat: 'phr',
              language: config.api_lang_map[this.props.i18n.locale]
          }));
      }
  }



  handleAutoCompleteMouseEnter = (e) => {
    e = e || window.event;
    e.preventDefault();
    const acItems = document.querySelectorAll(".header-search__input-holder .header-search__autocomplet-grp");
    for (let i = 0; i < acItems.length; i++) {
      if (acItems[i].classList.contains('acActive')) {
        acItems[i].classList.remove('acActive');
      }
    }
  }

  handleAutoCompleteMouseEnter = (e) => {
    const acItems = document.querySelectorAll(".header-search__input-holder .header-search__autocomplet-grp");
    for (let  i = 0; i < acItems.length; i++) {
      if (acItems[i].classList.contains('acActive')) {
        acItems[i].classList.remove('acActive');
      }
    }
  }

  handleAutoCompleteSelection = (e) => {
    e = e || window.event;
    if (e.keyCode === 38 || e.keyCode === 40) { // only handle up and down
      e.preventDefault(); // prevent the mouse cursor move to the left
      let no = -1;
      const acItems = document.querySelectorAll(".header-search__input-holder .header-search__autocomplet-grp");
      for (let  i = 0; i < acItems.length; i++) {
        if (acItems[i].classList.contains('acActive')) {
          no = i;
        }
      }
      switch (e.keyCode) {
        case 38:
          // up
          if (no > 0 && acItems[(no - 1)]) {
            acItems[no].classList.remove('acActive');
            acItems[(no - 1)].classList.add('acActive');
            const prevEle = acItems[(no - 1)].getElementsByTagName('a');
            if (prevEle[0]) {
              document.getElementById('headerSearchQ').value = (prevEle[0].innerHTML).trim();
            }
          }
        break;
        case 40:    
        default:
          // down
          if (no < acItems.length && acItems[(no + 1)]) {
            if (no !== -1) acItems[no].classList.remove('acActive');
            acItems[(no + 1)].classList.add('acActive');
            const prevEle = acItems[(no + 1)].getElementsByTagName('a');
            if (prevEle[0]) {
              document.getElementById('headerSearchQ').value = (prevEle[0].innerHTML).trim();
            }
          }      
        break;
      }
    }
    
  }

  handleSubmit = (event) => {
    const inputQ = document.getElementById('headerSearchQ').value || '';
    const finalQ = this.state.keyword !== inputQ ? inputQ : this.state.keyword;
    if (finalQ !== "") {
      if (h.stringBytes(finalQ) >= config.search_min_char) {
        this.setState({
          charError: false,
          keyword: h.whitelistchars(finalQ)
        }, () => {
          const urlParam = '/' + this.props.i18n.locale + '/' + config.pagename_search + '?type=' + encodeURIComponent(this.state.type) + '&q=' + encodeURIComponent(this.state.keyword);
          setTimeout(() => {
            this.toggleSearch(event);
            store.dispatch(clearSearchAutocomplete());
            if (window.location.hostname !== config.home) {
              window.location.href = config.full_home + urlParam;
            } else {
              this.props.history.push(urlParam);
            }
          }, 800);          
        });
      } else {
        this.setState({
          charError: true
        });
      }  
    }    
    event.preventDefault();
  }

  handleReset = (event) => {
      this.setState({
          charError: false,
          keyword: ""
      }, () => {
          store.dispatch(clearSearchAutocomplete());
      });
      event.preventDefault();
  }

  handleOptionClick = (itemTxt) => {
    document.getElementById('headerSearchQ').value = itemTxt
    const autocomplete = document.getElementsByClassName("header-search__autocomplete")[0];
      autocomplete.classList.add('force-close');
      setTimeout(() => {
          autocomplete.classList.remove('force-close');
          this.submitBtn.click();
      }, 500);
  }

  handleOptionChange = (e) => {
    this.setState({
      type: e.target.value
    });
    store.dispatch(switchTopSearch(e.target.value));
  }

  render() {

    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content

    const { searchTypes = [] } = config || {};
    const {searchTypeName={}} = static_content || {};

    const searchTypesElems = [];
    const TopsearchElem = [];
    const handleOptionChange = this.handleOptionChange;
    const topSearchItems = this.props.cmstopSearchReducer.data[this.props.i18n.locale]; // get Teamsite CMS Data
    const {data={}} = this.props.topSearchReducer || {};
    const curSearchType = data || {};

    for (let key in searchTypes) {
      
      let typekey = searchTypes[key];
      let typename = searchTypeName[typekey];
      let id = "header-search-filter-" + key;
      
      searchTypesElems.push(<div key={key} className="header-search__filter">
        <input id={id} tabIndex="-1" onChange={handleOptionChange} name="type" type="radio" key={id} id={id} value={typekey} defaultChecked={(key==='0'?  true : false)} />
        <label htmlFor={id}>{typename}<span className="visually-hidden">At Header</span></label>
      </div>);

      //Top Search
      if (topSearchItems) {
        if (curSearchType === typekey && topSearchItems[typekey].keyword.length > 0) {

          let keywords = Array.isArray(topSearchItems[typekey].keyword) ? topSearchItems[typekey].keyword : [topSearchItems[typekey].keyword];
          let topSearchItemsElems = Object.keys(keywords).map(function (tsKey) {
            let txt = keywords[tsKey];
            return (<Link key={'slink'+tsKey} to={config.pagename_search + '?type=' + h.filterThenEncode(typekey) + '&q='+h.filterThenEncode(txt) } className = "header-search__topsearch-item" > { txt } </Link>);
          });

          
          TopsearchElem.push(<div key={key} className="header-search__topsearch">
            <div className="header-search__topsearch-title">{static_content.topSearchTxt}:</div>
            {topSearchItemsElems}
          </div>);
        }
      }
    }

    const { headerSearchReducer } = this.props;
    const {suggestions = {}} = headerSearchReducer.data || {};
    // const autoCompleteItems = dataAutoComplete.items || {};

    const _that = this;
    
    let autoCompleteItemsElems = (suggestions.length) &&
    Object.keys(suggestions || {}).map(function (key) {
      let value = suggestions[key];
      let itemTxt = value.keyword;
      return (<div key={key} className="header-search__autocomplet-grp" id={'ac'+key}>
          <a onClick={_that.handleOptionClick.bind(null, itemTxt)} className = "header-search__autocomplet-item" > { itemTxt } </a>
        </div>);
      });
    return (
      <div className="header-search">
        <span tabIndex="0" onClick={this.toggleSearch} onKeyPress={this.toggleSearch} className="header-search__btn">
          <span className="header-search__txt-open">{static_content.searchTxt}</span>
          <span className="header-search__txt-close">{static_content.searchCloseTxt}</span>
        </span>
        <div className="header-search__panel">
          <div className="content-wrapper">
            <form onSubmit={this.handleSubmit} onReset={this.handleReset} className="header-search__wrapper" autoComplete="off">
              <div className="header-search__title">{static_content.searchTxt}</div>
              <div className="header-search__filters">
                {searchTypesElems}
              </div>
              <div className="header-search__input-holder">
                <label htmlFor="headerSearchQ"><span className="visually-hidden">{static_content.searchHints}</span></label>
                <input id="headerSearchQ" name="q" type="search" placeholder={static_content.searchHints} className="header-serach__input" onKeyDown={this.handleAutoCompleteSelection} onChange={this.handleChange.bind(this)} value={this.state.keyword} autoFocus={true} ref={c => (this._input = c)}/>
                {autoCompleteItemsElems && autoCompleteItemsElems.length ?
                  <div className="header-search__autocomplete" onMouseEnter={this.handleAutoCompleteMouseEnter} onMouseMove={this.handleAutoCompleteMouseEnter}>
                    {autoCompleteItemsElems}
                  </div> :
                  ''}         
                { this.state.charError &&
                  <span className="header-search__input-Error">{static_content.searchCharErrorTxt}</span>
                }
                <button title={static_content.search_clear_btn_txt} type="reset" className="header-search__clear-btn"></button>
                <button title={static_content.header_search_submit_btn_txt} type="submit" className="header-search__input-btn" ref={(el => this.submitBtn = el)}></button>
              </div>
              {TopsearchElem}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(HeaderSearch)