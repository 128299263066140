import React, { Component } from 'react';

import AdvantagesMainPageContainer from '../../containers/AdvantagesMainPageContainer';

import { Helmet } from "react-helmet";


export default class AdvantagesMainPage extends Component {
  
  render() {
    
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'AdvantagesMainPage' }} />    
        <AdvantagesMainPageContainer />
      </main>
    );
  }
}
