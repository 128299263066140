import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from "react-redux";
import ServicesItems from "../components/ServicesItems/";

const mapStateToProps = state => ({
	pageSourceReducer: state.pageSourceReducer,
	metaReducer: state.metaReducer,
	i18n: state.i18n,
	allowCookiesStatusReducer: state.allowCookiesStatusReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
	pure: true
})(ServicesItems);

const ComponentWithErrorBoundary = withErrorBoundary(
	ConnectedComponent,
	ErrorBoundaryMessage, // Or pass in your own fallback component
	(error, componentStack) => {
	  console.log(error, componentStack)
	}
  );
  
  export default ComponentWithErrorBoundary;