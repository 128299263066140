// awards

import React, { Component } from 'react';
import config from '../../config';
import LazyLoad from 'react-lazyload';

export default class Conformance extends Component {
  render() {
    const { footer={} } = this.props.headerFooterReducer.data[this.props.i18n.locale] || {}; // no need to dispatch when headerfooterreducer has been loaded in header section.
    const { awards_container={} } = footer || {};
    const { footerBottom = {} } = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
    const title_awards = <span className="comformance__title comformance__item">{footerBottom.title_awards}</span>

    let rewards = [];
    let rows = Math.ceil(awards_container.length / config.awards_per_row);

    for (let row = 0; row < rows; row++) {

      let rowItems = [];
      for (let key = config.awards_per_row * row; key < awards_container.length && key < config.awards_per_row * (row + 1); key++) {
        rowItems.push(
          <LazyLoad key={'awardsLazyLoad'+key} once><a target="_blank" key={'awards'+key} href={awards_container[key].award_url} className="comformance__item" title={awards_container[key].awards_image_alt_text}><img src={awards_container[key].awards_icon_image} alt={awards_container[key].awards_image_alt_text} /></a></LazyLoad>
        );
      }

      rewards.push(<div className="comformance" key={'awards'+row}>
        <div className="content-wrapper">
          {title_awards}
          {rowItems}
        </div>
      </div>)
    }
    
    return (
      <div>
        {rewards}
      </div>
    )
  }
}