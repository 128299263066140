import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import SearchPanel from '../components/SearchPanel/';

const mapStateToProps = (state, ownProps) => ({
  headerSearchReducer: state.headerSearchReducer,
  topSearchReducer: state.topSearchReducer,
	metaReducer: state.metaReducer,
  i18n: state.i18n,
  ownProps: ownProps,
  getSearchResultReducer: state.getSearchResultReducer,
  getSearchDataReducer: state.getSearchDataReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(SearchPanel);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;