import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentTypePreferredList from './ContentTypePreferredList';
import ContentTypeAdsysList from './ContentTypeAdsysList';

class FeatureListing extends Component {
    render() {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const preferredData = this.props.listData || {};
        const { searchData = {} } = this.props;

        let contentEle = []; // Sam

        if (preferredData.length>0 && searchData.data.offset==='1') {
            for (let key in preferredData) {
                let listItems = preferredData[key];
                if (listItems.companyName) {
                    // adsysList (Products)
                    contentEle.push(<ContentTypeAdsysList key={key} contentData={listItems} searchData={searchData}/>);
                } else {
                    // preferredList
                    contentEle.push(<ContentTypePreferredList key={key} contentData={listItems} searchData={searchData}/>);
                }
            }
        }

        return (
            <div className="feature-listing">
            {
                contentEle.length ?
                <div className="content-wrapper">
                    <div className="feature-listing__holder">
                        <div className="feature-listing__head">
                            <h3>{ static_content.searchPreferredListingTxt }</h3>
                        </div>
                        <div className="feature-listing__items">
                            {contentEle}
                        </div>
                    </div>
                </div>
                :
                null
            }                
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
    pure: true
})(FeatureListing);
