import React, { Component } from 'react';

import BusinessItemsContainer from '../../containers/BusinessItemsContainer';
import PageAdsContainer from '../../containers/PageAdsContainer';

import { Helmet } from "react-helmet";

export default class Business extends Component {
  render() {
    return (
      <main>                
      <Helmet bodyAttributes={{ 'data-page': 'business' }} />    
        <BusinessItemsContainer />
        <PageAdsContainer/>
      </main>
    );
  }
}
