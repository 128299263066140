import * as types from "../../constants/actionTypes";
import config from "../../config";
import axios from "axios";
import { isDateBetweenDateRange, currentDate } from "../../util/helper";
import { sprintf } from "sprintf-js";
import _ from "lodash";

function requestJumbotronEventsSlidesData() {
  return { type: types.REQ_JUMOBOEVENTS_SLIDES_DATA };
}

function receiveJumbotronEventsSlidesData(json) {
  return {
    type: types.RECV_JUMOBOEVENTS_SLIDES_DATA,
    data: json,
  };
}

function receiveJumbotronEventsSlidesError(json) {
  return {
    type: types.RECV_JUMOBOEVENTS_SLIDES_ERROR,
    data: json,
  };
}

function massageResponse(response) {
  return response.data.result.assets;
}

function filterByDate(data, date) {
  console.log("actionData:", data);
  let currentDateSlides = data.filter((v) => {
    return isDateBetweenDateRange(
      v.content.Content.setting.OnlineDate,
      v.content.Content.setting.OfflineDate,
      date
    );
  });

  let sortByBannerOrder = currentDateSlides.sort((a, b) => {
    let settingA = parseInt(a.content.Content.setting.location.slice(-1));
    let settingB = parseInt(b.content.Content.setting.location.slice(-1));
    return settingA - settingB;
  });

  let result = {};
  const languages = ["en", "tc", "sc"];
  console.log("sortByBannerOrder:", sortByBannerOrder);
  languages.forEach((element) => {
    result[element] = {};
    result[element]["jumbotron_container"] = sortByBannerOrder.reduce(
      (acc, v) => {
        const setting = v.content.Content.setting;
        console.log("setting", setting);
        if (setting.localeDisplay === undefined) {
          if (v.content.Content[element]) {
            acc.push(v.content.Content[element]);
          }
        } else if (setting.localeDisplay.length === 0) {
          if (v.content.Content[element]) {
            acc.push(v.content.Content[element]);
          }
        } else {
          const localeType = setting.localeDisplay;
          console.log("localeType:", localeType);
          const localeTypeString = localeType.toString();
          const array = localeTypeString
            .split(",")
            .map((item) => parseInt(item.trim(), 10));
          console.log("array:", array);
          const filteredLanguages = array.map((index) => languages[index]);
          console.log("filteredLanguages:", filteredLanguages);
          const isIncluded = filteredLanguages.includes(element);
          console.log("isIncluded:", isIncluded);
          if (isIncluded && v.content.Content[element]) {
            acc.push(v.content.Content[element]);
          }
        }
        return acc;
      },
      []
    );
  });

  console.log("result:", result);
  return result;
}

export function fetchJumbotronEventsSlidesData() {
  let cDate = currentDate();
  let currentMonth =
    cDate.getFullYear() + (cDate.getMonth() + 1).toString().padStart(2, "0");
  return function (dispatch) {
    dispatch(requestJumbotronEventsSlidesData());
    return axios({
      url: sprintf(config.json_main_jumbotron_eventSlides, currentMonth),
      timeout: 80000,
      method: "get",
      responseType: "json",
    })
      .then(function (response) {
        console.log({ response });
        let responseData = massageResponse(response);
        let filteredData = filterByDate(responseData, cDate);
        return filteredData;
      })
      .then(function (data) {
        dispatch(receiveJumbotronEventsSlidesData(data));
      })
      .catch(function (error) {
        console.log("error msg:", error);
        dispatch(receiveJumbotronEventsSlidesError(error));

        //dispatch(pushState(null,'/error'));
      });
  };
}
