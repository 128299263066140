import React, { Component } from 'react';

import WebcastPagesContainer from '../../containers/WebcastPagesContainer';

import { Helmet } from "react-helmet";


export default class WebcastPages extends Component {
  
  render() {
    
    return (
      <main>
      <Helmet bodyAttributes={{ 'data-page': 'WebcastPages' }} />    
        <WebcastPagesContainer />
      </main>
    );
  }
}
