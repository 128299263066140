import React, { Component } from "react";
import store from "../../store/";
import MetaHelmet from "../PageItem/MetaHelmet";
import PageIntroContainer from "../../containers/PageIntroContainer";
import NoMatch from "../Layout/NoMatch";
import { fetchAdvantagesMainPageData } from "../../actions/";
import SanitizedHTML from "react-sanitized-html";
import config from "../../config";
import Loading from "../Loading/Loading";
import _ from "lodash";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import "./hkAdvantages.css";

export default class AdvantagesMainPage extends Component {
  constructor(props) {
    super(props);
    let metaReady = false;
    store.dispatch(fetchAdvantagesMainPageData());
    this.state = {
      metaReady: metaReady,
      isActive: [true, false, false, false, false],
      isDesktopActive: [true, false, false, false, false],
    };
  }

  composeTealiumObj() {
    const advantagesMainPageData = this.props.advantagesMainPageData.data || {};
    const { seoData = {} } =
      advantagesMainPageData[this.props.i18n.locale] || {};
    const tealiumObj = {
      site_subsection: config.tealium.hkAdvantagePage.subsection,
      page_type: config.tealium.hkAdvantagePage.pageType,
      page_name: seoData.title,
    };
    //console.log(JSON.stringify(tealiumObj, null, 2));
    return tealiumObj;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.allowCookiesStatusReducer.allowCookiesStatus === true &&
      this.props.allowCookiesStatusReducer.allowCookiesStatus !==
        prevProps.allowCookiesStatusReducer.allowCookiesStatus
    ) {
      setTimeout(() => {
        if (window.utag_data) {
          console.log("Tealium Advantages Main Page load Sent:", new Date());
          window.utag_data = this.composeTealiumObj();
          window.enableTealium();
        }
      }, 1000);
    }

    if (this.props.match.params.pagename !== prevProps.match.params.pagename) {
      // handling: switch between static pages only
      this.setState(
        {
          metaReady: false,
        },
        () => {
          store.dispatch(fetchAdvantagesMainPageData());
          if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
            setTimeout(() => {
              if (window.utag) {
                console.log(
                  "Tealium Advantages Main Page Internal Link 2 Sent:",
                  new Date()
                );
                window.utag.view(this.composeTealiumObj());
              }
            }, 1000);
          }
        }
      );
    }
  }

  componentDidMount() {
    // Send data to WebTrends server on didMount if this is not landing page
    // if (this.state.metaReady) {
    //   console.log('Static Pages WT sent on DidMount Internal Link: ', new Date());
    //   window.sendWebTrendsData();
    // }
    if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
      if (window.utag) {
        console.log(
          "Tealium Advantages Main Page Internal Link Sent:",
          new Date()
        );
        window.utag.view(this.composeTealiumObj());
      }
    }
    //setState of section2 isActive array
    setTimeout(() => {
      const advantagesMainPageData =
        this.props.advantagesMainPageData.data || {};
      const { section2 = {} } =
        advantagesMainPageData[this.props.i18n.locale] || {};
      const length = section2.table && section2.table.length;
      const newArray = Array.from({ length }).map((_, index) => index === 0);
      this.setState({
        isActive: newArray,
        isDesktopActive: newArray,
      });
    }, 1000);
  }

  handleClick = (index) => {
    this.setState((prevState) => {
      const isActive = prevState.isActive.map((item, i) => {
        if (i === index) {
          return !item;
        } else if (item) {
          return false;
        }
        return item;
      });
      const allFalse = isActive.every((item) => item === false);
      const initialArray = [true, false, false, false, false];
      const destopArray = allFalse ? initialArray : isActive;
      return { isActive, isDesktopActive: destopArray };
    });
  };

  handleDestopClick = (index) => {
    this.setState((prevState) => {
      const isDesktopActive = prevState.isDesktopActive.map((item, i) => {
        return i === index;
      });
      return { isDesktopActive, isActive: isDesktopActive };
    });
  };

  generateInlineStyles = ({ index, totalItems, image }) => {
    const backgroundPosition = `${(index / totalItems) * 100}% 0`;

    const styles = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition,
      backgroundImage: `url(${image})`,
    };

    return styles;
  };

  render() {
    const advantagesMainPageData = this.props.advantagesMainPageData.data || {};
    const routePathTo = config.subDirectoryPath;
    const pageLocale = this.props.match.params.locale;
    const {
      section1 = {},
      section2 = {},
      section3 = {},
      section4 = {},
      seoData = {},
      displayTime = {},
    } = advantagesMainPageData[this.props.i18n.locale] || {};
    const currentTime = moment();
    const { isActive, isDesktopActive } = this.state;

    console.log({
      advantagesMainPageData,
      seoData,
      displayTime,
      currentTime,
      section1,
      section2,
      section3,
      section4,
      isActive,
    });

    if (_.isEmpty(advantagesMainPageData)) {
      return <Loading />;
    }

    //onlineTime and offlineTime checking for each locale in new version or whole DCR in old version
    if (
      (!_.isEmpty(displayTime.onlineTime) &&
        !_.isEmpty(displayTime.offlineTime)) ||
      (!_.isEmpty(advantagesMainPageData.onlineTime) &&
        !_.isEmpty(advantagesMainPageData.offlineTime))
    ) {
      const onlineTime = displayTime.onlineTime
        ? moment(displayTime.onlineTime)
        : moment(advantagesMainPageData.onlineTime);
      const offlineTime = displayTime.offlineTime
        ? moment(displayTime.offlineTime)
        : moment(advantagesMainPageData.offlineTime);
      const isWithinRange =
        currentTime.isSameOrAfter(onlineTime) &&
        currentTime.isSameOrBefore(offlineTime);

      if (!isWithinRange) {
        if (pageLocale !== "en") {
          return (
            <>
              {pageLocale == "tc" && <Redirect to={`${routePathTo}/tc/`} />}
              {pageLocale == "sc" && <Redirect to={`${routePathTo}/sc/`} />}
            </>
          );
        } else {
          return <NoMatch />;
        }
      }
    }

    const Body1 = ({}) => {
      return (
        <>
          <div className="adv_body1">
            <div className="body1_bgDiv">
              <img
                src={section1.image}
                alt={section1.sub_headline}
                className="body1_bgpic"
              />
              <div className="body1_bgpic_div"></div>
            </div>
          </div>
          <div className="adv_body1a">
            <div className="body1_bgpic_maxWidth">
              <div className="body1_box">
                <div className="body1_box_title">{section1.sub_headline}</div>
                <div className="body1_box_desc">{section1.description}</div>
              </div>
            </div>
          </div>
        </>
      );
    };

    const Body2 = ({}) => {
      return (
        <>
          <div className="adv_body2 desktop">
            <div className="body2_maxWidth">
              <div className="body2_box">
                <div className="body2_left">
                  {section2.table &&
                    section2.table.length > 0 &&
                    section2.table.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => this.handleDestopClick(index)}
                        >
                          <div
                            className={
                              isDesktopActive[index]
                                ? "body2_left_item active"
                                : "body2_left_item"
                            }
                          >
                            <div className="body2_left_item_title">
                              <span>{item.item}</span>
                            </div>
                            <div className="body2_img">
                              <img
                                src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/icon-black-arrow.svg"
                                className="icon-black"
                              />
                              <img
                                src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/icon-blue-arrow.svg"
                                className="icon-blue"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="body2_right">
                  {section2.table &&
                    section2.table.length > 0 &&
                    section2.table.map((item, index) => {
                      return (
                        <div key={index}>
                          <SanitizedHTML
                            className={
                              isDesktopActive[index]
                                ? "body2_right_item active"
                                : "body2_right_item"
                            }
                            allowedAttributes={config.allowedAttributes}
                            allowedTags={config.allowedTags}
                            html={item.item_description}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="adv_body2 mobile">
            <div className="body2_maxWidth">
              <div className="body2_box">
                {section2.table &&
                  section2.table.length > 0 &&
                  section2.table.map((item, index) => {
                    return (
                      <div key={index} onClick={() => this.handleClick(index)}>
                        <div
                          className={
                            isActive[index]
                              ? "body2_left_item active"
                              : "body2_left_item"
                          }
                        >
                          <div className="body2_left_item_title">
                            <span>{item.item}</span>
                          </div>
                          <div className="body2_img">
                            <img
                              src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/icon-blue-arrow.svg"
                              className="icon-blue"
                            />
                            <img
                              src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/icon-black-arrow.svg"
                              className="icon-black"
                            />
                          </div>
                        </div>
                        <SanitizedHTML
                          className={
                            isActive[index]
                              ? "body2_right_item active"
                              : "body2_right_item"
                          }
                          allowedAttributes={config.allowedAttributes}
                          allowedTags={config.allowedTags}
                          html={item.item_description}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      );
    };

    const Body3 = ({}) => {
      return (
        <>
          <div className="adv_body3">
            <div className="body3_maxWidth">
              <div className="body3_title">
                <span>{section3.headline}</span>
              </div>
              <div>
                <p className="adv_body3_desc">{section3.description}</p>
              </div>
              <div className="body3_box">
                {section3.table &&
                  section3.table.length > 0 &&
                  section3.table.map((item, index) => {
                    return (
                      <div className="body3_item" key={index}>
                        <div className="body3_img">
                          <a
                            href={item.item_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={item.image} alt={item.item} />
                          </a>
                        </div>
                        <div className="body3_item_title">
                          <a
                            href={item.item_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>{item.item}</span>
                          </a>
                        </div>
                        <div className="body3_item_desc">
                          <span>{item.description}</span>
                        </div>
                        <div className="arrow">
                          <a
                            href={item.item_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://home.hktdc.com/resources/images/Portal-Home/Jump-Pages/Static-Pages/363pyofm-8f9o0k0h-icon-long-arrow.svg"
                              alt="arrow"
                            />
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      );
    };

    const Body4 = ({}) => {
      const totalItems = section4.table && section4.table.length;
      const image = section4.image;
      return (
        <>
          <div className="adv_body4">
            <div className="adv_body4_title">
              <span>{section4.headline}</span>
            </div>
            <div className="body4_content">
              <div className="body4_maxWidth">
                {section4.table &&
                  section4.table.length > 0 &&
                  section4.table.map((item, index) => {
                    return (
                      <div className="body4_item" key={index}>
                        <div
                          className="body4_bg_item"
                          style={this.generateInlineStyles({
                            index,
                            totalItems,
                            image,
                          })}
                        ></div>
                        <div className="body4_item_bg2"></div>
                        <div className="body4_item_descBox">
                          <SanitizedHTML
                            className="body4_item_title"
                            allowedAttributes={config.allowedAttributes}
                            allowedTags={config.allowedTags}
                            html={item.item}
                          />
                          <SanitizedHTML
                            className="body4_item_alinkBox"
                            allowedAttributes={config.allowedAttributes}
                            allowedTags={config.allowedTags}
                            html={item.item_description}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="body5_content">
                <a
                  href={section4.btn1_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{section4.btn1}</span>
                </a>
                <a
                  href={section4.btn2_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{section4.btn2}</span>
                </a>
              </div>
              <div className="adv_body5"></div>
            </div>
          </div>
        </>
      );
    };

    return (
      <div>
        {section1.headline ? (
          <main>
            <PageIntroContainer
              title={section1.headline}
              pageHead={seoData.h1 || ""}
            />
            <MetaHelmet metaData={seoData} locale={this.props.i18n.locale} />
            <div>
              <div>
                <section>
                  <Body1 />
                  <Body2 />
                  <Body3 />
                  <Body4 />
                </section>
              </div>
            </div>
          </main>
        ) : (
          this.props.advantagesMainPageData.error && <NoMatch />
        )}
      </div>
    );
  }
}
