import React, { Component } from 'react';

export default class PageItemSlider extends Component {
	constructor(props) {
    super(props);
    this.state = {
			sliderInit: false,
			mousePause: false
    };
	}
	
	togglePause = () =>{
    this.setState({mousePause: !this.state.mousePause});
	}

	initSwiper(_this) {
		let itemSlider = document.getElementById("item-inner-slider");		
		if (itemSlider && itemSlider.swiper) {
			itemSlider.swiper.destroy(false, false);
		}
		let autoplayTime = 5000;
		let swiper = new window.Swiper(itemSlider, {
			spaceBetween: 40,
			slidesPerView: 2,
			slidesPerGroup: 2,
			loop: true,
			// effect: "fade",
			simulateTouch: true,
			speed: 800,
			autoplay: {
				delay: autoplayTime,
				disableOnInteraction: false
			},
			navigation: {
				prevEl: ".item-slider__btn--prev",
				nextEl: ".item-slider__btn--next",
			},
			pagination: {
				el: ".item-slider__pagination",
				type: "bullets",
				clickable: true,
			},
			fadeEffect: {
				crossFade: true
			},
			breakpoints: {
				768: {
					slidesPerView: 1,
					slidesPerGroup: 1,
					spaceBetween: 20,
				}
			},
			// Disable preloading of all images
			preloadImages: false,
			// Enable lazy loading
			lazy: {
				preloaderClass: 'mf'
			}
		});
		swiper.on("init", function () {
			swiper.update();
		})
  }

  componentDidUpdate() {
		let _this = this;
		let _thisInitSwiper = this.initSwiper;
		if(!_this.state.sliderInit){
			_thisInitSwiper(_this);	
      _this.setState({
        sliderInit: true
      });
		}
		if(document.getElementById("item-inner-slider").swiper.activeIndex === 0){
			_thisInitSwiper(_this);	
		}
		if(typeof document.getElementById("item-inner-slider").swiper !== "undefined"){
      if(_this.state.mousePause){
        document.getElementById("item-inner-slider").swiper.autoplay.stop();
      }else{
        document.getElementById("item-inner-slider").swiper.autoplay.start();
      }
    }
	}

  render() {
    let itemSlidersArr = this.props.sliderObj || {};
    const static_content = this.props.i18n.translations[this.props.i18n.locale] || {};
    
    let item_slider = Object.keys(itemSlidersArr || {}).map(function (sliderKey) {
			let sliderVal = itemSlidersArr[sliderKey];
			let sliderImg = sliderVal.image;
			let sliderAlt = sliderVal.image_alt_text;
			let sliderContentColor = sliderVal.sliderContentColor || "white";        
			let sliderTitle = sliderVal.book_title;
			let sliderDesc = sliderVal.book_description;
			let sliderLink= sliderVal.read_more_url;
			let sliderLinkTxt = static_content.btnServicesReadMore;
			return (
				<div key={sliderKey} className="item-slider-container swiper-slide">
					<div className="item-slider__img"><a href={sliderLink} target="_blank"><img src={sliderImg} alt={sliderAlt} title={sliderAlt} /></a></div>
					<div className="item-slider-text">
						<a className={"item-slider__title item-slider__title--"+sliderContentColor} href={sliderLink} target="_blank">{sliderTitle}</a>
					</div>
				</div>
			);
		});

    return (
      <div className="item-sliders item-sliders--product-mags">
        <div id="item-inner-slider" className="item-sliders-container item-has-slider">
          <div className="swiper-wrapper">
            {item_slider}
          </div>
          <div tabIndex="0" className={this.state.mousePause ? 'item-sliders__slider-pause is-active' : 'item-sliders__slider-pause'} onClick={this.togglePause} onKeyPress={this.togglePause}>
						<div className="pause-button"><span className="visually-hidden">{static_content.pause_btn}</span></div>
          </div>
					<button title={static_content.slider_button_prev} tabIndex="0" onClick={e => { e.preventDefault();}} className="item-slider__btn item-slider__btn--prev"><span className="visually-hidden">{static_content.slider_button_prev}</span></button>
					<button title={static_content.slider_button_next} tabIndex="0" onClick={e => { e.preventDefault();}} className="item-slider__btn item-slider__btn--next"><span className="visually-hidden">{static_content.slider_button_next}</span></button> 
          <div className="item-slider__pagination"></div>
        </div>
      </div>
    );
  }
}