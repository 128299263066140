import React, { Component } from 'react';
import { fetchHelpData } from '../../actions/';
import store from '../../store/';
import SideTabsItem from './SideTabsItem';
import ScrollAnimation from 'react-animate-on-scroll';
import config from '../../config';

export default class HowCanWeHelp extends Component {
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        store.dispatch(fetchHelpData()); // Teamsite CMS
    }

    updateDimensions() {
        var contentEl = document.querySelectorAll(".side-tabs__item.is-active .side-tabs__content");
        if (contentEl.length) {
            var contentElHeight = contentEl[0].clientHeight;

            var tarEl = document.querySelectorAll(".side-tabs__items");
            var tabEl = document.querySelectorAll(".side-tabs__tab");
            var tabHeight = 0;

            tarEl[0].style.height = 'auto';

            var i = 0;
            for (i = 0; i < tabEl.length; ++i) {
                tabHeight += tabEl[i].clientHeight;
            }
            tabHeight += 60;
            //console.log('tabHeight'+tabHeight);
            var tarHeight = contentElHeight;
            if (tarHeight < tabHeight) {
                tarHeight = tabHeight;
            }
            tarEl[0].style.height = tarHeight + 'px';
        }
    }
    setFirstItem() {
        var windowJsWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (windowJsWidth > 991) {
            var firstItem = document.getElementsByClassName('side-tabs__item')[0];
            if (firstItem) {
                var activeItem = document.querySelectorAll(".side-tabs__item.is-active");
                if (!activeItem.length) {
                    firstItem.classList.add("is-active");
                    this.updateDimensions();

                    window.removeEventListener("resize", this.setFirstItem.bind(this));
                }
            }
        }
    }
    componentDidUpdate() {
        this.setFirstItem();
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener("resize", this.setFirstItem.bind(this));
        this.setFirstItem();
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("resize", this.setFirstItem.bind(this));
        this.updateDimensions = null;
    }

    render() {

        const {help_container} = this.props.cmsData.data[this.props.i18n.locale] || {};

        const staticContent = this.props.i18n.translations[this.props.i18n.locale] || {};



        return (
            <section data-webtrend-group={config.WTTealiumGroupName.faq} className="side-tabs">
                <ScrollAnimation className="content-wrapper" animateIn="fadeIn" animateOnce>
                    <div className="side-title">{staticContent.title_help}</div>
                    <h2 className="side-tabs__title">{staticContent.title_help}</h2>
                    <div className="side-tabs__items">
                        {Object.keys(help_container || {}).map(function (key) {
                            let value = help_container[key];
                            let title = value.sub_header;
                            let qnaitems = value.qa_container;
                            let itemNo = value.qa_container.length;
                            return (
                                <SideTabsItem key={key} title={title} itemkey={key} qnaitems={qnaitems} itemNo={itemNo} />
                            );
                        })}
                    </div>
                </ScrollAnimation>
            </section>
        );
    }
}
