import React, { Component } from 'react';
import ContentTypeGeneral from './ContentTypeGeneral';
import ContentTypeArticle from './ContentTypeArticle';
import ContentTypeCompany from './ContentTypeCompany';
import ContentTypeProdcat from './ContentTypeProdcat';
import ContentTypeEventSch from './ContentTypeEventSch';
import { connect } from 'react-redux';
import config from '../../config';
import * as h from '../../util/helper';
import { searchEventAction } from '../../util/clickEvents';

var sprintf = require("sprintf-js").sprintf;

class OrganicListing extends Component {
    render() {
        const static_content = this.props.i18n.translations[this.props.i18n.locale] || {}; // local content
        const items = this.props.listData || {};
        const hasProduct = this.props.hasProduct || false;
        const searchData = this.props.searchData || {};

        let contentEle = []; // SAM
        if (items.length) {
            Object.keys(items || {}).map(function (key) {
                let value = items[key];
                const ct = config.search_contentType;

                switch (value.contentType) {
                    case ct.eventsch:
                    contentEle.push(<ContentTypeEventSch key={key} contentData={value} q={searchData.data.q} debugMode={searchData.debugMode}/>);
                    break;
                    case ct.prodcat:
                    contentEle.push(<ContentTypeProdcat key={key} contentData={value} q={searchData.data.q} debugMode={searchData.debugMode}/>);
                    break;
                    case ct.company:
                    contentEle.push(<ContentTypeCompany key={key} contentData={value} q={searchData.data.q} debugMode={searchData.debugMode}/>);
                    break;
                    case ct.general:
                    contentEle.push(<ContentTypeGeneral key={key} contentData={value} q={searchData.data.q} debugMode={searchData.debugMode}/>);
                    break;
                    case ct.success:
                    case ct.article:
                    case ct.eventinfo:
                    default:
                    contentEle.push(<ContentTypeArticle key={key} contentData={value} q={searchData.data.q} debugMode={searchData.debugMode}/>);
                }
                return ('')
            });
        }

        const sourceJumpURL = hasProduct && sprintf(config.sourcejump_searchurl, config.sourcejumpsearch_lang_map[this.props.i18n.locale || 'en'], 'Product-Search', h.filterThenEncode(searchData.data.q));
        return (
            <div className="organic-listing">
                <div className="content-wrapper">
                {
                    contentEle.length ? 
                    <div className="organic-listing__holder">
                        <div className="organic-listing__items">
                            {contentEle}
                            {hasProduct && (searchData.type==='all' || searchData.type === 'suppliers') ?
                            <div className="organic-listing__more">
                                {(static_content.searchResult.txt_view_more_result_suppliers).replace('%KEYWORD%', searchData.data.q)}
                                <a href={sourceJumpURL} className="organic-listing__more-link" target="_blank" onClick={()=>searchEventAction('searchResult_displayViewMoreClicked', this.props.i18n.locale, searchData.data.q, searchData.type, this.props.total)}> </a>
                            </div>
                            : null
                            }
                            
                        </div>
                    </div>
                    : null
                }
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    i18n: state.i18n
});

export default connect(mapStateToProps, null, null, {
    pure: true
})(OrganicListing);
