import { withErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { connect } from 'react-redux';
import CommonHeaderLinks from '../components/Header/CommonHeaderLinks';

const mapStateToProps = state => ({
  i18n: state.i18n,
  ssoReadyStatusReducer: state.ssoReadyStatusReducer
});

const ConnectedComponent = connect(mapStateToProps, null, null, {
  pure: true
})(CommonHeaderLinks);

const ComponentWithErrorBoundary = withErrorBoundary(
  ConnectedComponent,
  ErrorBoundaryMessage, // Or pass in your own fallback component
  (error, componentStack) => {
    console.log(error, componentStack)
  }
);

export default ComponentWithErrorBoundary;