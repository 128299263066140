import React, { Component } from "react";
import _ from "lodash";
import store from "../../store";
import MetaHelmet from "../PageItem/MetaHelmet";
import PageIntroContainer from "../../containers/PageIntroContainer";
import NoMatch from "../Layout/NoMatch";
import { fetchPodcastPagesData } from "../../actions";
import config from "../../config";
import moment from "moment-timezone";
import Link from "../../util/Link";

import { momentUTCDate, currentDate, forceToArray } from "../../util/helper";

export default class PodcastPages extends Component {
  constructor(props) {
    super(props);
    let metaReady = false;
    store.dispatch(fetchPodcastPagesData(props.match.params.pagename || ""));
    
    this.state = {
      metaReady: metaReady,
      selectedVideoLang: null
    };

    this.pageMeta = {
      title: "",
      type: "",
      description: "",
      keywords: "",
    };

    this.streamLangList = [];
    this.streamDisplay = {};
    this.shownVideo = null;
    this.currentVideoLang = null;
  }

  composeTealiumObj() {
    const { data = {} } = this.props.podcastPagesData || {};
    const { metadata = {} } = data;
    const pageData = data[this.props.i18n.locale] || {};
    const tealiumObj = {
      site_subsection: config.tealium.podcastPages.subsection,
      page_type: config.tealium.podcastPages.pageType,
      page_name: `Podcast - ${pageData.content_title}`,
      article_id: metadata.id,
      article_title: pageData.content_title
    };
    return tealiumObj;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.allowCookiesStatusReducer.allowCookiesStatus === true &&
      this.props.podcastPagesData.isLoading !== prevProps.podcastPagesData.isLoading &&
      this.props.podcastPagesData.isLoading === false &&
      !this.props.podcastPagesData.error
    ) {
      setTimeout(() => {
        if (window.utag_data) {
          console.log("Tealium Podcast Pages load Sent:", new Date());
          window.utag_data = this.composeTealiumObj();
          window.enableTealium();
      
        }
      }, 1000);
    }

    if (this.props.match.params.pagename !== prevProps.match.params.pagename) {
      // handling: switch between static pages only
      this.setState(
        {
          metaReady: false,
        },
        () => {
          store.dispatch(
            fetchPodcastPagesData(this.props.match.params.pagename || "")
          );
          if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
            setTimeout(() => {
              if (window.utag) {
                console.log(
                  "Tealium Podcast Pages Internal Link 2 Sent:",
                  new Date()
                );
                window.utag.view(this.composeTealiumObj());
              }
            }, 1000);
          }
        }
      );
    }
  }

  componentDidMount() {
    // Send data to WebTrends server on didMount if this is not landing page
    // if (this.state.metaReady) {
    //   console.log(
    //     "Podcast Pages WT sent on DidMount Internal Link: ",
    //     new Date()
    //   );
    //   window.sendWebTrendsData();
    // }
    if (this.props.allowCookiesStatusReducer.allowCookiesStatus) {
      if (window.utag) {
        console.log("Tealium Podcast Pages Internal Link Sent:", new Date());
        window.utag.view(this.composeTealiumObj());
      }
    }
  }

  // sendWebTrend = (l) => {
  //   if (l > 0 && !this.state.metaReady) {
  //     // ensure only send once
  //     this.setState(
  //       {
  //         metaReady: true,
  //       },
  //       () => {
  //         setTimeout(() => {
  //           console.log(
  //             "Podcast Pages WT sent in render page load: ",
  //             new Date()
  //           );
  //           window.sendWebTrendsData();
  //         }, 500);
  //       }
  //     );
  //   }
  // };

  toggleVideoLang(lang) {
    this.setState({
      selectedVideoLang: lang
    });
  }

  render() {
    const locale = this.props.i18n.locale;
    const static_content =
      this.props.i18n.translations[locale] || {}; // local content
    const { isLoading, data = {}, error } = this.props.podcastPagesData || {};
    const { metadata = {} } = data;
    const pageData = data[locale] || {};
    const indexPhotoInfo = pageData.index_photo_sect || {};

    if (!_.isEmpty(pageData.stream_info_sect)) {
      const streamInfo = forceToArray(pageData.stream_info_sect) || [];

      // Filter list of languages of videos available
      this.streamLangList = streamInfo.reduce((acum, obj) => {
        const langIndex = acum.findIndex(lang => lang === obj.media_lang);
        if (langIndex === -1) {
          acum.push(obj.media_lang);
        }
        return acum;
      }, []);

      const defaultLang = this.streamLangList.includes(locale) ? locale : this.streamLangList[0];
      this.currentVideoLang = this.state.selectedVideoLang || defaultLang;

      // Shortlist video by language
      for (const lang of this.streamLangList) {
        const videoListByLang = streamInfo.filter(obj => obj.media_lang === lang);
        let defaultVideoIndex = videoListByLang.findIndex(obj => obj.default_podcast === "Y");
        if (defaultVideoIndex === -1) defaultVideoIndex = 0;

        const otherVideoList = videoListByLang
          .filter((obj, i) => i !== defaultVideoIndex)
          .map(obj => obj.stream_url_bb);

        this.streamDisplay[lang] = {
          defaultVideoUrl: videoListByLang[defaultVideoIndex].stream_url_bb,
          otherVideoUrlList: otherVideoList
        };
      }

      // Determine which video to be shown on screen
      this.shownVideo = this.streamDisplay[this.currentVideoLang];
    }

    this.pageMeta = {
      title: pageData.content_title,
      type: "website",
      description: pageData.abstract,
      keywords: pageData.keyword,
    };

    const checkOnline = (minDateString, maxDateString, currentDateString) => {
      let compareDate = moment(currentDateString).toDate();
      return (
        (!minDateString || compareDate >= momentUTCDate(minDateString)) &&
        (!maxDateString || compareDate <= momentUTCDate(maxDateString))
      );
    };

    const isOnline = checkOnline(
      pageData.online_time,
      pageData.offline_time,
      currentDate()
    );

    const isAvailable = (metadata.availableLanguages || "")
      .split(",")
      .map((v) => v.trim())
      .includes(locale);

    const industry = (
      metadata[
      `industry.taxonomyForm.${locale}.industryType`
      ] || ""
    )
      .split(",")
      .map((v) => v.trim())
      .filter(function (x, i, a) {
        return a.indexOf(x) == i;
      })
      .join(", ");

    const region = (
      metadata[`location.taxonomyForm.${locale}.region`] || ""
    )
      .split(",")
      .map((v) => v.trim())
      .filter(function (x, i, a) {
        return a.indexOf(x) == i;
      })
      .join(", ");

    const keyword = (pageData.keyword || "")
      .split(",")
      .map((v) => v.trim())
      .filter(function (x, i, a) {
        return a.indexOf(x) == i;
      })
      .join(", ");

    const isSelectedLang = (lang) => {
      return (lang === this.currentVideoLang);
    };

    const detailsList = [
      {
        label: static_content.podcast.industry,
        data: industry || null,
      },
      {
        label: static_content.podcast.region,
        data: region || null,
      },
      {
        label: static_content.podcast.keyword,
        data: keyword || null,
      },
    ];

    const videoEle = this.shownVideo && (
      <p className="align--hc">
        <video
          className="page-multimedia__video"
          src={this.shownVideo && this.shownVideo.defaultVideoUrl}
          controls="controls"
          autoPlay="autoplay"
          poster={indexPhotoInfo.path}
        ></video>
      </p>
    );

    const videoLangMenuEle = !_.isEmpty(this.streamLangList) ? (
      <div className="page-multimedia__video_lang_button_container">
        <div className="page-multimedia__icon--video-play"></div>
        <div className="page-multimedia__video_lang_button_bar">
          {
            this.streamLangList.map((lang, i) => (
              <button
                key={i}
                className={`${isSelectedLang(lang) ? "page-multimedia__disabled_button" : "page-multimedia__active_button"}`}
                disabled={isSelectedLang(lang)}
                onClick={() => { this.toggleVideoLang(lang); }}
              >
                {static_content.streamingLang[lang]}
              </button>
            ))
          }
        </div>
      </div>
    ) : null;

    const dateEle = (
      <p className="sec-intro__detail-desc">
        {pageData.publish_date &&
          moment(pageData.publish_date, "YYYY-MM-DD hh:mm:ss")
            .locale(static_content.lang[locale].locale)
            .format("LL")}
      </p>
    );

    const speakerEle = pageData.speaker && (
      <p className="page-multimedia__speaker">
        {static_content.podcast.speaker}: {pageData.speaker}
      </p>
    );

    const abstractEle = pageData.abstract && (
      <p className="sec-intro__detail-desc">{pageData.abstract}</p>
    );

    const relatedLinksEle = pageData.podcast_related_links && (
      <p className="sec-intro__detail-desc">
        <strong>{static_content.podcast.relatedLinks}:</strong> <br />
        {forceToArray(pageData.podcast_related_links).map((v, i) => (
          <li key={i}>
            <a href={v.link_url} key={i}>
              {v.link_title}
            </a>
          </li>
        ))}
      </p>
    );

    const relatedProgramsEle = pageData.podcast_related_programs && (
      <p className="sec-intro__detail-desc">
        <strong>{static_content.podcast.relatedPrograms}:</strong> <br />
        {forceToArray(pageData.podcast_related_programs).map((v, i) => (
          <li key={i}>
            <Link to={`podcast/${v.podcast_fileName}`}>
              {v.podcast_title
                ? v.podcast_title
                : `#${i + 1} ${static_content.podcast.relatedPrograms}`}
            </Link>
          </li>
        ))}
      </p>
    );

    const detailsListEle = (
      <>
        <table
          className="page-multimedia__table"
          cellSpacing="0"
          cellPadding="0"
        >
          <tbody>
            {forceToArray(detailsList).map((detail, i) =>
              detail.data ? (
                <tr key={i}>
                  <td>
                    <strong>{detail.label}:</strong>
                  </td>
                  <td>{detail.data}</td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
        <br />
      </>
    );

    return (
      <div>
        {!_.isEmpty(pageData) && isAvailable && isOnline && !error ? (
          <main>
            <PageIntroContainer
              title={pageData.content_title}
              description={pageData.abstract}
              pageHead={pageData.content_title || ""}
            />
            <MetaHelmet metaData={this.pageMeta} locale={locale} />

            <div>
              <section className="sec-intro__detail">
                <div className="sec-intro__detail-wrapper">
                  {videoEle}
                  {videoLangMenuEle}
                  {dateEle}
                  {speakerEle}
                  {abstractEle}
                  {detailsListEle}
                  {relatedLinksEle}
                  {relatedProgramsEle}
                </div>
              </section>
            </div>
          </main>
        ) : (
          !isLoading && <NoMatch />
        )}
      </div>
    );
  }
}
